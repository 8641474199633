import { breakpointMap } from "common/breakpoint.info";
import {
  CENTS_PER_USD,
  CURRENCY_DECIMAL,
  CURRENCY_SIGN,
  PERCENT_DECIMAL,
  SHARE_PER_CONTRACT,
} from "common/common";
import { STANDARD_DATE_FORMAT } from "common/locale/dateLocale";
import { THOUSAND_SEPARTOR } from "common/locale/numberLocale";
import { internalErrorMessage } from "common/standardMessage";
import dayjs from "dayjs";

export const clampNumber = (
  num: number,
  upLimit: number,
  lowLimit: number
): number => Math.max(Math.min(num, upLimit), lowLimit);

export const getPhoneNumberFromString = (phoneNumber: string): string => {
  const regex = /[^0-9.]/g;
  return phoneNumber.replace(regex, "");
};

export const getBaseStringFromFormattedString = (
  text: string,
  separatorList: string[]
) => {
  const regex = new RegExp(`[${separatorList.join("|")}]`, "g");
  return (text || "").replaceAll(regex, "");
};

export const getMoneyFromCurrencyText = (text: string): number =>
  parseFloat(
    getBaseStringFromFormattedString(text, [CURRENCY_SIGN, THOUSAND_SEPARTOR])
  );

export const getValueFromCurrencyOrPercentText = (text: string): number =>
  parseFloat(
    getBaseStringFromFormattedString(text, [
      CURRENCY_SIGN,
      THOUSAND_SEPARTOR,
      "%",
    ])
  );

/** 999 => $9.99 */
export const getMoneyDisplayFromCents = (cents: number | undefined): string => {
  if (!cents) {
    return "$0";
  }

  return formatCurrency(cents / CENTS_PER_USD);
};

export const calculateStockCost = (price: number, quantity: number) =>
  +((price || 0) * quantity).toFixed(CURRENCY_DECIMAL);

export const calculateOptionCost = (price: number, contract: number) =>
  +(price * contract * SHARE_PER_CONTRACT).toFixed(CURRENCY_DECIMAL);

export const capitalizeFirstLetter = (text: string): string =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const formatDate = (date: string, format: string) =>
  dayjs(date).format(format);

export const formatCurrency = (money: number) =>
  `${money < 0 ? "-" : ""}${CURRENCY_SIGN}${Math.abs(money).toLocaleString(
    undefined,
    {
      minimumFractionDigits: CURRENCY_DECIMAL,
      maximumFractionDigits: CURRENCY_DECIMAL,
    }
  )}`;

export const formatPercent = (percent: number, mul100 = false) =>
  `${(mul100 ? percent * 100 : percent).toFixed(PERCENT_DECIMAL)}%`;

export const getMediaBreakpoint = (breakpoint: keyof typeof breakpointMap) =>
  `(min-width: ${breakpointMap[breakpoint]}px)`;

export const addDelimiterToDateString = (
  rawDate: string,
  format: string,
  delimiter: string
): string => {
  const splitFormat = format.split(delimiter);
  let formattedDate = "";
  let startPos = 0;
  splitFormat.forEach((curFormat) => {
    formattedDate +=
      rawDate.slice(startPos, startPos + curFormat.length) + delimiter;

    startPos += curFormat.length;
  });

  return formattedDate.slice(0, -1);
};

export const limitDate = (
  date: string,
  format: string,
  delimiter: string
): string => {
  const formatSplit = format.split(delimiter);
  const dateSplit = date.split(delimiter);

  if (formatSplit.length !== dateSplit.length) {
    throw new Error(internalErrorMessage.wrongDateFormat);
  }

  let parsedDate = "";
  formatSplit.forEach((curFormat, index) => {
    if (curFormat === "YYYY" || curFormat === "YY") {
      parsedDate += dateSplit[index] + delimiter;
    } else {
      let upLim = 99;
      let lowLim = 0;
      switch (curFormat) {
        case "YY":
        case "YYYY":
          upLim = 9999;
          break;
        case "M":
        case "MM":
          lowLim = 1;
          upLim = 12;
          break;
        case "D":
        case "DD":
          lowLim = 1;
          upLim = 31;
          break;
        case "d":
          lowLim = 0;
          upLim = 6;
          break;
        case "H":
        case "HH":
          upLim = 23;
          break;
        case "h":
        case "hh":
          lowLim = 1;
          upLim = 12;
          break;
        case "m":
        case "mm":
        case "s":
        case "ss":
          lowLim = 0;
          upLim = 59;
          break;
        case "SSS":
          lowLim = 0;
          upLim = 999;
          break;
      }
      const limitedDate = clampNumber(
        parseInt(dateSplit[index]),
        upLim,
        lowLim
      );
      parsedDate +=
        limitedDate.toString().padStart(curFormat.length, "0") + delimiter;
    }
  });

  // Remove the last redundant delimiter
  return parsedDate.slice(0, -1);
};

export const sortDayCallback = (
  dayA: string | Date,
  dayB: string | Date,
  format: string = STANDARD_DATE_FORMAT
) => {
  const aDate = dayjs(dayA, format);
  const bDate = dayjs(dayB, format);
  return aDate.isAfter(bDate) ? 1 : -1;
};

export const parseToValidApiDateString = (date: Date): string => {
  return dayjs(date).format("YYYY-MM-DD");
};

/**
 * Remove item if found or add if not found
 * This will modify array **in place**
 */
export function addOrRemoveItemFromList<T>(
  itemList: T[],
  item: T,
  comparer: (listItem: T, item: T) => boolean = (listItem, item) => {
    return listItem === item;
  }
) {
  const itemIndex = itemList.findIndex((entry) => {
    return comparer(entry, item);
  });

  if (itemIndex >= 0) {
    itemList.splice(itemIndex, 1);
  } else {
    itemList.push(item);
  }
}
