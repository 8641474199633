import { JournalLoadingType } from "common/journal/journal-manage.info";
import { persist } from "easy-peasy";
import {
  journalManageState,
  JournalManageState,
} from "stores/journal/journal-manage.state";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import {
  journalManageAction,
  JournalManageAction,
} from "./journal-manage.action";
import { journalManageThunk, JournalManageThunk } from "./journal-manage.thunk";

export type JournalManageModel = JournalManageThunk &
  JournalManageAction &
  JournalManageState &
  LoadingModel<JournalLoadingType>;

export const journalManage: JournalManageModel = persist(
  {
    ...journalManageState,
    ...journalManageAction,
    ...journalManageThunk,
    ...loadingPlugin(),
  },
  {
    storage: "localStorage",
    allow: ["draft"],
  }
);
