export const relayList = [
  "sms",
  "pushNotification",
  "email",
  // "phoneCall",
] as const;
export type RelayType = typeof relayList[number];

export const relayContextList = ["alert"] as const;
export type RelayContext = typeof relayContextList[number];

export const relayIconMap: Record<RelayType, [string, string]> = {
  email: ["fas", "envelope"],
  sms: ["fas", "sms"],
  pushNotification: ["fas", "bell"],
};
