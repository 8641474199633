import cx from "classnames";
import { useThemeColor } from "hooks/useThemeColor";
import React, { Fragment } from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

interface ShimmerBaseProps {
  children: React.ReactNode;
  contentLoaderProps: IContentLoaderProps;
  backgroundColorName?: string;
  foregroundColorName?: string;
  useRandomInterval?: boolean;
}
interface ShimmerProps {
  contentLoaderProps?: IContentLoaderProps;
  shimmerProps?: Partial<ShimmerBaseProps>;
}

const ShimmerBase = ({
  children,
  contentLoaderProps,
  backgroundColorName = "shimmer-background",
  foregroundColorName = "shimmer-foreground",
  useRandomInterval,
}: ShimmerBaseProps) => {
  const backgroundColor = useThemeColor(backgroundColorName);
  const foregroundColor = useThemeColor(foregroundColorName);

  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 400 50"
      preserveAspectRatio="none"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      interval={useRandomInterval ? Math.random() / 10 + 0.1 : undefined}
      {...contentLoaderProps}
    >
      {children}
    </ContentLoader>
  );
};

export const FormShimmer = (props: ShimmerProps) => (
  <ShimmerBase
    contentLoaderProps={{
      ...props.contentLoaderProps,
      height: "70",
      width: "100%",
    }}
    {...props.shimmerProps}
  >
    <rect x="0" y="0" rx="5" ry="5" width="50" height="10" />
    <rect x="0" y="15" rx="5" ry="5" width="100%" height="15" />
  </ShimmerBase>
);

export const TagShimmer = (props: ShimmerProps) => (
  <ShimmerBase
    contentLoaderProps={{
      ...props.contentLoaderProps,
      height: "30",
      width: "100",
    }}
    {...props.shimmerProps}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ShimmerBase>
);

export const TextShimmer = (props: ShimmerProps) => (
  <ShimmerBase
    contentLoaderProps={{
      ...props.contentLoaderProps,
      height: "30",
      width: "300",
    }}
    {...props.shimmerProps}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ShimmerBase>
);

export const CardShimmer = (props: ShimmerProps) => (
  <ShimmerBase
    contentLoaderProps={{
      ...props.contentLoaderProps,
      height: 150,
      width: "100%",
    }}
    {...props.shimmerProps}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
  </ShimmerBase>
);

interface ShimmerContainerProps {
  shimmerComponent: React.ReactNode;
  numShimmer?: number;
  children: React.ReactNode;
  isReady?: boolean;
  shimmerContainerClassName?: string;
}
export const ShimmerContainer = ({
  children,
  shimmerComponent,
  numShimmer = 1,
  isReady,
  shimmerContainerClassName,
}: ShimmerContainerProps) => {
  return !isReady ? (
    <div className={cx("shimmer-container", shimmerContainerClassName)}>
      {Array.apply(null, Array(numShimmer)).map((_, index) => (
        <Fragment key={index}>{shimmerComponent}</Fragment>
      ))}
    </div>
  ) : (
    <>{children}</>
  );
};
