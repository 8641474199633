import { AlertType } from "common/alert/alert-common.info";
import { AlertManageStep } from "common/alert/alert-page.info";
import {
  ChainedAlertTypeCard,
  ComingSoonAlertTypeCard,
  SingleAlertTypeCard,
} from "components/pages/Alert/AlertTypeCard";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/ChooseAlertStep.sass";

export const ChooseAlertTypeStep = () => {
  const alert = useStoreState((state) => state.alertManage.alert);
  const { switchAlertType, setCurManageStep } = useStoreActions(
    (actions) => actions.alertManage
  );

  if (!alert) {
    return null;
  }

  const switchType = async (type: AlertType) => {
    const result = await switchAlertType(type);
    if (!result.error) {
      setCurManageStep(AlertManageStep.ConditionAdding);
    }
  };

  return (
    <div className="choose-alert-type-step">
      <p>Select alert type for {alert.ticker}</p>

      <div className="type-card-container">
        <SingleAlertTypeCard onClick={switchType} />
        <ChainedAlertTypeCard onClick={switchType} />
        <ComingSoonAlertTypeCard />
      </div>
    </div>
  );
};
