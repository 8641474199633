import { messageService } from "api/service";
import { MessageType, messageTypeList } from "common/notification.info";
import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import {
  GeneralMessageDto,
  MediaMessageDto,
  MessageDto,
} from "models/dto/message/message.dto";
import { StoreModel } from "stores";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

const generateBaseMessageDataMap = () => ({
  general: [],
  media: [],
});

export interface MessageModel {
  // * State
  messageDataMap: {
    general: GeneralMessageDto[];
    media: MediaMessageDto[];
  };
  messageListByType: Computed<
    MessageModel,
    (type: MessageType) => MessageDto[]
  >;

  numUnread: Computed<MessageModel, number>;
  numUnreadByType: Computed<MessageModel, (type: MessageType) => number>;

  // * Actions
  resetStore: Action<MessageModel>;
  setMessageList: Action<
    MessageModel,
    { type: MessageType; messageList: MessageDto[] }
  >;

  // * Thunks
  initializeStore: Thunk<
    MessageModel,
    never,
    any,
    any,
    ((() => void) | null)[]
  >;
  markAllOfTypeAsRead: Thunk<MessageModel, MessageType, any, StoreModel>;
}

export const message: MessageModel = {
  // * State
  messageDataMap: generateBaseMessageDataMap(),
  messageListByType: computed(
    [(state) => state.messageDataMap],
    (messageDataMap) => (type) => messageDataMap[type]
  ),

  numUnread: computed(
    [(state) => state.messageListByType],
    (messageListByType) => {
      let total = 0;
      messageTypeList.forEach(
        (type) =>
          (total += messageListByType(type).reduce(
            (count, curNoti) => (curNoti.isRead ? count : count + 1),
            0
          ))
      );

      return total;
    }
  ),
  numUnreadByType: computed(
    [(state) => state.messageListByType],
    (messageListByType) => (type) =>
      messageListByType(type).reduce(
        (count, curNoti) => (curNoti.isRead ? count : count + 1),
        0
      )
  ),

  // * Actions
  resetStore: action((state) => {
    state.messageDataMap = generateBaseMessageDataMap();
  }),
  setMessageList: action((state, { messageList, type }) => {
    switch (type) {
      case "general":
        state.messageDataMap[type] = messageList as GeneralMessageDto[];
        break;
      case "media":
        state.messageDataMap[type] = messageList as MediaMessageDto[];
        break;
    }
  }),

  // * Thunks
  initializeStore: thunk((actions) => {
    return messageTypeList.map((messageType) => {
      return messageService.setupSnapshotListener(
        messageType,
        (messageList) => {
          actions.setMessageList({
            type: messageType,
            messageList: messageList,
          });
        }
      );
    });
  }),
  markAllOfTypeAsRead: thunk(async (_, messageType, { getState }) => {
    if (getState().numUnreadByType(messageType) <= 0) {
      return;
    }

    await executeAsync({
      funcToExecute: async () => {
        await messageService.markAsAllOfTypeRead(messageType);
      },
      transformError: extractFunctionError,
    });
  }),
};
