import {
  predefinedTagList,
  predefinedTagMap,
  PredefinedTagSection,
} from "common/tag.info";
import dayjs from "dayjs";
import { Direction, shuffle } from "weighted-shuffle";

// Shuffle tag list, prioritize tag in strategy category
export const getShuffledTagList = () => {
  const tagWithSectionList: {
    tag: string;
    section: PredefinedTagSection;
  }[] = predefinedTagList.map((tag) => ({
    tag: tag,
    section: predefinedTagMap[tag] || "Strategies",
  }));

  const weightedArr = tagWithSectionList.map(
    (tag, index) =>
      [index, tag.section === "Strategies" ? 3 : 1] as [number, number]
  );

  const shuffledIndexArr = shuffle(weightedArr, Direction.desc);
  return shuffledIndexArr.map(([index, _]) => predefinedTagList[index]);
};

export const calculateBreakEven = (strikePrice: number, premium: number) =>
  strikePrice + premium;

export const getQuarterStrFromDateStr = (
  dateStr: string,
  format = "YYYY-MM-DD"
) => {
  const date = dayjs(dateStr, format);
  const month = date.month();

  // Get the last 2 digit for the year
  const year = date.year().toString().slice(-2);

  const quarter = Math.floor(month / 3) + 1;

  return `Q${quarter} ${year}`;
};
