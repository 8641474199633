import { brokerageService } from "api/service/brokerage.service";
import { SupportedBrokerage } from "common/brokerage.info";
import {
  BrokerageAnalyticLoadingType,
  DEFAULT_BROKERAGE_ANALYTIC_OFFSET,
} from "common/brokerage/brokerage-analytic.info";
import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import lodash from "lodash";
import {
  GetAnalyzedHoldingDataResDto,
  GetAnalyzedTransactionDataReqDto,
  GetAnalyzedTransactionDataResDto,
} from "models/dto/brokerage/get-brokerage-data.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

export interface BrokerageAnalyticModel
  extends LoadingModel<BrokerageAnalyticLoadingType> {
  // * State
  transactionFetchResult: GetAnalyzedTransactionDataResDto | null;
  hasMoreTransactionToFetch: Computed<BrokerageAnalyticModel, boolean>;

  holdingFetchResult: GetAnalyzedHoldingDataResDto | null;

  // * Actions
  resetStore: Action<BrokerageAnalyticModel>;

  setTransactionFetchResult: Action<
    BrokerageAnalyticModel,
    GetAnalyzedTransactionDataResDto | null
  >;

  setHoldingFetchResult: Action<
    BrokerageAnalyticModel,
    GetAnalyzedHoldingDataResDto | null
  >;

  // * Thunks
  getAndSaveAnalyzedTransactionData: Thunk<
    BrokerageAnalyticModel,
    GetAnalyzedTransactionDataReqDto & {
      brokerage: SupportedBrokerage;
      paginationMode?: boolean;
    },
    any,
    StoreModel
  >;

  getAndSaveAnalyzedHoldingData: Thunk<
    BrokerageAnalyticModel,
    SupportedBrokerage
  >;
}
export const brokerageAnalytic: BrokerageAnalyticModel = {
  // * State
  transactionFetchResult: null,
  hasMoreTransactionToFetch: computed(
    [(state) => state.transactionFetchResult?.hasMore],
    (hasMore) => (hasMore != null ? hasMore : true)
  ),

  holdingFetchResult: null,

  // * Actions
  resetStore: action((state) => {
    state.transactionFetchResult = null;
    state.hasMoreTransactionToFetch = true;

    state.holdingFetchResult = null;
  }),

  setTransactionFetchResult: action((state, transactionFetchResult) => {
    state.transactionFetchResult = transactionFetchResult;
  }),
  setHoldingFetchResult: action((state, holdingFetchResult) => {
    state.holdingFetchResult = holdingFetchResult;
  }),

  // * Thunks
  getAndSaveAnalyzedTransactionData: thunk(
    async (
      actions,
      { brokerage, paginationMode, ...payload },
      { getState }
    ) => {
      const oldFetchResult = getState().transactionFetchResult;
      let offset = DEFAULT_BROKERAGE_ANALYTIC_OFFSET;
      if (
        oldFetchResult &&
        brokerage === oldFetchResult.institution &&
        paginationMode
      ) {
        offset +=
          oldFetchResult.optionTransactionList.length +
          oldFetchResult.stockTransactionList.length;
      }

      const { result } = await executeAsync({
        funcToExecute: async () =>
          await brokerageService.getAnalyzedTransactionData(brokerage, {
            ...payload,
            offset: offset,
          }),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            isLoading: isLoading,
            loadingType:
              BrokerageAnalyticLoadingType.FetchingAnalyzedTransaction,
          });
        },
      });

      if (result) {
        if (
          oldFetchResult &&
          oldFetchResult.institution === result?.institution &&
          paginationMode
        ) {
          const optionList = lodash.uniqBy(
            [
              ...oldFetchResult.optionTransactionList,
              ...result.optionTransactionList,
            ],
            (entry) => entry.id
          );
          const stockList = lodash.uniqBy(
            [
              ...oldFetchResult.stockTransactionList,
              ...result.stockTransactionList,
            ],
            (entry) => entry.id
          );
          actions.setTransactionFetchResult({
            ...result,
            total: result.total + oldFetchResult.total,
            optionTransactionList: optionList,
            stockTransactionList: stockList,
          });
        } else {
          actions.setTransactionFetchResult(result);
        }
      }
    }
  ),

  getAndSaveAnalyzedHoldingData: thunk(async (actions, brokerage) => {
    // TODO: Add cache here
    const { result } = await executeAsync({
      funcToExecute: async () =>
        await brokerageService.getAnalyzedHoldingsData(brokerage),
      transformError: extractFunctionError,
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          isLoading: isLoading,
          loadingType: BrokerageAnalyticLoadingType.FetchingAnalyzedHolding,
        });
      },
    });

    actions.setHoldingFetchResult(result);
  }),

  ...loadingPlugin(),
};
