export interface SubscriptionPlanPriceDto {
  id: string;
  active: boolean;
  description?: string;
  currency: string;
  interval: RecurInterval;
  intervalCount: number;
  unitAmount: number;
}

export enum RecurInterval {
  month = "month",
  year = "year",
}

export const DISPLAY_RECUR_INTERVAL_MAP: Record<RecurInterval, string> = {
  [RecurInterval.month]: "Month",
  [RecurInterval.year]: "Year",
};
