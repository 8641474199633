import {
  supportedBrokerage,
  SupportedBrokerage,
  supportedBrokerageDisplayMap,
} from "common/brokerage.info";
import { BrokerageAnalyticLoadingType } from "common/brokerage/brokerage-analytic.info";
import { TransactionType } from "common/transaction/transaction.info";
import {
  BottomSheet,
  CustomModal,
  FormDropDown,
  Icon,
  LoadingSpinner,
} from "components/common";
import { TransactionViewerComponent } from "components/pages/Journal/Manage/TransactionViewerComponent";
import { Transaction } from "models/schema/transaction.model";
import React, { useEffect, useMemo, useState } from "react";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Journal/Manage/BrokerageTransactionComponent.sass";
import { getMediaBreakpoint, sortDayCallback } from "utils/common.utils";
import { toastCustomMessage } from "utils/ui.util";
import { SubscriptionViewTriggerButton } from "views";
import { LinkBrokerageView } from "views/common/LinkBrokerageView";

const isSupportedBrokerage = (str: string): str is SupportedBrokerage =>
  supportedBrokerage.includes(str as any);

const BASE_MAX_DATA_TO_DISPLAY = 5;
const HARD_DISPLAY_DATA_CAP = 500;
interface BrokerageTransactionComponentProps {
  addTransaction?: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  removeTransaction?: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  checkActiveTransaction?: (transaction: Transaction) => boolean;
  hideBrokerageLink?: boolean;
  maxDataToDisplayCap?: number;
}

export const BrokerageTransactionComponent = ({
  addTransaction,
  removeTransaction,
  checkActiveTransaction,
  hideBrokerageLink,
  maxDataToDisplayCap = HARD_DISPLAY_DATA_CAP,
}: BrokerageTransactionComponentProps) => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  // * Account state
  const canUseBrokerage = useStoreState((state) =>
    state.account.canUseFeature("brokerIntegration")
  );

  // * Brokerage state
  const userBrokerage = useStoreState((state) => state.brokerage.userBrokerage);
  const linkedBrokerageList = useMemo(() => {
    if (!userBrokerage) {
      return [];
    }

    return Object.keys(userBrokerage).filter(
      (key) => userBrokerage[key as SupportedBrokerage] != null
    ) as SupportedBrokerage[];
  }, [userBrokerage]);

  // --- Transaction
  const { getAndSaveAnalyzedTransactionData } = useStoreActions(
    (actions) => actions.brokerageAnalytic
  );
  const transactionFetchResult = useStoreState(
    (state) => state.brokerageAnalytic.transactionFetchResult
  );
  const hasMoreTransactionToFetch = useStoreState(
    (state) => state.brokerageAnalytic.hasMoreTransactionToFetch
  );
  const isFetching = useStoreState((state) =>
    state.brokerageAnalytic.isLoading(
      BrokerageAnalyticLoadingType.FetchingAnalyzedTransaction
    )
  );

  const rawTransactionList = useMemo(() => {
    const transactionList: Transaction[] = [];

    if (transactionFetchResult) {
      transactionList.push(...transactionFetchResult.optionTransactionList);
      transactionList.push(...transactionFetchResult.stockTransactionList);
    }

    transactionList.sort(
      (entryA, entryB) =>
        -sortDayCallback(entryA.transactionDate, entryB.transactionDate)
    );
    return transactionList;
  }, [transactionFetchResult]);

  // * UI State
  const [showBrokerageLink, setShowBrokerageLink] = useState(false);
  const [maxDataToDisplay, setMaxDataToDisplay] = useState(
    BASE_MAX_DATA_TO_DISPLAY
  );
  const [curBrokerage, setCurBrokerage] = useState<SupportedBrokerage>(
    linkedBrokerageList[0]
  );

  useEffect(() => {
    if (isSupportedBrokerage(curBrokerage)) {
      getAndSaveAnalyzedTransactionData({ brokerage: curBrokerage });
      setMaxDataToDisplay(BASE_MAX_DATA_TO_DISPLAY);
    }
  }, [curBrokerage, getAndSaveAnalyzedTransactionData]);

  const showMore = () => {
    const newMaxData = BASE_MAX_DATA_TO_DISPLAY + maxDataToDisplay;
    if (newMaxData <= maxDataToDisplayCap) {
      if (
        rawTransactionList.length < newMaxData &&
        isSupportedBrokerage(curBrokerage)
      ) {
        getAndSaveAnalyzedTransactionData({
          brokerage: curBrokerage,
          paginationMode: true,
        });
      }

      setMaxDataToDisplay(newMaxData);
    } else {
      toastCustomMessage(
        `Can't show more than ${maxDataToDisplayCap} results`,
        "error"
      );
    }
  };

  const displayData = useMemo(() => {
    return rawTransactionList.slice(0, maxDataToDisplay);
  }, [rawTransactionList, maxDataToDisplay]);

  // * --- Render
  if (!canUseBrokerage) {
    return (
      <div className="subscription-block">
        <p>Only Elite Members Can Use This Feature</p>
        <SubscriptionViewTriggerButton text="Check Subscription" />
      </div>
    );
  }

  // * Methods
  const closeShowBrokerageLink = () => {
    setShowBrokerageLink(false);
  };

  const renderLinkView = () => (
    <LinkBrokerageView
      showHeader={false}
      className="brokerage-transaction-link-brokerage-view"
    />
  );

  return (
    <>
      <div className="brokerage-transaction-component">
        <div className="brokerage-header">
          {linkedBrokerageList.length > 0 && (
            <FormDropDown
              readOnly={isFetching}
              value={curBrokerage}
              className="brokerage-selector"
              dataList={linkedBrokerageList.map((brokerage) => {
                const label = (
                  <div className="selector-item">
                    <img
                      src={supportedBrokerageDisplayMap[brokerage].imgUrl}
                      alt={supportedBrokerageDisplayMap[brokerage].name}
                    />

                    <p>{supportedBrokerageDisplayMap[brokerage].name}</p>
                  </div>
                );

                return { value: brokerage, label: label };
              })}
              label="Choose Brokerage"
              onChange={(brokerage) => {
                setCurBrokerage(brokerage);
              }}
            />
          )}

          {!hideBrokerageLink && (
            <button
              className="add-more"
              onClick={() => {
                setShowBrokerageLink(true);
              }}
            >
              <Icon icon={["far", "plus"]} />
              <p>Link Brokerage</p>
            </button>
          )}
        </div>

        <TransactionViewerComponent
          transactionList={displayData}
          onSelectClick={
            addTransaction && removeTransaction
              ? (isSelected, transaction) => {
                  if (isSelected) {
                    addTransaction({ type: transaction.type, transaction });
                  } else {
                    removeTransaction({ type: transaction.type, transaction });
                  }
                }
              : undefined
          }
          checkActiveTransaction={checkActiveTransaction}
          title=""
        />

        {linkedBrokerageList.length > 0 && (
          <button
            className="load-more"
            onClick={showMore}
            disabled={isFetching || !hasMoreTransactionToFetch}
          >
            {isFetching ? <LoadingSpinner /> : <p>Load More</p>}
          </button>
        )}
      </div>

      {showBrokerageLink &&
        (isLg ? (
          <CustomModal
            midComponent="Manage Brokerage"
            onClickAway={closeShowBrokerageLink}
            onCloseClick={closeShowBrokerageLink}
          >
            {renderLinkView()}
          </CustomModal>
        ) : (
          <BottomSheet
            supplementaryContent={renderLinkView()}
            onChange={(isActive) => {
              if (!isActive) {
                closeShowBrokerageLink();
              }
            }}
          >
            Manage Brokerage
          </BottomSheet>
        ))}
    </>
  );
};
