import { TransactionType } from "common/transaction/transaction.info";
import { ListSlider } from "components/common";
import {
  BrokerageTransactionComponent,
  TransactionInputComponent,
  UploadCSVToTransactionComponent,
} from "components/pages/Journal";
import { Transaction } from "models/schema/transaction.model";
import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import "styles/views/common/AddTransactionMobileView.sass";

const sliderList = ["Input", "Brokerage", "Upload"];
enum AddTransactionView {
  Input,
  Brokerage,
  Upload,
}
interface AddTransactionMobileViewProps {
  addTransaction: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  removeTransaction: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  addButtonText?: string;
  checkActiveTransaction?: (transaction: Transaction) => boolean;
}
export const AddTransactionMobileView = ({
  addTransaction,
  removeTransaction,
  addButtonText = "Add Transaction",
  checkActiveTransaction,
}: AddTransactionMobileViewProps) => {
  const [curView, setView] = useState(AddTransactionView.Input);

  return (
    <div className="add-transaction-mobile-view">
      <ListSlider
        componentList={sliderList}
        curComponent={sliderList[curView]}
        useContainerPreset="preset-1"
        containerClassName="type-switch-container"
        itemWrapperClassName="item"
        activeItemWrapperClassName="item-active"
        barClassName="bar"
        onClick={(index) => {
          setView(index);
        }}
        isInline
      />

      <SwipeableViews
        index={curView}
        slideClassName="slide"
        onChangeIndex={(index) => {
          setView(index);
        }}
      >
        <TransactionInputComponent
          addTransaction={addTransaction}
          buttonText={addButtonText}
        />

        <BrokerageTransactionComponent
          removeTransaction={removeTransaction}
          addTransaction={addTransaction}
          checkActiveTransaction={checkActiveTransaction}
        />

        <UploadCSVToTransactionComponent
          removeTransaction={removeTransaction}
          addTransaction={addTransaction}
          checkActiveTransaction={checkActiveTransaction}
        />
      </SwipeableViews>
    </div>
  );
};
