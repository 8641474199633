export enum StockLoadingType {
  SearchingCompanyInfo,
  GetCompanyDetail,
  FetchingTickerRating,
  FetchingTickerEarning,
  FetchingChartData,
}

export const stockRatingTypeList = [
  "buy",
  "outperform",
  "hold",
  "underperform",
  "sell",
] as const;
export type StockRatingType = typeof stockRatingTypeList[number];

export const stockNameToDisplayNameMap: Record<string, string> = {
  SPY: "SPDR S&P 500 ETF",
};
