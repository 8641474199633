import { JournalPageLoadingType } from "common/journal/journal-page.info";
import {
  CardShimmer,
  LoadingSpinner,
  ShimmerContainer,
} from "components/common";
import { JournalDto } from "models/dto/journal/journal.dto";
import React from "react";
import FadeIn from "react-fade-in";
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Journal/JournalSummaryContainer.sass";
import { JournalSummary } from ".";

interface JournalSummaryContainerProp {
  onCardClick: (arg: JournalDto) => void;
  onYEndReach?: () => void;
  hasMoreToSearch?: boolean;
}
export const JournalSummaryContainer = ({
  onCardClick,
  onYEndReach,
  hasMoreToSearch,
}: JournalSummaryContainerProp) => {
  const curViewJournal = useStoreState(
    (state) => state.journalDetailView.journal
  );
  const isInSelectMode = useStoreState(
    (state) => state.journalDashboard.isInSelectMode
  );
  const journalList = useStoreState(
    (state) => state.journalDashboard.journalList
  );

  const selectedJournalIdList = useStoreState(
    (state) => state.journalDashboard.selectedJournalIdList
  );
  const isLoadingJournalList = useStoreState((state) =>
    state.journalDashboard.isLoading(JournalPageLoadingType.LoadingJournalList)
  );

  const { selectOrDeselectJournal, deleteJournal } = useStoreActions(
    (actions) => actions.journalDashboard
  );

  return (
    <PerfectScrollbar
      className="journal-summary-container"
      onYReachEnd={onYEndReach}
      id="journal-summary-container"
    >
      <InfiniteScroll
        dataLength={journalList.length}
        hasMore={hasMoreToSearch || false}
        scrollableTarget="journal-summary-container"
        next={() => {
          onYEndReach?.();
        }}
        loader={<LoadingSpinner />}
      >
        <ShimmerContainer
          numShimmer={4}
          shimmerComponent={
            <CardShimmer shimmerProps={{ useRandomInterval: true }} />
          }
          isReady={!isLoadingJournalList}
        >
          {journalList.map((entry, index) => (
            <FadeIn key={`${entry.id}-${index}`}>
              <JournalSummary
                isSelected={selectedJournalIdList.includes(entry.id)}
                isInSelectMode={isInSelectMode}
                onSelect={(curJournal) =>
                  selectOrDeselectJournal(curJournal.id)
                }
                isActive={entry.id === curViewJournal?.id}
                onCardClick={(journal) => {
                  onCardClick(journal);
                }}
                onDeleteClick={(journal) => {
                  if (window.confirm("Delete journal?")) {
                    deleteJournal([journal.id]);
                  }
                }}
                journal={entry}
              />
            </FadeIn>
          ))}
        </ShimmerContainer>
      </InfiniteScroll>
    </PerfectScrollbar>
  );
};
