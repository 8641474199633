import { JournalPageLoadingType } from "common/journal/journal-page.info";
import { predefinedTagList } from "common/tag.info";
import { FormInput, Icon, TagBySectionComponent } from "components/common";
import lodash from "lodash";
import React, { useRef, useState } from "react";
import { useClickAway } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Journal/SearchJournalForm.sass";
import cx from "classnames";

interface SearchCache {
  term: string;
  tagList: string[];
}

interface SearchJournalFormProps {
  preset: "dropdown" | "normal";
  onCancelClick?: () => void;
  onConfirmClick?: () => void;
}
export const SearchJournalForm = ({
  preset = "dropdown",
  onCancelClick,
  onConfirmClick,
}: SearchJournalFormProps) => {
  const baseSearchTerm = useStoreState(
    (state) => state.journalDashboard.searchKeyword
  );
  const baseTagListToSearch = useStoreState(
    (state) => state.journalDashboard.tagListToSearch
  );

  // * --- Cache
  // --- Used to prevent same search
  const cacheSearch = useRef<SearchCache>({
    term: baseSearchTerm,
    tagList: baseTagListToSearch,
  });
  // --- Used to reset input on lose focus or click away
  const [prevSearch, setPrevSearch] = useState<SearchCache>({
    term: baseSearchTerm,
    tagList: baseTagListToSearch,
  });

  // * --- Search term
  const [searchKeyword, setSearchKeyword] = useState<string>(baseSearchTerm);
  const [searchTagList, setSearchTagList] = useState<string[]>(
    baseTagListToSearch
  );

  // * UI state
  const isLoading = useStoreState((state) =>
    state.journalDashboard.isLoading(JournalPageLoadingType.LoadingJournalList)
  );
  const [showDropdown, setShowDropdown] = useState(preset === "normal");
  const containerRef = useRef<HTMLFormElement>(null);
  useClickAway(containerRef, () => {
    if (preset === "dropdown") {
      setShowDropdown(false);
    }

    setSearchKeyword(prevSearch.term);
    setSearchTagList(prevSearch.tagList);
  });

  // * Methods
  const { searchAndSaveJournal } = useStoreActions(
    (actions) => actions.journalDashboard
  );

  const addTag = (tag: string) => {
    setSearchTagList((prev) => [...prev, tag]);
  };

  const removeTag = (tag: string) => {
    const tagIndex = searchTagList.findIndex((entry) => entry === tag);
    const newList = [...searchTagList];
    newList.splice(tagIndex, 1);
    setSearchTagList(newList);
  };

  const onConfirmClickWrapper = async () => {
    if (
      cacheSearch.current.term !== searchKeyword ||
      !lodash.isEqual(
        [...searchTagList].sort(),
        cacheSearch.current.tagList.sort()
      )
    ) {
      // No point in searching if we query for the same journal
      searchAndSaveJournal({ searchKeyword, tagListToSearch: searchTagList });

      setPrevSearch({ tagList: searchTagList, term: searchKeyword });
      cacheSearch.current = { tagList: searchTagList, term: searchKeyword };
    }

    if (preset === "dropdown") {
      setShowDropdown(false);
    }

    onConfirmClick?.();
  };

  const onCancelClickWrapper = () => {
    if (preset === "dropdown") {
      setShowDropdown(false);
    }
    setSearchKeyword(prevSearch.term);
    setSearchTagList(prevSearch.tagList);

    onCancelClick?.();
  };

  return (
    <form
      className={cx("search-journal-form", {
        "search-journal-form-dropdown": preset === "dropdown",
      })}
      ref={containerRef}
      onSubmit={(event) => {
        event.preventDefault();
        onConfirmClickWrapper();
      }}
    >
      <FormInput
        className="search-form"
        placeholder="Search by Title and Note"
        onChange={(searchTerm) => {
          setSearchKeyword(searchTerm);
        }}
        value={searchKeyword}
        rightCTA={<Icon icon={["cio", "search"]} />}
        borderType="full-border"
        autoComplete="off"
        onFocus={() => {
          setShowDropdown(true);
        }}
      />

      {showDropdown && (
        <div
          className={cx("search-result", {
            [`search-result-${preset}`]: preset,
          })}
        >
          <TagBySectionComponent
            tagList={predefinedTagList as any}
            activeTagList={searchTagList}
            addTag={addTag}
            removeTag={removeTag}
          />

          <div className="cta-container">
            <button
              className="confirm-cta"
              onClick={onConfirmClickWrapper}
              disabled={isLoading}
              type="submit"
            >
              Confirm
            </button>

            <button
              type="button"
              className="cancel-cta"
              onClick={onCancelClickWrapper}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </form>
  );
};
