export const predefinedTagSectionList = ["Strategies", "Sectors"] as const;
export type PredefinedTagSection = typeof predefinedTagSectionList[number];

export const predefinedTagList = [
  "Day Trading",
  "Scalping",
  "Swing",
  "Position",
  "Breakout",
  "Reversal",
  "Momentum",
  "Long-Term",
  "Options",
  "Spread",
  "Short-term",
  "Bull market",
  "IPO",
  "YOLO",
  "WSB",
  "MACD",
  "RSI",

  "Energy",
  "Materials",
  "Industrials",
  "Utilities",
  "Healthcare",
  "Financials",
  "Consumer Discretionary",
  "Consumer Staples",
  "Information Technology",
  "Communication Services",
  "Real Estate",
] as const;
export type PredefinedTag = typeof predefinedTagList[number];

export const predefinedTagMap: Record<PredefinedTag, PredefinedTagSection> = {
  "Day Trading": "Strategies",
  Scalping: "Strategies",
  Swing: "Strategies",
  Position: "Strategies",
  Breakout: "Strategies",
  Reversal: "Strategies",
  Momentum: "Strategies",
  "Long-Term": "Strategies",
  Options: "Strategies",
  Spread: "Strategies",
  "Short-term": "Strategies",
  "Bull market": "Strategies",
  IPO: "Strategies",
  YOLO: "Strategies",
  WSB: "Strategies",
  MACD: "Strategies",
  RSI: "Strategies",

  Energy: "Sectors",
  Materials: "Sectors",
  Industrials: "Sectors",
  Utilities: "Sectors",
  Healthcare: "Sectors",
  Financials: "Sectors",
  "Consumer Discretionary": "Sectors",
  "Consumer Staples": "Sectors",
  "Information Technology": "Sectors",
  "Communication Services": "Sectors",
  "Real Estate": "Sectors",
};
