import cx from "classnames";
import { BasicFormInputProp } from "common/form/form.common";
import { StockLoadingType } from "common/stock/stock.info";
import {
  CompanySummaryCard,
  FormInput,
  FormShimmer,
  Icon,
  ShimmerContainer,
} from "components/common";
import { CompanySummaryDto } from "models/dto/stock/search-company-info.dto";
import React, { useEffect, useRef, useState } from "react";
import { useClickAway, useDebounce } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/common/SearchCompanyView.sass";

const DEBOUNCE_TIME = 250;

interface SearchCompanyViewProps {
  resultPreset?: "dropdown" | "normal";
  useLabel?: boolean;
  onlyShowResultOnFocus?: boolean;
  onCompanyNameClick?: (
    companyName: string,
    symbol: string,
    companySummary: CompanySummaryDto,
  ) => void;
  className?: string;
  formOption?: BasicFormInputProp;
}
export const SearchCompanyView = ({
  onCompanyNameClick,
  resultPreset = "normal",
  useLabel = true,
  onlyShowResultOnFocus = false,
  className,
  formOption,
}: SearchCompanyViewProps) => {
  // --- Used to prevent same search
  const cachedTerm = useRef("");
  // --- Used to reset input on lose focus or click away
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevTerm, setPrevTerm] = useState("");

  const [showResult, setShowResult] = useState(!onlyShowResultOnFocus);
  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => {
    if (onlyShowResultOnFocus) {
      setShowResult(false);
    }

    if (prevTerm) {
      setSearchTerm(prevTerm);
    }
  });

  const isLoading = useStoreState((state) =>
    state.stock.isLoading(StockLoadingType.SearchingCompanyInfo),
  );
  const { searchStockCompany, setCompanyInfoSearchResult } = useStoreActions(
    (actions) => actions.stock,
  );
  const companyInfoSearchResult = useStoreState(
    (state) => state.stock.companyInfoSearchResult,
  );

  useDebounce(
    () => {
      if (cachedTerm.current !== searchTerm) {
        searchStockCompany(searchTerm);
        cachedTerm.current = searchTerm;
      }
    },
    DEBOUNCE_TIME,
    [searchTerm],
  );

  // Reset store on unmount in case of switch view
  useEffect(() => {
    return () => {
      setCompanyInfoSearchResult([]);
    };
  }, [setCompanyInfoSearchResult]);

  useEffect(() => {
    setSearchTerm(prevTerm);
  }, [prevTerm]);

  return (
    <div className={cx("search-company-view", className)} ref={containerRef}>
      <FormInput
        className={cx("search-form", { "search-form-with-label": useLabel })}
        label={useLabel && "Name/Symbol"}
        placeholder="Company name or Ticker"
        onChange={(searchTerm) => {
          setSearchTerm(searchTerm);
        }}
        value={searchTerm}
        rightCTA={<Icon icon={["cio", "search"]} />}
        borderType="full-border"
        autoComplete="off"
        onFocus={() => {
          setShowResult(true);
        }}
        {...formOption}
      />

      {showResult && (
        <div
          className={cx("search-result", {
            [`search-result-${resultPreset}`]: resultPreset,
          })}
        >
          <ShimmerContainer
            numShimmer={10}
            shimmerComponent={<FormShimmer />}
            isReady={!isLoading}
          >
            {companyInfoSearchResult.length > 0
              ? companyInfoSearchResult.map((companySummary) => (
                  <CompanySummaryCard
                    key={companySummary.symbol}
                    companySummary={companySummary}
                    wrapperTag="button"
                    wrapperOnClick={({ companyName, symbol }) => {
                      onCompanyNameClick &&
                        onCompanyNameClick(companyName, symbol, companySummary);
                      setShowResult(!onlyShowResultOnFocus);

                      setPrevTerm(symbol);
                    }}
                  />
                ))
              : searchTerm.length > 0
              ? "No result found"
              : "Please enter your search term"}
          </ShimmerContainer>
        </div>
      )}
    </div>
  );
};
