import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import {
  DEFAULT_JOURNAL_SEARCH_LIMIT,
  DEFAULT_JOURNAL_SEARCH_OFFSET,
} from "common/journal/journal-page.info";
import { DeleteJournalDto } from "models/dto/journal/delete-journal.dto";
import {
  GetJournalReqDto,
  GetJournalsResDto,
} from "models/dto/journal/get-journal.dto";
import { DetailJournalDto, JournalDto } from "models/dto/journal/journal.dto";
import { WriteJournalDto } from "models/dto/journal/write-journal.dto";

export const journalService = {
  getJournalList: async (
    param?: GetJournalReqDto,
  ): Promise<GetJournalsResDto> => {
    const endpointInfo = functionEndpointList.getAllJournal;

    return await sendRequest<GetJournalsResDto, GetJournalReqDto>({
      endpointInfo,
      data: param || {
        limit: DEFAULT_JOURNAL_SEARCH_LIMIT,
        offset: DEFAULT_JOURNAL_SEARCH_OFFSET,
        searchKeyword: "",
        tagListToSearch: [],
      },
    });
  },
  createOrUpdateJournal: async (
    journalEntry: JournalDto,
    noteImageSrcList: string[],
    type: "create" | "update",
  ): Promise<JournalDto> => {
    const {
      id,
      lastModifiedAt,
      createdAt,
      ...journalToCreateOrUpdate
    } = journalEntry;

    const endpointInfo =
      type === "create"
        ? functionEndpointList.createJournal
        : functionEndpointList.updateJournal;

    return await sendRequest<DetailJournalDto, WriteJournalDto>({
      endpointInfo,
      extraPath: type === "create" ? [] : [id],
      data: { ...journalToCreateOrUpdate, noteImageSrcList },
    });
  },

  deleteJournal: async (journalIdList: string[]) => {
    const endpointInfo = functionEndpointList.deleteJournalList;

    return await sendRequest<string[], DeleteJournalDto>({
      endpointInfo,
      data: { journalIdList },
    });
  },
};
