import { JournalManageModalState } from "common/journal/journal-manage.info";
import { Icon } from "components/common";
import { useStoreActions } from "stores";
import "styles/components/pages/Journal/Manage/JournalManageMobileCTA.sass";

export const JournalManageMobileCTA = () => {
  const { setModalState } = useStoreActions((actions) => actions.journalManage);

  const onLinkAlertClick = () => {
    setModalState(JournalManageModalState.LinkAlert);
  };

  const onAddTransactionClick = () => {
    setModalState(JournalManageModalState.AddTransaction);
  };

  return (
    <div className="journal-manage-mobile-CTA">
      <button onClick={onAddTransactionClick}>
        <Icon icon={["fas", "envelope-open-dollar"]} className="fa-fw" />
        <p>Add Transactions</p>
      </button>

      <button onClick={onLinkAlertClick}>
        <Icon icon={["cis", "add-alert"]} className="fa-fw" />
        <p>Link Alerts</p>
      </button>
    </div>
  );
};
