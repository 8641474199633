import dayjs from "dayjs";
import { JournalDto } from "models/dto/journal/journal.dto";
import { v4 as uuidv4 } from "uuid";

export const generateBaseJournal = (): JournalDto => ({
  id: uuidv4(),
  lastModifiedAt: "",
  createdAt: "",

  title: "",
  htmlNote: "",
  attachmentUrlList: [],
  alertIdList: [],
  tagList: [],

  transactionsMap: {
    optionTransactionList: [],
    stockTransactionList: [],
  },
});

export const generateFirstJournal = (): JournalDto => ({
  title: "First Entry - Apheleia Inc.",
  htmlNote: `<p><a href="https://www.apheleia.me/" rel="noopener noreferrer" target="_blank">Apheleia Inc</a>&nbsp;is a new Tech company and creators of stockwise, I think that their stock can only grow! 📈 They have an amazing customer service team that is available by&nbsp;<a href="mailto:help@apheleia.me" rel="noopener noreferrer" target="_blank">email</a>&nbsp;📧</p><p><br></p><p>They also have a&nbsp;<a href="https://discord.gg/9VMCvpghjz" rel="noopener noreferrer" target="_blank">Discord server</a>&nbsp;that is great for connecting with the developers and other traders.</p><p><br></p><p>Their newsletter🗞 is full of helpful information and I wait every week to listen to their podcast&nbsp;<a href="https://anchor.fm/apheleia" rel="noopener noreferrer" target="_blank"><em>To the Moon</em></a></p><p><br></p><p>I believe that Aphelia can become one of the next great unicorns in tech! 🦄</p>`,
  id: "first-entry-ever",
  tagList: [],
  transactionsMap: { optionTransactionList: [], stockTransactionList: [] },
  lastModifiedAt: dayjs().toISOString(),
  createdAt: dayjs().toISOString(),
  attachmentUrlList: [],
  alertIdList: [],
});
