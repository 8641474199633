import cx from "classnames";
import React from "react";
import "styles/components/common/Badge.sass";
import "styles/components/common/common.sass";

interface RoundBadgeProps {
  children: React.ReactNode;
  noBorder?: boolean;
}
export const RoundBadge = ({ children, noBorder }: RoundBadgeProps) => {
  return (
    <div className={cx("badge badge-round", { "badge-no-border": noBorder })}>
      {children}
    </div>
  );
};

interface NotificationBadgeProps {
  isActive: boolean;
}
export const NotificationBadge = ({ isActive }: NotificationBadgeProps) => {
  if (!isActive) {
    return null;
  }

  return (
    <RoundBadge noBorder>
      <div className="red-dot" />
    </RoundBadge>
  );
};
