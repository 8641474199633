import { StarSvg } from "assets/images";
import cx from "classnames";
import { FormTextArea, TagContainer } from "components/common";
import { DiscordComponent } from "components/common/DiscordComponent";
import { ThankyouView } from "components/common/ThankyouView";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMedia } from "react-use";
import { useHover } from "react-use-gesture";
import { useStoreActions } from "stores";
import "styles/components/pages/Setting/FeedbackAndContactView.sass";
import { getMediaBreakpoint } from "utils/common.utils";

interface FeedbackStarProps {
  isActive: boolean;
  index: number;
  onHover: (arg: number) => void;
  onHoverOut: () => void;
  onClick: (arg: number) => void;
}
const FeedbackStar = ({
  isActive,
  index,
  onHover,
  onHoverOut,
  onClick,
}: FeedbackStarProps) => {
  const bind = useHover(({ hovering }) => {
    if (hovering) {
      onHover(index);
    } else {
      onHoverOut();
    }
  });

  return (
    <button
      {...bind()}
      className="star-container"
      onClick={() => onClick(index)}
      type="button"
    >
      <StarSvg className={cx("star", { "star-active": isActive })} />
    </button>
  );
};
interface FormViewProps {
  setSubmitState: (arg: boolean) => void;
}
const FormView = ({ setSubmitState }: FormViewProps) => {
  const { handleSubmit, errors, register } = useForm<{ feedback: string }>();
  const [chosenTagList, setChosenTagList] = useState<string[]>([]);

  const [chosenStarIndex, setChosenStarIndex] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const { submitFeedback } = useStoreActions((actions) => actions.setting);

  const onSubmit = handleSubmit(async (data) => {
    if (
      data.feedback.length === 0 &&
      chosenStarIndex === -1 &&
      chosenTagList.length === 0
    ) {
      throw new Error("You haven't chosen anything!");
    }

    const submitResult = await submitFeedback({
      feedback: data.feedback || "",
      rating: chosenStarIndex,
      tagList: chosenTagList,
    });

    submitResult && setSubmitState(true);
  });

  const renderFeedbackStar = () => {
    const NUM_STAR = 5;
    const starList: JSX.Element[] = [];
    for (let index = 0; index < NUM_STAR; index++) {
      starList.push(
        <FeedbackStar
          key={index}
          index={index}
          isActive={index <= chosenStarIndex || index <= hoverIndex}
          onClick={(index) => setChosenStarIndex(index)}
          onHover={(index) => setHoverIndex(index)}
          onHoverOut={() => {
            setHoverIndex(-1);
          }}
        />
      );
    }
    return starList;
  };

  return (
    <form className="form-view" onSubmit={onSubmit}>
      <section>
        <h4>Rate your experience</h4>
        {renderFeedbackStar()}
      </section>

      <section>
        <h4>Tell us how you feel</h4>

        <TagContainer
          tagList={feedbackTagList}
          getDisplayText={(tag) => tag}
          onClick={(tag) => {
            const newList = [...chosenTagList];
            if (newList.includes(tag)) {
              newList.splice(newList.indexOf(tag), 1);
            } else {
              newList.push(tag);
            }
            setChosenTagList(newList);
          }}
          checkActive={(tag) => chosenTagList.includes(tag)}
        />

        <FormTextArea
          className="feedback-form"
          formValidator={{
            name: "feedback",
            inputRef: register,
            errorText: errors["feedback"]?.message,
          }}
          borderType="full-border"
        />
      </section>

      <button className="submit-button">Submit</button>

      <DiscordComponent />
    </form>
  );
};

export const FeedbackView = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const [submitState, setSubmitState] = useState(false);

  return (
    <div
      className={cx("feedback-and-contact-view", {
        "feedback-and-contact-view-no-padding": submitState && !isLg,
      })}
    >
      {isLg && <h2 className="title">Feedback</h2>}

      {submitState ? (
        <ThankyouView
          extraText={
            <>
              <p>We appreciate your feedback</p>
              <p>
                We will work to resolve your concern into our future iteration
              </p>
            </>
          }
        />
      ) : (
        <FormView setSubmitState={setSubmitState} />
      )}
    </div>
  );
};

export const feedbackTagList = [
  "Easy to use",
  "Speed and Efficiency",
  "User-friendly",
  "Expensive",
];
