import cx from "classnames";
import { Icon, LoadingSpinner } from "components/common";
import { SectionLineDivider } from "components/common/SectionLineDivider";
import {
  DISPLAY_RECUR_INTERVAL_MAP,
  RecurInterval,
  SubscriptionPlanPriceDto,
} from "models/dto/payment/subscription-plan-price.dto";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import { PaymentSettingsLoading } from "stores/payment";
import "styles/components/common/SubscriptionCardsView.sass";
import { getMoneyDisplayFromCents } from "utils/common.utils";
import {
  checkIsShowChoosePlanBtn,
  getSubscriptionPlanCentAmount,
} from "utils/payment.util";
import { CurrentPlanSection } from "./CurrentPlanSection";

export const SubscriptionCardsView = () => {
  const planList = useStoreState((state) => state.paymentSettings.planList);
  const subscribeToPlan = useStoreActions(
    (actions) => actions.paymentSettings.subscribeToPlan
  );

  const onSubscribeClicked = async (planPriceId: string) => {
    await subscribeToPlan({ planPriceId });
  };

  const renderPlanCards = () => {
    return planList.map((plan) => (
      <PlanCard
        key={plan.id}
        plan={plan}
        recurInterval={RecurInterval.month}
        onClickChoosePlan={(planPrice) => onSubscribeClicked(planPrice.id)}
      />
    ));
  };

  return (
    <div className="subscription-cards-view">
      <CurrentPlanSection />
      <div className="divider-container">
        <SectionLineDivider text="Or" />
      </div>
      <div className="plans-section">{renderPlanCards()}</div>
    </div>
  );
};

interface PlanCardProps {
  plan: SubscriptionPlanDto;
  recurInterval: RecurInterval;
  onClickChoosePlan: (planPrice: SubscriptionPlanPriceDto) => void;
}
const PlanCard = ({
  plan,
  recurInterval,
  onClickChoosePlan,
}: PlanCardProps) => {
  const stripeRole = useStoreState((state) => state.account.stripeRole);
  const isLoadingMap = useStoreState(
    (state) => state.paymentSettings.isLoadingMap
  );

  const theme = useStoreState((state) => state.theme.theme);

  if (!plan.active) {
    return null;
  }

  const planColor =
    theme === "light"
      ? plan.metadata?.colorTheme.lightThemeColor
      : plan.metadata?.colorTheme.darkThemeColor;

  const getDisplayRecurInterval = () => {
    if (SubscriptionPlanDto.isFreePlan(plan)) {
      return "";
    }

    return "/" + DISPLAY_RECUR_INTERVAL_MAP[recurInterval];
  };

  const getFeatureList = (): string[] => {
    return plan.metadata?.featureSummaryList || ["N/A"];
  };

  const isChoosePlanBtnLoading = (): boolean | undefined => {
    return isLoadingMap[PaymentSettingsLoading.choosePlan];
  };

  const isSubscribed = (): boolean => {
    return SubscriptionPlanDto.isCurrentPlan(plan, stripeRole);
  };

  const renderPlanCard = () => {
    const planPrice = plan.prices.find(
      (price) => price.interval === recurInterval
    );

    if (SubscriptionPlanDto.isFreePlan(plan) === false && !planPrice) {
      return null;
    }

    const centAmount = getSubscriptionPlanCentAmount(plan, recurInterval);

    return (
      <div
        className={`plan-card ${
          SubscriptionPlanDto.isPopularPlan(plan.planKey) ? "popular" : ""
        }`}
      >
        <div className="plan-card-name" style={{ color: planColor }}>
          {plan.name}
        </div>
        <div className="plan-card-price">
          <span className="price-text">
            {getMoneyDisplayFromCents(centAmount)}
          </span>
          <sub>{getDisplayRecurInterval()}</sub>
        </div>
        <div className="plan-card-description">{plan.description}</div>
        <div className="plan-card-features">
          {getFeatureList().map((feature) => (
            <div className="feature-item" key={plan.id + feature}>
              <Icon
                className="feature-item-icon"
                icon={["fas", "check-circle"]}
              />
              <div className="feature-item-text">{feature}</div>
            </div>
          ))}
        </div>
        {renderChoosePlanBtn(planPrice)}
      </div>
    );
  };

  const renderChoosePlanBtn = (
    planPrice: SubscriptionPlanPriceDto | undefined
  ) => {
    if (SubscriptionPlanDto.isFreePlan(plan) === true || !planPrice) {
      return null;
    }

    if (checkIsShowChoosePlanBtn(plan.role, stripeRole) === false) {
      return null;
    }

    return (
      <button
        className={cx("plan-card-subscribe-btn", {
          subscribed: isSubscribed(),
        })}
        disabled={isChoosePlanBtnLoading() || isSubscribed()}
        onClick={() => onClickChoosePlan(planPrice)}
      >
        {renderChoosePlanBtnContent()}
      </button>
    );
  };

  const renderChoosePlanBtnContent = () => {
    if (isChoosePlanBtnLoading()) {
      return <LoadingSpinner size={10} />;
    }

    if (isSubscribed()) {
      return <span>Subscribed</span>;
    }

    return <span>Choose Plan</span>;
  };

  return renderPlanCard();
};
