import { createStore, createTypedHooks } from "easy-peasy";
import {
  alertDashboard,
  AlertDashboardModel,
  alertManage,
  AlertManageModel,
} from "stores/alert";
import { global, GlobalModel } from "stores/global";
import { message, MessageModel } from "stores/message";
import { notificationStore, NotificationStoreModel } from "stores/notification";
import { stock, StockModel } from "stores/stock";
import { account, AccountModel } from "./auth";
import {
  brokerage,
  brokerageAnalytic,
  BrokerageAnalyticModel,
  BrokerageModel,
} from "./brokerage";
import {
  journalDashboard,
  JournalDashboardModel,
  journalDetailView,
  JournalDetailViewModel,
  journalManage,
  JournalManageModel,
} from "./journal";
import { modal, ModalModel, theme, ThemeModel } from "./misc";
import { news, NewsModel } from "./news";
import { paymentSettings } from "./payment";
import { PaymentSettingsModel } from "./payment/payment-settings.store";
import { setting, SettingStoreModel } from "./setting";
import { stockPage, StockPageModel } from "./stock-page";
import { watchList, WatchListModel } from "./watch-list";

export interface StoreModel {
  global: GlobalModel;
  theme: ThemeModel;
  modal: ModalModel;

  account: AccountModel;
  watchList: WatchListModel;
  setting: SettingStoreModel;

  alertDashboard: AlertDashboardModel;
  alertManage: AlertManageModel;

  notification: NotificationStoreModel;
  message: MessageModel;

  stockPage: StockPageModel;

  stock: StockModel;
  news: NewsModel;

  journalDashboard: JournalDashboardModel;
  journalManage: JournalManageModel;
  journalDetailView: JournalDetailViewModel;

  paymentSettings: PaymentSettingsModel;

  brokerage: BrokerageModel;
  brokerageAnalytic: BrokerageAnalyticModel;
}

const model: StoreModel = {
  global,
  theme,
  modal,

  account,
  watchList,
  setting,

  alertDashboard,
  alertManage,

  stock,
  news,

  notification: notificationStore,
  message,

  stockPage,

  journalDashboard,
  journalManage,
  journalDetailView,
  paymentSettings,

  brokerage,
  brokerageAnalytic,
};

const { useStoreState, useStoreActions } = createTypedHooks<StoreModel>();

export const store = createStore(model);
export { useStoreState, useStoreActions };
