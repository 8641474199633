import { CustomHeader } from "api/definition/common.definition";
import axios, { Method } from "axios";
import { getFirebaseUserToken } from "../../utils/firebase.util";

interface SendRequestProps<T> {
  endpointInfo: {
    endpoint: string;
    method: Method;
  };
  data?: T;
  params?: object;
  extraPath?: string[];
  useTokenInHeaders?: boolean;
  customHeader?: CustomHeader;
}

export async function sendRequest<ResT = any, ReqT = any>({
  endpointInfo,
  data,
  useTokenInHeaders = true,
  customHeader,
  params,
  extraPath,
}: SendRequestProps<ReqT>) {
  const baseURL =
    endpointInfo.endpoint + (extraPath ? `${extraPath?.join("/")}` : "");
  const headers: Record<string, any> = { ...customHeader };

  if (useTokenInHeaders) {
    const token = await getFirebaseUserToken();
    headers["Authorization"] = `Bearer ${token}`;
  }

  const response = await axios({
    baseURL,
    params,
    headers,
    method: endpointInfo.method,
    data,
  });

  return response.data as ResT;
}
