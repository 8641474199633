import { Action, action, computed, Computed } from "easy-peasy";
import lodash from "lodash";
import { JournalDto } from "models/dto/journal/journal.dto";
import { Transaction } from "models/schema/transaction.model";

export interface JournalDetailViewModel {
  // * State
  journal: JournalDto | null;

  tickerList: Computed<JournalDetailViewModel, string[]>;
  alertIdList: Computed<JournalDetailViewModel, string[]>;

  // * Actions
  resetStore: Action<JournalDetailViewModel>;
  setJournal: Action<JournalDetailViewModel, JournalDto | null>;
}

export const journalDetailView: JournalDetailViewModel = {
  // * State
  journal: null,

  tickerList: computed(
    [(state) => state.journal?.transactionsMap],
    (transactionsMap) => {
      const tickerList: string[] = [];
      if (transactionsMap) {
        Object.values(transactionsMap).forEach((transactionList) => {
          tickerList.push(
            ...(transactionList as Transaction[]).map((entry) => entry.ticker)
          );
        });
      }

      const uniqTickerList = lodash.uniq(tickerList);
      return uniqTickerList;
    }
  ),
  alertIdList: computed(
    [(state) => state.journal?.alertIdList],
    (alertIdList) => alertIdList || []
  ),

  // * Actions
  resetStore: action((state) => {
    state.journal = null;
  }),
  setJournal: action((state, journal) => {
    state.journal = journal;
  }),
};
