import { mediaService } from "api/service";
import { bytePerMegaByte } from "common/common";
import { journalFormName } from "common/journal/journal-manage.info";
import { routePathMap } from "common/routeList";
import { internalErrorMessage } from "common/standardMessage";
import { predefinedTagList } from "common/tag.info";
import {
  BottomSheet,
  CustomModal,
  FormInput,
  SimpleWysiwyg,
  Tag,
  TagBySectionComponent,
  TagContainer,
} from "components/common";
import { TransactionViewerComponent } from "components/pages/Journal/Manage/TransactionViewerComponent";
import { Transaction } from "models/schema/transaction.model";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Journal/Manage/NoteSection.sass";
import { getMediaBreakpoint } from "utils/common.utils";
import { extractFunctionError } from "utils/error.util";
import { getShuffledTagList } from "utils/stock.util";
import { toastCustomMessage } from "utils/ui.util";

const TagSection = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const [modalOpen, setModalOpen] = useState(false);

  const { addTag, removeTag } = useStoreActions(
    (actions) => actions.journalManage,
  );
  const entry = useStoreState((state) => state.journalManage.entry);
  if (!entry) {
    throw new Error(internalErrorMessage.nullJournalEntry);
  }

  const tagListToRender = useMemo(() => {
    const shuffledTagMap = getShuffledTagList();
    const newTagList = Object.values(shuffledTagMap);

    const MAX_TAG_TO_RENDER = isLg ? 5 : 3;
    return newTagList.slice(0, MAX_TAG_TO_RENDER);
  }, [isLg]);

  const renderModalHeader = () => "Add or Remove Tags";
  const renderModalContent = () => (
    <TagBySectionComponent
      tagList={predefinedTagList as any}
      activeTagList={entry.tagList || []}
      addTag={addTag}
      removeTag={removeTag}
      className="note-section-modal-tag-container"
    />
  );

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="new-tag-section">
        <div className="inner-container">
          <TagContainer
            tagList={tagListToRender}
            onClick={(tag) => {
              if (entry.tagList.includes(tag)) {
                removeTag(tag);
              } else {
                addTag(tag);
              }
            }}
            checkActive={(tag) => entry.tagList.includes(tag)}
          />
        </div>

        <Tag
          onClick={() => {
            setModalOpen(true);
          }}
        >
          + More
        </Tag>
      </div>

      {modalOpen &&
        (isLg ? (
          <CustomModal
            midComponent={renderModalHeader()}
            onClickAway={closeModal}
            onCloseClick={closeModal}
          >
            {renderModalContent()}
          </CustomModal>
        ) : (
          <BottomSheet
            isOpen={modalOpen}
            closeAllOnClose
            showAllOnOpen
            supplementaryContent={renderModalContent()}
            maxHeightInPercent={80}
            onChange={(isActive) => {
              if (!isActive) {
                closeModal();
              }
            }}
          >
            {renderModalHeader()}
          </BottomSheet>
        ))}
    </>
  );
};

const uploadImageToServer = async (file: File) => {
  try {
    const MAX_MEGABYTE = 1;
    if (file.size > MAX_MEGABYTE * bytePerMegaByte) {
      toastCustomMessage(
        `Your file is larger than ${MAX_MEGABYTE}MB. Please try to resize the image before uploading`,
        "warning",
      );
      return "";
    }

    return (await mediaService.uploadImage([file]))[0];
  } catch (error) {
    const { detailMessage } = await extractFunctionError(error);
    toastCustomMessage(`Fail to upload image ${detailMessage}`, "error");
    console.error(`Fail to upload image ${error}`);
    return "";
  }
};

// TODO: Delete later
// const deleteImageFromServer = async (urlList: string[]) => {
//   try {
//     return await mediaService.deleteImageFromUrlList(urlList);
//   } catch (error) {
//     const { detailMessage } = await extractFunctionError(error);
//     toastCustomMessage(`Fail to upload image ${detailMessage}`, "error");
//     return false;
//   }
// };

export const NoteSection = () => {
  const history = useHistory();

  const { control, register, errors, trigger, setError } = useForm();

  const entry = useStoreState((state) => state.journalManage.entry);
  const transactionList = useStoreState(
    (state) => state.journalManage.transactionList,
  );
  const {
    setJournalNote,
    setJournalName,
    submitEntry,
    removeTransaction,
    resetStore,
    addImageSrc,
  } = useStoreActions((actions) => actions.journalManage);

  if (!entry) {
    // TODO: Add a empty screen or redirect back
    return <div>Entry missing</div>;
  }

  const onTransactionDeleteClick = (transaction: Transaction) => {
    removeTransaction({ id: transaction.id, type: transaction.type });
  };

  const onUploadImageToServer = async (file: File): Promise<string> => {
    const newSrc = await uploadImageToServer(file);
    addImageSrc(newSrc);
    return newSrc;
  };

  const onSaveClick = async () => {
    if (await trigger()) {
      // TODO: Decouple this
      const error = await submitEntry();

      if (!error) {
        history.push(routePathMap.journalDashboard);
        resetStore();
      } else {
        console.error(error);
      }
    }
  };

  return (
    <div className="note-section">
      <div className="note">
        <FormInput
          className="entry-name-section"
          borderType="no-border"
          placeholder="Title"
          formValidator={{
            name: journalFormName.title,
            inputRef: register({ required: "Can't be empty" }),
            errorText: errors[journalFormName.title]?.message,
          }}
          defaultValue={entry.title}
          onChange={(val) => {
            setJournalName(val);
            setError(journalFormName.title, {});
          }}
        />

        <TagSection />

        <SimpleWysiwyg
          placeholder="Your text here..."
          defaultValue={entry.htmlNote}
          formValidator={{ name: journalFormName.htmlNote, control }}
          onChange={(val) => {
            setJournalNote(val);
          }}
          extraSection={
            transactionList.length > 0 && (
              <TransactionViewerComponent
                transactionList={transactionList}
                onDeleteClick={onTransactionDeleteClick}
              />
            )
          }
          uploadImageToServer={onUploadImageToServer}
        />
      </div>

      <div className="save-container">
        <button onClick={onSaveClick} className="save-button">
          Save
        </button>
      </div>
    </div>
  );
};
