export const CURRENCY_DECIMAL = 2;
export const CURRENCY_DECIMAL_STEP = 1 / 10 ** CURRENCY_DECIMAL;
export const PERCENT_DECIMAL = 2;
export const PERCENT_DECIMAL_STEP = 1 / 10 ** PERCENT_DECIMAL;
export const COMMON_DECIMAL = 2;
export const COMMON_DECIMAL_STEP = 1 / 10 ** COMMON_DECIMAL;
export const SHARE_PER_CONTRACT = 100;
export const CURRENCY_SIGN = "$";
export const CENTS_PER_USD = 100;
export const CURRENCY = "USD";
export const CURRENCY_DECIMAL_SEPARATOR = ".";
export const THOUSAND_SEPARATOR = ",";

export enum PriceChangeState {
  Positive,
  Neutral,
  Negative,
}

export const ROOT_ID = "root";

export const bytePerMegaByte = 1000000;
