import cx from "classnames";
import { ConditionLimitType } from "common/alert/alert-condition.info";
import { alertConditionMap } from "common/alert/alert-manage.info";
import { Icon } from "components/common";
import { AlertConditionModel } from "models/dto/alert";
import React from "react";
import "styles/components/pages/Alert/AlertConditionIcon.sass";

const getConditionIcon = (condition: AlertConditionModel) =>
  alertConditionMap[condition.type]?.icon || ["fas", "bell"];

interface AlertConditionIconProps {
  condition: AlertConditionModel;
  className?: string;
}
export const AlertConditionIcon = ({
  className,
  condition,
}: AlertConditionIconProps) => {
  // TODO: Find a way to lint this
  // @ts-ignore
  const toCheckAttribute = condition["limitType"] as
    | ConditionLimitType
    | undefined;
  const usePositiveColor = toCheckAttribute && toCheckAttribute === "above";
  const useNegativeColor = toCheckAttribute && toCheckAttribute === "below";

  return (
    <Icon
      icon={getConditionIcon(condition)}
      className={cx(
        "alert-condition-icon",
        "fa-fw",
        { "stock-change-neu": !usePositiveColor && !useNegativeColor },
        { "stock-change-pos": usePositiveColor },
        { "stock-change-neg": useNegativeColor },
        className
      )}
    />
  );
};
