import {
  AlertDashboardLoadingType,
  AlertDashboardView,
} from "common/alert/alert-page.info";
import {
  CardShimmer,
  Icon,
  InformationCard,
  ShimmerContainer,
} from "components/common";
import { NotificationCard } from "components/common/NotificationCard";
import { CompanyAlertSummaryCard } from "components/pages/Alert/CompanyAlertSummaryCard";
import React, { useEffect } from "react";
import FadeIn from "react-fade-in";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/AlertDashboard/AlertAndNotificationView.sass";

const AlertSection = () => {
  const { toggleAlertList, setCurDashboardView } = useStoreActions(
    (actions) => actions.alertDashboard
  );
  const alertByCompanyList = useStoreState(
    (state) => state.alertDashboard.alertByCompanyList
  );
  const isTogglingAlert = useStoreState((state) =>
    state.alertDashboard.isLoading(AlertDashboardLoadingType.ToggleAlert)
  );
  const isLoadingAlert = useStoreState((state) =>
    state.alertDashboard.isLoading(AlertDashboardLoadingType.LoadingAlert)
  );

  const { initializeStore } = useStoreActions((actions) => actions.alertManage);

  const addNewAlert = () => {
    initializeStore(null);
  };

  const seeAllAlert = () => {
    setCurDashboardView(AlertDashboardView.AllAlert);
  };

  return (
    <div className="alert-section">
      <div className="title">
        <h3>Your Alerts</h3>
        <button onClick={seeAllAlert}>See all</button>
      </div>

      <ShimmerContainer
        shimmerComponent={<CardShimmer />}
        isReady={!isTogglingAlert && !isLoadingAlert}
        numShimmer={1}
      >
        <FadeIn className="alert-summary-container">
          <button className="add-alert-button" onClick={addNewAlert}>
            <p>Add alert</p>
            <Icon icon={["cio", "add-alert"]} />
          </button>

          {alertByCompanyList.map((entry, index) => {
            if (entry) {
              let isActive = false;
              for (const alert of entry.alertList) {
                if (alert.isActive) {
                  isActive = true;
                  break;
                }
              }

              const alertIdList = entry.alertList.map((alert) => alert.alertId);
              const onToggle = (curActive: boolean) => {
                toggleAlertList({ alertIdList, curActive });
              };

              return (
                <CompanyAlertSummaryCard
                  key={index}
                  alertList={entry.alertList}
                  companySummary={entry.companySummary}
                  isActive={isActive}
                  type="card"
                  onToggle={onToggle}
                />
              );
            }

            return null;
          })}
        </FadeIn>
      </ShimmerContainer>
    </div>
  );
};

const NotificationSection = () => {
  const { setCurDashboardView } = useStoreActions(
    (actions) => actions.alertDashboard
  );
  const { markAllOfTypeAsRead } = useStoreActions(
    (actions) => actions.notification
  );

  const alertNotificationList = useStoreState((state) =>
    state.notification.notificationListByType("alert")
  );

  useEffect(() => {
    markAllOfTypeAsRead("alert");
  }, [markAllOfTypeAsRead]);

  return (
    <div className="notification-section">
      <div className="title">
        <h3>Recent Notifications</h3>
        <button
          onClick={() => {
            setCurDashboardView(AlertDashboardView.AllNotification);
          }}
        >
          See all
        </button>
      </div>

      <div className="notification-container">
        {alertNotificationList.length > 0 ? (
          <>
            {alertNotificationList.map((entry) => (
              <NotificationCard
                notification={entry}
                type="card"
                key={entry.id}
              />
            ))}
          </>
        ) : (
          <InformationCard>
            <p>No new notification</p>
          </InformationCard>
        )}
      </div>
    </div>
  );
};

export const AlertAndNotificationView = () => {
  return (
    <div className="alert-and-notification-view">
      <AlertSection />

      <NotificationSection />
    </div>
  );
};
