import Color from "color";
import { Point } from "highcharts";
import { useThemeColor } from "hooks/useThemeColor";
import { formatCurrency, sortDayCallback } from "utils/common.utils";
import { getQuarterStrFromDateStr } from "utils/stock.util";
import { ScatterPlot } from "./ScatterPlot";

const markerRadius = 8;
interface EarningChartProps {
  /** [Fiscal Date in YYYY-MM-DD, EPS of that quarter] */
  actualEPSDataList: [string, number | null][];
  /** [Fiscal Date in YYYY-MM-DD, EPS of that quarter] */
  expectedEPSDataList: [string, number | null][];
}

function formatter(type: "Expected" | "Actual") {
  return (point: Point) => {
    const quarterStr = getQuarterStrFromDateStr(point.options.name as any);
    return `${type} earning for ${quarterStr}: ${formatCurrency(point.y || 0)}`;
  };
}
export const EarningChart = ({
  actualEPSDataList,
  expectedEPSDataList,
}: EarningChartProps) => {
  const actualEPSColor = useThemeColor("positive-blue");
  const expectedEPSColor = Color(actualEPSColor).alpha(0.2).rgb().toString();

  const textColor = useThemeColor("secondary");

  return (
    <ScatterPlot
      options={{
        title: { text: "" },
        credits: { enabled: false },
        xAxis: {
          type: "datetime",
          labels: {
            style: { color: textColor },
            formatter: (chart) =>
              getQuarterStrFromDateStr(
                actualEPSDataList[chart.value as any][0]
              ),
          },
          lineWidth: 0,
          tickLength: 0,
        },
        yAxis: {
          labels: {
            style: { color: textColor },
            formatter: (chart) => formatCurrency(parseFloat(chart.value)),
          },
          title: { text: "" },
          gridLineColor: "transparent",
        },
        legend: { itemStyle: { color: textColor } },
      }}
      series={[
        {
          name: "Actual EPS",
          color: actualEPSColor,
          // @ts-ignore
          data: actualEPSDataList.sort((entryA, entryB) =>
            sortDayCallback(entryA[0], entryB[0])
          ),
          // @ts-ignore
          marker: { radius: markerRadius },
          tooltip: {
            pointFormatter: function (this) {
              return formatter("Actual")(this);
            },
          },
        },
        {
          // @ts-ignore
          marker: { symbol: "circle", radius: markerRadius },
          name: "Expected EPS",
          color: expectedEPSColor,
          data: expectedEPSDataList.sort((entryA, entryB) =>
            sortDayCallback(entryA[0], entryB[0])
          ),
          tooltip: {
            pointFormatter: function (this) {
              return formatter("Expected")(this);
            },
          },
        },
      ]}
    />
  );
};
