import cx from "classnames";
import {
  activeBrokerage,
  ActiveCSVBrokerage,
  supportedBrokerageDisplayMap,
} from "common/brokerage.info";
import { FileUploadComponent } from "common/FileUploadComponent";
import { TransactionType } from "common/transaction/transaction.info";
import {
  DropdownNoForm,
  FormDropDown,
  Icon,
  TransactionSummary,
} from "components/common";
import { Transaction } from "models/schema/transaction.model";
import React, { useRef, useState } from "react";
import { useStoreState } from "stores";
import "styles/components/pages/Journal/Manage/UploadCSVToTransactionComponent.sass";
import { parseCsv } from "utils/csv.util";
import { transformCSVDataToTransaction } from "utils/transaction.util";
import { toastCustomMessage } from "utils/ui.util";
import { SubscriptionViewTriggerButton } from "views";

interface UploadCSVComponentProps {
  addTransaction: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  removeTransaction: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
  checkActiveTransaction?: (transaction: Transaction) => boolean;
}

export const UploadCSVToTransactionComponent = ({
  addTransaction,
  removeTransaction,
  checkActiveTransaction,
}: UploadCSVComponentProps) => {
  // * Account state
  const canUploadCSV = useStoreState((state) =>
    state.account.canUseFeature("uploadBrokerCSV")
  );

  const chosenBrokerage = useRef<ActiveCSVBrokerage>(activeBrokerage[0]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [uploadResult, setUploadResult] = useState<{
    file: File;
    transactionList: Transaction[];
  } | null>(null);

  const onFileAccepted = async (fileList: File[]) => {
    const curBrokerage = chosenBrokerage.current;

    if (fileList.length === 0) {
      toastCustomMessage(
        "File upload failed, please check your file and try again",
        "error"
      );
      return;
    }

    try {
      const result = await parseCsv(fileList[0]);
      const transactionList = transformCSVDataToTransaction(
        (result as any).data,
        curBrokerage
      );

      setUploadResult({ file: fileList[0], transactionList });
    } catch (error) {
      console.error(`Uploading csv for ${curBrokerage}`);
    } finally {
      setIsProcessing(false);
    }
  };

  if (!canUploadCSV) {
    return (
      <div className="subscription-block">
        <p>Only Premium and Elite Members Can Use This Feature</p>
        <SubscriptionViewTriggerButton text="Check Subscription" />
      </div>
    );
  }

  const onDrop = () => {
    setIsProcessing(true);
  };

  const onReuploadClick = () => {
    setUploadResult(null);
  };

  return (
    <div className="upload-CSV-to-transaction-component">
      <div className={cx("overlay", { "overlay-show": isProcessing })}>
        Processing...
      </div>

      {uploadResult ? (
        <div className="result-container">
          <div className="file-info">
            <div className="basic">
              <Icon icon={["fas", "file-excel"]} className="fa-fw" />
              <p>{uploadResult.file.name}</p>
            </div>

            <DropdownNoForm
              mainButtonContent={<Icon icon={["fas", "ellipsis-v"]} />}
              contentClassName="journal-manage-file-upload-retry"
            >
              <button onClick={onReuploadClick}>
                <p>Delete File</p>
                <Icon icon={["fas", "trash"]} className="fa-fw" />
              </button>
            </DropdownNoForm>
          </div>

          <div className="transaction-container">
            {uploadResult.transactionList.map((transaction) => (
              <TransactionSummary
                key={transaction.id}
                transaction={transaction}
                isSelected={checkActiveTransaction?.(transaction) || false}
                onSelectClick={(isChecked) => {
                  const handler = isChecked
                    ? addTransaction
                    : removeTransaction;

                  handler({
                    type: transaction.type,
                    transaction: transaction,
                  });
                }}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="uploader-container">
          <FormDropDown
            className="brokerage-selector"
            dataList={activeBrokerage.map((brokerage) => ({
              value: brokerage,
              label: (
                <div className="selector-item">
                  <img
                    src={supportedBrokerageDisplayMap[brokerage].imgUrl}
                    alt={supportedBrokerageDisplayMap[brokerage].name}
                  />

                  <p>{supportedBrokerageDisplayMap[brokerage].name}</p>
                </div>
              ),
            }))}
            label="Choose Brokerage"
            onChange={(value) => {
              chosenBrokerage.current = value;
            }}
          />

          <FileUploadComponent
            dragAndDropText="Upload your file here"
            dialogOpenerComponent="Upload File"
            dropdownOptions={{
              accept: ".csv , .xlsx",
              maxFiles: 1,
              onDrop: onDrop,
              onDropAccepted: onFileAccepted,
              onDropRejected: () => {
                toastCustomMessage(
                  "File is invalid, please choose a CSV file",
                  "error"
                );
                setIsProcessing(false);
              },
            }}
          />
        </div>
      )}
    </div>
  );
};
