import cx from "classnames";
import Color from "color";
import { chartTimeSelectorList } from "common/stock/stock-graph.info";
import { TimeSelectorButton } from "components/common/Chart/TimeSelectorButton";
import dayjs from "dayjs";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { PointOptionsObject } from "highcharts/highstock";
import { useThemeColor } from "hooks/useThemeColor";
import lodash from "lodash";
import { ChartData, DailyPrice } from "models";
import React, { useMemo, useRef, useState } from "react";
import { useAsync, useMeasure } from "react-use";
import { useStoreActions } from "stores";
import "styles/components/common/StockChart.sass";
import { getDataRangeFromTimeSelector } from "utils/chart.util";
import { formatCurrency } from "utils/common.utils";

type StockUIData = PointOptionsObject[];
type ChartRange = { max: number; min: number };

type SeriesColorType = "upperGradient" | "lowerGradient" | "lineColor";
type SeriesColorMap = Record<SeriesColorType, string>;

type ChartColorKey = "labelColor" | "gridLine";

interface RawChartUIData {
  data: {
    chartData: StockUIData;
    ticker: string;
  }[];
  range: ChartRange;
}
interface ChartUIData {
  series: Highcharts.SeriesOptionsType[];
  range: ChartRange;
}

const getStockUIDataFromChartData = (
  chartDataList: ChartData[]
): StockUIData[] =>
  chartDataList.map((chartData) => {
    if (chartData.length <= 0) {
      return [];
    }

    const transformedDataList: StockUIData = [];

    const hasMinuteInterval = chartData[0].hasOwnProperty("minute");
    chartData.forEach((curData, curIndex) => {
      let dateString = curData.date;
      if (hasMinuteInterval) {
        dateString += ` ${(curData as DailyPrice).minute}`;
      }

      const tempData = {
        x: dayjs.tz(dateString, "EST").unix(),
        y: curData.close,
      };

      // Search for previous non-null
      for (
        let index = curIndex - 1;
        tempData.y === null && index >= 0;
        index--
      ) {
        tempData.y = chartData[index].close;
      }

      // Search for next non-null
      for (
        let index = curIndex + 1;
        tempData.y === null && index < chartData.length;
        index++
      ) {
        tempData.y = chartData[index].close;
      }

      transformedDataList.push(tempData);
    });

    return transformedDataList;
  });

const createChartOptions = (
  chartData: ChartUIData,
  colorMap: Record<ChartColorKey, string>,
  options?: { width?: number; height?: number }
): Highcharts.Options => ({
  chart: {
    margin: 0,
    height: options?.height,
    width: options?.width,
    backgroundColor: "transparent",
    animation: { duration: 1000 },
  },
  credits: { enabled: false },
  xAxis: {
    labels: { enabled: false },
    crosshair: { zIndex: 3, dashStyle: "Dash" },
    gridLineColor: colorMap["gridLine"],
  },
  yAxis: {
    visible: false,  // TODO: Change this on candle line chart
    maxPadding: 0.5,
    gridLineDashStyle: "Dash",
    crosshair: { zIndex: 3 },
    labels: {
      x: -10,
      style: { color: colorMap["labelColor"] },
      align: "right",
    },
    max: chartData.range.max,
    min: chartData.range.min,
    gridLineColor: colorMap["gridLine"],
  },
  navigator: { enabled: false },
  scrollbar: { enabled: false },
  rangeSelector: { enabled: false },
  tooltip: {
    headerShape: "callout",
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    className: "custom-highchart-tooltip",
    backgroundColor: "transparent",
    formatter() {
      const formattedTime = dayjs.unix(this.x).format("MMM DD, YYYY h:mm A");

      // Source: https://jsfiddle.net/j3mhoger/
      // Return an array of 1 + numTicker elements:
      //  header (usually time), then value of each ticker at that point
      return [`${formattedTime}`].concat(
        this.points?.map((point) => {
          const price = point.y;
          const tickerName = point.series.name;
          return `${tickerName}: ${formatCurrency(price)}`;
        }) || []
      );
    },
    split: true,
  },
  series: chartData.series,
});

const createSplineSeries = (
  stockData: StockUIData,
  name: string,
  colorMap: SeriesColorMap
): Highcharts.SeriesOptionsType => ({
  turboThreshold: Math.max(stockData.length, 5000),
  type: "spline",
  name: name,
  data: stockData,
  // TODO: Find a way to dynamically replace this
  // color: colorMap["lineColor"],
});

const createAreaSplineSeries = (
  stockData: StockUIData,
  name: string,
  colorMap: SeriesColorMap
): Highcharts.SeriesOptionsType => ({
  turboThreshold: Math.max(stockData.length, 5000),
  type: "areaspline",
  name: name,
  data: stockData,
  fillColor: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [
      [0, colorMap["lowerGradient"]],
      [1, colorMap["upperGradient"]],
    ],
  },
  lineColor: colorMap["lineColor"],
});

interface StockChartProps {
  tickerList: string[];
  chartOptions?: { height?: number };
}
export const StockChart = ({ tickerList, chartOptions }: StockChartProps) => {
  // * --- Color
  const lineColor = useThemeColor("positive-blue");
  const upperGradient = useThemeColor("chart-upper-gradient");
  const lowerGradient = useThemeColor("chart-lower-gradient");
  const labelColor = useThemeColor("secondary");

  // TODO: Support different colors for multiple tickers
  const seriesColorMap: SeriesColorMap = useMemo(
    () => ({
      lineColor,
      upperGradient,
      lowerGradient,
    }),
    [lineColor, upperGradient, lowerGradient]
  );

  const chartColorMap = useMemo(
    () => ({
      labelColor,
      gridLine: Color(labelColor).alpha(0.1).toString(),
    }),
    [labelColor]
  );

  // * --- UI and Selector
  const [chartRef, { width }] = useMeasure<HTMLDivElement>();
  const [selectorIndex, setSelectorIndex] = useState(0);

  // * --- ETL
  const { getChartDataForTickerList } = useStoreActions(
    (actions) => actions.stock
  );

  // --- Fetching data
  const prevRawChartData = useRef<
    { rawData: RawChartUIData; timeRangeIndex: number } | undefined
  >(undefined);

  const { value: rawChartData, loading: isFetchingChartData } = useAsync<
    () => Promise<{ rawData: RawChartUIData; timeRangeIndex: number }>
  >(async () => {
    if (tickerList.length <= 0) {
      return {
        rawData: { data: [], range: { min: 0, max: 0 } },
        timeRangeIndex: selectorIndex,
      };
    }

    if (
      prevRawChartData.current &&
      prevRawChartData.current.timeRangeIndex === selectorIndex
    ) {
      const prevTickerList = prevRawChartData.current.rawData.data
        .map((entry) => entry.ticker)
        .sort();
      const newTickerList = [...tickerList].sort();

      if (lodash.isEqual(prevTickerList, newTickerList)) {
        // No point in fetching if ticker list is the same
        return prevRawChartData.current;
      }
    }

    const timeRange = getDataRangeFromTimeSelector(
      chartTimeSelectorList[selectorIndex]
    );

    const chartDataInfoList = await getChartDataForTickerList({
      tickerList: tickerList,
      timeRange: timeRange,
    });
    chartDataInfoList.sort((entryA, entryB) =>
      entryA.ticker.localeCompare(entryB.ticker)
    );

    const stockUIDataList = getStockUIDataFromChartData(
      chartDataInfoList.map((chartData) => chartData.chartData)
    );

    const min = Math.min(
      ...stockUIDataList.map((entry) =>
        Math.min(...entry.map((entry) => entry.y || 9999))
      )
    );

    const max = Math.max(
      ...stockUIDataList.map((entry) =>
        Math.max(...entry.map((entry) => entry.y || 0))
      )
    );

    const returnData = {
      data: stockUIDataList.map((chartData, index) => ({
        chartData: chartData,
        ticker: chartDataInfoList[index].ticker,
      })),
      // For aesthetic reason
      range: { min: min, max: max },
    };

    prevRawChartData.current = {
      rawData: returnData,
      timeRangeIndex: selectorIndex,
    };
    return { rawData: returnData, timeRangeIndex: selectorIndex };
  }, [tickerList, selectorIndex]);

  // --- Transforming display data
  const displayData = useMemo(() => {
    if (rawChartData && rawChartData.rawData.data.length > 0) {
      // Use Spline series when there is 1 ticker
      //  and AreaSpline when there are multiple
      const transformer =
        rawChartData.rawData.data.length === 1
          ? createAreaSplineSeries
          : createSplineSeries;

      return {
        series: rawChartData.rawData.data.map(({ chartData, ticker }) =>
          transformer(chartData, `${ticker} Price`, seriesColorMap)
        ),
        range: rawChartData.rawData.range,
      };
    }

    return { series: [], range: { min: 0, max: 0 } };
  }, [rawChartData, seriesColorMap]);

  return (
    <div ref={chartRef} className="stock-chart">
      <div className="chart-container">
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="stockChart"
          options={createChartOptions(displayData, chartColorMap, {
            width: width,
            height: chartOptions?.height,
          })}
          allowChartUpdate
        />
      </div>

      <div className="time-selector-container">
        {chartTimeSelectorList.map((timeSelector, index) => (
          <TimeSelectorButton
            key={`${timeSelector.type}-${timeSelector.displayText}`}
            timeSelector={timeSelector}
            onClick={() => {
              if (selectorIndex !== index) {
                setSelectorIndex(index);
              }
            }}
            isActive={index === selectorIndex}
          />
        ))}
      </div>

      <div
        className={cx("overlay", {
          "overlay-show": isFetchingChartData || tickerList.length <= 0,
        })}
      >
        {tickerList.length <= 0 ? "No Data to Show" : "Loading chart..."}
      </div>
    </div>
  );
};
