import { accountFormName, usStates } from "common/account.info";
import { fullNameRegex, zipCodeRegex } from "common/regex";
import {
  FormDatePicker,
  FormDropDown,
  FormInput,
  FormShimmer,
  Icon,
  ShimmerContainer,
} from "components/common";
import { EmailResetComponent } from "components/common/ModalComponent";
import dayjs from "dayjs";
import "firebase/auth";
import parsePhoneNumber from "libphonenumber-js";
import { AccountFirebaseInfo, AccountInfo } from "models";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Setting/AccountView.sass";
import {
  getMediaBreakpoint,
  getPhoneNumberFromString,
  parseToValidApiDateString,
} from "utils/common.utils";
import { getFirebaseUser } from "utils/firebase.util";

interface AccountInfoForm {
  fullName: string;
  dateOfBirth: string;
  phoneNumber: string;
  streetAddress: string;
  city: string;
  zipCode: string;
  state: string;
  country: string;
}
export const AccountView = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  const {
    register,
    errors,
    handleSubmit,
    control,
    reset,
  } = useForm<AccountInfoForm>();
  const { setPreset, setContent } = useStoreActions((actions) => actions.modal);

  const firebaseUser = useStoreState((state) => state.account.firebaseUser);
  const account = useStoreState((state) => state.account.account);
  const { setAccount } = useStoreActions((actions) => actions.account);

  const isInEditMode = useStoreState((state) => state.setting.isInEditMode);
  const { changeAccountInfo, changeEmail, setIsInEditMode } = useStoreActions(
    (actions) => actions.setting
  );

  useEffect(() => {
    if (!isInEditMode) {
      reset();
    }
  }, [isInEditMode, reset]);

  const isReady = getFirebaseUser() != null;
  const turnOnEditMode = () => setIsInEditMode(true);

  const { accountInfo, accountFirebaseInfo, currentPlan } = account || {};
  const curPhoneNumber =
    parsePhoneNumber(
      accountFirebaseInfo?.phoneNumber || ""
    )?.nationalNumber.toString() || "";

  const onSubmit = handleSubmit(async (data) => {
    const rawPhoneNumber = getPhoneNumberFromString(data.phoneNumber);
    let newPhoneNumber = "";
    if (rawPhoneNumber.length > 0) {
      newPhoneNumber =
        parsePhoneNumber(rawPhoneNumber, "US")?.number.toString() || "";
    }

    const newDateOfBirth = parseToValidApiDateString(
      (data.dateOfBirth as unknown) as Date
    );

    const accountInfoToUpdate: AccountInfo = {
      dateOfBirth: newDateOfBirth,
      address: {
        streetAddress: data.streetAddress,
        city: data.city,
        zipCode: data.zipCode,
        state: data.state,
        country: data.country,
      },
    };
    const accountFirebaseInfoToUpdate: AccountFirebaseInfo = {
      displayName: data.fullName,
      phoneNumber: newPhoneNumber,
    };
    const apiResult = await changeAccountInfo({
      accountInfo: accountInfoToUpdate,
      accountFirebaseInfo: accountFirebaseInfoToUpdate,
    });

    // TODO: Handle error here
    if (apiResult.result) {
      const {
        updatedAccountInfo,
        updatedAccountFirebaseInfo,
      } = apiResult.result;
      setAccount({
        accountInfo: updatedAccountInfo,
        accountFirebaseInfo: updatedAccountFirebaseInfo,
        currentPlan: currentPlan!,
      });
      reset({
        fullName: updatedAccountFirebaseInfo.displayName || undefined,
        phoneNumber: updatedAccountFirebaseInfo.phoneNumber || undefined,

        dateOfBirth: updatedAccountInfo.dateOfBirth || undefined,
        streetAddress: updatedAccountInfo.address.streetAddress || undefined,
        city: updatedAccountInfo.address.city || undefined,
        zipCode: updatedAccountInfo.address.zipCode || undefined,
        state: updatedAccountInfo.address.state || undefined,
        country: updatedAccountInfo.address.country || undefined,
      });
    }
  });
  const onChangeEmailAddress = () => {
    setContent({
      title: "Change email",
      body: (
        <EmailResetComponent
          onCancel={() => setContent(null)}
          onConfirm={async (newEmail) => {
            try {
              await changeEmail(newEmail);
              setContent(null);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      ),
    });
  };

  return (
    <form className="account-view" onSubmit={onSubmit}>
      {isLg && <h2 className="title">Account</h2>}

      <section>
        <h3>Profile</h3>

        <ShimmerContainer
          numShimmer={4}
          shimmerComponent={<FormShimmer />}
          isReady={isReady}
        >
          <FormInput
            label="Name"
            placeholder="John Smith"
            autoComplete="name"
            defaultValue={accountFirebaseInfo?.displayName || undefined}
            wrapperOnClick={turnOnEditMode}
            formValidator={{
              name: accountFormName.fullName,
              inputRef: register({
                required: "Can't be empty",
                pattern: { value: fullNameRegex, message: "Invalid name" },
              }),
              errorText: errors[accountFormName.fullName]?.message,
            }}
          />
          <FormDatePicker
            label="Birth Date"
            value={dayjs(accountInfo?.dateOfBirth || undefined).toDate()}
            wrapperOnClick={turnOnEditMode}
            formValidator={{
              name: accountFormName.dateOfBirth,
              control,
              inputRef: register,
            }}
            popperPlacement="bottom-end"
            rightCTA={<Icon icon={["far", "calendar"]} />}
          />
          <button
            className="forgot-password"
            onClick={() => {
              setPreset("forgotModal");
            }}
            type="button"
          >
            Reset Password
          </button>
        </ShimmerContainer>
      </section>

      <section>
        <h3>Contact</h3>

        <ShimmerContainer
          numShimmer={2}
          shimmerComponent={<FormShimmer />}
          isReady={isReady}
        >
          <FormInput
            label="Email Address"
            defaultValue={firebaseUser?.email || "No email associated"}
            readOnly={true}
            rightCTA={
              <button className="" onClick={onChangeEmailAddress} type="button">
                Change
              </button>
            }
          />

          <FormInput
            label="Phone number"
            autoComplete="tel"
            defaultValue={curPhoneNumber}
            placeholder="(999) 999-9999"
            wrapperOnClick={turnOnEditMode}
            maskProp={{ format: "(###) ###-####", mask: "_" }}
            formValidator={{
              name: accountFormName.phoneNumber,
              inputRef: register,
              errorText: errors[accountFormName.phoneNumber]?.message,
            }}
          />
        </ShimmerContainer>

        <div className="address-group">
          <ShimmerContainer
            numShimmer={1}
            shimmerComponent={<FormShimmer />}
            isReady={isReady}
          >
            <FormInput
              label="Street address"
              className="street"
              autoComplete="street-address"
              defaultValue={accountInfo?.address.streetAddress || undefined}
              wrapperOnClick={turnOnEditMode}
              formValidator={{
                name: accountFormName.streetAddress,
                inputRef: register,
                errorText: errors[accountFormName.streetAddress]?.message,
              }}
            />
          </ShimmerContainer>

          <ShimmerContainer
            numShimmer={2}
            shimmerComponent={<FormShimmer />}
            isReady={isReady}
          >
            <FormInput
              label="City"
              className="city"
              autoComplete="address-level2"
              defaultValue={accountInfo?.address.city || ""}
              wrapperOnClick={turnOnEditMode}
              formValidator={{
                name: accountFormName.city,
                inputRef: register,
                errorText: errors[accountFormName.city]?.message,
              }}
            />
            <FormDropDown
              label="State"
              className="state"
              borderType="no-border"
              dataList={usStates.map((state) => ({
                label: state.abbreviation,
                value: state.abbreviation,
              }))}
              defaultValue={accountInfo?.address.state}
              formValidator={{
                name: accountFormName.state,
                control: control,
                errorText: errors[accountFormName.state]?.message,
              }}
              isSearchable={true}
              wrapperOnClick={turnOnEditMode}
            />
          </ShimmerContainer>

          <ShimmerContainer
            numShimmer={2}
            shimmerComponent={<FormShimmer />}
            isReady={isReady}
          >
            <FormInput
              label="Zip Code"
              className="zip"
              autoComplete="postal-code"
              defaultValue={accountInfo?.address.zipCode || ""}
              wrapperOnClick={turnOnEditMode}
              formValidator={{
                inputRef: register({
                  pattern: {
                    message: "Invalid zip code",
                    value: zipCodeRegex,
                  },
                }),
                name: accountFormName.zipCode,
                errorText: errors[accountFormName.zipCode]?.message,
              }}
            />
            {/* // TODO: Change this to dropDown once we support it */}
            <FormInput
              label="Country"
              className="country"
              autoComplete="country-name"
              defaultValue={accountInfo?.address.country || ""}
              wrapperOnClick={turnOnEditMode}
              formValidator={{
                name: accountFormName.country,
                inputRef: register,
                errorText: errors[accountFormName.country]?.message,
              }}
            />
          </ShimmerContainer>
        </div>
      </section>

      {isInEditMode && <button className="save-button">Save</button>}
    </form>
  );
};
