import {
  PlanMetadataFeatureDto,
  FeatureKey,
} from "models/dto/payment/subscription-plan-metadata.dto";

const getValueFromSubscriptionNumberStr = (
  numStr: string,
  unlimitedValue: number = 9999999
): number | null => {
  if (numStr.toLowerCase() === "unlimited") {
    return unlimitedValue;
  }

  const maxNumber = Number(numStr);
  if (Number.isNaN(maxNumber)) {
    return null;
  }

  return maxNumber;
};

const getBooleanFromStr = (boolStr: string): boolean | null => {
  switch (boolStr.toLowerCase()) {
    case "yes":
      return true;
    case "no":
      return false;
    default:
      return null;
  }
};

export const getNumberValueFromFeatureKey = (
  featureList: PlanMetadataFeatureDto[] | null,
  key: FeatureKey,
  unlimitedFallback = 99999999
) => {
  return (
    getValueFromSubscriptionNumberStr(
      featureList?.find((entry) => entry.featureKey === key)?.value ||
        "unlimited",
      unlimitedFallback // ! Let the BE handle if somehow we can't get feature data
    ) || unlimitedFallback
  );
};

export const getBoolValueFromFeatureKey = (
  featureList: PlanMetadataFeatureDto[] | null,
  key: FeatureKey
) => {
  return (
    getBooleanFromStr(
      featureList?.find((entry) => entry.featureKey === key)?.value ||
        "unlimited"
    ) || false // ! Let the BE handle if somehow we can't get feature data
  );
};
