import { AlertConditionType } from "common/alert/alert-condition.info";
import {
  AlertSubType,
  AlertSubTypeData,
  AlertTypeData,
} from "common/alert/alert-manage.info";
import { Icon } from "components/common";
import React from "react";
import "styles/components/pages/Alert/ConditionTypeCard.sass";
import cx from "classnames";

interface CardInnerContentProps {
  data: AlertTypeData | AlertSubTypeData;
}
const CardInnerContent = ({
  data: { icon, name, description },
}: CardInnerContentProps) => {
  return (
    <>
      {icon && <Icon icon={icon} className="icon fa-fw" />}

      <div className="content">
        <p className="name">{name}</p>
        <p className="description">{description}</p>
      </div>

      <Icon icon={["fas", "chevron-right"]} className="icon fa-fw" />
    </>
  );
};

interface ConditionTypeCardProps {
  onClick: (type: AlertConditionType) => void;
  type: AlertConditionType;
  data: AlertTypeData;
  isActive: boolean;
}
export const ConditionTypeCard = ({
  type,
  data,
  onClick,
  isActive,
}: ConditionTypeCardProps) => {
  return (
    <button
      type="button"
      onClick={() => {
        onClick(type);
      }}
      className={cx("condition-type-card", {
        "condition-type-card-active": isActive,
      })}
    >
      <CardInnerContent data={data} />
    </button>
  );
};

interface ConditionSubTypeCardProps {
  onClick: (subType: AlertSubType) => void;
  subType: AlertSubType;
  data: AlertSubTypeData;
  isActive: boolean;
}
export const ConditionSubTypeCard = ({
  subType,
  data,
  onClick,
  isActive,
}: ConditionSubTypeCardProps) => {
  return (
    <button
      type="button"
      onClick={() => {
        onClick(subType);
      }}
      className={cx("condition-type-card", {
        "condition-type-card-active": isActive,
      })}
    >
      <CardInnerContent data={data} />
    </button>
  );
};
