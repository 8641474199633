import {
  ActiveCSVBrokerage,
  supportedBrokerage,
  SupportedBrokerage,
} from "common/brokerage.info";
import { STANDARD_DATE_FORMAT } from "common/locale/dateLocale";
import {
  TransactionOptionType,
  TransactionSource,
  transactionSourceList,
} from "common/transaction/transaction.info";
import dayjs from "dayjs";
import lodash from "lodash";
import {
  OptionTransactionModel,
  StockTransactionModel,
  Transaction,
} from "models/schema/transaction.model";

type AllowedBrokerage = SupportedBrokerage | "unknown";
const checkIfValidBrokerage = (
  brokerage: string
): brokerage is AllowedBrokerage =>
  brokerage === "unknown" ||
  supportedBrokerage.includes(brokerage as SupportedBrokerage);

const checkIfValidSource = (source: string): source is TransactionSource =>
  transactionSourceList.includes(source as TransactionSource);

export const createTransactionId = (
  baseId: string,
  source: TransactionSource,
  brokerage: AllowedBrokerage
) => `${source}---${brokerage}---${baseId}`;

export const getInfoFromTransactionId = (
  id: string
): {
  baseId: string;
  source: TransactionSource;
  brokerage: AllowedBrokerage;
} | null => {
  const splittedId = id.split("---");

  if (
    checkIfValidSource(splittedId[1]) &&
    checkIfValidBrokerage(splittedId[2])
  ) {
    return {
      baseId: splittedId[0],
      source: splittedId[1],
      brokerage: splittedId[2],
    };
  }

  return null;
};

type CSVDataList = string[][];
/**
 * Analyze TD Ameritrade dataList
 * @param dataList
 *
 * Sample header (fixed format)
 *
  [
    "DATE",
    "TRANSACTION ID",
    "DESCRIPTION",
    "QUANTITY",
    "SYMBOL",
    "PRICE",
    "COMMISSION",
    "AMOUNT",
    "REG FEE",
    "SHORT-TERM RDM FEE",
    "FUND REDEMPTION FEE",
    " DEFERRED SALES CHARGE"
  ]

  * Sample option data
  *
  * [
  * "05/18/2020",
  *
  * "26427560298",
  *
  * "Bought 1 AKAM Jun 19 2020 98.0 Call @ 4.75",
  *
  *  "1",
  *
  *  "AKAM Jun 19 2020 98.0 Call",
  *
  *  "4.75",
  *  "0.65",
  *  "-475.67",
  *  "0.02",
  *  "",
  *  "",
  *  ""
  ]
 */

const splitTDAmOptionInfoFromName = (name: string) => {
  const splitName = name.split(" ");

  return {
    ticker: splitName[0],
    strikePrice: parseFloat(splitName[splitName.length - 2]),

    optionType: splitName[
      splitName.length - 1
    ].toLowerCase() as TransactionOptionType,

    expirationDate: dayjs(
      splitName.slice(1, 4).join(" "),
      "MMM DD YYYY"
    ).toISOString(),
  };
};

const checkIsOptionString = (data: string): boolean => {
  const lowerCase = data.toLowerCase();
  return lowerCase.includes("call") || lowerCase.includes("buy");
};
const tdameritradeAnalyzer = (dataList: CSVDataList): Transaction[] => {
  const BROKERAGE = "tdAmeritrade";

  const columnList = dataList[0];
  const dateColIndex = columnList.findIndex((entry) => entry === "DATE");
  const symbolColIndex = columnList.findIndex((entry) => entry === "SYMBOL");
  const priceColIndex = columnList.findIndex((entry) => entry === "PRICE");
  const amountColIndex = columnList.findIndex((entry) => entry === "AMOUNT");

  const quantityColIndex = columnList.findIndex(
    (entry) => entry === "QUANTITY"
  );

  const idColIndex = columnList.findIndex(
    (entry) => entry === "TRANSACTION ID"
  );

  const transactionList: Transaction[] = [];
  for (let index = 1; index < dataList.length; index++) {
    const row = dataList[index];
    if (row.length !== columnList.length) {
      continue;
    }

    const baseId = row[idColIndex];

    const amount = parseFloat(row[amountColIndex]);
    const price = parseFloat(row[priceColIndex]);

    const quantity = parseFloat(row[quantityColIndex]);

    const symbol = row[symbolColIndex];

    if (
      lodash.isEmpty(baseId) ||
      lodash.isEmpty(symbol) ||
      lodash.isNaN(price) ||
      lodash.isNaN(amount) ||
      lodash.isNaN(quantity)
    ) {
      continue;
    }

    const dateCol = row[dateColIndex];
    let date: string;
    try {
      date = dayjs(dateCol, STANDARD_DATE_FORMAT).toISOString();
    } catch (error) {
      continue;
    }

    if (checkIsOptionString(symbol)) {
      const newTransaction: OptionTransactionModel = {
        id: createTransactionId(baseId, "csv", BROKERAGE),
        type: "option",
        tradeType: amount > 0 ? "sell" : "buy",
        transactionDate: date,
        premiumPrice: price,
        contract: quantity,
        ...splitTDAmOptionInfoFromName(symbol),
      };
      transactionList.push(newTransaction);
    } else {
      const newTransaction: StockTransactionModel = {
        id: createTransactionId(baseId, "csv", BROKERAGE),
        ticker: symbol.toUpperCase(),
        type: "stock",
        tradeType: amount > 0 ? "sell" : "buy",
        transactionDate: date,
        price: price,
        quantity: quantity,
      };
      transactionList.push(newTransaction);
    }
  }

  return transactionList;
};

const transactionTransformerMap: Record<
  ActiveCSVBrokerage,
  (dataList: CSVDataList) => Transaction[]
> = {
  tdAmeritrade: tdameritradeAnalyzer,
};

export const transformCSVDataToTransaction = (
  dataList: CSVDataList,
  source: ActiveCSVBrokerage
): Transaction[] => transactionTransformerMap[source](dataList);
