import { FormInputProp } from "common/form/form.common";
import React, { useState } from "react";
import { Icon } from "..";
import { FormInput } from "./FormBasic";

export const PasswordForm = (props: FormInputProp) => {
  const [showingPassword, setShowingPassword] = useState(false);

  return (
    <FormInput
      {...props}
      label="Password"
      type={showingPassword ? "text" : "password"}
      rightCTA={
        <button
          onClick={() => {
            setShowingPassword((prevState) => !prevState);
          }}
          type="button"
        >
          <Icon
            icon={["fas", showingPassword ? "eye-slash" : "eye"]}
            className="fa-fw"
          />
        </button>
      }
    />
  );
};
