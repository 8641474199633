import { imgSrcRegex } from "common/regex";
import { DeltaStatic } from "quill";

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getImgUrlsFromDelta = (delta: DeltaStatic) => {
  return delta.ops
    ?.filter((op) => op.insert && op.insert.image)
    .map((op) => op.insert.image);
};

export const extractImgSrcFromString = (html: string): string[] => {
  const resultList: string[] = [];
  const matches = html.matchAll(imgSrcRegex);
  for (const match of matches) {
    resultList.push(match[2]);
  }

  return resultList;
};
