import { sendRequest } from "api/utils/common.util";
import { notificationEndpointMap } from "api/utils/function.util";
import { FirebaseCollection } from "common/firebase.info";
import { NotificationType } from "common/notification.info";
import firebase from "firebase/app";
import { NotificationDto } from "models/dto/notification/notification.dto";
import { getFirebaseUser } from "utils/firebase.util";

const subcollectionMap: Record<NotificationType, string> = {
  alert: "notificationAlertList",
} as const;

export const notificationService = {
  setupSnapshotListener: (
    type: NotificationType,
    onNewSnapshot: (arg: NotificationDto[]) => void
  ) => {
    const db = firebase.firestore();
    const userId = getFirebaseUser()?.uid;

    const subcollection = subcollectionMap[type];

    if (userId) {
      return db
        .collection(
          `${FirebaseCollection.Notification}/${userId}/${subcollection}`
        )
        .onSnapshot((doc) => {
          onNewSnapshot(
            doc.docs.map((entry) => entry.data() as NotificationDto)
          );
        });
    }

    return null;
  },

  markAsAllRead: async (type: NotificationType) => {
    const endpointInfo = notificationEndpointMap.markAllOfTypeAsRead;
    await sendRequest({
      endpointInfo,
      useTokenInHeaders: true,
      extraPath: [type],
    });
  },
};
