import { firebaseService } from "api/service/firebase.service";
import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { AccountFirebaseInfo, AccountInfo, MyAccount } from "models";
import { AccountDataInfoDto } from "models/dto/user/account-data-info.dto";
import { AccountDataDto } from "models/dto/user/account-data.dto";
import { getFirebaseUser } from "utils/firebase.util";

export const accountService = {
  /** Return null if not logged in */
  getAccount: async (): Promise<MyAccount | null> => {
    const firebaseUser = getFirebaseUser();
    if (firebaseUser == null) {
      return null;
    }

    const response = await sendRequest<AccountDataDto>({
      endpointInfo: functionEndpointList.getCurrentUser,
      useTokenInHeaders: true,
    });

    return AccountDataDto.toSchema(response, firebaseUser);
  },

  /** Return null if update failed */
  updateAccountInfo: async (
    accountInfo: AccountInfo,
    accountFirebaseInfo: AccountFirebaseInfo
  ): Promise<{
    updatedAccountInfo: AccountInfo;
    updatedAccountFirebaseInfo: AccountFirebaseInfo;
  } | null> => {
    const curUser = getFirebaseUser();
    if (curUser == null) {
      return null;
    }

    // Update FirebaseUser info
    // - firebase phone number
    const newPhoneNumber = accountFirebaseInfo.phoneNumber;
    if (!newPhoneNumber || newPhoneNumber.length <= 0) {
      await firebaseService.unlinkPhoneNumber();
    } else {
      if (curUser.phoneNumber !== newPhoneNumber) {
        await firebaseService.updateProfilePhoneNumber(newPhoneNumber);
      }
    }

    // - firebase profile info
    const updatedFirebaseUser = await firebaseService.updateProfileInfo({
      displayName: accountFirebaseInfo.displayName,
    });

    // Update accountInfo via API
    const updatedAccountInfo = await sendRequest<AccountDataInfoDto>({
      endpointInfo: functionEndpointList.updateUserInfo,
      useTokenInHeaders: true,
      data: AccountDataInfoDto.fromSchema(accountInfo),
    });

    return {
      updatedAccountInfo: AccountDataInfoDto.toSchema(updatedAccountInfo),
      updatedAccountFirebaseInfo: {
        displayName: updatedFirebaseUser.displayName,
        phoneNumber: updatedFirebaseUser.phoneNumber,
      },
    };
  },
};
