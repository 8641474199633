import React from "react";
import "styles/components/common/LimitReachNotification.sass";

interface LimitReachNotificationProps {
  text: string;
}
export const LimitReachNotification = ({
  text,
}: LimitReachNotificationProps) => {
  return (
    <div className="limit-reach-notification">
      <p>{text}</p>
    </div>
  );
};
