import cx from "classnames";
import {
  PredefinedTag,
  predefinedTagMap,
  PredefinedTagSection,
} from "common/tag.info";
import { Tag } from "components/common/Tag";
import React, { useMemo } from "react";
import "styles/components/common/TagBySectionComponent.sass";

export interface TagBySectionViewProps {
  tagList: string[];
  activeTagList: string[];
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  className?: string;
}
export const TagBySectionComponent = ({
  tagList,
  activeTagList,
  addTag,
  removeTag,
  className,
}: TagBySectionViewProps) => {
  const tagBySection = useMemo(() => {
    const tagBySection: Record<PredefinedTagSection, string[]> = {
      Sectors: [],
      Strategies: [],
    };

    tagList.forEach((tag) => {
      const section: PredefinedTagSection =
        predefinedTagMap[tag as PredefinedTag] || "Strategies";
      tagBySection[section].push(tag);
    });

    return tagBySection;
  }, [tagList]);

  const onTagClick = (tag: string, isActive: boolean) => {
    if (!isActive) {
      addTag(tag);
    } else {
      removeTag(tag);
    }
  };

  return (
    <div className={cx("tag-by-section-component", className)}>
      {Object.entries(tagBySection).map(([section, tagList]) => (
        <div className="tag-section" key={section}>
          <h4 className="title">{section}</h4>
          <div className="body">
            {tagList.map((tag) => {
              const isActive = activeTagList.includes(tag);

              return (
                <Tag
                  key={tag}
                  isActive={isActive}
                  onClick={() => {
                    onTagClick(tag, isActive);
                  }}
                >
                  {tag}
                </Tag>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
