import { SubscriptionView } from "components/pages/Setting/SubscriptionView";
import React from "react";
import { useMedia } from "react-use";
import { getMediaBreakpoint } from "utils/common.utils";

export const SettingSubscriptionView = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  return (
    <div className="settings-subscription-view">
      {isLg && <h2 className="title">Subscription</h2>}
      <SubscriptionView />
    </div>
  );
};
