import { StripeRole } from "common/payment.info";
import { subscriptionPlanOrder } from "common/subscription.info";
import { SubscriptionPlanMetadataDto } from "./subscription-plan-metadata.dto";
import { SubscriptionPlanPriceDto } from "./subscription-plan-price.dto";

export enum PlanKey {
  free = "free",
  basic = "basic",
  premium = "premium",
  elite = "elite",
}

export class SubscriptionPlanDto {
  id: string;
  planKey: PlanKey;
  active: boolean;
  name: string;
  description?: string;
  imageList: string[];
  role?: StripeRole;
  prices: SubscriptionPlanPriceDto[];
  metadata?: SubscriptionPlanMetadataDto;

  static isFreePlan = (planDto: SubscriptionPlanDto): boolean => {
    return planDto.planKey === PlanKey.free;
  };

  static isPopularPlan = (planKey: PlanKey): boolean => {
    return (
      planKey === RECOMMEND_PLAN_KEY &&
      subscriptionPlanOrder[planKey] < subscriptionPlanOrder[RECOMMEND_PLAN_KEY]
    );
  };

  static isCurrentPlan = (
    planDto: SubscriptionPlanDto,
    currentStripeRole: StripeRole | null
  ): boolean => {
    if (!currentStripeRole) {
      return false;
    }

    return planDto.role === currentStripeRole;
  };
}

export const PLAN_FREE_ID = "plan-free-id";

export const PLAN_FREE_NAME = "Free";

// TODO Make this more meaningful
export const PLAN_FREE_DESCRIPTION =
  "Lorem ipsum dolor sit, amet consectetur adipisicing elit.";

export const RECOMMEND_PLAN_KEY: PlanKey = PlanKey.premium;
