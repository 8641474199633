import React from "react";
import cx from "classnames";

import "styles/components/common/InformationCard.sass";

interface InformationCardProps {
  children: React.ReactNode;
  usePreset?: "faded";
  onCardClick?: Function;
}
export const InformationCard = ({
  children,
  usePreset,
  onCardClick,
}: InformationCardProps) => {
  const Container = onCardClick ? "button" : "div";
  return (
    <Container
      className={cx("Information-card", {
        [`Information-card-${usePreset}`]: usePreset,
      })}
      type="button"
      onClick={() => {
        onCardClick && onCardClick();
      }}
    >
      {children}
    </Container>
  );
};
