import bell from "assets/animation/433-checked-done.json";
import { LottiePlayer } from "components/common";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/AlertEndStep.sass";
import { craftConditionMessage } from "utils/alert.util";

export const AlertEndStep = () => {
  const { initializeStore, resetStore } = useStoreActions(
    (actions) => actions.alertManage
  );
  const alert = useStoreState((state) => state.alertManage.alert);

  if (!alert) {
    return null;
  }

  const onDoneClick = () => {
    resetStore();
  };

  const onCreateAnotherClick = () => {
    initializeStore(null);
  };

  return (
    <div className="alert-end-step">
      <div className="illustration">
        <LottiePlayer animationData={bell} options={{ loop: false }} />
      </div>

      <div className="body">
        <div className="content">
          <p className="title">Alert for {alert.ticker} set</p>
          <ul>
            <p>We'll notify you when:</p>
            {alert.conditionList.map((condition) => (
              <li key={condition.id}>{craftConditionMessage(condition)}</li>
            ))}
          </ul>
        </div>

        <div className="cta-container">
          <button className="another" onClick={onCreateAnotherClick}>
            Create Another
          </button>

          <button className="done" onClick={onDoneClick}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};
