import { useForm } from "react-hook-form";

import { AlertPresetComponent } from ".";
import { emailRegex } from "common/regex";
import { FormInput } from "../Forms";

interface EmailResetComponentProps {
  onConfirm?: (email: string) => void;
  onCancel?: Function;
}
export const EmailResetComponent = ({
  onConfirm,
  onCancel,
}: EmailResetComponentProps) => {
  const { getValues, register, errors, trigger } = useForm<{ email: string }>();

  return (
    <AlertPresetComponent
      onConfirm={async () => {
        if (await trigger()) {
          onConfirm && onConfirm(getValues("email"));
        }
      }}
      onCancel={() => onCancel && onCancel()}
      mainContent={
        <div>
          <p>Please enter your new email</p>

          <FormInput
            label="New email"
            formValidator={{
              name: "email",
              errorText: errors["email"]?.message,
              inputRef: register({
                required: "Can't be empty",
                pattern: { value: emailRegex, message: "Invalid email" },
              }),
            }}
          />
        </div>
      }
    />
  );
};
