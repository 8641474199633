import { useStoreActions } from "stores";
import { AlertPresetComponent } from ".";

interface LogoutComponentProps {
  onLogout?: Function;
  onBackClick?: Function;
}
export const LogoutComponent = ({
  onLogout,
  onBackClick,
}: LogoutComponentProps) => {
  const { logout } = useStoreActions((actions) => actions.account);

  return (
    <AlertPresetComponent
      onConfirm={() => {
        logout();
        onLogout && onLogout();
      }}
      onCancel={() => onBackClick && onBackClick()}
      mainContent="Are you sure you want to logout?"
    />
  );
};
