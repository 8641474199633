import cx from "classnames";
import { alertConditionMap } from "common/alert/alert-manage.info";
import { AlertAddConditionView } from "common/alert/alert-page.info";
import { internalErrorMessage } from "common/standardMessage";
import { Icon } from "components/common";
import { AlertConditionIcon } from "components/pages/Alert/AlertConditionIcon";
import { AlertConditionModel } from "models/dto/alert";
import React from "react";
import { useStoreActions } from "stores";
import "styles/components/pages/Alert/ConditionPreviewCard.sass";
import { executeAsync } from "utils/api.util";

interface ConditionPreviewCardProps {
  condition: AlertConditionModel;
  isActive: boolean;
}
export const ConditionPreviewCard = ({
  condition,
  isActive,
}: ConditionPreviewCardProps) => {
  const metaData = alertConditionMap[condition.type];
  const { removeCondition, initializeCondition } = useStoreActions(
    (actions) => actions.alertManage
  );

  if (!metaData) {
    throw new Error(internalErrorMessage.unimplementedConditionDataMap);
  }

  const onRemoveClick = () => {
    removeCondition(condition.id);
  };

  const onCardClick = () => {
    initializeCondition(condition);
  };

  const { name, icon } = metaData;
  return (
    <div
      className={cx("condition-preview-card", {
        "condition-preview-card-active": isActive,
      })}
    >
      <button onClick={onRemoveClick} type="button">
        <Icon icon={["fas", "times"]} />
      </button>

      {icon && <AlertConditionIcon condition={condition} />}
      <button className="name" onClick={onCardClick}>
        {name}
      </button>
    </div>
  );
};

export const AddConditionCard = () => {
  const {
    checkCanAddCondition,
    setCurAddConditionView,
    initializeCondition,
  } = useStoreActions((actions) => actions.alertManage);

  const onClick = () => {
    executeAsync({
      funcToExecute: async () => {
        const canAdd = await checkCanAddCondition();

        if (canAdd) {
          initializeCondition(null);
        } else {
          setCurAddConditionView(AlertAddConditionView.SubscriptionWarning);
        }
      },
    });
  };

  return (
    <button onClick={onClick} className="add-condition-card">
      <Icon icon={["fas", "plus"]} className="icon" />
      <p className="name">Add Condition</p>
    </button>
  );
};
