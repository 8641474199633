import { DarkLogo, LightLogo } from "assets/logo";
import { AccountLoadingType } from "common/account.info";
import { Icon, ThemeToggleButton } from "components/common";
import { NavLink } from "react-router-dom";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Setting/SettingMainView.sass";
import { getMediaBreakpoint } from "utils/common.utils";
import { settingViewMap } from "./SettingCommon";

export const SettingMainView = () => {
  const isLoggingOut = useStoreState((state) =>
    state.account.isLoading(AccountLoadingType.LoggingOut)
  );
  const theme = useStoreState((state) => state.theme.theme);
  const { setPreset } = useStoreActions((actions) => actions.modal);

  const isLg = useMedia(getMediaBreakpoint("lg"));
  const Logo = theme === "dark" ? DarkLogo : LightLogo;

  return (
    <div className="setting-main-view">
      {Object.entries(settingViewMap).map(
        ([viewKey, view]) =>
          view &&
          view.displayInfo && (
            <NavLink
              key={viewKey}
              to={viewKey}
              className="cta"
              activeClassName="cta-active"
            >
              <Icon icon={view.displayInfo.icon} className="fa-fw" />
              <p>{view.displayInfo.text}</p>
            </NavLink>
          )
      )}

      {!isLg && <ThemeToggleButton className="cta" showText />}

      <button
        className="cta"
        onClick={() => {
          setPreset("logoutModal");
        }}
        disabled={isLoggingOut}
      >
        <Icon icon={["fas", "sign-out-alt"]} className="fa-fw" />
        <p>Sign out</p>
      </button>

      {!isLg && <Logo className="logo" />}
    </div>
  );
};
