import React from "react";
import cx from "classnames";

import "styles/components/common/Header.sass";

interface HeaderProp {
  leftComponent?: React.ReactNode;
  leftClassName?: string;
  rightComponent?: React.ReactNode;
  rightClassName?: string;
  midComponent?: React.ReactNode;
  midClassName?: string;
}

export const Header = ({
  leftClassName,
  midClassName,
  rightClassName,
  leftComponent,
  rightComponent,
  midComponent,
}: HeaderProp) => (
  <header className="header">
    <div className={cx("left", leftClassName)}>{leftComponent}</div>
    <div className={cx("mid", midClassName)}>{midComponent}</div>
    <div className={cx("right", rightClassName)}>{rightComponent}</div>
  </header>
);
