import { alertService } from "api/service";
import { AlertType } from "common/alert/alert-common.info";
import {
  AlertDashboardLoadingType,
  AlertDashboardView,
} from "common/alert/alert-page.info";
import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunk,
  thunkOn,
  ThunkOn,
} from "easy-peasy";
import { CompanySummary } from "models";
import { AlertDto } from "models/dto/alert/alert.dto";
import { getMaxAlert } from "stores/alert/alert-manage.util";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";
import { toastCustomMessage } from "utils/ui.util";

interface AlertWithCompanySummary {
  alertList: AlertDto[];
  companySummary: CompanySummary;
}
type AlertByCompanyMap = Partial<Record<string, AlertWithCompanySummary>>;
type AlertByCompanyList = AlertWithCompanySummary[];
export interface AlertDashboardModel
  extends LoadingModel<AlertDashboardLoadingType> {
  // * Business logic
  alertList: AlertDto[];
  alertByCompanyMap: AlertByCompanyMap;

  alertByCompanyList: Computed<AlertDashboardModel, AlertByCompanyList>;
  alertByIdListList: Computed<
    AlertDashboardModel,
    (idList: string[]) => AlertDto[]
  >;

  // * Actions
  resetStore: Action<AlertDashboardModel>;
  setAlertList: Action<AlertDashboardModel, AlertDto[]>;
  setAlertByCompanyMap: Action<AlertDashboardModel, AlertByCompanyMap>;

  // * Thunks
  initializeStore: Thunk<AlertDashboardModel>;
  fetchAndSaveAlertList: Thunk<
    AlertDashboardModel,
    never,
    any,
    any,
    Promise<AlertDto[] | null>
  >;
  toggleAlertList: Thunk<
    AlertDashboardModel,
    { alertIdList: string[]; curActive: boolean },
    any,
    StoreModel
  >;
  deleteAlertList: Thunk<AlertDashboardModel, string[]>;
  // TODO: optimize this?
  computeAlertByCompanyMap: ThunkOn<AlertDashboardModel, any, StoreModel>;

  // * UI
  curDashboardView: AlertDashboardView;
  setCurDashboardView: Action<AlertDashboardModel, AlertDashboardView>;
}

export const alertDashboard: AlertDashboardModel = {
  // * Business logic
  alertList: [],
  alertByCompanyMap: {},

  alertByCompanyList: computed(
    [(state) => state.alertByCompanyMap],
    (alertByCompanyMap) =>
      Object.values(alertByCompanyMap).filter(
        (entry) => entry != null
      ) as AlertByCompanyList
  ),
  alertByIdListList: computed(
    [(state) => state.alertList],
    (alertList) => (idList) => {
      return alertList.filter((alert) => idList.includes(alert.alertId));
    }
  ),

  // * Actions
  resetStore: action((state) => {
    state.alertList = [];
    state.alertByCompanyMap = {};
    state.alertByCompanyList = [];
  }),
  setAlertList: action((state, alertList) => {
    state.alertList = alertList;
  }),
  setAlertByCompanyMap: action((state, alertByCompanyMap) => {
    state.alertByCompanyMap = alertByCompanyMap;
  }),

  // * Thunks
  initializeStore: thunk(async (actions) => {
    actions.fetchAndSaveAlertList();
  }),
  fetchAndSaveAlertList: thunk(async (actions) => {
    const { result } = await executeAsync({
      funcToExecute: async () => {
        const alertList = await alertService.getAlertList();
        actions.setAlertList(alertList);
        return alertList;
      },
      setIsLoading: (isLoading: boolean) => {
        actions.setIsLoading({
          isLoading,
          loadingType: AlertDashboardLoadingType.LoadingAlert,
        });
      },
    });

    return result;
  }),

  toggleAlertList: thunk(
    async (
      actions,
      { alertIdList, curActive },
      { getState, getStoreState }
    ) => {
      if (alertIdList.length <= 0) {
        return;
      }

      const featureList = getStoreState().account.featureList;
      const alertList = getState().alertList;

      const getNumActiveFromType = (alertType: AlertType) => {
        const curActiveAlert = alertList.filter(
          (entry) => entry.isActive && entry.type === alertType
        ).length;
        const newActiveAlert = alertList.filter(
          (entry) =>
            alertIdList.includes(entry.alertId) && entry.type === alertType
        ).length;

        return curActiveAlert + newActiveAlert;
      };

      const maxSingleAlert = getMaxAlert(featureList, "basicAlerts");
      const maxChainedAlert = getMaxAlert(featureList, "chainedAlerts");
      if (
        curActive === false &&
        (getNumActiveFromType("single") > maxSingleAlert ||
          getNumActiveFromType("chained") > maxChainedAlert)
      ) {
        toastCustomMessage("Max active alert reached!", "error");
        return;
      }

      const newAlertList = (
        await executeAsync({
          funcToExecute: async () =>
            await alertService.toggleAlertList(alertIdList, !curActive),
          transformError: extractFunctionError,
          setIsLoading: (isLoading) => {
            actions.setIsLoading({
              loadingType: AlertDashboardLoadingType.ToggleAlert,
              isLoading: isLoading,
            });
          },
        })
      ).result;

      if (newAlertList) {
        actions.setAlertList(newAlertList);
      }
    }
  ),

  deleteAlertList: thunk(async (actions, alertIdList) => {
    if (alertIdList.length <= 0) {
      return;
    }

    const newAlertList = (
      await executeAsync({
        toastMessage: "Deleting alert 🗑️",
        funcToExecute: async () => {
          return await alertService.deleteAlertList(alertIdList);
        },
        transformError: extractFunctionError,
      })
    ).result;

    if (newAlertList) {
      actions.setAlertList(newAlertList);
    }
  }),

  computeAlertByCompanyMap: thunkOn(
    (actions) => [actions.setAlertList],
    async (actions, payload, { getStoreActions }) => {
      const alertByCompanyMap: Partial<Record<string, AlertDto[]>> = {};
      payload.payload.forEach((entry) => {
        const symbol = entry.ticker as string;
        if (alertByCompanyMap[symbol]) {
          alertByCompanyMap[symbol]?.push(entry);
        } else {
          alertByCompanyMap[symbol] = [entry];
        }
      });

      const quoteList = (
        await executeAsync({
          funcToExecute: async () =>
            await getStoreActions().stock.getQuoteList(
              Object.keys(alertByCompanyMap) as string[]
            ),
          transformError: extractFunctionError,
          setIsLoading: (isLoading) => {
            actions.setIsLoading({
              loadingType: AlertDashboardLoadingType.ToggleAlert,
              isLoading: isLoading,
            });
          },
        })
      ).result;

      const newMap: AlertByCompanyMap = {};
      (quoteList || []).forEach((quote) => {
        const symbol = quote.symbol as string;
        newMap[symbol] = {
          alertList: alertByCompanyMap[symbol] || [],
          companySummary: {
            symbol,
            companyName: quote.companyName,
            changePercent: quote.changePercent,
            latestPrice: quote.iexRealtimePrice || quote.latestPrice,
            avgTotalVolume: quote.avgTotalVolume,
          },
        };
      });

      actions.setAlertByCompanyMap(newMap);
    }
  ),

  // * UI
  curDashboardView: AlertDashboardView.AlertAndNotification,
  setCurDashboardView: action((state, curDashboardView) => {
    state.curDashboardView = curDashboardView;
  }),

  // --- Plugins
  ...loadingPlugin(),
};
