import { AddConditionCard, ConditionPreviewCard } from "components/pages/Alert";
import React from "react";
import { useStoreState } from "stores";
import "styles/components/pages/Alert/AlertConditionSummary.sass";

export const AlertConditionSummary = () => {
  const alert = useStoreState((state) => state.alertManage.alert);
  const curEditingCondition = useStoreState(
    (state) => state.alertManage.curEditingCondition
  );

  if (!alert) {
    return null;
  }

  const numCondition = alert.conditionList.length;

  const checkShowAddConditionCard = (): boolean => {
    if (alert.type === "single" && numCondition > 0) {
      return false;
    }

    return true;
  };

  return (
    <div className="alert-condition-summary">
      <p>Alert conditions ({numCondition})</p>

      <div className="condition-preview-container">
        {checkShowAddConditionCard() && <AddConditionCard />}

        <div className="current-condition-container">
          {alert.conditionList.map((condition) => (
            <ConditionPreviewCard
              isActive={curEditingCondition?.id === condition.id}
              condition={condition}
              key={condition.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
