import { journalService } from "api/service/journal.service";
import { JournalManageType } from "common/journal/journal-manage.info";
import { internalErrorMessage } from "common/standardMessage";
import { thunk, Thunk } from "easy-peasy";
import { FunctionBackendError } from "models";
import { JournalDto } from "models/dto/journal/journal.dto";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";
import { toastCustomMessage } from "utils/ui.util";
import { JournalManageModel } from ".";

export interface JournalManageThunk {
  // * Thunks
  submitEntry: Thunk<
    JournalManageModel,
    never,
    any,
    StoreModel,
    Promise<null | FunctionBackendError>
  >;
}

export const journalManageThunk: JournalManageThunk = {
  // * Thunk
  submitEntry: thunk(
    async (actions, _, { getState, getStoreActions, getStoreState }) => {
      const entry = getState().entry;
      if (!entry) {
        throw new Error(internalErrorMessage.nullJournalEntry);
      }

      const manageType = getState().manageType;

      const result = await executeAsync({
        toastMessage: "Saving Journal 📝",
        funcToExecute: async () => {
          const submitEntry = async () => {
            return await journalService.createOrUpdateJournal(
              entry,
              getState().entryImageSrcList,
              manageType === JournalManageType.Edit ? "update" : "create",
            );
          };

          return await submitEntry();
        },
        onSuccess: () => {
          toastCustomMessage("Successfully saved entry!", "success");
        },
        transformError: extractFunctionError,
      });

      if (!result.error && result.result) {
        const newJournal = result.result;

        const newJournalList: JournalDto[] =
          getStoreState().journalDashboard.journalList;
        if (manageType === JournalManageType.Edit) {
          const index = newJournalList.findIndex(
            (entry) => entry.id === newJournal.id,
          );

          if (index >= 0) {
            newJournalList[index] = newJournal;
          }
        } else {
          newJournalList.push(newJournal);
        }

        if (manageType === JournalManageType.Add) {
          actions.clearDraft();
        }

        getStoreActions().journalDetailView.setJournal(entry);
        actions.initalizeStore(entry);

        return null;
      }

      return result.error;
    },
  ),
};
