import cx from "classnames";
import { TimeSelector } from "common/stock/stock-graph.info";

interface TimeSelectorButtonProps {
  timeSelector: TimeSelector;
  onClick: (arg: TimeSelector) => void;
  isActive: boolean;
}
export const TimeSelectorButton = ({
  timeSelector,
  onClick,
  isActive,
}: TimeSelectorButtonProps) => {
  return (
    <button
      className={cx("time-selector", { "time-selector-active": isActive })}
      type="button"
      onClick={() => {
        onClick(timeSelector);
      }}
    >
      {timeSelector.displayText}
    </button>
  );
};
