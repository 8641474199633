import cx from "classnames";
import { AlertConditionType } from "common/alert/alert-condition.info";
import {
  alertConditionMap,
  AlertSubType,
  alertSubTypeMap,
  ConditionFormInfo,
} from "common/alert/alert-manage.info";
import {
  AlertAddConditionView,
  AlertManageLoadingType,
} from "common/alert/alert-page.info";
import { THOUSAND_SEPARATOR } from "common/common";
import { FormDropDownProps, FormInputProp } from "common/form/form.common";
import {
  internalErrorMessage,
  serverErrorMessage,
} from "common/standardMessage";
import { FormDropDown, FormInput, PriceOrPercent } from "components/common";
import {
  ConditionSubTypeCard,
  ConditionTypeCard,
} from "components/pages/Alert/ConditionTypeCard";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/AddConditionSubStepComponents.sass";
import { extractFormToCondition } from "utils/alert.util";

export const TypeSelectionStep = () => {
  const conditionList = useMemo(() => Object.entries(alertConditionMap), []);

  const { setCurConditionType } = useStoreActions(
    (actions) => actions.alertManage
  );
  const curConditionType = useStoreState(
    (state) => state.alertManage.curConditionType
  );

  const onClick = (type: AlertConditionType) => {
    setCurConditionType(type);
  };

  return (
    <div className={cx("step-container", "type-selection-step")}>
      {conditionList.map(([type, data]) => (
        <ConditionTypeCard
          key={type}
          type={type as AlertConditionType}
          data={data!}
          onClick={onClick}
          isActive={type === curConditionType}
        />
      ))}
    </div>
  );
};

export const SubTypeSelectionStep = () => {
  const containerCN = cx("step-container", "sub-type-selection-step");

  const curConditionType = useStoreState(
    (state) => state.alertManage.curConditionType
  );
  const curConditionSubType = useStoreState(
    (state) => state.alertManage.curConditionSubType
  );

  const { setCurConditionSubType } = useStoreActions(
    (actions) => actions.alertManage
  );

  if (!curConditionType) {
    return (
      <div className={containerCN}>
        <p>Please select a condition type</p>
      </div>
    );
  }

  const onClick = (subType: AlertSubType) => {
    setCurConditionSubType(subType);
  };

  return (
    <div className={containerCN}>
      {alertConditionMap[curConditionType]?.subType.map((subType) => (
        <ConditionSubTypeCard
          key={subType}
          onClick={onClick}
          subType={subType}
          data={alertSubTypeMap[subType]!}
          isActive={curConditionSubType === subType}
        />
      ))}
    </div>
  );
};

export const DetailAddingStep = () => {
  const containerCN = cx("step-container", "detail-adding-selection-step");
  const [generalError, setGeneralError] = useState("");
  const {
    handleSubmit,
    register,
    errors,
    setError,
    clearErrors,
    control,
  } = useForm();

  const isLoadingMap = useStoreState((state) => state.alertManage.isLoadingMap);
  const curEditingCondition = useStoreState(
    (state) => state.alertManage.curEditingCondition
  );

  const targetCompanySummary = useStoreState(
    (state) => state.alertManage.targetCompanySummary
  );
  const curConditionSubType = useStoreState(
    (state) => state.alertManage.curConditionSubType
  );

  const {
    addOrUpdateCondition,
    setCurAddConditionView,
    validateCondition,
  } = useStoreActions((actions) => actions.alertManage);

  if (!curEditingCondition) {
    return (
      <div className={containerCN}>
        <p>Please select a type or subtype</p>
      </div>
    );
  }

  if (!curConditionSubType) {
    return (
      <div className={containerCN}>
        <p>Please select subtype</p>
      </div>
    );
  }

  const subTypeItem = alertSubTypeMap[curConditionSubType];

  if (!subTypeItem) {
    throw new Error(internalErrorMessage.unimplementedConditionLookup);
  }

  const renderTargetCompanyDetail = () => {
    if (targetCompanySummary) {
      return (
        <>
          <p className="title">Current {targetCompanySummary.symbol} data:</p>
          <ul>
            <li>
              <p>Price: </p>
              <PriceOrPercent
                value={targetCompanySummary.latestPrice}
                type="price"
              />
            </li>

            <li>
              <p>Percent change:</p>
              <PriceOrPercent
                value={targetCompanySummary.changePercent * 100}
                type="percent"
              />
            </li>
          </ul>
        </>
      );
    }
    return <p>You chose marketwide condition</p>;
  };

  const renderInputForm = (
    { formProps, name }: ConditionFormInfo,
    required: boolean
  ) => {
    const { maskProp, ...otherMetaData } = formProps as FormInputProp;
    const min = maskProp?.min;
    const max = maskProp?.max;

    // @ts-ignore
    const defaultValue = curEditingCondition[name] ?? formProps.defaultValue;

    return (
      <FormInput
        key={`${curEditingCondition.id}-${name}`}
        borderType="full-border"
        defaultValue={
          otherMetaData.type === "number"
            ? (defaultValue as number).toLocaleString()
            : defaultValue
        }
        formValidator={{
          name: name,
          inputRef: register({
            required: { value: required, message: "Can't be empty" },
            min:
              min != null
                ? { value: min, message: `Can't be less than ${min}` }
                : undefined,
            max:
              max != null
                ? { value: max, message: `Can't be more than ${max}` }
                : undefined,
          }),
          errorText: errors[name]?.message,
        }}
        maskProp={
          otherMetaData.type === "number"
            ? {
                thousandSeparator: THOUSAND_SEPARATOR,
                isAllowed: ({ floatValue }) => {
                  if (floatValue == null) {
                    return false;
                  }

                  const atLeastMin = min != null ? floatValue >= min : true;
                  const atMostMax = max != null ? floatValue <= max : true;
                  return atLeastMin && atMostMax;
                },
                ...maskProp,
              }
            : maskProp
        }
        {...otherMetaData}
      />
    );
  };

  const renderDropdown = (
    { formProps, name }: ConditionFormInfo,
    required: boolean
  ) => {
    return (
      <FormDropDown
        key={name}
        formValidator={{
          name: name,
          inputRef: register({
            required: { value: required, message: "Can't be empty" },
          }),
          errorText: errors[name]?.message,
          control,
        }}
        {...(formProps as FormDropDownProps<string | number>)}
      />
    );
  };

  const renderFormElement = (
    formMetaData: ConditionFormInfo,
    required: boolean
  ) => {
    if (formMetaData) {
      switch (formMetaData.type) {
        case "input":
          return renderInputForm(formMetaData, required);
        case "dropdown":
          return renderDropdown(formMetaData, required);
      }
    }

    return null;
  };

  const onSubmit = handleSubmit(async (data) => {
    const newCondition = extractFormToCondition(
      curEditingCondition,
      data,
      targetCompanySummary
    );

    const { error } = await validateCondition(newCondition);
    if (!error) {
      addOrUpdateCondition(newCondition);
      setCurAddConditionView(AlertAddConditionView.Empty);
      setGeneralError("");
    } else {
      // * Detail message will be in the form:
      // * `${problematic attribute}---${message}!!!${problematic attribute 2}---${message 2} ...etc`
      if (error.detailMessage) {
        const errorStrList = error.detailMessage?.split("!!!");
        errorStrList.forEach((errStr) => {
          const splitErrorStr = errStr.split("---");
          const attrName = splitErrorStr[0];
          const message = splitErrorStr[1];

          setError(attrName, { message, shouldFocus: true });
        });
      } else {
        setGeneralError(serverErrorMessage.internalError);
      }
    }
  });

  const { description, requiredInputList, optionalInputList } = subTypeItem;

  return (
    <div className={containerCN}>
      {generalError && <p className="general-error">{generalError}</p>}

      <div className="company-summary">{renderTargetCompanyDetail()}</div>

      <div className="description-container">
        <p className="title">Condition detail:</p>
        <p className="description">{description}</p>
      </div>

      <form className="form-container" onSubmit={onSubmit}>
        {requiredInputList.length > 0 && (
          <>
            <p className="title">Please enter the required detail</p>
            {requiredInputList.map((formGenerator) =>
              renderFormElement(formGenerator(), true)
            )}
          </>
        )}

        {optionalInputList && optionalInputList.length > 0 && (
          <>
            <p className="title">Please enter the optional detail</p>
            {optionalInputList.map((formGenerator) =>
              renderFormElement(formGenerator(), false)
            )}
          </>
        )}

        <button
          onClick={() => {
            clearErrors();
          }}
          disabled={isLoadingMap[AlertManageLoadingType.CheckingCondition]}
        >
          Save condition
        </button>
      </form>
    </div>
  );
};
