import cx from "classnames";
import { sentimentFullText } from "common/news.info";
import dayjs from "dayjs";
import { NewsEntry } from "models";
import React from "react";
import "styles/components/common/NewsSummary.sass";
import { Icon } from ".";

export type NewsSummaryPreset = "preset-card" | "preset-border";
export interface NewsSummaryProp {
  onAddClick?: (news: NewsEntry) => void;
  isAdded?: boolean;
  onRemoveClick?: (news: NewsEntry) => void;
  isRemoved?: boolean;
  newsEntry: NewsEntry;
  showPicture?: boolean;
  usePreset?: NewsSummaryPreset;
}

export const NewsSummary = ({
  onAddClick,
  isAdded = false,
  onRemoveClick,
  isRemoved = false,
  newsEntry,
  showPicture,
  usePreset,
}: NewsSummaryProp) => {
  return (
    <div
      className={cx("news-summary", {
        [`news-summary-${usePreset}`]: usePreset,
      })}
    >
      <span className="news-header">
        <span className="time">{newsEntry.source}</span>
        <span>•</span>
        <span>{dayjs().from(dayjs(newsEntry.time), true)}</span>
      </span>

      <div className="content">
        <div className="col-1">
          <a href={newsEntry.newsURL} target="_blank" rel="noopener noreferrer">
            {newsEntry.title}
          </a>
          <p className={cx("sentiment", `sentiment-${newsEntry.sentiment}`)}>
            {sentimentFullText[newsEntry.sentiment]}
          </p>
        </div>

        <div className="col-2">
          {showPicture && <img src={newsEntry.pictureURL} alt="news icon" />}

          {onAddClick && !isAdded && (
            <button
              type="button"
              onClick={() => {
                onAddClick && onAddClick(newsEntry);
              }}
              className="add-button"
            >
              <Icon icon={["cio", "add"]} className="icon" />
              <p>Add</p>
            </button>
          )}

          {isAdded && <p className="state-text">Added</p>}

          {onRemoveClick && !isRemoved && (
            <button
              type="button"
              onClick={() => {
                onRemoveClick && onRemoveClick(newsEntry);
              }}
              className="remove-button"
            >
              <Icon icon={["fas", "times"]} className="icon" />
            </button>
          )}

          {isRemoved && <p className="state-text">Removed</p>}
        </div>
      </div>
    </div>
  );
};
