import {
  GA_TRACKING_ID,
  HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION,
} from "common/analytic.info";
import { ROOT_ID } from "common/common";
import { StoreProvider } from "easy-peasy";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "stores";
import "styles/main/index.sass";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactGA.initialize(GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);
hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <div id="recaptcha-container" />
      <App />
      <ToastContainer
        position="top-right"
        toastClassName="toastify-container"
        bodyClassName="toastify-body"
        limit={4}
      />
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById(ROOT_ID)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
