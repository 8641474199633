import warningBell from "assets/animation/6407-alert-notification.json";
import { AlertManageStep } from "common/alert/alert-page.info";
import { LottiePlayer } from "components/common";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/AddAlertLimitReachStep.sass";

export const AddAlertLimitReachStep = () => {
  const errorMap = useStoreState((state) => state.alertManage.errorMap);
  const { setCurManageStep } = useStoreActions(
    (actions) => actions.alertManage
  );

  const onClick = () => {
    if (errorMap.submitError) {
      switch (errorMap.submitError?.message) {
        case "alert/sms-not-allowed":
          setCurManageStep(AlertManageStep.Summary);
          break;
        case "alert/hard-alert-limit-reach":
        case "alert/num-alert-limit-reach":
        case "alert/hard-condition-limit-reach":
        case "alert/num-condition-limit-reach":
        case "alert/invalid-alert-condition":
        case "alert/alert-subscription":
          setCurManageStep(AlertManageStep.ConditionAdding);
          break;
        default:
          setCurManageStep(AlertManageStep.SubscriptionChoose);
          break;
      }
    } else {
      setCurManageStep(AlertManageStep.SubscriptionChoose);
    }
  };

  const renderHeader = () => {
    switch (errorMap.submitError?.message) {
      case "alert/sms-not-allowed":
        return "SMS Not Available";
      case "alert/hard-alert-limit-reach":
      case "alert/num-alert-limit-reach":
        return "Alert Limit Reached!";
      case "alert/hard-condition-limit-reach":
      case "alert/num-condition-limit-reach":
        return "Condition Limit Reached!";
      case "alert/invalid-alert-condition":
        return "Invalid Alert Condition!";
      case "alert/alert-subscription":
        return "Alert Subscription";
      default:
        return "Alert Subscription";
    }
  };

  return (
    <div className="add-alert-limit-reach-step">
      <div className="illustration">
        <div className="container">
          <LottiePlayer animationData={warningBell} />
        </div>
      </div>

      <div className="body">
        <h3>{renderHeader()}</h3>

        <p>{errorMap["submitError"]?.detailMessage}</p>
        <p>Please check your subscription</p>

        <button onClick={onClick}>Okay</button>
      </div>
    </div>
  );
};
