import { AlertManageStep } from "common/alert/alert-page.info";
import {
  AddAlertLimitReachStep,
  AddAlertSummaryStep,
  AddConditionStep,
  AlertEndStep,
  ChooseAlertTypeStep,
} from "components/pages/Alert";
import { SubscriptionView } from "components/pages/Setting";
import React, { useEffect } from "react";
import SwipeableViews from "react-swipeable-views";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/AlertDashboard/AlertManageView.sass";
import { SearchCompanyView } from "views/common";

export const AlertManageView = () => {
  const curManageView = useStoreState(
    (state) => state.alertManage.curManageStep
  );
  const { setCurManageStep, setTicker, resetStore } = useStoreActions(
    (actions) => actions.alertManage
  );

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, [resetStore]);

  const onSearchCompanyClick = (_: any, ticker: string) => {
    setTicker(ticker);
    setCurManageStep(AlertManageStep.TypeChose);
  };

  return (
    <SwipeableViews
      index={curManageView}
      disabled={true}
      className="alert-manage-view"
    >
      <div className="search-company-view-container">
        {curManageView === AlertManageStep.CompanySearch && (
          <SearchCompanyView onCompanyNameClick={onSearchCompanyClick} />
        )}
      </div>

      <ChooseAlertTypeStep />
      <AddConditionStep />
      <AddAlertSummaryStep />
      <AlertEndStep />
      <AddAlertLimitReachStep />

      <div className="subscription-view-container">
        {curManageView === AlertManageStep.SubscriptionChoose && (
          <SubscriptionView />
        )}
      </div>
    </SwipeableViews>
  );
};
