import {
  SupportedBrokerage,
  supportedBrokerageDisplayMap,
} from "common/brokerage.info";
import { BrokerageLoadingType } from "common/brokerage/brokerage.info";
import { CustomModal, Icon, PlaidLink } from "components/common";
import { AlertPresetComponent } from "components/common/ModalComponent";
import lodash from "lodash";
import React, { useState } from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/page-component.common.sass";
import "styles/views/common/LinkBrokerageView.sass";
import { SubscriptionViewTriggerButton } from "views";
import cx from "classnames";

interface BrokerageItemProps {
  brokerage: SupportedBrokerage;
  onUnlinkClick: (brokerage: SupportedBrokerage) => void;
  isActive: boolean;
}
const BrokerageItem = ({
  brokerage,
  onUnlinkClick,
  isActive,
}: BrokerageItemProps) => {
  const { imgUrl, name } = supportedBrokerageDisplayMap[brokerage];
  return (
    <div className="brokerage-item">
      <div>
        <img alt={name} src={imgUrl} />
        <p>{name}</p>
      </div>

      <button onClick={() => onUnlinkClick(brokerage)} disabled={!isActive}>
        <Icon icon={["far", "times-circle"]} />
      </button>
    </div>
  );
};

interface LinkBrokerageViewProps {
  showHeader?: boolean;
  className?: string;
}
export const LinkBrokerageView = ({
  showHeader = true,
  className,
}: LinkBrokerageViewProps) => {
  const [
    brokerageToUnlink,
    setBrokerageToUnlink,
  ] = useState<SupportedBrokerage | null>();
  const closeConfirmation = () => {
    setBrokerageToUnlink(null);
  };

  const canLinkBrokerage = useStoreState((state) =>
    state.account.canUseFeature("brokerIntegration")
  );
  const userBrokerage = useStoreState((state) => state.brokerage.userBrokerage);
  const isUnlinkingBrokerage = useStoreState((state) =>
    state.brokerage.isLoading(BrokerageLoadingType.UnlinkingBrokerage)
  );

  const { unlinkBrokerage } = useStoreActions((actions) => actions.brokerage);
  const onUnlinkClick = (brokerage: SupportedBrokerage) => {
    setBrokerageToUnlink(brokerage);
  };

  return (
    <>
      <div
        className={cx("page-component-common link-brokerage-view", className)}
      >
        {showHeader && <p className="component-title">Linked Brokerage</p>}

        <div className="content">
          {canLinkBrokerage ? (
            <>
              {Object.keys(userBrokerage || {}).map(
                (brokerage) =>
                  userBrokerage?.[brokerage as SupportedBrokerage] != null && (
                    <BrokerageItem
                      key={brokerage}
                      brokerage={brokerage as SupportedBrokerage}
                      onUnlinkClick={onUnlinkClick}
                      isActive={!isUnlinkingBrokerage}
                    />
                  )
              )}
              <PlaidLink />
            </>
          ) : (
            <>
              <p>Only Elite member can link brokerage</p>
              <SubscriptionViewTriggerButton text="Check Subscription" />
            </>
          )}
        </div>
      </div>

      {brokerageToUnlink && (
        <CustomModal
          onClickAway={closeConfirmation}
          onCloseClick={closeConfirmation}
          midComponent={`Unlink ${lodash.capitalize(brokerageToUnlink)}`}
        >
          <AlertPresetComponent
            mainContent={`Are you sure you want to unlink ${lodash.capitalize(
              brokerageToUnlink
            )}?`}
            onCancel={() => {
              closeConfirmation();
            }}
            onConfirm={() => {
              unlinkBrokerage(brokerageToUnlink);
              closeConfirmation();
            }}
          />
        </CustomModal>
      )}
    </>
  );
};
