import { Action, action } from "easy-peasy";

type ModalPresetName = "forgotModal" | "logoutModal";
interface ModalContent {
  // * Title
  title?: React.ReactNode;
  leftComponent?: React.ReactNode;
  rightComponent?: React.ReactNode;
  leftClassName?: string;
  midClassName?: string;
  rightClassName?: string;

  // * Other
  body: React.ReactNode;
  customCloseButton?: React.ReactNode;
  onClose?: Function;
  titleClassName?: string;
  bodyClassName?: string;
}
export interface ModalModel {
  content: ModalContent | null;
  preset: ModalPresetName | null;

  setContent: Action<ModalModel, ModalContent | null>;
  setPreset: Action<ModalModel, ModalPresetName | null>;
}

export const modal: ModalModel = {
  content: null,
  preset: null,

  setContent: action((state, content) => {
    state.content = content;
  }),
  setPreset: action((state, preset) => {
    state.preset = preset;
  }),
};
