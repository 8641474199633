import { useThemeColor } from "hooks/useThemeColor";
import React, { useMemo } from "react";
import { formatPercent } from "utils/common.utils";
import { VictoryAxis, VictoryBar, VictoryChart } from "victory";

export type RecommendationText =
  | "Sell"
  | "Underperform"
  | "Hold"
  | "Outperform"
  | "Buy";

export interface RecommendationBarDatum {
  recommendationText: RecommendationText;
  percent: number;
}

const cornerRadius = 4;

interface AnalystRatingChartProps {
  data: RecommendationBarDatum[];
}
export const AnalystRatingChart = ({ data }: AnalystRatingChartProps) => {
  const buyColor = useThemeColor("buy");
  const outperformColor = useThemeColor("outperform");
  const holdColor = useThemeColor("hold");
  const underperformColor = useThemeColor("underperform");
  const sellColor = useThemeColor("sell");

  const textColor = useThemeColor("secondary");

  const colorMap = useMemo<Record<RecommendationText, string>>(
    () => ({
      Buy: buyColor,
      Outperform: outperformColor,
      Hold: holdColor,
      Underperform: underperformColor,
      Sell: sellColor,
    }),
    [buyColor, outperformColor, holdColor, sellColor, underperformColor]
  );

  return (
    <div>
      <VictoryChart>
        <VictoryAxis
          style={{ axis: { stroke: "none" }, tickLabels: { fill: textColor } }}
          crossAxis
        />

        <VictoryBar
          labels={({ datum }) =>
            formatPercent((datum as RecommendationBarDatum).percent)
          }
          style={{
            labels: { fill: textColor },
            data: {
              fill: ({ datum }) =>
                colorMap[(datum as RecommendationBarDatum).recommendationText],
            },
          }}
          data={data}
          x="recommendationText"
          y="percent"
          cornerRadius={{ top: cornerRadius, bottom: cornerRadius }}
        />
      </VictoryChart>
    </div>
  );
};
