export enum WatchListLoadingType {
  FetchingData,
  DeletingWatchList,
  WritingWatchList,
}

export enum WatchListWriteMode {
  None,
  Add,
  Update,
}

export const WATCHLIST_MAX_TICKER = 30;
export const WATCHLIST_MAX = 20;
