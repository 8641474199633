import { toastOptionPreset, ToastType } from "common/toast.info";
import { ToastTemplate } from "components/common";
import React from "react";
import { toast, ToastOptions } from "react-toastify";

export const toastCustomMessage = (
  message: string,
  type: ToastType,
  options?: ToastOptions
) =>
  toast(<ToastTemplate message={message} type={type} />, {
    ...toastOptionPreset[type],
    ...options,
  });
