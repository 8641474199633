import { ApiReturnValue } from "common/api.info";
import { urlRegex } from "common/regex";
import { loadingToastId } from "common/toast.info";
import { CustomError } from "models";
import { toast } from "react-toastify";
import { handleCustomError } from "./error.util";
import { toastCustomMessage } from "./ui.util";

// Use this to execute any async function

interface ExecuteAsyncProp<T, E extends CustomError> {
  toastMessage?: string;
  setIsLoading?: (arg: boolean) => void;

  funcToExecute: (...arg: any) => Promise<T> | T;

  transformError?: (err: any) => Promise<E> | E;
  onError?: (err: E) => void;
  onSuccess?: Function;
  doToastError?: boolean;
}

export async function executeAsync<T, E extends CustomError>({
  toastMessage,
  funcToExecute,
  setIsLoading,
  onSuccess,
  onError,
  transformError,
  doToastError = true,
}: ExecuteAsyncProp<T, E>): Promise<ApiReturnValue<T, E>> {
  let result: ApiReturnValue<T, E> = { result: null, error: null };

  try {
    // Try to toggle loading if possible
    setIsLoading && setIsLoading(true);
    toastMessage && toastCustomMessage(toastMessage, "loading");

    // Execute the main function
    result.result = await funcToExecute();
    onSuccess && onSuccess();
  } catch (error) {
    const transformedError = transformError
      ? await transformError(error)
      : error;

    handleCustomError(transformedError, doToastError);
    onError && onError(transformedError);

    result.error = transformedError;
  } finally {
    // Dismiss all loading toast
    toastMessage && toast.dismiss(loadingToastId);
    setIsLoading && setIsLoading(false);
    return result;
  }
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const isUrl = (str: string) => urlRegex.test(str);
