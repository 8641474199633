import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";

export const internalService = {
  sendContactEmail: async (content: string, subject: string) => {
    const endpointInfo = functionEndpointList.sendContactEmail;
    await sendRequest({
      endpointInfo,
      useTokenInHeaders: true,
      data: { content, subject },
    });
  },

  sendFeedbackEmail: async (
    feedback: string,
    rating: number,
    tagList: string[]
  ) => {
    const endpointInfo = functionEndpointList.sendFeedbackEmail;
    await sendRequest({
      endpointInfo,
      useTokenInHeaders: true,
      data: { feedback, rating, tagList },
    });
  },
};
