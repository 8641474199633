import cx from "classnames";
import {
  DraggableCard,
  FormChoice,
  Icon,
  TagContainer,
} from "components/common";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import lodash from "lodash";
import { JournalDto } from "models/dto/journal/journal.dto";
import { Transaction } from "models/schema/transaction.model";
import React, { useEffect, useMemo, useState } from "react";
import "styles/components/pages/Journal/JournalSummary.sass";

interface JournalSummaryProp {
  journal: JournalDto;
  activeTagList?: string[];
  onTagClick?: (tag: string) => void;
  onCardClick?: (journal: JournalDto) => void;
  isActive?: boolean;

  onDeleteClick?: (journal: JournalDto) => void;
  isInSelectMode?: boolean;
  onSelect?: (journal: JournalDto) => void;
  isSelected?: boolean;
}
export const JournalSummary = ({
  journal,
  onTagClick,
  activeTagList,
  onCardClick,
  isActive = false,

  onDeleteClick,
  isInSelectMode,
  onSelect,
  isSelected,
}: JournalSummaryProp) => {
  const { tagList, htmlNote } = journal;
  const [noteStr, setNoteStr] = useState("");

  useEffect(() => {
    if (htmlNote) {
      // ! Note, there shouldn't be any nested <p> tag
      const regex = /<p(.*?)<\/p>/s;
      setNoteStr(DOMPurify.sanitize(htmlNote.match(regex)?.[0] || ""));
    }
  }, [htmlNote]);

  const headerText = useMemo<string>(() => {
    const tickerList: string[] = [];
    Object.values(journal.transactionsMap).forEach((transactionList) => {
      tickerList.push(
        ...(transactionList as Transaction[]).map((entry) => entry.ticker)
      );
    });

    const uniqTickerList = lodash.uniq(tickerList);

    if (uniqTickerList.length === 0) {
      return "";
    }
    return uniqTickerList.length === 1
      ? lodash.upperCase(tickerList[0])
      : "Multiple Stocks";
  }, [journal]);

  const onContentClick = () => {
    onCardClick && onCardClick(journal);
  };

  return (
    <DraggableCard
      containerClassName="journal-summary"
      childrenContainerClassName={cx("outer-container", {
        "outer-container-active": isActive,
      })}
      backgroundContentClassName="cta-container"
      backgroundContent={
        isInSelectMode ? (
          <button
            className="select-cta"
            onClick={() => {
              onSelect && onSelect(journal);
            }}
          >
            <FormChoice value={isSelected} type="checkbox" />
          </button>
        ) : (
          <button
            className="delete-cta"
            onClick={() => {
              onDeleteClick && onDeleteClick(journal);
            }}
          >
            <Icon icon={["fas", "trash"]} />
            <p>Delete</p>
          </button>
        )
      }
      animationProps={{
        useBgContentAsAnimProps: true,
        axis: "x",
        lockDirection: isInSelectMode ? ["left"] : ["right"],
        activeDirection: isInSelectMode ? "right" : undefined,
      }}
    >
      <div className="inner-container" onClick={onContentClick}>
        <div className="content-container">
          <p className="summary-header">{headerText}</p>
          <p className="title">{journal.title}</p>

          {noteStr && <div className="note">{parse(noteStr)}</div>}
        </div>

        {tagList.length > 0 && (
          <TagContainer
            tagList={tagList.slice(0, 2)}
            checkActive={(tag) => activeTagList?.includes(tag) || false}
            onClick={(tag) => onTagClick && onTagClick(tag)}
            useActiveColor
          />
        )}
      </div>

      <div className="icon" onClick={onContentClick}>
        <Icon icon={["fas", "chevron-right"]} />
      </div>
    </DraggableCard>
  );
};
