import { StripeRole } from "common/payment.info";
import { RecurInterval } from "models/dto/payment/subscription-plan-price.dto";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";

export const getSubscriptionPlanCentAmount = (
  plan: SubscriptionPlanDto,
  recurInterval: RecurInterval
) => {
  const planPrice = plan.prices.find(
    (price) => price.interval === recurInterval && price.active
  );

  return planPrice?.unitAmount || 0;
};

// If already subscribed to any plan other than Free,
// Hide all other plans' buttons
export const checkIsShowChoosePlanBtn = (
  planRole: string | undefined,
  currentUserStripeRole: StripeRole | null
): boolean => {
  if (
    [StripeRole.free, null].includes(currentUserStripeRole) === false &&
    planRole !== currentUserStripeRole
  ) {
    return false;
  }

  return true;
};
