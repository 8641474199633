import { logoPNG } from "assets/logo";
import { LoadingSpinner } from "components/common";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";
import React from "react";
import ReactTooltip from "react-tooltip";
import { useStoreActions, useStoreState } from "stores";
import { PaymentSettingsLoading } from "stores/payment";
import "styles/components/common/CurrentPlanSection.sass";

export const CurrentPlanSection = () => {
  const planList = useStoreState((state) => state.paymentSettings.planList);
  const isLoading = useStoreState((state) =>
    state.paymentSettings.isLoading(PaymentSettingsLoading.createPortalLink)
  );

  const navigateToCustomerPortal = useStoreActions(
    (actions) => actions.paymentSettings.navigateToCustomerPortal
  );

  const stripeRole = useStoreState((state) => state.account.stripeRole);

  const theme = useStoreState((state) => state.theme.theme);

  const onManageYourPlanClicked = async () => {
    await navigateToCustomerPortal();
  };

  const getCurrentPlan = (): SubscriptionPlanDto | undefined => {
    return planList.find((plan) => plan.role === stripeRole);
  };

  const renderBtnContent = () => {
    if (isLoading) {
      return <LoadingSpinner size={10} />;
    }

    return <span>Manage your plan</span>;
  };

  // RENDER SECTION
  const currentPlan = getCurrentPlan();
  if (!currentPlan) {
    return null;
  }

  const planColor =
    theme === "light"
      ? currentPlan.metadata?.colorTheme.lightThemeColor
      : currentPlan.metadata?.colorTheme.darkThemeColor;

  const isFreePlan = SubscriptionPlanDto.isFreePlan(currentPlan);

  return (
    <div className="current-plan-section">
      <div className="info-container">
        <div className="plan-title">
          <img src={logoPNG} alt="logo" className="plan-title-image" />

          <div
            className="plan-title-name"
            style={{
              color: planColor,
            }}
          >
            {currentPlan.name}
          </div>
        </div>
        <ul className="plan-features">
          {currentPlan.metadata?.featureSummaryList?.map(
            (shortFeature, index) => (
              <li key={`plan-${currentPlan.name}-shortFeature-${index}`}>
                {shortFeature}
              </li>
            )
          )}
        </ul>
      </div>

      <div className="button-container">
        <div className="plan-manage" data-tip data-for="manage-plan-tooltip">
          <button
            disabled={isLoading || isFreePlan}
            onClick={onManageYourPlanClicked}
            className="plan-manage-btn"
          >
            {renderBtnContent()}
          </button>

          {isFreePlan && (
            <ReactTooltip
              id="manage-plan-tooltip"
              type="info"
              effect="solid"
              place="top"
            >
              <span>You haven't subscribed to any plans</span>
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
