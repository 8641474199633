import { brokerageService } from "api/service/brokerage.service";
import { SupportedBrokerage } from "common/brokerage.info";
import { BrokerageLoadingType } from "common/brokerage/brokerage.info";
import dayjs from "dayjs";
import {
  action,
  Action,
  computed,
  Computed,
  persist,
  thunk,
  Thunk,
} from "easy-peasy";
import lodash from "lodash";
import { UserBrokerageDto } from "models/dto/brokerage/get-brokerage-user.dto";
import { SetPlaidAccessTokenDto } from "models/dto/brokerage/set-access-token.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

export interface BrokerageModel extends LoadingModel<BrokerageLoadingType> {
  // * State
  plaidLinkToken: string | null;
  plaidLinkExpirationTime: string | null;
  userBrokerage: UserBrokerageDto | null;
  isLinkedToBrokerage: Computed<BrokerageModel, boolean>;

  // * Actions
  resetStore: Action<BrokerageModel>;
  setPlaidLinkTokenAndExpirationTime: Action<
    BrokerageModel,
    { linkToken: string | null; linkExpirationTime: string }
  >;
  setUserBrokerage: Action<BrokerageModel, UserBrokerageDto | null>;

  // * Thunk
  initializeStore: Thunk<BrokerageModel>;
  unlinkBrokerage: Thunk<BrokerageModel, SupportedBrokerage>;
  // --- Plaid
  createPlaidLinkToken: Thunk<BrokerageModel, never, any, StoreModel>;
  setPlaidAccessToken: Thunk<BrokerageModel, SetPlaidAccessTokenDto>;
}

export const brokerage: BrokerageModel = persist(
  {
    // * State
    plaidLinkToken: null,
    plaidLinkExpirationTime: null,
    userBrokerage: null,
    isLinkedToBrokerage: computed(
      [(state) => state.userBrokerage],
      (userBrokerage) =>
        userBrokerage != null &&
        Object.values(userBrokerage).some((entry) => entry != null)
    ),

    // * Actions
    resetStore: action((state) => {
      state.plaidLinkToken = null;
      state.plaidLinkExpirationTime = null;
      state.userBrokerage = null;
    }),
    setPlaidLinkTokenAndExpirationTime: action(
      (state, { linkToken, linkExpirationTime }) => {
        state.plaidLinkToken = linkToken;
        state.plaidLinkExpirationTime = linkExpirationTime;
      }
    ),
    setUserBrokerage: action((state, userBrokerage) => {
      state.userBrokerage = userBrokerage;
    }),

    // * Thunks
    initializeStore: thunk(async (actions) => {
      const { result } = await executeAsync({
        funcToExecute: async () => await brokerageService.getBrokerageUser(),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            isLoading: isLoading,
            loadingType: BrokerageLoadingType.ExchangingPlaidPublicToken,
          });
        },
      });

      if (result) {
        actions.setUserBrokerage(result);
      }
    }),
    unlinkBrokerage: thunk(async (actions, brokerage) => {
      const { result } = await executeAsync({
        funcToExecute: async () =>
          await brokerageService.unlinkBrokerage(brokerage),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            isLoading: isLoading,
            loadingType: BrokerageLoadingType.UnlinkingBrokerage,
          });
        },
      });

      if (result) {
        actions.setUserBrokerage(result);
      }
    }),

    // --- Plaid
    createPlaidLinkToken: thunk(async (actions, _, { getState }) => {
      const linkExpirationTime = getState().plaidLinkExpirationTime;
      if (
        getState().plaidLinkToken &&
        linkExpirationTime &&
        dayjs(new Date(linkExpirationTime)).diff(dayjs()) > 0
      ) {
        console.info("Using old link token");
        return;
      }

      const result = await executeAsync({
        funcToExecute: async () =>
          await brokerageService.createPlaidLinkToken(),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            isLoading: isLoading,
            loadingType: BrokerageLoadingType.CreatingPlaidLinkToken,
          });
        },
      });

      actions.setPlaidLinkTokenAndExpirationTime({
        linkToken: result.result,
        // https://plaid.com/docs/api/tokens/#link-token-create-response-expiration
        linkExpirationTime: dayjs().add(4, "hours").toISOString(),
      });
    }),
    setPlaidAccessToken: thunk(async (actions, dto) => {
      const { result } = await executeAsync({
        toastMessage: `Linking ${lodash.capitalize(dto.institutionName)}...`,
        funcToExecute: async () =>
          await brokerageService.setPlaidAccessToken(dto),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            isLoading: isLoading,
            loadingType: BrokerageLoadingType.ExchangingPlaidPublicToken,
          });
        },
      });

      if (result) {
        actions.setUserBrokerage(result);
      }
    }),

    ...loadingPlugin(),
  },
  {
    allow: ["plaidLinkToken", "plaidLinkExpirationTime"],
    storage: "sessionStorage",
  }
);
