import React from "react";
import cx from "classnames";

import "styles/components/common/Tag.sass";
import Icon from "./Icon";

interface TagProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  isActive?: boolean;
  useActiveColor?: boolean;
  noTimesIconOnActive?: boolean;
}

export const Tag = ({
  children,
  onClick,
  className,
  isActive,
  useActiveColor,
  noTimesIconOnActive,
}: TagProps) => {
  return (
    <button
      onClick={() => {
        onClick && onClick();
      }}
      className={cx(
        "tag",
        { "tag-active": isActive || useActiveColor },
        className
      )}
      type="button"
    >
      {children}

      {isActive && !noTimesIconOnActive && (
        <Icon icon={["fas", "times"]} className="icon" />
      )}
    </button>
  );
};
