import cx from "classnames";
import { relayIconMap } from "common/alert-relay.info";
import { DropdownNoForm, Icon } from "components/common";
import { AlertConditionIcon } from "components/pages/Alert";
import { AlertDto } from "models/dto/alert";
import React from "react";
import Collapsible from "react-collapsible";
import "styles/components/pages/Alert/AlertSummaryCard.sass";
import { craftConditionMessage } from "utils/alert.util";

interface CTAButtonProps {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}
const CTAButton = ({
  isActive,
  onClick,
  children,
  className,
  disabled,
}: CTAButtonProps) => {
  return (
    <button
      className={cx("cta", { "cta-active": isActive }, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

interface AlertSummaryCardProps {
  alert: AlertDto;
  onEditClick?: (alert: AlertDto) => void;
  onToggle?: (alert: AlertDto) => void;
  onDelete?: (alert: AlertDto) => void;
  isLoading?: boolean;
}

export const AlertSummaryCard = ({
  alert,
  onEditClick,
  onToggle,
  onDelete,
  isLoading,
}: AlertSummaryCardProps) => {
  const conditionList = alert.conditionList;
  const isChainAlert = alert.type === "chained";
  const isActive = alert.isActive;
  const containerCN = cx("alert-summary-card", {
    "alert-summary-card-active": isActive,
  });

  const renderTrigger = () => {
    return (
      <>
        <div className="preview">
          {isChainAlert ? (
            <Icon icon={["fas", "link"]} className="fa-fw icon" />
          ) : (
            <AlertConditionIcon condition={conditionList[0]} />
          )}

          <p>
            {!isChainAlert
              ? craftConditionMessage(conditionList[0])
              : "Chained Alert"}
          </p>

          {isChainAlert && (
            <Icon className="chevron" icon={["fas", "chevron-up"]} />
          )}
        </div>

        <div className="other">
          <div className="relay-list">
            {alert.relayList.map((relay) => (
              <Icon
                icon={relayIconMap[relay]}
                key={relay}
                className="icon fa-fw"
              />
            ))}
          </div>

          {(onDelete || onEditClick || onToggle) && (
            <DropdownNoForm
              mainButtonContent={<Icon icon={["fas", "ellipsis-v"]} />}
              closeOnClickAway
              contentClassName="alert-summary-card-cta-container"
            >
              {onEditClick && (
                <CTAButton
                  onClick={() => {
                    onEditClick(alert);
                  }}
                  isActive={false}
                  disabled={isLoading}
                >
                  Edit
                </CTAButton>
              )}

              {onToggle && (
                <CTAButton
                  isActive={!isActive}
                  onClick={() => {
                    onToggle(alert);
                  }}
                  disabled={isLoading}
                >
                  {isActive ? "Deactivate" : "Activate"}
                </CTAButton>
              )}

              {onDelete && (
                <CTAButton
                  isActive={true}
                  onClick={() => {
                    onDelete(alert);
                  }}
                  className="cta-delete"
                  disabled={isLoading}
                >
                  Delete
                </CTAButton>
              )}
            </DropdownNoForm>
          )}
        </div>
      </>
    );
  };

  return (
    <Collapsible
      className={containerCN}
      openedClassName={containerCN}
      trigger={renderTrigger()}
      triggerDisabled={!isChainAlert}
      triggerClassName="trigger-title"
      triggerOpenedClassName={cx("trigger-title", "trigger-title-active")}
      contentInnerClassName="collapsible-content-container"
      transitionTime={200}
    >
      {conditionList.map((condition) => (
        <div key={condition.id} className="sub-condition-preview">
          <AlertConditionIcon condition={condition} />
          <p>{craftConditionMessage(condition)}</p>
        </div>
      ))}
    </Collapsible>
  );
};
