import { settingPathMap } from "common/routeList";
import { SettingSubscriptionView } from "components/pages/Setting/SettingSubscriptionView";
import { AccountView } from "./AccountView";
import { ContactView } from "./ContactView";
import { FeedbackView } from "./FeedbackView";

export interface SettingViewProps {
  isHidden: boolean;
}

interface SettingViewMapEntry {
  Component: () => JSX.Element;
  displayInfo: {
    text: string;
    icon: [string, string];
  };
}

export type ViewMapKey = typeof settingPathMap[keyof typeof settingPathMap];
export const settingViewMap: Partial<
  Record<ViewMapKey, SettingViewMapEntry>
> = {
  [settingPathMap.settingAccount]: {
    Component: AccountView,
    displayInfo: {
      text: "Account",
      icon: ["fas", "user"],
    },
  },
  [settingPathMap.settingSubscription]: {
    Component: SettingSubscriptionView,
    displayInfo: {
      text: "Subscription",
      icon: ["fas", "redo"],
    },
  },
  [settingPathMap.settingFeedback]: {
    Component: FeedbackView,
    displayInfo: {
      text: "Feedback",
      icon: ["fas", "heart"],
    },
  },
  [settingPathMap.settingContact]: {
    Component: ContactView,
    displayInfo: {
      text: "Contact Us",
      icon: ["fas", "phone"],
    },
  },
};
