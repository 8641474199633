import React from "react";
import "styles/components/common/TagContainer.sass";
import { Tag } from ".";

interface TagContainerProps<T> {
  tagList: T[];
  getDisplayText?: (tag: T) => string;
  onClick?: (tag: T) => void;
  checkActive?: (tag: T) => boolean;
  tagClassName?: string;
  useActiveColor?: boolean;
}
export function TagContainer<T>({
  tagList,
  getDisplayText,
  onClick,
  checkActive,
  tagClassName,
  useActiveColor,
}: TagContainerProps<T>) {
  return (
    <span className="tag-container">
      {tagList.map((tag, key) => (
        <Tag
          className={tagClassName}
          key={key}
          onClick={() => {
            onClick && onClick(tag);
          }}
          isActive={checkActive && checkActive(tag)}
          useActiveColor={useActiveColor}
        >
          {getDisplayText?.(tag) || tag}
        </Tag>
      ))}
    </span>
  );
}
