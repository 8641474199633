import React from "react";
import cx from "classnames";

import "styles/components/common/ModalComponent/AlertPresetComponent.sass";

interface AlertPresetComponentProps {
  onConfirm?: Function;
  onCancel?: Function;
  mainContent?: React.ReactNode;
  mainContentClassName?: string;
  confirmButtonClassName?: string;
  cancelButtonClassName?: string;
}
export const AlertPresetComponent = ({
  onCancel,
  onConfirm,
  mainContent,
  mainContentClassName,
  confirmButtonClassName,
  cancelButtonClassName,
}: AlertPresetComponentProps) => {
  return (
    <div className="alert-preset-component">
      <div className={cx("main-content", mainContentClassName)}>
        {mainContent}
      </div>

      <div className="cta-container">
        {onConfirm && (
          <button
            className={cx("confirm-button", confirmButtonClassName)}
            onClick={() => onConfirm()}
            type="button"
          >
            Confirm
          </button>
        )}

        {onCancel && (
          <button
            className={cx("cancel-button", cancelButtonClassName)}
            onClick={() => onCancel()}
            type="button"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};
