import { AccountLoadingType } from "common/account.info";
import {
  authPathMap,
  redirectList,
  routeList,
  routePathMap,
} from "common/routeList";
import { AlertPresetComponent } from "components/common/ModalComponent";
import { NavBar } from "components/common/NavBar";
import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useStoreActions, useStoreState } from "stores";
import { checkIfRenderRoute } from "utils/auth.util";
import { LoadingView } from "views";
import { DesktopHeader, Modal } from "../common";

export const RouteManager = () => {
  const { setContent } = useStoreActions((actions) => actions.modal);

  const isLoggedIn = useStoreState((state) => state.account.isLoggedIn);
  const account = useStoreState((state) => state.account.account);
  const isLoggingIn = useStoreState((state) =>
    state.account.isLoading(AccountLoadingType.LoggingIn)
  );
  const isLoggingOut = useStoreState((state) =>
    state.account.isLoading(AccountLoadingType.LoggingOut)
  );

  const isLoading = isLoggingIn || isLoggingOut || (isLoggedIn && !account);

  return (
    <Router
      getUserConfirmation={(message, callback) => {
        setContent({
          title: "Page leave confirmation",
          body: (
            <AlertPresetComponent
              mainContent={message}
              onCancel={() => {
                setContent(null);
                callback(false);
              }}
              onConfirm={() => {
                setContent(null);
                callback(true);
              }}
            />
          ),
        });
      }}
    >
      <div className="page-content">
        {!isLoading ? (
          <Suspense fallback={<LoadingView />}>
            <Switch>
              {routeList().map(
                ({
                  Component,
                  path,
                  requireLogin,
                  hideIfLoggedIn,
                  useLocationHeaderPathSplit,
                }) => (
                  <Route path={path} key={path}>
                    {checkIfRenderRoute(
                      isLoggedIn,
                      requireLogin,
                      hideIfLoggedIn
                    ) ? (
                      <>
                        {isLoggedIn && (
                          <DesktopHeader
                            path={path}
                            useLocationHeaderPathSplit={
                              useLocationHeaderPathSplit
                            }
                          />
                        )}
                        <Component />
                      </>
                    ) : (
                      <Redirect
                        to={isLoggedIn ? routePathMap.home : authPathMap.login}
                      />
                    )}
                  </Route>
                )
              )}

              {redirectList.map(({ from, to }) => (
                <Redirect {...{ from, to }} key={`${from}-${to}`} />
              ))}
            </Switch>
          </Suspense>
        ) : (
          <LoadingView />
        )}
      </div>

      <Modal />

      <NavBar />
    </Router>
  );
};
