import { Icon } from "components/common";
import cx from "classnames";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useStoreActions, useStoreState } from "stores";

interface ThemeToggleButtonProps {
  className?: string;
  showText?: boolean;
}
export const ThemeToggleButton = ({
  className,
  showText,
}: ThemeToggleButtonProps) => {
  const theme = useStoreState((state) => state.theme.theme);
  const { toggleTheme } = useStoreActions((actions) => actions.theme);

  return (
    <button
      className={cx(className)}
      onClick={() => {
        toggleTheme();
      }}
    >
      <SwitchTransition>
        <CSSTransition
          key={theme === "light" ? 0 : 1}
          classNames="basic-fade"
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
        >
          {theme === "light" ? (
            <>
              <Icon icon={["fas", "sun-cloud"]} className="fa-fw" />
              {showText && <p>Day</p>}
            </>
          ) : (
            <>
              <Icon icon={["fas", "moon-stars"]} className="fa-fw" />
              {showText && <p>Night</p>}
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </button>
  );
};
