import cx from "classnames";
import { ROOT_ID } from "common/common";
import {
  LogoutComponent,
  PasswordResetComponent,
} from "components/common/ModalComponent";
import React, { useCallback, useEffect, useRef } from "react";
import { useClickAway } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/Modal.sass";
import Icon from "./Icon";
import { Portal } from "./Portal";
import PerfectScrollbar from "react-perfect-scrollbar";

export const Modal = () => {
  const { content, preset } = useStoreState((state) => state.modal);
  const { setContent, setPreset } = useStoreActions((actions) => actions.modal);

  const handleClose = useCallback(() => {
    if (content) {
      content.onClose && content.onClose();
      setContent(null);
      setPreset(null);
    }
  }, [content, setContent, setPreset]);

  const containerRef = useRef<HTMLDivElement>(null);
  useClickAway(containerRef, () => {
    handleClose();
  });

  useEffect(() => {
    if (content) {
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    }
  }, [content]);

  useEffect(() => {
    const handleClose = () => {
      setContent(null);
      setPreset(null);
    };

    switch (preset) {
      case "forgotModal":
        setContent({
          title: "Reset Password",
          body: <PasswordResetComponent />,
        });
        break;
      case "logoutModal":
        setContent({
          title: "Logout",
          body: (
            <LogoutComponent onBackClick={handleClose} onLogout={handleClose} />
          ),
        });
        break;
    }
  }, [preset, setContent, setPreset]);

  if (!content) {
    return null;
  }

  const {
    title,
    leftClassName,
    leftComponent,
    midClassName,
    rightClassName,
    rightComponent,
    body,
    titleClassName,
    bodyClassName,
  } = content;

  return (
    <CustomModal
      {...{
        titleClassName,
        leftClassName,
        leftComponent,
        midClassName,
        midComponent: title,
        rightClassName,
        rightComponent,
        bodyClassName,
        onClickAway: handleClose,
        onCloseClick: handleClose,
      }}
    >
      {body}
    </CustomModal>
  );
};

interface CustomModalProps {
  titleClassName?: string;
  leftClassName?: string;
  leftComponent?: React.ReactNode;
  midClassName?: string;
  midComponent?: React.ReactNode;
  rightClassName?: string;
  rightComponent?: React.ReactNode;

  bodyClassName?: string;
  children: React.ReactNode;

  onCloseClick: () => void;
  onClickAway: () => void;
  fulLScreen?: boolean;
}
export const CustomModal = ({
  titleClassName,
  leftClassName,
  leftComponent,
  midClassName,
  midComponent,
  rightClassName,
  rightComponent,
  bodyClassName,
  children,

  onCloseClick,
  onClickAway,
  fulLScreen,
}: CustomModalProps) => {
  return (
    <Portal containerId={ROOT_ID}>
      <div className={cx("modal", { "modal-fullscreen": fulLScreen })}>
        <div
          className="overlay"
          onClick={() => {
            onClickAway && onClickAway();
          }}
        />

        <div className="content">
          <div className={cx("title", titleClassName)}>
            <div className={cx("left", leftClassName)}>{leftComponent}</div>
            <div className={cx("mid", midClassName)}>{midComponent}</div>
            <div className={cx("right", rightClassName)}>{rightComponent}</div>
          </div>
          <PerfectScrollbar className={cx("body", bodyClassName)}>
            {children}
          </PerfectScrollbar>

          {onCloseClick && (
            <button className="close-button" onClick={onCloseClick}>
              <Icon icon={["fas", "times"]} />
            </button>
          )}
        </div>
      </div>
    </Portal>
  );
};
