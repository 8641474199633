import cx from "classnames";
import { AlertType } from "common/alert/alert-common.info";
import { Icon } from "components/common";
import "styles/components/pages/Alert/AlertTypeCard.sass";

interface AlertTypeCardProps {
  onClick: (type: AlertType) => void;
}

interface AlertTypeCardInnerProps extends AlertTypeCardProps {
  icon: [string, string];
  title: string;
  description: string;
  isActive: boolean;
  type: AlertType;
  subscriptionTier?: string; // TODO: strict type hint this
}
const AlertTypeCardInner = ({
  description,
  icon,
  isActive,

  title,
  type,
  subscriptionTier,
  onClick,
}: AlertTypeCardInnerProps) => {
  return (
    <button
      className={cx("alert-type-card", { "alert-type-card-active": isActive })}
      onClick={() => {
        onClick(type);
      }}
      disabled={!isActive}
    >
      <div className="icon-container">
        <Icon icon={icon} className="fa-fw" />
      </div>

      <div className="content">
        <p className="title">{title}</p>

        {subscriptionTier && (
          <div className="subscription">
            <Icon icon={["fas", "star"]} className="fa-fw" />
            <p>{`${subscriptionTier} subscription`}</p>
          </div>
        )}

        <p className="description">{description}</p>
      </div>
    </button>
  );
};

export const SingleAlertTypeCard = ({ onClick }: AlertTypeCardProps) => {
  return (
    <AlertTypeCardInner
      title="Single Alert"
      description="Notifies you when one condition is met"
      icon={["fas", "bell"]}
      isActive={true}
      type="single"
      onClick={onClick}
    />
  );
};

export const ChainedAlertTypeCard = ({ onClick }: AlertTypeCardProps) => {
  return (
    <AlertTypeCardInner
      title="Chained Alert"
      description="Notifies you when multiple conditions are met"
      icon={["fas", "link"]}
      isActive={true}
      type="chained"
      onClick={onClick}
    />
  );
};

export const ComingSoonAlertTypeCard = () => {
  return (
    <AlertTypeCardInner
      title="Coming soon"
      description="More alert types coming soon"
      icon={["fas", "ellipsis-h"]}
      isActive={false}
      type="chained"
      onClick={() => {}}
      subscriptionTier="elite"
    />
  );
};
