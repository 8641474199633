import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-use";
import "styles/components/common/DesktopHeader.sass";
import lodash from "lodash";

interface DesktopHeaderProps {
  path: string;
  useLocationHeaderPathSplit?: boolean;
}
export const DesktopHeader = ({
  path,
  useLocationHeaderPathSplit,
}: DesktopHeaderProps) => {
  const splitPathList = useMemo(() => path.split("/").slice(1), [path]);
  const locationPathList = useLocation().pathname?.split("/").slice(1);

  /*
  [#118 comment](https://github.com/Apheleia-Inc/stockwise/pull/118#discussion_r594496600)
  - The purpose is to make a breadcrumb
  - The idea is to split the path passed in from props or the path coming from useLocation if useLocationHeaderPathSplit is true

  --- Why sometimes we want to use the path from useLocation and sometimes from the props?
  * useLocation includes everything from the path we currently use, which also include IDs in most case (which we don't want to show in the breadcrumb) so useLocation is not an option
  path passed in props only includes base path, so in case we navigate from the base path to somewhere else dynamically and we also wants to show that (/stock -> /stock/FB) then this will fails
  * There is an edge case where both of those combined which I haven't handled but that's too complicated and we should use other types of paths for SEO and such anyway so I don't think it'll ever happen
  */
  const pathListToUse = useLocationHeaderPathSplit
    ? locationPathList || []
    : splitPathList;

  return (
    <ul className="desktop-header">
      {pathListToUse.map((pathSegment, index) => {
        const newUrlList = pathListToUse.slice(0, index);
        newUrlList.push(pathSegment);

        return (
          <li key={pathSegment}>
            <NavLink
              to={`/${newUrlList.join("/")}`}
              activeClassName="bread-active"
              className="bread"
            >
              {lodash.startCase(pathSegment)}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};
