import cx from "classnames";
import { FormInput, FormTextArea } from "components/common";
import { DiscordComponent } from "components/common/DiscordComponent";
import { ThankyouView } from "components/common/ThankyouView";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMedia } from "react-use";
import { useStoreActions } from "stores";
import "styles/components/pages/Setting/FeedbackAndContactView.sass";
import { getMediaBreakpoint } from "utils/common.utils";

interface FormViewProps {
  setSubmitState: (arg: boolean) => void;
}
const FormView = ({ setSubmitState }: FormViewProps) => {
  const { handleSubmit, errors, register } = useForm<{
    subject: string;
    content: string;
  }>();

  const { submitContactForm } = useStoreActions((actions) => actions.setting);

  const onSubmit = handleSubmit(async ({ subject, content }) => {
    const submitResult = await submitContactForm({ subject, content });
    submitResult && setSubmitState(true);
  });

  return (
    <form className="form-view" onSubmit={onSubmit}>
      <section>
        <FormInput
          label="Subject"
          formValidator={{
            name: "subject",
            inputRef: register({ required: "Can't be empty" }),
            errorText: errors["subject"]?.message,
          }}
          borderType="full-border"
        />

        <FormTextArea
          className="feedback-form"
          placeholder="What would you like to contact us for"
          formValidator={{
            name: "content",
            inputRef: register({ required: "Can't be empty" }),
            errorText: errors["content"]?.message,
          }}
          borderType="full-border"
        />
      </section>

      <button className="submit-button">Send</button>

      <DiscordComponent />
    </form>
  );
};

export const ContactView = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const [submitState, setSubmitState] = useState(false);

  return (
    <div
      className={cx("feedback-and-contact-view", {
        "feedback-and-contact-view-no-padding": submitState && !isLg,
      })}
    >
      {isLg && <h2 className="title">Contact Us</h2>}

      {submitState ? (
        <ThankyouView extraText={<p>We will get back to you soon!</p>} />
      ) : (
        <FormView setSubmitState={setSubmitState} />
      )}
    </div>
  );
};
