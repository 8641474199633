import firebase from "firebase/app";
import "firebase/auth";
import { sleep } from "./api.util";
import { toastCustomMessage } from "./ui.util";

/**
 * @param forceRefresh
 * Force refresh regardless of token expiration.
 */
export const getFirebaseUserToken = async (
  forceRefresh?: boolean
): Promise<string> => {
  let token = await getFirebaseUser()?.getIdToken(forceRefresh);

  const MAX_RETRY = 10;
  let counter = 0;
  for (; counter < MAX_RETRY && !token; counter++) {
    // try again first
    await sleep(500);
    token = await getFirebaseUser()?.getIdToken();
  }

  if (counter > 0) {
    console.warn(`Account load after ${counter} retries`);
  }
  if (!token) {
    toastCustomMessage(
      "Fail to load your account, please refresh or check your network",
      "error"
    );
    throw new Error(`Fail to load account after ${counter} retries`);
  }

  return token;
};

export const getFirebaseUser = () => firebase.auth().currentUser;

export const getUserPhoneNumber = () => getFirebaseUser()?.phoneNumber;

export const getUserEmail = () => getFirebaseUser()?.email;
