import cx from "classnames";
import {
  FormChoice,
  FormShimmer,
  InformationCard,
  ShimmerContainer,
} from "components/common";
import { AlertSummaryCard } from "components/pages/Alert/AlertSummaryCard";
import { AlertDto } from "models/dto/alert";
import { useAsync } from "react-use";
import { useStoreActions } from "stores";
import "styles/pages/common/LinkAlertView.sass";

interface LinkAlertViewProps {
  className?: string;
  activeAlertIdList: string[];
  onAlertSelect: (alertId: string, alert: AlertDto) => void;
  noAlertText?: string;
}

type AlertListByTickerList = { ticker: string; alertList: AlertDto[] }[];
export const LinkAlertView = ({
  activeAlertIdList,
  onAlertSelect,
  className,
  noAlertText,
}: LinkAlertViewProps) => {
  const { fetchAndSaveAlertList } = useStoreActions(
    (actions) => actions.alertDashboard
  );
  const { loading, value: alertListByTickerList } = useAsync<
    () => Promise<AlertListByTickerList>
  >(async () => {
    const alertList = (await fetchAndSaveAlertList()) || [];

    const alertByTickerMap: Record<string, AlertDto[]> = {};
    alertList.forEach((alert) => {
      const ticker = alert.ticker;
      if (alertByTickerMap[ticker]) {
        alertByTickerMap[ticker].push(alert);
      } else {
        alertByTickerMap[ticker] = [alert];
      }
    });

    return Object.entries(alertByTickerMap).map(([ticker, alertList]) => ({
      ticker: ticker,
      alertList: alertList,
    }));
  });

  return (
    <div className={cx("link-alert-view", className)}>
      <ShimmerContainer isReady={!loading} shimmerComponent={<FormShimmer />}>
        {alertListByTickerList && alertListByTickerList.length > 0 ? (
          alertListByTickerList?.map(({ alertList, ticker }, index) => {
            const renderAlertList = () =>
              alertList.map((alert) => {
                const id = alert.alertId;
                const isActive = activeAlertIdList.includes(id);

                return (
                  <div className="alert-wrapper" key={`${id}---${index}`}>
                    <AlertSummaryCard alert={alert} />
                    <FormChoice
                      type="checkbox"
                      onChange={() => onAlertSelect(id, alert)}
                      value={isActive}
                    />
                  </div>
                );
              });

            return (
              <div className="ticker-wrapper">
                <div className="title">
                  <p>Company: </p>
                  <p className="ticker">{ticker}</p>
                </div>

                {renderAlertList()}
              </div>
            );
          })
        ) : (
          <InformationCard>{noAlertText || "No Alert to Link"}</InformationCard>
        )}
      </ShimmerContainer>
    </div>
  );
};
