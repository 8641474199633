import { newsService } from "api/service";
import {
  newSentimentList,
  NewsLoadingType,
  NewsSentiment,
  NewsSource,
  newsSourceList,
} from "common/news.info";
import { action, Action, persist, Thunk, thunk } from "easy-peasy";
import { NewsEntry } from "models";
import { NewsFilterCriteria } from "models/schema/news.schema";
import { StoreModel } from "stores";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { executeAsync } from "utils/api.util";
import { addOrRemoveItemFromList, sortDayCallback } from "utils/common.utils";
import { extractFunctionError } from "utils/error.util";

const generateCriteria = (): NewsFilterCriteria => ({
  sourceList: newsSourceList as NewsSource[],
  sentimentList: newSentimentList as NewsSentiment[],
});

export interface NewsModel extends LoadingModel<NewsLoadingType> {
  // * State
  criteria: NewsFilterCriteria;

  // * Actions
  resetStore: Action<NewsModel>;
  setCriteria: Action<NewsModel, NewsFilterCriteria>;
  addOrRemoveSentiment: Action<NewsModel, NewsSentiment>;
  addOrRemoveNewsSource: Action<NewsModel, NewsSource>;
  selectOrDeselectAllNewsSource: Action<NewsModel>;

  // * Thunks
  searchNews: Thunk<
    NewsModel,
    { ticker: string; useCriteria?: boolean },
    any,
    StoreModel,
    Promise<NewsEntry[]>
  >;
}

export const news: NewsModel = persist(
  {
    // * State
    criteria: generateCriteria(),

    // * Actions
    resetStore: action((state) => {
      state.criteria = generateCriteria();
    }),
    setCriteria: action((state, criteria) => {
      state.criteria = criteria;
    }),
    addOrRemoveSentiment: action((state, sentiment) => {
      const curCriteria = state.criteria;
      if (curCriteria.sentimentList) {
        addOrRemoveItemFromList(curCriteria.sentimentList, sentiment);
      } else {
        curCriteria.sentimentList = [sentiment];
      }
    }),
    addOrRemoveNewsSource: action((state, newsSource) => {
      const curCriteria = state.criteria;
      if (curCriteria.sourceList) {
        addOrRemoveItemFromList(curCriteria.sourceList, newsSource);
      } else {
        curCriteria.sourceList = [newsSource];
      }
    }),
    selectOrDeselectAllNewsSource: action((state) => {
      const curCriteria = state.criteria;
      if (
        !curCriteria.sourceList ||
        curCriteria.sourceList.length < newsSourceList.length
      ) {
        curCriteria.sourceList = newsSourceList as NewsSource[];
      } else {
        curCriteria.sourceList = [];
      }
    }),

    // * Thunks
    searchNews: thunk(
      async (actions, { ticker, useCriteria }, { getState }) => {
        if (ticker.length <= 0) {
          return [];
        }

        const callSearchNews = async () => {
          return await newsService.getNewsList(
            [ticker],
            useCriteria ? getState().criteria : undefined
          );
        };
        const searchResult = await executeAsync({
          funcToExecute: callSearchNews,
          transformError: extractFunctionError,
          setIsLoading: (isLoading: boolean) => {
            actions.setIsLoading({
              loadingType: NewsLoadingType.FetchNews,
              isLoading: isLoading,
            });
          },
        });

        const newsList = searchResult.result || [];
        newsList.sort((entryA, entryB) => {
          return -sortDayCallback(entryA.time, entryB.time);
        });

        return newsList;
      }
    ),

    ...loadingPlugin(),
  },
  { storage: "sessionStorage" }
);
