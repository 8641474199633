import { ListSlider } from "components/common";
import "styles/components/common/SwitchSlider.sass";
import cx from "classnames";

interface SwitchSliderProp<T extends string> {
  switchList: readonly T[];
  defaultIndex?: number;
  onClick: (arg: T) => void;
  className?: string;
}
export function SwitchSlider<T extends string>({
  switchList,
  onClick,
  defaultIndex,
  className,
}: SwitchSliderProp<T>) {
  return (
    <ListSlider
      componentList={(switchList as unknown) as Array<T>}
      defaultIndex={defaultIndex}
      useContainerPreset="preset-1"
      containerClassName={cx("switch-slider-container", className)}
      itemWrapperClassName="item"
      activeItemWrapperClassName="item-active"
      barClassName="bar"
      onClick={(_, item) => {
        onClick(item);
      }}
      isInline
    />
  );
}
