export const transactionTradeTypeList = ["buy", "sell"] as const;
export type TransactionTradeType = typeof transactionTradeTypeList[number];

export const transactionOptionTypeList = ["call", "put"] as const;
export type TransactionOptionType = typeof transactionOptionTypeList[number];

export const transactionTypeList = ["stock", "option"] as const;
export type TransactionType = typeof transactionTypeList[number];

export const transactionSourceList = ["manual", "plaid", "csv"] as const;
export type TransactionSource = typeof transactionSourceList[number];
