import cx from "classnames";
import { WatchListWriteMode } from "common/watch-list.info";
import { Icon } from "components/common";
import { DropdownNoForm } from "components/common/DropdownNoForm";
import { WatchListTickerItem } from "components/common/WatchList/WatchListTickerItem";
import { TickerDetail, WatchListDto } from "models/dto/watchlist/watchlist.dto";
import React, { useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/WatchList/WatchList.sass";

interface WatchListHeaderProps {
  title: string;
  numberOfItem?: number;
  icon: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}
const WatchListHeader = ({
  title,
  numberOfItem,
  icon,
  onClick,
  disabled,
  className,
}: WatchListHeaderProps) => {
  return (
    <button
      disabled={disabled}
      className={cx("watch-list-title", className)}
      onClick={onClick}
    >
      <div className="icon-container">{icon}</div>

      <div className="content">
        <p className="title">{title}</p>
        {numberOfItem != null && (
          <p className="num-item">{numberOfItem} Items</p>
        )}
      </div>
    </button>
  );
};

interface WatchListProps {
  watchList: WatchListDto;
  watchListTickerInfoMap: Record<string, TickerDetail>;
  onDeleteClick: (watchList: WatchListDto) => void;
  onEditClick: (watchList: WatchListDto) => void;
  ctaDisabled: boolean;
}
export const WatchList = ({
  watchList,
  watchListTickerInfoMap,
  onDeleteClick,
  onEditClick,
  ctaDisabled,
}: WatchListProps) => {
  const [open, setOpen] = useState(false);
  const triggerOpen = () => {
    if (watchList.tickerInfoList.length > 0) {
      setOpen((prev) => !prev);
    }
  };

  useEffect(() => {
    if (watchList.tickerInfoList.length <= 0) {
      setOpen(false);
    }
  }, [watchList.tickerInfoList]);

  return (
    <Collapsible
      open={open}
      // ! Disable this because of the dropdown cta
      triggerDisabled={true}
      transitionTime={200}
      className="watch-list"
      openedClassName="watch-list"
      triggerClassName="watch-list-trigger"
      triggerOpenedClassName="watch-list-trigger watch-list-trigger-active"
      trigger={
        <>
          <WatchListHeader
            onClick={triggerOpen}
            title={watchList.watchListUIInfo.name}
            numberOfItem={watchList.tickerInfoList.length}
            icon={watchList.watchListUIInfo.icon}
          />

          <div
            className="cta"
            // TODO: Replace cta if watchlist is from external source
          >
            <DropdownNoForm
              mainButtonContent={<Icon icon={["fas", "ellipsis-h"]} />}
              contentClassName="watchlist-dropdown-container"
            >
              <button
                disabled={ctaDisabled}
                onClick={() => {
                  onEditClick(watchList);
                }}
                className="edit-cta"
              >
                <p>Edit</p>
                <Icon icon={["fas", "pen"]} className="fa-fw" />
              </button>

              <button
                disabled={ctaDisabled}
                onClick={() => {
                  onDeleteClick(watchList);
                }}
                className="delete-cta"
              >
                <p>Delete</p>
                <Icon icon={["fas", "trash"]} className="fa-fw" />
              </button>
            </DropdownNoForm>

            <button className="chevron" onClick={triggerOpen}>
              <Icon icon={["fas", "chevron-down"]} />
            </button>
          </div>
        </>
      }
    >
      {watchList.tickerInfoList.map(({ ticker }) => {
        const detail = watchListTickerInfoMap[ticker];
        if (!detail) {
          return null;
        }

        return <WatchListTickerItem key={ticker} tickerDetail={detail} />;
      })}
    </Collapsible>
  );
};

export const WatchListAddButton = () => {
  const { initializeWriteMode: initializeEditMode } = useStoreActions(
    (actions) => actions.watchList
  );
  const editMode = useStoreState((state) => state.watchList.writeMode);

  const onClick = () => {
    initializeEditMode(null);
  };

  return (
    <WatchListHeader
      onClick={onClick}
      disabled={editMode !== WatchListWriteMode.None}
      title="Create New List"
      icon={<Icon icon={["fas", "plus"]} className="fa-fw icon" />}
      className="new-watch-list-button"
    />
  );
};
