import { InformationCard } from "components/common";
import { NotificationCard } from "components/common/NotificationCard";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/AlertDashboard/AllNotificationView.sass";
import { getMediaBreakpoint } from "utils/common.utils";
import cx from "classnames";
import { NotificationType } from "common/notification.info";

interface AllNotificationViewProps {
  containerClassName?: string;
  titleClassName?: string;
  notificationType: NotificationType;
}
export const AllNotificationView = ({
  containerClassName,
  titleClassName,
  notificationType,
}: AllNotificationViewProps) => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  const notificationList = useStoreState((state) =>
    state.notification.notificationListByType(notificationType)
  );
  const { markAllOfTypeAsRead } = useStoreActions(
    (actions) => actions.notification
  );

  useEffect(() => {
    markAllOfTypeAsRead(notificationType);
  }, [markAllOfTypeAsRead, notificationType]);

  const Container = isLg ? PerfectScrollbar : "div";
  return (
    <Container className={cx("all-notification-view", containerClassName)}>
      <div className={cx("title", titleClassName)}>
        <h3>
          All {notificationType} Notifications ({notificationList.length})
        </h3>
      </div>

      {notificationList.length > 0 ? (
        <>
          {notificationList.map((entry) => (
            <NotificationCard notification={entry} type="flat" key={entry.id} />
          ))}
        </>
      ) : (
        <InformationCard>
          <p>No new notification</p>
        </InformationCard>
      )}
    </Container>
  );
};
