import { stockNameToDisplayNameMap } from "common/stock/stock.info";
import {
  FormShimmer,
  ShimmerContainer,
} from "components/common/ContentShimmer";
import { PriceOrPercent } from "components/common/PriceOrPercent";
import lodash from "lodash";
import { Quote } from "models";
import React, { useRef } from "react";
import { useAsync } from "react-use";
import { useStoreActions } from "stores";
import "styles/components/common/ChartWithQuoteComponent.sass";
import { StockChart } from "..";

interface ChartWithQuoteComponentProps {
  tickerList: string[];
  showCompanyName?: boolean;
  chartOptions?: { height?: number };
}
export const ChartWithQuoteComponent = ({
  tickerList,
  showCompanyName,
  chartOptions,
}: ChartWithQuoteComponentProps) => {
  const { getQuoteList } = useStoreActions((actions) => actions.stock);

  const prevQuoteList = useRef<Quote[]>([]);
  const { value: quoteList, loading } = useAsync<
    () => Promise<Quote[] | null>
  >(async () => {
    const prevTickerList = prevQuoteList.current.map((entry) => entry.symbol);
    if (prevTickerList.length === tickerList.length) {
      prevTickerList.sort();
      const newTickerList = [...tickerList].sort();

      if (lodash.isEqual(prevTickerList, newTickerList)) {
        // No point in fetching if ticker list is the same
        return prevQuoteList.current;
      }
    }

    const resultData = await getQuoteList(tickerList);
    prevQuoteList.current = resultData;
    return resultData;
  }, [tickerList, getQuoteList]);

  const headerText =
    tickerList.length > 1 ? "Multiple Stocks" : lodash.upperCase(tickerList[0]);

  const renderQuoteInfo = () => {
    if (!quoteList || quoteList.length <= 0) {
      return;
    }

    if (quoteList.length > 1) {
      return (
        <>
          {quoteList.map((quote) => (
            <div className="stat" key={quote.symbol}>
              <p className="symbol">{quote.symbol}</p>

              <PriceOrPercent
                type="price"
                useSentimentColor={false}
                value={quote.iexRealtimePrice || quote.latestPrice}
              />
              <PriceOrPercent
                type="percent"
                value={quote.changePercent * 100}
              />
            </div>
          ))}
          <p>Today</p>
        </>
      );
    }

    const quote = quoteList[0];
    return (
      <div className="stat">
        <PriceOrPercent
          type="price"
          useSentimentColor={false}
          value={quote.iexRealtimePrice || quote.latestPrice}
          className="big-price"
        />
        <PriceOrPercent
          type="percent"
          value={quote.changePercent * 100}
          className="small-percent"
          showPosSignOnPos
        />

        <p>Today</p>
      </div>
    );
  };

  return (
    <div className="chart-quote-container">
      <ShimmerContainer shimmerComponent={<FormShimmer />} isReady={!loading}>
        {quoteList && (
          <div className="quote">
            <p className="symbol">{headerText}</p>
            {quoteList.length > 0 && showCompanyName && (
              <p className="name">
                {quoteList.length > 1
                  ? "Multiple Stocks"
                  : stockNameToDisplayNameMap[quoteList[0].symbol] ||
                    quoteList[0].companyName}
              </p>
            )}

            <div className="stat-container">{renderQuoteInfo()}</div>
          </div>
        )}
      </ShimmerContainer>

      <StockChart tickerList={tickerList} chartOptions={chartOptions} />
    </div>
  );
};
