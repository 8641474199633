import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { SupportedBrokerage } from "common/brokerage.info";
import {
  DEFAULT_BROKERAGE_ANALYTIC_LIMIT,
  DEFAULT_BROKERAGE_ANALYTIC_OFFSET,
} from "common/brokerage/brokerage-analytic.info";
import {
  GetAnalyzedHoldingDataResDto,
  GetAnalyzedTransactionDataReqDto,
  GetAnalyzedTransactionDataResDto,
} from "models/dto/brokerage/get-brokerage-data.dto";
import { UserBrokerageDto } from "models/dto/brokerage/get-brokerage-user.dto";
import { SetPlaidAccessTokenDto } from "models/dto/brokerage/set-access-token.dto";

export const brokerageService = {
  unlinkBrokerage: async (brokerage: SupportedBrokerage) => {
    const endpointInfo = functionEndpointList.unlinkBrokerage;
    return await sendRequest<UserBrokerageDto>({
      endpointInfo: endpointInfo,
      extraPath: [brokerage],
    });
  },

  getBrokerageUser: async () => {
    const endpointInfo = functionEndpointList.getBrokerageUser;
    return await sendRequest<UserBrokerageDto>({ endpointInfo });
  },

  getAnalyzedTransactionData: async (
    brokerage: SupportedBrokerage,
    data?: GetAnalyzedTransactionDataReqDto
  ) => {
    const endpointInfo = functionEndpointList.getAnalyzedTransactionList;
    return await sendRequest<
      GetAnalyzedTransactionDataResDto,
      GetAnalyzedTransactionDataReqDto
    >({
      endpointInfo: endpointInfo,
      extraPath: [brokerage],
      data: {
        ...{
          limit: DEFAULT_BROKERAGE_ANALYTIC_LIMIT,
          offset: DEFAULT_BROKERAGE_ANALYTIC_OFFSET,
        },
        ...data,
      },
    });
  },

  getAnalyzedHoldingsData: async (brokerage: SupportedBrokerage) => {
    const endpointInfo = functionEndpointList.getAnalyzedHoldingList;
    return await sendRequest<GetAnalyzedHoldingDataResDto>({
      endpointInfo: endpointInfo,
      extraPath: [brokerage],
    });
  },

  // --- Plaid
  createPlaidLinkToken: async () => {
    const endpointInfo = functionEndpointList.createPlaidLinkToken;
    return await sendRequest<string>({ endpointInfo });
  },

  setPlaidAccessToken: async (dto: SetPlaidAccessTokenDto) => {
    const endpointInfo = functionEndpointList.setPlaidAccessToken;
    return await sendRequest<UserBrokerageDto, SetPlaidAccessTokenDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: dto,
    });
  },
};
