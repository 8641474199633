import "@stripe/stripe-js";
import { firebaseConfig } from "common/firebase.config";
import { AuthManager, RouteManager, ThemeManager } from "components/managers";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useStoreRehydrated } from "easy-peasy";
import firebase from "firebase/app";
import "firebase/firestore";
import "react-perfect-scrollbar/dist/css/styles.css";
import "styles/main/App.sass";
import "styles/main/theme.sass";
import { executeBasedOnEnv } from "utils/env.utils";
import { initializeIconList } from "utils/icons/iconInitializer";
import { LoadingView } from "views";
import("firebase/firestore");

initializeIconList();
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

if (firebase.apps.length <= 0) {
  firebase.initializeApp(firebaseConfig);
  executeBasedOnEnv({
    devFunc: () => {
      console.info("In DEV environment");
    },
    uatFunc: async () => {
      console.info("In UAT environment");

      const BASE_HOST = "localhost";

      const FIRESTORE_PORT = 8080;
      const AUTH_PORT = 9099;

      firebase.firestore().useEmulator(BASE_HOST, FIRESTORE_PORT);
      firebase.auth().useEmulator(`http://${BASE_HOST}:${AUTH_PORT}`);
    },
    prdFunc: () => {},
  });
} else {
  firebase.app();
}

function App() {
  const isRehydrated = useStoreRehydrated();

  return (
    <div className="app">
      <AuthManager />
      {isRehydrated ? <RouteManager /> : <LoadingView />}
      <ThemeManager />
    </div>
  );
}

export default App;
