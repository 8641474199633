import { FormChoice } from "components/common";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Journal/JournalSelectCTA.sass";

export const JournalSelectCTA = () => {
  const {
    journalList,
    selectedJournalIdList: selectedJournalList,
  } = useStoreState((state) => state.journalDashboard);
  const {
    clearSelectedJournal,
    setIsInSelectMode,
    selectOrDeselectJournal,
  } = useStoreActions((actions) => actions.journalDashboard);

  return (
    <div className="journal-select-cta-container">
      <FormChoice
        type="checkbox"
        label={`Select all (${journalList.length})`}
        onChange={() => {
          if (journalList.length !== selectedJournalList.length) {
            journalList.forEach((journal) =>
              selectOrDeselectJournal(journal.id)
            );
          } else {
            clearSelectedJournal();
          }
        }}
        value={journalList.length === selectedJournalList.length}
      />

      <button
        className="cancel-button"
        onClick={() => {
          clearSelectedJournal();
          setIsInSelectMode(false);
        }}
      >
        Cancel
      </button>
    </div>
  );
};

export const JournalSelectSwitch = () => {
  const { setIsInSelectMode } = useStoreActions(
    (actions) => actions.journalDashboard
  );

  return (
    <FormChoice
      type="checkbox"
      label="To Select Mode"
      onChange={(value) => {
        setIsInSelectMode(value);
      }}
    />
  );
};
