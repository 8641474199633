import progressBar from "assets/animation/117-progress-bar.json";
import { DarkLogo, LightLogo } from "assets/logo";
import { LottiePlayer } from "components/common";
import React from "react";
import { useStoreState } from "stores";
import "styles/views/common/LoadingView.sass";

export const LoadingView = () => {
  const theme = useStoreState((state) => state.theme.theme);
  const Logo = theme === "dark" ? DarkLogo : LightLogo;

  return (
    <div className="loading-view">
      <div className="content-container">
        <Logo className="logo" />
        <LottiePlayer animationData={progressBar} />
      </div>
    </div>
  );
};
