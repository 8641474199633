import React, { useEffect, useState, useRef } from "react";
import { useSpring, a } from "react-spring";
import { v4 as uuidv4 } from "uuid";
import cx from "classnames";
import lodash from "lodash";

import "styles/components/common/ListSlider.sass";

import { htmlErrorMessage } from "common/standardMessage";

interface ListSliderProps<T extends React.ReactNode> {
  componentList: Array<T>;
  curComponent?: T;
  defaultIndex?: number;
  isAtTop?: boolean;
  isInline?: boolean;
  onClick?: (index: number, item: T) => void;
  onClickWithCondition?: (index: number, item: T) => boolean;
  buttonType?: "button" | "reset" | "submit";
  itemWrapperClassName?: string;
  activeItemWrapperClassName?: string;
  containerClassName?: string;
  useContainerPreset?: "preset-1";
  barClassName?: string;
}
export function ListSlider<T>({
  componentList,
  curComponent,
  defaultIndex = 0,
  isAtTop = false,
  isInline = false,
  onClick,
  onClickWithCondition,
  buttonType = "button",
  itemWrapperClassName,
  activeItemWrapperClassName,
  containerClassName,
  useContainerPreset,
  barClassName,
}: ListSliderProps<T>) {
  const [baseId] = useState(uuidv4());
  const containerRef = useRef<HTMLDivElement>(null);

  // ? Should we store HTMLElement or just the result of getBoundingClient?
  // ?  Storing HTMLElement and dynamically calculate the boundary
  // ?    might help with elements with dynamic size
  const [itemList, setItemList] = useState<Array<HTMLElement | null>>([]);
  const [curViewIndex, setCurViewIndex] = useState(defaultIndex);

  const [{ x, y, width }, set] = useSpring(() => ({ x: 0, y: 0, width: 0 }));

  useEffect(() => {
    const elementList: Array<HTMLElement | null> = [];
    for (let index = 0; index < componentList.length; index++) {
      const curId = `${baseId}-${index}`;
      const element = document.getElementById(curId);
      elementList.push(element);
    }

    setItemList(elementList);
  }, [baseId, componentList]);

  useEffect(() => {
    if (itemList.length > 0) {
      // Move the bar!
      const item = itemList[curViewIndex];
      if (item && containerRef.current) {
        // TODO: Generalize this for more cases (vertical container, etc)
        const {
          x: containerX,
          y: containerY,
        } = containerRef.current.getBoundingClientRect();
        const { x, y, width } = item.getBoundingClientRect();

        // Minus to translate back
        set({ x: x - containerX, y: isAtTop ? y - containerY : 0, width });
      } else {
        throw new Error(htmlErrorMessage.nullElement("List Slider"));
      }
    }
  }, [curViewIndex, itemList, set, isAtTop]);

  useEffect(() => {
    if (curComponent) {
      setCurViewIndex(
        componentList.findIndex((component) =>
          lodash.isEqual(curComponent, component)
        ) || 0
      );
    }
  }, [curComponent, componentList]);

  const onComponentClick = (index: number, item: T) => {
    if (onClickWithCondition) {
      if (onClickWithCondition(index, item) === true) {
        setCurViewIndex(index);
      }
    } else {
      if (!curComponent) {
        setCurViewIndex(index);
      }
      onClick && onClick(index, item);
    }
  };

  return (
    <a.div
      className={cx(
        "list-slider-container",
        { [`list-slider-container-${useContainerPreset}`]: useContainerPreset },
        containerClassName
      )}
      ref={containerRef}
    >
      <a.div
        className={cx("slider-bar", barClassName)}
        style={{
          x,
          y,
          width,
          ...(isInline
            ? { bottom: isAtTop ? "100%" : 0 }
            : { top: isAtTop ? 0 : "100%" }),
        }}
      />

      {componentList.map((item, index) => (
        <a.button
          key={index}
          type={buttonType}
          className={cx("item-wrapper", itemWrapperClassName, {
            [activeItemWrapperClassName || ""]:
              activeItemWrapperClassName && index === curViewIndex,
          })}
          onClick={() => onComponentClick(index, item)}
          id={`${baseId}-${index}`}
          style={{ width: `${100 / componentList.length}%` }}
        >
          {item}
        </a.button>
      ))}
    </a.div>
  );
}
