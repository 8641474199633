import { AlertType } from "common/alert/alert-common.info";
import { AlertConditionType } from "common/alert/alert-condition.info";
import {
  AlertManageErrorMapKey,
  AlertManageType,
  AlertSubType,
  alertSubTypeMap,
} from "common/alert/alert-manage.info";
import {
  AlertAddConditionStep,
  AlertAddConditionView,
  AlertManageStep,
} from "common/alert/alert-page.info";
import { internalErrorMessage } from "common/standardMessage";
import { Action, action, ActionOn, actionOn } from "easy-peasy";
import lodash from "lodash";
import { CompanySummary, FunctionErrorMessage } from "models";
import { AlertConditionModel, AlertDto } from "models/dto/alert";
import { AlertManageModel } from "stores/alert";
import { revertLookUpConditionSubType } from "utils/alert.util";
import { v4 as uuidv4 } from "uuid";

export interface AlertManageAction {
  // * Actions
  // --- Store level actions
  resetStore: Action<AlertManageModel>;
  setAlertManageType: Action<AlertManageModel, AlertManageType>;

  setAlert: Action<AlertManageModel, AlertDto | null>;
  setAlertType: Action<AlertManageModel, AlertType>;

  // --- Alert level actions
  setTicker: Action<AlertManageModel, string>;
  sliceConditionList: Action<AlertManageModel, number>;
  addOrUpdateCondition: Action<AlertManageModel, AlertConditionModel>;
  removeCondition: Action<AlertManageModel, string>;
  setTargetCompanySummary: Action<AlertManageModel, CompanySummary | null>;

  // --- Condition level
  setCurConditionType: Action<AlertManageModel, AlertConditionType | null>;
  onSetType: ActionOn<AlertManageModel>;

  setCurConditionSubType: Action<AlertManageModel, AlertSubType | null>;
  onSetSubType: ActionOn<AlertManageModel>;

  initializeCondition: Action<AlertManageModel, AlertConditionModel | null>;

  // --- UI
  setCurManageStep: Action<AlertManageModel, AlertManageStep>;
  setCurAddConditionView: Action<AlertManageModel, AlertAddConditionView>;
  setCurAddConditionStep: Action<AlertManageModel, AlertAddConditionStep>;
  setError: Action<
    AlertManageModel,
    {
      key: AlertManageErrorMapKey;
      detailMessage: string | null;
      message?: FunctionErrorMessage;
    }
  >;
  resetErrorMap: Action<AlertManageModel>;
}

export const alertManageAction: AlertManageAction = {
  resetStore: action((state) => {
    state.alert = null;
    state.originalAlert = null;
    state.alertManageType = AlertManageType.None;
    state.curManageStep = AlertManageStep.CompanySearch;
    state.curAddConditionStep = AlertAddConditionStep.TypeSelection;
    state.curAddConditionView = AlertAddConditionView.Empty;
    state.curConditionType = null;
    state.curConditionSubType = null;
    state.curEditingCondition = null;
    state.targetCompanySummary = null;
    state.errorMap = {};
  }),

  // --- Alert level
  setAlert: action((state, newAlert) => {
    state.originalAlert = lodash.clone(newAlert);
    state.alert = newAlert;
  }),
  setAlertType: action((state, type) => {
    if (!state.alert) {
      throw new Error(internalErrorMessage.alertNotFound);
    }

    state.alert.type = type;
  }),

  // --- Condition
  initializeCondition: action((state, newCondition) => {
    if (newCondition) {
      state.curEditingCondition = newCondition;
      state.curConditionType = newCondition.type;
      state.curConditionSubType = revertLookUpConditionSubType(newCondition);
      state.curAddConditionStep = AlertAddConditionStep.DetailAdding;
    } else {
      state.curEditingCondition = {
        id: uuidv4(),
        type: "percentChange",
        basePrice: -1,
        targetPercentChange: 0,
      };
      state.curConditionType = null;
      state.curConditionSubType = null;
      state.curAddConditionStep = AlertAddConditionStep.TypeSelection;
    }
    state.curAddConditionView = AlertAddConditionView.AddCondition;
  }),

  setCurConditionType: action((state, type) => {
    state.curConditionType = type;
  }),

  setCurConditionSubType: action((state, subType) => {
    state.curConditionSubType = subType;
  }),

  setAlertManageType: action((state, alertManageType) => {
    state.alertManageType = alertManageType;
  }),

  setTicker: action((state, ticker) => {
    if (state.alert) {
      state.alert.ticker = ticker;
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),

  sliceConditionList: action((state, numToKeep) => {
    const alert = state.alert;
    if (alert) {
      alert.conditionList = alert.conditionList.slice(0, numToKeep);
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),

  addOrUpdateCondition: action((state, condition) => {
    const alert = state.alert;

    if (alert) {
      const conditionList = alert.conditionList;
      const curIndex = conditionList.findIndex(
        (curCondition) => curCondition.id === condition.id
      );

      if (curIndex < 0) {
        conditionList.push(condition);
      } else {
        conditionList[curIndex] = condition;
      }
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),

  removeCondition: action((state, conditionId) => {
    const alert = state.alert;

    if (alert) {
      const conditionList = alert.conditionList;
      const curIndex = conditionList.findIndex(
        (curCondition) => curCondition.id === conditionId
      );

      if (curIndex >= 0) {
        conditionList.splice(curIndex, 1);
      }
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),

  setTargetCompanySummary: action((state, targetCompanySummary) => {
    state.targetCompanySummary = targetCompanySummary;
  }),

  onSetType: actionOn(
    (actions) => actions.setCurConditionType,
    (state, target) => {
      state.curAddConditionStep = target.payload
        ? AlertAddConditionStep.SubTypeSelection
        : AlertAddConditionStep.TypeSelection;
    }
  ),
  onSetSubType: actionOn(
    (actions) => actions.setCurConditionSubType,
    (state, target) => {
      if (target.payload) {
        const subTypeData = alertSubTypeMap[target.payload];

        if (subTypeData) {
          state.curEditingCondition = subTypeData.conditionOnChosen(
            state.curEditingCondition?.id
          );
          state.curAddConditionStep = AlertAddConditionStep.DetailAdding;
        } else {
          throw new Error("Unimplemented subtype");
        }
      }
    }
  ),

  // --- UI
  setCurManageStep: action((state, alertManageView) => {
    state.curManageStep = alertManageView;
  }),
  setCurAddConditionStep: action((state, addConditionStep) => {
    state.curAddConditionStep = addConditionStep;
  }),
  setCurAddConditionView: action((state, addConditionView) => {
    state.curAddConditionView = addConditionView;
  }),
  setError: action((state, { key, detailMessage, message }) => {
    state.errorMap[key] = { message, detailMessage };
  }),
  resetErrorMap: action((state) => {
    state.errorMap = {};
  }),
};
