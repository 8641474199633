import { authFormName } from "common/auth.info";
import { emailRegex } from "common/regex";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/PasswordResetComponent.sass";
import { FormInput } from "..";

export const PasswordResetComponent = () => {
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [error, setError] = useState("");

  const { handleSubmit, errors, register } = useForm<{ email: string }>();
  const { resetPassword } = useStoreActions((actions) => actions.account);
  const firebaseUser = useStoreState((state) => state.account.firebaseUser);

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      const success = await resetPassword(email);
      if (success) {
        setSubmittedEmail(email);
      }
    } catch (error) {
      setError(error);
    }
  });

  // * Lock the email once the user has logged in
  const useAccountEmail = firebaseUser != null && firebaseUser.email != null;

  return (
    <form className="password-reset-component" onSubmit={onSubmit}>
      <p>
        {(!useAccountEmail ? "Worry not! " : "") +
          "We'll email the instructions to reset your password"}
      </p>
      <FormInput
        borderType="full-border"
        label="Email"
        placeholder="john@doe.com"
        defaultValue={firebaseUser?.email || undefined}
        readOnly={useAccountEmail}
        type="email"
        formValidator={{
          name: authFormName.email,
          inputRef: register({
            required: "Can't be empty",
            pattern: { value: emailRegex, message: "Invalid email" },
          }),
          errorText: errors[authFormName.email]?.message,
        }}
      />

      {submittedEmail && (
        <p className="success-noti">{`Instruction sent ${submittedEmail}! Please check your email`}</p>
      )}
      {error && (
        <p className="error-noti">{`Error while sending instruction: ${error}`}</p>
      )}

      <button>Submit</button>
    </form>
  );
};
