import { InformationCard, TransactionSummary } from "components/common";
import { Transaction } from "models/schema/transaction.model";
import React from "react";
import "styles/components/common/page-component.common.sass";
import "styles/components/pages/Journal/TransactionViewerComponent.sass";

interface TransactionViewerComponentProps {
  transactionList: Transaction[];
  onDeleteClick?: (transaction: Transaction) => void;
  onSelectClick?: (isSelected: boolean, transaction: Transaction) => void;
  checkActiveTransaction?: (transaction: Transaction) => boolean;
  title?: string;
}
export const TransactionViewerComponent = ({
  transactionList,
  onDeleteClick,
  onSelectClick,
  checkActiveTransaction,
  title = "Attached Transactions",
}: TransactionViewerComponentProps) => {
  return (
    <div className="page-component-common transaction-viewer-component">
      <p className="component-title">{title}</p>

      <div className="transaction-container">
        {transactionList.length > 0 ? (
          transactionList.map((transaction, index) => (
            <TransactionSummary
              key={`${transaction.id}-${index}`}
              transaction={transaction}
              onSelectClick={onSelectClick}
              onDeleteClick={onDeleteClick}
              isSelected={checkActiveTransaction?.(transaction)}
            />
          ))
        ) : (
          <InformationCard>No Transaction Attached</InformationCard>
        )}
      </div>
    </div>
  );
};
