import Switch from "@material-ui/core/Switch";
import cx from "classnames";
import {
  DatePickerInnerProp,
  DatePickerProps,
  DropdownData,
  FormChoiceInnerProps,
  FormChoiceProps,
  FormDropdownInnerProp,
  FormDropDownProps,
} from "common/form/form.common";
import dayjs from "dayjs";
import lodash from "lodash";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FadeIn from "react-fade-in";
import Select from "react-select";
import { CSSTransition } from "react-transition-group";
import "styles/components/common/form.sass";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "../Icon";
import { ControllerWrapper, FormWrapper } from "./FormCommon";

// * Motivation for the form with controlller:
/*
  Because checkbox and dropdown can't be styled easily if we're going with pure HTML approach,
    we need to create custom components to match the style with other components
  In order to reduce the ammount of work put into form validation, Controller will help us
    by holding the value related form info in their own storage.
  That way whenever we want to retrieve or reset the value, the form already do that for us
  The render prop of the Controller take responsibility of passing value (through `value`) and
    taking in new value (through `onChange`). Behind the scene, it will automatically change the
    value inside its storage
  Take a look at ControllerWrapper in FormCommon for more info
  Keep in mind that we need a `control` prop, which is taken from useForm(), for form context
 */

// * Checkbox, radio or toggler
const FormChoiceInner = ({
  label,
  type,
  className,
  defaultValue,
  value,
  onChange,
  readOnly,
  autoFocus,
  formValidator,
  disabled,
}: FormChoiceInnerProps) => {
  const hasError = !lodash.isEmpty(formValidator?.errorText);
  const [isChecked, setChecked] = useState(defaultValue || false);

  const id = useRef<string>(uuidv4());

  const handleChange = () => {
    if (!readOnly) {
      if (value === undefined) {
        // If it's not uncontrolled, we'll compute the new state
        const newState = isChecked;
        setChecked(!newState);
        onChange && onChange(!newState);
      } else {
        // If it's controlled, we only bubble the reverse value up
        onChange && onChange(!isChecked);
      }
    }
  };

  useEffect(() => {
    if (lodash.isBoolean(value)) {
      setChecked(value);
    }
  }, [value]);

  const renderContent = () => {
    const defaultInput = {
      id: id.current,
      autoFocus: autoFocus,
      // Use this since the onChange won't work with toggle
      onClick: handleChange,
      onChange: () => {}, // To bypass the warnin
      name: formValidator?.name,
      ...(value == null
        ? { defaultChecked: defaultValue }
        : { checked: value }),
      readOnly: readOnly,
      disabled: disabled,
    };

    switch (type) {
      case "checkbox":
      case "radio":
        return (
          <div
            className={cx("icon-container", {
              "icon-container-rounded": type === "radio",
              "icon-container-toggle-active": isChecked,
              "icon-container-disabled": disabled,
            })}
          >
            <CSSTransition
              in={isChecked}
              timeout={500}
              classNames={"form-choice-fade"}
              unmountOnExit
            >
              {type === "checkbox" ? (
                <Icon icon={["fas", "check"]} className="icon" />
              ) : (
                <Icon icon={["fas", "circle"]} className="icon" />
              )}
            </CSSTransition>

            <input
              type={type}
              className="hidden-input"
              ref={formValidator?.inputRef}
              {...defaultInput}
            />
          </div>
        );
      case "toggle":
        return <Switch {...defaultInput} color="primary" />;
    }
  };

  return (
    <div className={cx("form-section", className)}>
      <div className="form-choice-content-container">
        {renderContent()}

        {label && (
          <label
            htmlFor={id.current}
            className={cx("form-choice-label", {
              "form-choice-label-error": hasError,
            })}
          >
            {label}
          </label>
        )}
      </div>

      {hasError && (
        <FadeIn>
          <p className="form-error-text">
            <Icon icon={["fas", "exclamation-circle"]} />
            {formValidator?.errorText}
          </p>
        </FadeIn>
      )}
    </div>
  );
};

export const FormChoice = ({
  defaultValue = false,
  formValidator,
  ...props
}: FormChoiceProps) => {
  return (
    <ControllerWrapper
      Component={FormChoiceInner}
      defaultValue={defaultValue}
      onChange={props.onChange}
      props={{ ...props, defaultValue }}
      formValidator={formValidator}
    />
  );
};

// * Date picker

const FormDatePickerInner = ({
  autoFocus,
  value,
  onChange,
  filterDate,
  readOnly,
  popperPlacement,
  datePickerProps,

  ...wrapperProps
}: DatePickerInnerProp) => {
  const hasError = !lodash.isNil(wrapperProps.errorText);

  const id = useRef(uuidv4());

  return (
    <FormWrapper
      id={id.current}
      {...{ hasError, isActive: true, ...wrapperProps }}
    >
      <DatePicker
        id={id.current}
        // Parse date to avoid any error
        selected={(value ? dayjs(value!) : dayjs()).toDate()}
        onChange={(date) => {
          onChange && onChange(date);
        }}
        filterDate={filterDate}
        closeOnScroll
        className="form-input date-picker-input"
        readOnly={readOnly}
        autoFocus={autoFocus}
        popperPlacement={popperPlacement}
        {...datePickerProps}
      />
    </FormWrapper>
  );
};

export const FormDatePicker = ({
  formValidator,
  value,
  ...props
}: DatePickerProps) => {
  return (
    <ControllerWrapper
      Component={FormDatePickerInner}
      defaultValue={value}
      onChange={props.onChange}
      props={{ ...props, errorText: formValidator?.errorText, value }}
      formValidator={formValidator}
    />
  );
};

// * Dropdown

function findIndex<T>(dataList: ReadonlyArray<DropdownData<T>>, data: T) {
  return Math.max(
    dataList.findIndex((item) => lodash.isEqual(item.value, data)),
    0
  );
}

export function FormDropDownInner<T>({
  label,
  autoFocus,
  className,
  borderType,
  isSearchable,

  dataList,
  defaultValue,
  value,
  onChange,
  onChangeWithCondition,
  readOnly,

  formValidator,
  wrapperOnClick,
}: FormDropdownInnerProp<T>) {
  const hasError = !lodash.isNil(formValidator?.errorText);
  const id = useRef(uuidv4());

  const [chosenIndex, setChosenIndex] = useState(0);

  useEffect(() => {
    setChosenIndex(findIndex(dataList, value || defaultValue));
  }, [value, defaultValue, dataList]);

  return (
    <FormWrapper
      id={id.current}
      {...{ className, hasError, errorText: formValidator?.errorText, label }}
      isActive={true}
      wrapperOnClick={wrapperOnClick}
    >
      <Select
        id={id.current}
        value={dataList[chosenIndex]}
        onChange={(value) => {
          const executeOnChange = (newChosenIndex: number, newValue: T) => {
            setChosenIndex(newChosenIndex);
            onChange && onChange(newValue);
          };

          const newChosenIndex = findIndex(dataList, value?.value);
          const newValue = dataList[newChosenIndex].value;

          if (onChangeWithCondition) {
            if (onChangeWithCondition(newValue)) {
              executeOnChange(newChosenIndex, newValue);
            }
          } else {
            executeOnChange(newChosenIndex, newValue);
          }
        }}
        options={dataList}
        isSearchable={isSearchable}
        className={cx("form-dropdown-container", {
          [`form-dropdown-container-${borderType}`]: borderType,
        })}
        classNamePrefix="form-dropdown"
        autoFocus={autoFocus}
        isDisabled={readOnly}
        ref={formValidator?.inputRef}
        name={formValidator?.name}
      />
    </FormWrapper>
  );
}

export function FormDropDown<T>({
  formValidator,
  ...props
}: FormDropDownProps<T>) {
  return (
    <ControllerWrapper
      Component={FormDropDownInner}
      defaultValue={props.defaultValue || props.dataList[0].value}
      onChange={props.onChange}
      props={{ ...props, errorText: formValidator?.errorText }}
      formValidator={formValidator}
    />
  );
}
