export const checkIfRenderRoute = (
  isLoggedIn: boolean,
  requireLogin: boolean,
  hideIfLoggedIn?: boolean
) => {
  if (isLoggedIn) {
    if (hideIfLoggedIn) {
      return false;
    } else {
      return true;
    }
  } else {
    return !requireLogin;
  }
};
