import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";

const defaultOptions: Highcharts.Options = {
  chart: { type: "scatter", zoomType: "xy", backgroundColor: "transparent" },
  title: { text: "Scatter Plot Name" },
};

interface ScatterPlotProps {
  series: Highcharts.SeriesOptionsType[];
  options?: Highcharts.Options;
}
export const ScatterPlot = ({ series, options }: ScatterPlotProps) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        ...defaultOptions,
        ...options,
        series: series,
      }}
    />
  );
};
