import {
  AlertSubType,
  alertSubTypeRevertLookUpMap,
} from "common/alert/alert-manage.info";
import { AlertManageStep } from "common/alert/alert-page.info";
import { internalErrorMessage } from "common/standardMessage";
import { CompanySummary } from "models";
import {
  AlertConditionModel,
  IndicatorConditionModel,
  MarketCapConditionModel,
  PERatioConditionModel,
  PercentChangeConditionModel,
  PriceConditionModel,
  TrailingPriceConditionModel,
  VolumeConditionModel,
} from "models/dto/alert";
import {
  formatCurrency,
  formatPercent,
  getValueFromCurrencyOrPercentText,
} from "utils/common.utils";

// -------------------------- Alert manage
/** Used to map condition to the corresponding subtype when editing */
export const revertLookUpConditionSubType = (
  condition: AlertConditionModel
): AlertSubType => {
  const firstLayer = alertSubTypeRevertLookUpMap[condition.type];
  switch (condition.type) {
    case "price":
    case "volume":
    case "marketCap":
    case "peRatio":
    case "trailingPrice":
      return firstLayer![condition.limitType]!;
    case "percentChange":
    case "fiftyTwoHigh":
    case "fiftyTwoLow":
      return condition.type;
    case "indicator":
      return condition.indicatorType;

    default:
      throw new Error(internalErrorMessage.unimplementedConditionLookup);
  }
};

export const extractFormToCondition = (
  baseCondition: AlertConditionModel,
  formData: { [x: string]: any },
  companySummary: CompanySummary | null
): AlertConditionModel => {
  switch (baseCondition.type) {
    case "price":
      const returnPrice: PriceConditionModel = {
        ...baseCondition,
        targetPrice: getValueFromCurrencyOrPercentText(formData["targetPrice"]),
      };
      return returnPrice;
    case "marketCap":
      const returnMarketCap: MarketCapConditionModel = {
        ...baseCondition,
        targetMarketCap: getValueFromCurrencyOrPercentText(
          formData["targetMarketCap"]
        ),
      };
      return returnMarketCap;
    case "peRatio":
      const returnPERatio: PERatioConditionModel = {
        ...baseCondition,
        targetPERatio: getValueFromCurrencyOrPercentText(
          formData["targetPERatio"]
        ),
      };
      return returnPERatio;
    case "volume":
      const returnVolume: VolumeConditionModel = {
        ...baseCondition,
        targetVolume: getValueFromCurrencyOrPercentText(
          formData["targetVolume"]
        ),
      };
      return returnVolume;
    case "percentChange":
      if (!companySummary) {
        throw new Error(internalErrorMessage.missingCompanySummary);
      }

      const returnPercent: PercentChangeConditionModel = {
        ...baseCondition,
        targetPercentChange: getValueFromCurrencyOrPercentText(
          formData["targetPercentChange"]
        ),
        basePrice: companySummary.latestPrice,
      };
      return returnPercent;
    case "fiftyTwoHigh":
    case "fiftyTwoLow":
      return baseCondition;
    case "indicator":
      const returnIndicator: IndicatorConditionModel = {
        ...baseCondition,
        targetStat: parseFloat(formData["targetStat"]),
        limitType: formData["limitType"],
        range: formData["range"],
        // Null if parse fail
        input1: parseInt(formData["input1"]),
        input2: parseInt(formData["input2"]),
        input3: parseInt(formData["input3"]),
        input4: parseInt(formData["input4"]),
      };
      return returnIndicator;
    case "trailingPrice":
      const trailingTarget = getValueFromCurrencyOrPercentText(
        formData["trailingTarget"]
      );
      const targetPrice =
        (companySummary?.latestPrice || 0) +
        (baseCondition.limitType === "below"
          ? -trailingTarget
          : trailingTarget);

      const returnTrailingPrice: TrailingPriceConditionModel = {
        ...baseCondition,
        trailingTarget: trailingTarget,
        targetPrice: targetPrice,
      };
      return returnTrailingPrice;
  }
};

export const craftConditionMessage = (condition: AlertConditionModel) => {
  switch (condition.type) {
    case "price":
      return `Price reaches ${condition.limitType} ${formatCurrency(
        condition.targetPrice
      )}`;
    case "trailingPrice":
      return `Trailing price reaches ${condition.limitType} ${formatCurrency(
        condition.targetPrice
      )} with trailing target ${formatCurrency(condition.trailingTarget)}`;
    case "marketCap":
      return `Market Cap reaches ${condition.limitType} ${formatCurrency(
        condition.targetMarketCap
      )}`;
    case "peRatio":
      return `P/E Ratio reaches ${condition.limitType} ${formatCurrency(
        condition.targetPERatio
      )}`;
    case "volume":
      return `Volume reaches ${
        condition.limitType
      } ${condition.targetVolume.toLocaleString()} shares`;
    case "percentChange":
      return `Percent change passes ${formatPercent(
        condition.targetPercentChange
      )}`;
    case "fiftyTwoLow":
      return `Price reaches below 52 Week Lows`;
    case "fiftyTwoHigh":
      return `Price reaches above 52 Week Highs`;
    case "indicator":
      return `Technical indicator ${condition.indicatorType.toUpperCase()} reaches ${
        condition.limitType
      } ${condition.targetStat.toLocaleString()}`;
  }
};

// ------------------------------ ALert dashboard

export const getAlertScreenHeaderText = (
  isInManageView: boolean,
  curManageStep: AlertManageStep,
  ticker?: string,
  mainViewText: string = "Alert"
) => {
  if (!isInManageView) {
    return mainViewText;
  }

  switch (curManageStep) {
    case AlertManageStep.TypeChose:
      return "Choose Type of Alert";
    case AlertManageStep.CompanySearch:
      return "Find Ticker";
    case AlertManageStep.ConditionAdding:
      return `${ticker} Alert Conditions`;
    case AlertManageStep.Summary:
      return `${ticker} Alert Summary`;
    case AlertManageStep.End:
      return `Alert Set!`;
    case AlertManageStep.LimitReach:
      return `Alert Limit`;
    case AlertManageStep.SubscriptionChoose:
      return `Subscription`;
  }
};
