import React from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import cx from "classnames";

import "styles/components/common/DashboardMainCTA.sass";
import "styles/animation/basic.sass";

import Icon from "./Icon";

interface DashboardMainCTAProps {
  onClick: Function;
  icon: [string, string];
  text: string;
  className?: string;
  type?: "primary" | "danger";
  disable?: boolean;
}
export const DashboardMainCTA = ({
  onClick,
  className,
  icon,
  text,
  type = "primary",
  disable,
}: DashboardMainCTAProps) => {
  return (
    <div className={cx("dashboard-main-cta", className)}>
      <SwitchTransition>
        <CSSTransition
          key={type}
          classNames="basic-fade"
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
        >
          <button
            onClick={() => onClick()}
            className={cx("cta", { [`cta-${type}`]: type })}
            disabled={disable}
          >
            <Icon icon={icon} className="fa-fw icon" />
            <p>{text}</p>
          </button>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};
