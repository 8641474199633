import error from "assets/animation/10960-error.json";
import warning from "assets/animation/1174-warning.json";
import info from "assets/animation/12246-info.json";
import success from "assets/animation/433-checked-done.json";
import loading from "assets/animation/SW_Loading_Animation_wBackground.json";
import { ToastType } from "common/toast.info";
import { LottiePlayer } from "components/common/LottiePlayer";
import "styles/components/common/ToastTemplate.sass";

interface ToastProp {
  message: string;
  type: ToastType;
}

const iconList = { warning, success, error, info, loading };

export const ToastTemplate = ({ message, type }: ToastProp) => {
  return (
    <div className="toast-template">
      <div className="lottie-container">
        <LottiePlayer animationData={iconList[type]} />
      </div>

      <span className="message">{message}</span>
    </div>
  );
};
