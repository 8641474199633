import cx from "classnames";
import {
  EmojiPickerInnerProps,
  EmojiPickerProps,
} from "common/form/form.common";
import { BottomSheet, ControllerWrapper } from "components/common";
import Picker, { IEmojiData } from "emoji-picker-react";
import React, { useRef, useState } from "react";
import { useClickAway, useMedia } from "react-use";
import "styles/components/common/EmojiPicker.sass";
import { getMediaBreakpoint } from "utils/common.utils";

const EmojiPickerInner = ({
  defaultValue,
  value,
  onChange,
  readOnly,
}: EmojiPickerInnerProps) => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [curEmoji, setCurEmoji] = useState(value || defaultValue || "");
  const [showPicker, setShowPicker] = useState(false);

  const closePicker = () => {
    setShowPicker(false);
  };

  const onEmojiClick = (_: any, data: IEmojiData) => {
    setCurEmoji(data.emoji);
    closePicker();
    onChange && onChange(data.emoji);
  };

  useClickAway(containerRef, () => {
    if (isLg) {
      closePicker();
    }
  });

  const renderPicker = () => (
    <div
      className={cx("emoji-picker-inner-container", {
        _hidden: !showPicker && isLg,
      })}
    >
      <Picker onEmojiClick={onEmojiClick} disableSkinTonePicker={true} />
    </div>
  );

  const renderTitle = () => <p>Pick Your Emoji</p>;

  return (
    <div
      className={cx("emoji-picker-container", {
        "emoji-picker-container-active": showPicker,
      })}
      ref={containerRef}
    >
      <button
        type="button"
        disabled={readOnly}
        onClick={() => {
          setShowPicker((prev) => !prev);
        }}
      >
        {curEmoji}
      </button>

      {isLg
        ? renderPicker()
        : showPicker && (
            <BottomSheet
              maxHeightInPx={400}
              showAllOnOpen
              closeAllOnClose
              onChange={(isOpen) => {
                setShowPicker(isOpen);
              }}
              isOpen={showPicker}
              supplementaryContent={renderPicker()}
            >
              {renderTitle()}
            </BottomSheet>
          )}
    </div>
  );
};

export const EmojiPicker = ({ formValidator, ...props }: EmojiPickerProps) => {
  return (
    <ControllerWrapper
      Component={EmojiPickerInner}
      defaultValue={props.value || props.defaultValue}
      onChange={props.onChange}
      props={{ ...props, errorText: formValidator?.errorText }}
      formValidator={formValidator}
    />
  );
};
