import { notificationService } from "api/service/notification.service";
import {
  NotificationType,
  notificationTypeList,
} from "common/notification.info";
import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";
import {
  AlertNotificationDto,
  NotificationDto,
} from "models/dto/notification/notification.dto";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { sortDayCallback } from "utils/common.utils";
import { extractFunctionError } from "utils/error.util";

const generateBaseNotificationMap = () => ({
  alert: [],
});
export interface NotificationStoreModel {
  // * State
  notificationDataMap: {
    alert: AlertNotificationDto[];
  };
  notificationListByType: Computed<
    NotificationStoreModel,
    (type: NotificationType) => NotificationDto[]
  >;

  numUnread: Computed<NotificationStoreModel, number>;
  numUnreadByType: Computed<
    NotificationStoreModel,
    (type: NotificationType) => number
  >;

  // * Actions
  resetStore: Action<NotificationStoreModel>;
  setNotificationList: Action<
    NotificationStoreModel,
    { type: NotificationType; notificationList: NotificationDto[] }
  >;

  // * Thunks
  initializeStore: Thunk<
    NotificationStoreModel,
    never,
    any,
    any,
    ((() => void) | null)[]
  >;
  markAllOfTypeAsRead: Thunk<
    NotificationStoreModel,
    NotificationType,
    any,
    StoreModel
  >;
}

export const notificationStore: NotificationStoreModel = {
  // * State
  notificationDataMap: generateBaseNotificationMap(),
  notificationListByType: computed(
    [(state) => state.notificationDataMap],
    (notificationDataMap) => (type) => notificationDataMap[type]
  ),

  numUnread: computed(
    [(state) => state.notificationDataMap],
    (notificationDataMap) => {
      let total = 0;
      notificationTypeList.forEach(
        (type) =>
          (total += notificationDataMap[type].reduce(
            (count, curNoti) => (curNoti.isRead ? count : count + 1),
            0
          ))
      );

      return total;
    }
  ),
  numUnreadByType: computed(
    [(state) => state.notificationDataMap],
    (notificationDataMap) => (type) =>
      notificationDataMap[type].reduce(
        (count, curNoti) => (curNoti.isRead ? count : count + 1),
        0
      )
  ),

  // * Actions
  resetStore: action((state) => {
    state.notificationDataMap = generateBaseNotificationMap();
  }),
  setNotificationList: action((state, { notificationList, type }) => {
    const listToSet = [...notificationList].sort((entryA, entryB) => {
      return -sortDayCallback(entryA.createDate, entryB.createDate);
    });

    switch (type) {
      case "alert":
        state.notificationDataMap[type] = listToSet as AlertNotificationDto[];
        break;
    }
  }),

  // * Thunks
  initializeStore: thunk((actions) => {
    return notificationTypeList.map((notificationType) => {
      return notificationService.setupSnapshotListener(
        notificationType,
        (notificationList) => {
          actions.setNotificationList({
            type: notificationType,
            notificationList,
          });
        }
      );
    });
  }),

  markAllOfTypeAsRead: thunk(async (_, notificaitonType, { getState }) => {
    if (getState().numUnreadByType(notificaitonType) <= 0) {
      return;
    }

    await executeAsync({
      funcToExecute: async () => {
        await notificationService.markAsAllRead(notificaitonType);
      },
      transformError: extractFunctionError,
    });
  }),
};
