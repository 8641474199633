import {
  AlertConditionType,
  ConditionLimitType,
  IndicatorType,
} from "common/alert/alert-condition.info";
import {
  COMMON_DECIMAL,
  CURRENCY_DECIMAL_STEP,
  CURRENCY_SIGN,
  PERCENT_DECIMAL_STEP,
} from "common/common";
import { FormDropDownProps, FormInputProp } from "common/form/form.common";
import {
  AlertConditionModel,
  FiftyTwoHighConditionModel,
  FiftyTwoLowConditionModel,
  IndicatorConditionModel,
  MarketCapConditionModel,
  PERatioConditionModel,
  PercentChangeConditionModel,
  PriceConditionModel,
  TrailingPriceConditionModel,
  VolumeConditionModel,
} from "models/dto/alert";
import { timeRangeMap } from "models/dto/stock.dto";
import { KeysOfUnion } from "utils/types.util";

export enum AlertManageType {
  None,
  Edit,
  Add,
}

export type ConditionFormInfo =
  | {
      name: PossibleAlertConditionKey;
      type: "input";
      formProps: FormInputProp;
    }
  | {
      name: PossibleAlertConditionKey;
      type: "dropdown";
      formProps: FormDropDownProps<string | number>;
    };

type PossibleAlertConditionKey = KeysOfUnion<AlertConditionModel>;
export const alertConditionInputMap: Partial<
  Record<PossibleAlertConditionKey, (...arg: any) => ConditionFormInfo>
> = {
  limitType: () => ({
    name: "limitType",
    type: "dropdown",
    formProps: {
      label: "Upper or Lower limit",
      dataList: [
        { label: "Upper", value: "above" },
        { label: "Lower", value: "below" },
      ],
      isSearchable: true,
    },
  }),
  range: () => ({
    name: "range",
    type: "dropdown",
    formProps: {
      label: "Time range",
      dataList: [
        ...Object.entries(timeRangeMap).map(([range, label]) => ({
          label: label!,
          value: range,
        })),
      ],
      isSearchable: true,
    },
  }),
  targetStat: () => ({
    name: "targetStat",
    type: "input",
    formProps: {
      label: "Target Amount",
      type: "number",
      step: CURRENCY_DECIMAL_STEP,
      maskProp: { min: 0 },
    },
  }),

  targetPrice: () => ({
    name: "targetPrice",
    type: "input",
    formProps: {
      label: "Target Price",
      type: "number",
      step: COMMON_DECIMAL,
      maskProp: { prefix: CURRENCY_SIGN, min: 0 },
    },
  }),

  trailingTarget: () => ({
    name: "trailingTarget",
    type: "input",
    formProps: {
      label: "Trailing Target Price",
      type: "number",
      step: COMMON_DECIMAL,
      maskProp: { prefix: CURRENCY_SIGN, min: 0 },
    },
  }),

  targetMarketCap: () => ({
    name: "targetMarketCap",
    type: "input",
    formProps: {
      label: "Target Market Cap",
      type: "number",
      step: COMMON_DECIMAL,
      maskProp: { prefix: CURRENCY_SIGN, min: 0 },
    },
  }),
  targetVolume: () => ({
    name: "targetVolume",
    type: "input",
    formProps: {
      label: "Target Volume",
      type: "number",
      step: CURRENCY_DECIMAL_STEP,
      maskProp: { min: 0 },
      rightCTA: "Shares",
    },
  }),
  targetPercentChange: () => ({
    name: "targetPercentChange",
    type: "input",
    formProps: {
      label: "Target Percentage",
      type: "number",
      step: PERCENT_DECIMAL_STEP,
      maskProp: { suffix: "%", min: 0 },
    },
  }),

  targetPERatio: () => ({
    name: "targetPERatio",
    type: "input",
    formProps: {
      label: "Target P/E Ratio",
      type: "number",
      step: COMMON_DECIMAL,
      maskProp: { min: 0 },
    },
  }),

  input1: (label?: string, defaultValue?: number) => ({
    name: "input1",
    type: "input",
    formProps: {
      defaultValue: defaultValue,
      label: label || "Input 1",
      type: "number",
      step: "1",
      min: 1,
    },
  }),
  input2: (label?: string, defaultValue?: number) => ({
    name: "input2",
    type: "input",
    formProps: {
      defaultValue: defaultValue,
      label: label || "Input 2",
      type: "number",
      step: "1",
      min: 1,
    },
  }),
  input3: (label?: string, defaultValue?: number) => ({
    name: "input3",
    type: "input",
    formProps: {
      defaultValue: defaultValue,
      label: label || "Input 3",
      type: "number",
      step: "1",
      min: 1,
    },
  }),
  input4: (label?: string, defaultValue?: number) => ({
    name: "input4",
    type: "input",
    formProps: {
      defaultValue: defaultValue,
      label: label || "Input 4",
      type: "number",
      step: "1",
      min: 1,
    },
  }),
};

export type AlertConditionInputKey = keyof typeof alertConditionInputMap;
export type AlertSubType =
  | "priceAbove"
  | "priceBelow"
  | "trailingPriceAbove"
  | "trailingPriceBelow"
  | "marketCapAbove"
  | "marketCapBelow"
  | "peRatioAbove"
  | "peRatioBelow"
  | "volumeAbove"
  | "volumeBelow"
  | "percentChange"
  | "fiftyTwoHigh"
  | "fiftyTwoLow"
  | IndicatorType;

export interface AlertSubTypeData {
  name: string;
  description: string;
  icon: [string, string];

  requiredInputList: (() => ConditionFormInfo)[];
  optionalInputList?: (() => ConditionFormInfo)[];
  conditionOnChosen: (oldId?: string) => AlertConditionModel;
}

export const alertSubTypeMap: Partial<
  Record<AlertSubType, AlertSubTypeData>
> = {
  priceAbove: {
    name: "Upper Limit Price Target",
    description: "Notify when the price move above the target",
    icon: ["fas", "chevron-circle-up"],
    requiredInputList: [() => alertConditionInputMap.targetPrice!()],
    conditionOnChosen: (oldId) => new PriceConditionModel("above", oldId),
  },
  priceBelow: {
    name: "Lower Limit Price Target",
    description: "Notify when the price move below the target",
    icon: ["fas", "chevron-circle-down"],
    requiredInputList: [() => alertConditionInputMap.targetPrice!()],
    conditionOnChosen: (oldId) => new PriceConditionModel("below", oldId),
  },

  marketCapAbove: {
    name: "Upper Limit Market Cap Target",
    description: "Notify when the market cap move above the target",
    icon: ["fas", "chevron-circle-up"],
    requiredInputList: [() => alertConditionInputMap.targetMarketCap!()],
    conditionOnChosen: (oldId) => new MarketCapConditionModel("above", oldId),
  },
  marketCapBelow: {
    name: "Lower Limit Market Cap Target",
    description: "Notify when the market cap move below the target",
    icon: ["fas", "chevron-circle-down"],
    requiredInputList: [() => alertConditionInputMap.targetMarketCap!()],
    conditionOnChosen: (oldId) => new MarketCapConditionModel("below", oldId),
  },

  peRatioAbove: {
    name: "Upper Limit P/E Ratio Target",
    description: "Notify when the P/E ratio move above the target",
    icon: ["fas", "chevron-circle-up"],
    requiredInputList: [() => alertConditionInputMap.targetPERatio!()],
    conditionOnChosen: (oldId) => new PERatioConditionModel("above", oldId),
  },
  peRatioBelow: {
    name: "Lower Limit P/E Ratio Target",
    description: "Notify when the P/E ratio move below the target",
    icon: ["fas", "chevron-circle-down"],
    requiredInputList: [() => alertConditionInputMap.targetPERatio!()],
    conditionOnChosen: (oldId) => new PERatioConditionModel("below", oldId),
  },

  volumeAbove: {
    name: "Upper Limit Volume Target",
    description: "Notify when the volume move above the target",
    icon: ["fas", "chevron-circle-up"],
    requiredInputList: [() => alertConditionInputMap.targetVolume!()],
    conditionOnChosen: (oldId) => new VolumeConditionModel("above", oldId),
  },
  volumeBelow: {
    name: "Lower Limit Volume Target",
    description: "Notify when the volume move below the target",
    icon: ["fas", "chevron-circle-down"],
    requiredInputList: [() => alertConditionInputMap.targetVolume!()],
    conditionOnChosen: (oldId) => new VolumeConditionModel("below", oldId),
  },

  percentChange: {
    name: "Percent Change Target",
    description: "Notify when the percent change move above the target",
    icon: ["fas", "percent"],
    requiredInputList: [() => alertConditionInputMap.targetPercentChange!()],
    conditionOnChosen: (oldId) => new PercentChangeConditionModel(oldId),
  },

  fiftyTwoHigh: {
    name: "52 Week Highs",
    description: "Notify when the price moves above 52-week highs",
    icon: ["fas", "bell"],
    requiredInputList: [],
    conditionOnChosen: (oldId) => new FiftyTwoHighConditionModel(oldId),
  },
  fiftyTwoLow: {
    name: "52 Week Highs",
    description: "Notify when the price moves below 52-week lows",
    icon: ["fas", "bell"],
    requiredInputList: [],
    conditionOnChosen: (oldId) => new FiftyTwoLowConditionModel(oldId),
  },

  // * --- Technical
  sma: {
    name: "Simple Moving Average (SMA)",
    description: "Notify when SMA moves above/below a certain number",
    icon: ["fas", "square-root-alt"],
    requiredInputList: [
      () => alertConditionInputMap.limitType!(),
      () => alertConditionInputMap.targetStat!(),
      () => alertConditionInputMap.range!(),
    ],
    optionalInputList: [() => alertConditionInputMap.input1!("Period", 5)],
    conditionOnChosen: (oldId) => new IndicatorConditionModel("sma", oldId),
  },
  macd: {
    name: "Moving Average Convergence Divergence",
    description: "Notify when MACD moves above/below a certain number",
    icon: ["fas", "square-root-alt"],
    requiredInputList: [
      () => alertConditionInputMap.limitType!(),
      () => alertConditionInputMap.targetStat!(),
      () => alertConditionInputMap.range!(),
    ],
    optionalInputList: [
      () => alertConditionInputMap.input1!("Short Period", 12),
      () => alertConditionInputMap.input1!("Long Period", 26),
      () => alertConditionInputMap.input1!("Signal Period", 9),
    ],
    conditionOnChosen: (oldId) => new IndicatorConditionModel("macd", oldId),
  },
  rsi: {
    name: "Relative Strength Index Divergence",
    description: "Notify when RSI moves above/below a certain number",
    icon: ["fas", "square-root-alt"],
    requiredInputList: [
      () => alertConditionInputMap.limitType!(),
      () => alertConditionInputMap.targetStat!(),
      () => alertConditionInputMap.range!(),
    ],
    optionalInputList: [() => alertConditionInputMap.input1!("Period", 5)],
    conditionOnChosen: (oldId) => new IndicatorConditionModel("rsi", oldId),
  },
  stoch: {
    name: "Stochastic Oscillator",
    description: "Notify when STOCH moves above/below a certain number",
    icon: ["fas", "square-root-alt"],
    requiredInputList: [
      () => alertConditionInputMap.limitType!(),
      () => alertConditionInputMap.targetStat!(),
      () => alertConditionInputMap.range!(),
    ],
    optionalInputList: [
      () => alertConditionInputMap.input1!("%k Period", 5),
      () => alertConditionInputMap.input1!("%k Slowing Period", 3),
      () => alertConditionInputMap.input1!("%d Period", 3),
    ],
    conditionOnChosen: (oldId) => new IndicatorConditionModel("stoch", oldId),
  },
  bbands: {
    name: "Bollinger Bands",
    description: "Notify when BBANDS moves above/below a certain number",
    icon: ["fas", "square-root-alt"],
    requiredInputList: [
      () => alertConditionInputMap.limitType!(),
      () => alertConditionInputMap.targetStat!(),
      () => alertConditionInputMap.range!(),
    ],
    optionalInputList: [
      () => alertConditionInputMap.input1!("Period", 20),
      () => alertConditionInputMap.input1!("Standard Deviation", 2),
    ],
    conditionOnChosen: (oldId) => new IndicatorConditionModel("bbands", oldId),
  },

  // * --- Special
  trailingPriceBelow: {
    name: "Lower Limit Trailing Price",
    description: "Notify when Trailing Prices moves below a certain price",
    icon: ["fas", "analytics"],
    requiredInputList: [() => alertConditionInputMap.trailingTarget!()],
    conditionOnChosen: (oldId) =>
      new TrailingPriceConditionModel("below", oldId),
  },
  trailingPriceAbove: {
    name: "Upper Limit Trailing Price",
    description: "Notify when Trailing Prices moves above a certain price",
    icon: ["fas", "analytics"],
    requiredInputList: [() => alertConditionInputMap.trailingTarget!()],
    conditionOnChosen: (oldId) =>
      new TrailingPriceConditionModel("above", oldId),
  },
};

export interface AlertTypeData {
  name: string;
  description: string;
  icon: [string, string];
  subType: AlertSubType[];
}

export const alertConditionMap: Partial<
  Record<AlertConditionType, AlertTypeData>
> = {
  price: {
    name: "Price target",
    description: "Notify when the price move above/below the target",
    subType: ["priceAbove", "priceBelow"],
    icon: ["fas", "dollar-sign"],
  },

  marketCap: {
    name: "Market Cap target",
    description: "Notify when the market cap move above/below the target",
    subType: ["marketCapAbove", "marketCapBelow"],
    icon: ["fas", "analytics"],
  },

  peRatio: {
    name: "P/E Ratio target",
    description: "Notify when the P/E Ratio move above/below the target",
    subType: ["peRatioAbove", "peRatioBelow"],
    icon: ["fas", "analytics"],
  },

  volume: {
    name: "Volume target",
    description: "Notify when the volume move above/below the target",
    subType: ["volumeAbove", "volumeBelow"],
    icon: ["fas", "volume-up"],
  },

  percentChange: {
    name: "Percent Change",
    description: "Notify when the price moves a certain percentage",
    subType: ["percentChange"],
    icon: ["fas", "percent"],
  },

  fiftyTwoHigh: {
    name: "52 Week Highs",
    description: "Notify when the price moves above 52-week highs",
    subType: ["fiftyTwoHigh"],
    icon: ["fas", "bell"],
  },
  fiftyTwoLow: {
    name: "52 Week Lows",
    description: "Notify when the price moves below 52-week lows",
    subType: ["fiftyTwoLow"],
    icon: ["fas", "bell"],
  },

  indicator: {
    name: "Technical Analysis",
    description: "Technical triggers and patterns",
    subType: ["sma", "macd", "rsi", "stoch", "bbands"],
    icon: ["fas", "square-root-alt"],
  },

  // * Special
  trailingPrice: {
    name: "Trailing Price",
    description: "Notify when the price dips or raises by a certain amount",
    subType: ["trailingPriceBelow", "trailingPriceAbove"],
    icon: ["fas", "analytics"],
  },
};

/** Used to look up which sub type a condition is using when edit condition */
export const alertSubTypeRevertLookUpMap: Partial<
  Record<AlertConditionType, Partial<Record<ConditionLimitType, AlertSubType>>>
> = {
  price: { above: "priceAbove", below: "priceBelow" },
  trailingPrice: { above: "trailingPriceAbove", below: "trailingPriceBelow" },
  marketCap: { above: "marketCapAbove", below: "marketCapBelow" },
  peRatio: { above: "peRatioAbove", below: "peRatioBelow" },
  volume: { above: "volumeAbove", below: "volumeBelow" },
};

export type AlertManageErrorMapKey = "relay" | "submitError";
