import cx from "classnames";
import {
  FormWysiwygInnerProp,
  FormWysiwygProps,
} from "common/form/form.common";
import lodash from "lodash";
import Quill, { DeltaStatic } from "quill";
// @ts-ignore
import ImageUploader from "quill-image-uploader";
// @ts-ignore
import MagicUrl from "quill-magic-url";
import React, { useCallback, useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "styles/components/common/form.sass";
import "styles/components/common/wysiwyg.sass";
import { isUrl } from "utils/api.util";
import { getImgUrlsFromDelta, toBase64 } from "utils/wysiwyg.util";
import { v4 as uuidv4 } from "uuid";
import { FormWrapper } from ".";
import { Portal } from "../Portal";

Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/imageUploader", ImageUploader);

// --- Main

const FormWysiwygInner = ({
  wrapperClassName,
  className,
  errorText,
  label,
  value, // TODO Find a way to handle this later
  defaultValue,
  onChange,
  readOnly,
  modules,
  extraSection,
  placeholder,
  formatList,
  deleteImageFromServer,
}: FormWysiwygInnerProp) => {
  const hasError = !lodash.isNil(errorText);

  const editorId = useRef(uuidv4());
  const editorRef = useRef<ReactQuill>(null);
  const prevDeltaRef = useRef<DeltaStatic>();

  useEffect(() => {
    if (editorRef.current && editorRef.current.editor) {
      prevDeltaRef.current = editorRef.current.editor.getContents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef.current]);

  return (
    <div className={cx("wysiwyg-input", wrapperClassName)}>
      <FormWrapper
        id={editorId.current}
        {...{ className, hasError, errorText, label }}
        isActive={true}
      >
        <ReactQuill
          ref={editorRef}
          placeholder={placeholder}
          id={editorId.current}
          className="wysiwyg-inner-container"
          theme="snow"
          defaultValue={defaultValue}
          // value={value}
          modules={modules}
          formats={formatList}
          readOnly={readOnly}
          onChange={(value, delta, source, editor) => {
            onChange && onChange(value);

            // TODO: Handle image upload and deletion later here
            // Source https://github.com/quilljs/quill/issues/1898
            const curContent = editor.getContents();
            if (
              deleteImageFromServer &&
              source === "user" &&
              prevDeltaRef.current
            ) {
              const didDelete = delta.ops?.find((op) => op.delete);

              if (didDelete) {
                const deleteImgUrlList = getImgUrlsFromDelta(
                  curContent.diff(prevDeltaRef.current),
                )?.filter((url) => isUrl(url));
                // Filter only url, not base64
                // ! This also help avoid the delete operation coming from
                // !  tempImage of ImageUploader

                if (deleteImgUrlList?.length) {
                  deleteImageFromServer(deleteImgUrlList);
                }
              }
            }
            prevDeltaRef.current = curContent;
          }}
        />

        <Portal containerId={editorId.current}>
          <div className="extra-section-container">{extraSection}</div>
        </Portal>
      </FormWrapper>
    </div>
  );
};

export const FormWysiwyg = ({
  formValidator,
  onChange: defaultOnChange,
  ...props
}: FormWysiwygProps) => {
  if (!formValidator || !formValidator.control) {
    return <FormWysiwygInner {...props} />;
  }

  const { control, rules, name } = formValidator;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={props.defaultValue}
      render={({ onChange }) => (
        <FormWysiwygInner
          {...props}
          onChange={(newValue) => {
            onChange(newValue);
            defaultOnChange && defaultOnChange(newValue);
          }}
          value={props.value}
        />
      )}
    />
  );
};

export const SimpleWysiwyg = (props: FormWysiwygProps) => {
  const handleUpload = useCallback(async (file: File) => {
    let imageUrl: string;
    try {
      imageUrl = await (props.uploadImageToServer || toBase64)(file);
    } catch (error) {
      console.error(error);
    }

    return new Promise((resolve, reject) => {
      if (imageUrl.length > 0) {
        resolve(imageUrl);
      } else {
        reject();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormWysiwyg
      {...props}
      className={cx("simpleWysiwyg", props.className)}
      modules={{
        toolbar: {
          container: [["image"], ["bold", "italic"], [{ list: "bullet" }]],
        },
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        magicUrl: true,
        imageUploader: { upload: handleUpload },
      }}
      formatList={[
        "link",
        "size",
        "italic",
        "bold",
        "indent",
        "list",
        "align",
        "image",
        "imageBlot",
      ]}
    />
  );
};
