import { accountService, internalService } from "api/service";
import { ApiReturnValue } from "common/api.info";
import { Action, action, Thunk, thunk } from "easy-peasy";
import { AccountFirebaseInfo, AccountInfo } from "models";
import { executeAsync } from "utils/api.util";
import {
  extractFirebaseAuthError,
  extractFunctionError,
} from "utils/error.util";
import { getFirebaseUser } from "utils/firebase.util";
import { toastCustomMessage } from "utils/ui.util";

export interface SettingStoreModel {
  isInEditMode: boolean;
  setIsInEditMode: Action<SettingStoreModel, boolean>;

  resetStore: Action<SettingStoreModel>;
  changeAccountInfo: Thunk<
    SettingStoreModel,
    { accountInfo: AccountInfo; accountFirebaseInfo: AccountFirebaseInfo },
    any,
    any,
    Promise<
      ApiReturnValue<{
        updatedAccountInfo: AccountInfo;
        updatedAccountFirebaseInfo: AccountFirebaseInfo;
      } | null>
    >
  >;
  changeEmail: Thunk<
    SettingStoreModel,
    string,
    any,
    any,
    Promise<ApiReturnValue<boolean>>
  >;

  submitFeedback: Thunk<
    SettingStoreModel,
    { tagList: string[]; feedback: string; rating: number },
    any,
    any,
    Promise<boolean>
  >;
  submitContactForm: Thunk<
    SettingStoreModel,
    { subject: string; content: string },
    any,
    any,
    Promise<boolean>
  >;
}

export const setting: SettingStoreModel = {
  isInEditMode: false,
  setIsInEditMode: action((state, isInEditMode) => {
    state.isInEditMode = isInEditMode;
  }),

  resetStore: action((state) => {
    state.isInEditMode = false;
  }),

  changeAccountInfo: thunk(async (actions, accountInfo) => {
    return await executeAsync({
      toastMessage: "Changing account info...",
      funcToExecute: async () => {
        const userInfo = await accountService.updateAccountInfo(
          accountInfo.accountInfo,
          accountInfo.accountFirebaseInfo
        );

        actions.setIsInEditMode(false);
        toastCustomMessage("Successfully update account info!", "success");

        return userInfo;
      },
    });
  }),
  changeEmail: thunk(async (_, email) => {
    return await executeAsync({
      toastMessage: `Changing email to ${email} 📧`,
      funcToExecute: async () => {
        await getFirebaseUser()?.updateEmail(email);
        return true;
      },
      transformError: extractFirebaseAuthError,
    });
  }),

  submitFeedback: thunk(async (_, { feedback, rating, tagList }) => {
    let result = true;
    await executeAsync({
      toastMessage: "Sending feedback 🙏",
      funcToExecute: async () => {
        await internalService.sendFeedbackEmail(feedback, rating, tagList);
      },
      transformError: extractFunctionError,
      onError: () => {
        result = false;
      },
    });

    return result;
  }),

  submitContactForm: thunk(async (_, { content, subject }) => {
    let result = true;
    await executeAsync({
      toastMessage: "Sending contact email ✉️",
      funcToExecute: async () => {
        await internalService.sendContactEmail(content, subject);
      },
      transformError: extractFunctionError,
      onError: () => {
        result = false;
      },
    });

    return result;
  }),
};
