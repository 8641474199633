import Color from "color";
import {
  StockLoadingType,
  StockRatingType,
  stockRatingTypeList,
} from "common/stock/stock.info";
import {
  AnalystRatingChart,
  RecommendationBarDatum,
  RecommendationText,
} from "components/common";
import {
  CardShimmer,
  ShimmerContainer,
} from "components/common/ContentShimmer";
import Icon from "components/common/Icon";
import { useThemeColor } from "hooks/useThemeColor";
import lodash from "lodash";
import { useMemo } from "react";
import { useAsync } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/Chart/AnalystRatingChartWithDetail.sass";

const calculatePercent = (totalRater: number, numRater: number) =>
  (numRater / totalRater) * 100;

interface AnalystRatingChartWithDetailProps {
  ticker: string;
}
export const AnalystRatingChartWithDetail = ({
  ticker,
}: AnalystRatingChartWithDetailProps) => {
  const { getRatingForTicker } = useStoreActions((actions) => actions.stock);
  const { value: ratingData, loading } = useAsync(async () => {
    return await getRatingForTicker(ticker);
  }, [ticker]);

  const isFetchingTickerRating = useStoreState((state) =>
    state.stock.isLoading(StockLoadingType.FetchingTickerRating)
  );

  const barData = useMemo<RecommendationBarDatum[]>(() => {
    if (!ratingData) {
      return [];
    }

    return stockRatingTypeList.map((ratingType) => ({
      percent: calculatePercent(
        ratingData.numberOfRaters,
        ratingData[ratingType]
      ),
      recommendationText: lodash.upperFirst(ratingType) as RecommendationText,
    }));
  }, [ratingData]);

  const maxRatingType = useMemo(() => {
    let returnStr: StockRatingType | "No Result" = "No Result";
    if (!ratingData) {
      return returnStr;
    }

    let maxVal = -1;
    stockRatingTypeList.forEach((ratingType) => {
      if (ratingData[ratingType] > maxVal) {
        returnStr = ratingType;
        maxVal = ratingData[ratingType];
      }
    });

    return returnStr;
  }, [ratingData]);

  const buyColor = useThemeColor("sw-blue");
  const outperformColor = useThemeColor("positive-blue");
  const holdColor = useThemeColor("non-essential");
  const sellColor = useThemeColor("warning");

  // TODO: Refactor this
  const colorMap = useMemo<Record<StockRatingType, string>>(
    () => ({
      buy: buyColor,
      outperform: Color(outperformColor).alpha(0.5).rgb().toString(),
      hold: holdColor,
      underperform: Color(sellColor).alpha(0.5).rgb().toString(),
      sell: sellColor,
    }),
    [buyColor, outperformColor, holdColor, sellColor]
  );

  return (
    <ShimmerContainer
      isReady={!loading && !isFetchingTickerRating}
      shimmerComponent={<CardShimmer />}
      numShimmer={1}
    >
      {ratingData && (
        <div className="analyst-rating-chart-with-detail">
          <div className="info-container">
            <div>
              <Icon
                className="fa-fw icon"
                icon={["fas", "diamond"]}
                style={{ color: colorMap[maxRatingType as StockRatingType] }}
              />
              {lodash.upperFirst(maxRatingType)}
            </div>
            &nbsp;|&nbsp;
            <p className="num-analyst">
              Based on {ratingData.numberOfRaters} analysts
            </p>
          </div>

          <AnalystRatingChart data={barData} />
        </div>
      )}
    </ShimmerContainer>
  );
};
