import cx from "classnames";
import {
  newSourceMap,
  NewsSentiment,
  newsSentimentMap,
  NewsSource,
  newsSourceList,
} from "common/news.info";
import { Icon, Tag } from "components/common";
import { NewsFilterCriteria } from "models/schema/news.schema";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/common/NewsFilterView.sass";

interface FilterSectionProps {
  title: string;
  children: React.ReactNode;
  contentClassName?: string;
  cta?: React.ReactNode;
}
const FilterSection = ({
  children,
  title,
  contentClassName,
  cta,
}: FilterSectionProps) => {
  return (
    <section className="news-filter-section">
      <div className="title-container">
        <p className="filter-title">{title}</p>

        {cta && <div className="cta-container">{cta}</div>}
      </div>

      <div className={cx("filter-content", contentClassName)}>{children}</div>
    </section>
  );
};

interface NewsFilterViewProps {
  onApplyClick: (criteria: NewsFilterCriteria) => void;
  onCancelClick: () => void;
}
export const NewsFilterView = ({
  onApplyClick,
  onCancelClick,
}: NewsFilterViewProps) => {
  const criteria = useStoreState((state) => state.news.criteria);
  const {
    addOrRemoveSentiment,
    addOrRemoveNewsSource,
    selectOrDeselectAllNewsSource,
  } = useStoreActions((actions) => actions.news);

  const onCancel = () => {
    onCancelClick();
  };

  const onApply = () => {
    onApplyClick(criteria);
  };

  const onSourceListSelect = () => {
    selectOrDeselectAllNewsSource();
  };

  return (
    <div className="news-filter-view">
      <div className="section-container">
        <FilterSection title="Dates">Filter by date coming soon!</FilterSection>

        <FilterSection title="Sentiment">
          {Object.entries(newsSentimentMap).map(([sentiment, text]) => (
            <Tag
              key={text}
              onClick={() => {
                addOrRemoveSentiment(sentiment as NewsSentiment);
              }}
              isActive={
                criteria.sentimentList?.find(
                  (entry) => entry === (sentiment as NewsSentiment)
                ) != null || false
              }
            >
              {text}
            </Tag>
          ))}
        </FilterSection>

        <FilterSection
          title="Sources"
          contentClassName="source-content"
          cta={
            <>
              <button onClick={onSourceListSelect}>
                <Icon icon={["fal", "object-group"]} />
                <p>
                  {criteria.sourceList &&
                  criteria.sourceList.length === newsSourceList.length
                    ? "Deselect All"
                    : "Select All"}
                </p>
              </button>
            </>
          }
        >
          {Object.entries(newSourceMap).map(([source, text]) => (
            <Tag
              key={text}
              onClick={() => {
                addOrRemoveNewsSource(source as NewsSource);
              }}
              isActive={
                criteria.sourceList?.find(
                  (entry) => entry === (source as NewsSource)
                ) != null || false
              }
            >
              {text}
            </Tag>
          ))}
        </FilterSection>
      </div>

      <div className="cta-container">
        <button className="cancel" onClick={onCancel}>
          CANCEL
        </button>
        <button className="apply" onClick={onApply}>
          APPLY
        </button>
      </div>
    </div>
  );
};
