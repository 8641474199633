import { AccountInfo } from "models/schema";
export class AccountDataInfoDto {
  dateOfBirth: string | null;
  address: {
    streetAddress: string | null;
    city: string | null;
    zipCode: string | null;
    state: string | null;
    country: string | null;
  };

  static toSchema(dto: AccountDataInfoDto): AccountInfo {
    return { dateOfBirth: dto.dateOfBirth, address: dto.address };
  }

  static fromSchema(schema: AccountInfo): AccountDataInfoDto {
    return { dateOfBirth: schema.dateOfBirth, address: schema.address };
  }
}
