import { useThemeColor } from "hooks/useThemeColor";
import { CSSProperties } from "react";
import "styles/components/common/SectionLineDivider.sass";

export interface SectionLineDividerProps {
  text: string;
  lineThickness?: string | number;
  lineColor?: string;
  textClassNames?: string[];
}

export const SectionLineDivider = ({
  text,
  lineThickness = 2,
  lineColor,
  textClassNames = [],
}: SectionLineDividerProps) => {
  const defaultLineColor = useThemeColor("primary");

  const renderLine = () => {
    const lineStyle: CSSProperties = {
      borderTopWidth: lineThickness,
      borderTopColor: lineColor || defaultLineColor,
      flex: 1,
    };

    return <div style={lineStyle}></div>;
  };

  return (
    <div className="section-line-divider">
      {/* We don't use ::before and ::after because it's not customizable with inline style */}
      {renderLine()}
      <div className={`divider-text ${textClassNames.join(" ")}`}>{text}</div>
      {renderLine()}
    </div>
  );
};
