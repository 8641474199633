import cx from "classnames";
import { Icon, LoadingSpinner } from "components/common";
import {
  DISPLAY_RECUR_INTERVAL_MAP,
  RecurInterval,
  SubscriptionPlanPriceDto,
} from "models/dto/payment/subscription-plan-price.dto";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";
import React from "react";
import ReactTooltip from "react-tooltip";
import { useStoreActions, useStoreState } from "stores";
import { PaymentSettingsLoading } from "stores/payment";
import "styles/components/common/SubscriptionTableView.sass";
import { getMoneyDisplayFromCents } from "utils/common.utils";
import {
  checkIsShowChoosePlanBtn,
  getSubscriptionPlanCentAmount,
} from "utils/payment.util";
import { CurrentPlanSection } from "./CurrentPlanSection";

export const SubscriptionTableView = () => {
  const subscribeToPlan = useStoreActions(
    (actions) => actions.paymentSettings.subscribeToPlan
  );

  const onSubscribeClicked = async (planPriceId: string) => {
    await subscribeToPlan({ planPriceId });
  };

  return (
    <div className="subscription-table-view">
      <CurrentPlanSection />
      <PlansTableViewSection
        recurInterval={RecurInterval.month}
        onClickChoosePlan={(planPrice) => onSubscribeClicked(planPrice.id)}
      />
    </div>
  );
};

interface PlansTableViewSectionProps {
  recurInterval: RecurInterval;
  onClickChoosePlan: (planPrice: SubscriptionPlanPriceDto) => void;
}
const PlansTableViewSection = ({
  recurInterval,
  onClickChoosePlan,
}: PlansTableViewSectionProps) => {
  const planList = useStoreState((state) => state.paymentSettings.planList);
  const baseFeatureList = useStoreState(
    (state) => state.paymentSettings.baseFeatureList
  );
  const isLoading = useStoreState((state) =>
    state.paymentSettings.isLoading(PaymentSettingsLoading.choosePlan)
  );
  const stripeRole = useStoreState((state) => state.account.stripeRole);

  const theme = useStoreState((state) => state.theme.theme);

  const renderBaseFeatureCells = () => {
    return baseFeatureList.map((baseFeature) => {
      const key = `base-feature-${baseFeature.key}`;
      return (
        <div className="cell cell-bottom-line" key={key}>
          <div className="feature-text">
            <p>{baseFeature.name}</p>

            {baseFeature.description && (
              <div className="icon" data-tip data-for={key}>
                <Icon icon={["fal", "info-circle"]} />
              </div>
            )}
          </div>

          <ReactTooltip id={key} type="info" effect="solid" place="top">
            <span>{baseFeature.description}</span>
          </ReactTooltip>
        </div>
      );
    });
  };

  const renderPlanFeatureCellContent = (value: string | undefined) => {
    if (!value || value === "No") {
      return null;
    }

    if (value === "Yes") {
      return <Icon icon={["fas", "check"]} />;
    }

    return value;
  };

  const renderPlanPrice = (plan: SubscriptionPlanDto) => {
    const centAmount = getSubscriptionPlanCentAmount(plan, recurInterval);

    const displayRecurInterval = SubscriptionPlanDto.isFreePlan(plan)
      ? "Forever"
      : "Per " + DISPLAY_RECUR_INTERVAL_MAP[recurInterval];

    return (
      <div className="plan-price">
        <div className="price-text">{getMoneyDisplayFromCents(centAmount)}</div>
        <div className="price-recur">{displayRecurInterval}</div>
      </div>
    );
  };

  const renderPlanFeatureCells = (plan: SubscriptionPlanDto) => {
    const planFeatures = plan.metadata?.featureList || [];

    return baseFeatureList.map((baseFeature) => {
      const planFeatureValue = planFeatures.find(
        (x) => x.featureKey === baseFeature.key
      )?.value;

      return (
        <div
          className="cell plan-cell cell-bottom-line"
          key={`plan-${plan.planKey}-feature-${baseFeature.key}`}
        >
          {renderPlanFeatureCellContent(planFeatureValue)}
        </div>
      );
    });
  };

  const renderChoosePlanButton = (plan: SubscriptionPlanDto) => {
    const planPrice = plan.prices.find((x) => x.interval === recurInterval);
    if (!planPrice) {
      return null;
    }

    if (checkIsShowChoosePlanBtn(plan.role, stripeRole) === false) {
      return null;
    }

    const renderBtnContent = () => {
      if (plan.role === stripeRole) {
        return <span>Current plan</span>;
      }

      if (isLoading) {
        return <LoadingSpinner size={10} />;
      }

      return <span>Choose plan</span>;
    };

    return (
      <button
        disabled={plan.role === stripeRole || isLoading}
        className={cx("choose-plan-btn", {
          subscribed: plan.role === stripeRole,
        })}
        // className="choose-plan-btn"
        onClick={() => onClickChoosePlan(planPrice)}
      >
        {renderBtnContent()}
      </button>
    );
  };

  const renderPlanColumns = () => {
    return planList.map((plan) => {
      const isPopular = SubscriptionPlanDto.isPopularPlan(plan.planKey);
      const planColor =
        theme === "light"
          ? plan.metadata?.colorTheme.lightThemeColor
          : plan.metadata?.colorTheme.darkThemeColor;

      return (
        <div
          className={cx("plan-cols-item", { popular: isPopular })}
          key={`cell-${plan.name}`}
        >
          <div className="cell header-cell plan-cell">
            <div className="plan-name" style={{ color: planColor }}>
              {plan.name}
            </div>
            {renderPlanPrice(plan)}
          </div>
          {renderPlanFeatureCells(plan)}
          <div className="cell bottom-cell">{renderChoosePlanButton(plan)}</div>
        </div>
      );
    });
  };

  return (
    <div className="plans-table-view-section">
      {/* Base Column */}
      <div className="feature-list-col">
        <div className="cell header-cell">
          <span className="header-title">PLANS</span>
        </div>
        {renderBaseFeatureCells()}
        <div className="cell bottom-cell"></div>
      </div>
      {/* Plan Columns */}
      <div className="plan-cols">{renderPlanColumns()}</div>
    </div>
  );
};
