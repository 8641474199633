export const internalErrorMessage = {
  wrongDateFormat: "Wrong date format ??? Contact your admin",
  accountNotFound: "Account not found ??? Contact your admin",
  wrongJournalEntryType: "Wrong journal entry type ??? Contact your admin",
  journalNotFound: "Journal not found ??? Contact your admin",
  nullJournalEntry: "Empty journal entry ??? Contact your admin",
  imageUploadFailed: "Can't upload image ??? Contact your admin",
  imageConversionError: "Fail to convert to base64 ??? Contact your admin",
  tagExist: "Tag already exist ??? Contact your admin",
  tagNotFound: "Tag not found ??? Contact your admin",

  // Alert
  alertNotFound: "Alert not found ??? Contact your admin",
  unimplementedConditionLookup:
    "Unimplemented condition lookup ??? Contact your admin",
  unimplementedConditionDataMap:
    "Unimplemented condition data map ??? Contact your admin",
  unimplementedConditionExtraction:
    "Unimplemented condition extraction ??? Contact your admin",
  missingCompanySummary: "Missing company summary ??? Contact your admin",

  // Chart
  unitCountNotFound:
    "Unit count for time range not found ??? Contact your admin",
  timeTypeNotFound: "Time type not found ??? Contact your admin",

  // Other
  invalidFilterParam: "Invalid filter parameters ??? Contact your admin",

  // Auth
  missingLoginCredential: "Missing login credential ??? Contact your admin",

  // Watch List
  missingEditingWatchList: "Missing editing watch list ??? Contact your admin",
} as const;

export type InternalErrorMessage = keyof typeof internalErrorMessage;
export type InternalErrorMessageDetail = typeof internalErrorMessage[InternalErrorMessage];

export const serverErrorMessage = {
  internalError: "Internal server error",
  networkError: "Network error",
} as const;

export type ServerErrorMessage = keyof typeof internalErrorMessage;
export type ServerErrorMessageDetail = typeof internalErrorMessage[ServerErrorMessage];

export const htmlErrorMessage = {
  nullElement: (type: string) => `Null ${type} element ??? Contact your admin`,
} as const;

export const dtoErrorMessage = {
  incorrectDto: (type: string) =>
    `${type} is in wrong format ??? Contact your admin`,
} as const;
