import firebase from "firebase/app";
import "firebase/auth";
import { extractFirebaseAuthError } from "utils/error.util";
import { getFirebaseUser } from "utils/firebase.util";

export const firebaseService = {
  updateProfilePhoneNumber: async (newPhoneNumber: string) => {
    // Turn off phone auth app verification.
    // firebase.auth().settings.appVerificationDisabledForTesting = true;

    try {
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );

      const provider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await provider.verifyPhoneNumber(
        newPhoneNumber,
        appVerifier
      );

      const verificationCode = window.prompt(
        "Please enter the verification code that was sent to your mobile device."
      );

      if (verificationCode) {
        const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        await getFirebaseUser()?.updatePhoneNumber(phoneCredential);
      }
    } catch (error) {
      throw extractFirebaseAuthError(error);
    }
  },
  unlinkPhoneNumber: async () => {
    try {
      if (getFirebaseUser()?.phoneNumber == null) {
        return;
      }

      await getFirebaseUser()?.unlink(
        firebase.auth.PhoneAuthProvider.PROVIDER_ID
      );
    } catch (error) {
      throw extractFirebaseAuthError(error);
    }
  },
  updateProfileInfo: async (profileInfo: {
    displayName: string | null;
  }): Promise<firebase.User> => {
    try {
      await getFirebaseUser()?.updateProfile({
        displayName: profileInfo.displayName,
      });
      return getFirebaseUser()!;
    } catch (error) {
      throw extractFirebaseAuthError(error);
    }
  },
};
