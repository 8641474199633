import { CURRENCY_DECIMAL, CURRENCY_SIGN } from "common/common";
import { optionTransactionFormName } from "common/journal/journal-manage.info";
import {
  TransactionOptionType,
  transactionOptionTypeList,
  TransactionTradeType,
  transactionTradeTypeList,
} from "common/transaction/transaction.info";
import { FormDatePicker, FormInput, SwitchSlider } from "components/common";
import { OptionTransactionModel } from "models/schema/transaction.model";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";
import {
  calculateOptionCost,
  getMoneyFromCurrencyText,
} from "utils/common.utils";
import { createTransactionId } from "utils/transaction.util";
import { v4 as uuidv4 } from "uuid";
import { SearchCompanyView } from "views";

export interface OptionTransactionFormField {
  [optionTransactionFormName.ticker]: string;
  [optionTransactionFormName.expirationDate]: Date;
  [optionTransactionFormName.transactionDate]: Date;
  [optionTransactionFormName.premiumPrice]: string;
  [optionTransactionFormName.strikePrice]: string;
  [optionTransactionFormName.contract]: string;
  [optionTransactionFormName.tradeType]: TransactionTradeType;
  [optionTransactionFormName.optionType]: TransactionOptionType;
}

const priceInputMask: NumberFormatProps = {
  decimalScale: CURRENCY_DECIMAL,
  fixedDecimalScale: true,
  allowNegative: false,
  prefix: CURRENCY_SIGN,
};

export const getOptionTransactionFromFormData = (
  data: OptionTransactionFormField
): OptionTransactionModel => ({
  id: createTransactionId(uuidv4(), "manual", "unknown"),
  type: "option",
  ticker: data.ticker,
  transactionDate: data.transactionDate.toISOString(),
  expirationDate: data.expirationDate.toISOString(),
  premiumPrice: getMoneyFromCurrencyText(data.premiumPrice),
  strikePrice: getMoneyFromCurrencyText(data.strikePrice),
  contract: parseInt(data.contract),
  tradeType: data.tradeType,
  optionType: data.optionType,
});

const defaultTradeType: TransactionTradeType = "buy";
const defaultOptionType: TransactionOptionType = "call";

export const OptionTransactionComponent = () => {
  const {
    register,
    errors,
    control,
    setValue,
  } = useFormContext<OptionTransactionFormField>();

  useEffect(() => {
    setValue(optionTransactionFormName.tradeType, defaultTradeType);
    setValue(optionTransactionFormName.optionType, defaultOptionType);
  }, [setValue]);

  const [transactionDate, setTransactionDate] = useState(new Date());
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [premiumPrice, setPremiumPrice] = useState(0);
  const [strikePrice, setStrikePrice] = useState(0);
  const [contract, setContract] = useState(0);

  return (
    <div className="option-transaction-component">
      {/* Empty input to register trade and option type */}
      <input
        className="_hidden"
        readOnly={true}
        tabIndex={-1}
        name={optionTransactionFormName.tradeType}
        ref={register}
      />
      <input
        className="_hidden"
        readOnly={true}
        tabIndex={-1}
        name={optionTransactionFormName.optionType}
        ref={register}
      />

      <SwitchSlider
        switchList={transactionTradeTypeList}
        defaultIndex={0}
        onClick={(type) => {
          setValue(optionTransactionFormName.tradeType, type);
        }}
        className="trade-type"
      />

      <SwitchSlider
        switchList={transactionOptionTypeList}
        defaultIndex={0}
        onClick={(type) => {
          setValue(optionTransactionFormName.optionType, type);
        }}
        className="option-type"
      />

      <SearchCompanyView
        className="ticker"
        formOption={{
          label: "Stock/Ticker",
          borderType: "normal",
          formValidator: {
            inputRef: register({ required: "Can't be empty" }),
            name: optionTransactionFormName.ticker,
            errorText: errors[optionTransactionFormName.ticker]?.message,
          },
        }}
        onlyShowResultOnFocus
        resultPreset="dropdown"
        onCompanyNameClick={(_, _1, summary) => {
          setPremiumPrice(summary.latestPrice);
        }}
      />

      <FormInput
        label="Premium Price"
        min={0}
        formValidator={{
          inputRef: register({
            required: "Can't be empty",
            validate: (value) =>
              getMoneyFromCurrencyText(value) > 0.001 ||
              "Must be greater than 0",
          }),
          name: optionTransactionFormName.premiumPrice,
          errorText: errors[optionTransactionFormName.premiumPrice]?.message,
        }}
        className="premium-price"
        maskProp={priceInputMask}
        value={premiumPrice}
        onChange={(newPrice) => {
          setPremiumPrice(getMoneyFromCurrencyText(newPrice));
        }}
      />

      <FormInput
        label="Strike Price"
        min={0}
        formValidator={{
          inputRef: register({
            required: "Can't be empty",
            validate: (value) =>
              getMoneyFromCurrencyText(value) > 0.001 ||
              "Must be greater than 0",
          }),
          name: optionTransactionFormName.strikePrice,
          errorText: errors[optionTransactionFormName.strikePrice]?.message,
        }}
        className="strike-price"
        maskProp={priceInputMask}
        value={strikePrice}
        onChange={(newPrice) => {
          setStrikePrice(getMoneyFromCurrencyText(newPrice));
        }}
      />

      <FormInput
        label="Contract"
        min={0}
        rightCTA="x100 shares"
        type="number"
        formValidator={{
          inputRef: register({
            required: "Can't be empty",
            validate: (value) => value > 0.001 || "Must be greater than 0",
          }),
          name: optionTransactionFormName.contract,
          errorText: errors[optionTransactionFormName.contract]?.message,
        }}
        className="contract"
        defaultValue={contract}
        step="any"
        onChange={(newContract) => {
          setContract(parseInt(newContract || 0));
        }}
      />

      <FormDatePicker
        label="Date Filled"
        datePickerProps={{ showTimeSelect: true, dateFormat: "Pp" }}
        value={transactionDate}
        formValidator={{
          name: optionTransactionFormName.transactionDate,
          control,
          inputRef: register,
        }}
        className="transaction-date"
        popperPlacement="top-end"
        onChange={(date) => {
          setTransactionDate(date as Date);
        }}
      />

      <FormDatePicker
        label="Expiration Date"
        datePickerProps={{ showTimeSelect: true, dateFormat: "Pp" }}
        value={expirationDate}
        formValidator={{
          name: optionTransactionFormName.expirationDate,
          control,
          inputRef: register,
        }}
        className="expiration-date"
        popperPlacement="top-end"
        onChange={(date) => {
          setExpirationDate(date as Date);
        }}
      />

      <FormInput
        label="Total Cost"
        readOnly={true}
        className="total-cost"
        value={`$${calculateOptionCost(premiumPrice, contract)}`}
      />
    </div>
  );
};
