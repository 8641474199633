import { ROOT_ID } from "common/common";
import { Icon, Portal } from "components/common";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { a, config, useSpring } from "react-spring";
import { useMeasure, useWindowSize } from "react-use";
import { useDrag } from "react-use-gesture";
import "styles/components/common/BottomSheet.sass";

interface BottomSheetProps {
  children: React.ReactNode;
  supplementaryContent?: React.ReactNode;

  isOpen?: boolean;
  onChange: (isOpen: boolean) => void;

  showAllOnOpen?: boolean;
  closeAllOnClose?: boolean;

  yOffset?: number;
  /* Max height calculated from raw pixel */
  maxHeightInPx?: number;
  /* Max height calculated from % of screen height */
  maxHeightInPercent?: number;
}
export const BottomSheet = ({
  children,
  supplementaryContent,

  isOpen,
  onChange,

  showAllOnOpen,
  closeAllOnClose,

  yOffset = 0,
  maxHeightInPx,
  maxHeightInPercent,
}: BottomSheetProps) => {
  const [
    mainContentContainerRef,
    { height: mainContentHeight },
  ] = useMeasure<HTMLDivElement>();
  const { height: windowHeight } = useWindowSize();
  const supplementaryContentRef = useRef<HTMLDivElement>(null);

  const [{ y }, set] = useSpring(() => ({ y: yOffset }));
  const [atTop, setAtTop] = useState(false);

  const maxY = useMemo(() => {
    let returnVal = Infinity;

    if (maxHeightInPx) {
      returnVal = maxHeightInPx;
    }

    // Prioritize %
    if (maxHeightInPercent) {
      returnVal = (windowHeight * maxHeightInPercent) / 100;
    }

    return -Math.min(windowHeight, returnVal);
  }, [maxHeightInPercent, maxHeightInPx, windowHeight]);

  const isAtMaxY = () => Math.abs(y.get() - maxY) < 0.5;

  const openTo = (
    newY: number,
    option?: { canceled?: boolean; immediate?: boolean }
  ) => {
    set({
      y: newY,
      immediate: option?.immediate,
      config: option?.canceled ? config.wobbly : config.stiff,
    });

    setAtTop(newY === maxY);
  };

  const openToMainContent = (canceled?: boolean) => {
    // when cancel is true, it means that the user passed the upwards threshold
    // so we change the spring config to create a nice wobbly effect
    openTo(-Math.min(mainContentHeight, windowHeight * 0.5), { canceled });
    onChange && onChange(true);
  };

  const close = (velocity = 0) => {
    set({
      y: yOffset,
      immediate: false,
      config: { ...config.stiff, velocity },
    });
    onChange && onChange(false);
  };

  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], canceled }) => {
      if (isAtMaxY()) {
        return;
      }

      if (last) {
        if (my < maxY) {
          openToMainContent(canceled);
        } else if (my > -mainContentHeight * 0.5 || vy > 0.5) {
          // when the user releases the sheet, we check whether it passed
          // the threshold for it to close, or if we reset it to its open position
          close(vy);
        } else if (vy < -0.5) {
          openTo(maxY);
        }
      } else {
        // when the user keeps dragging, we just move the sheet according to
        // the cursor position
        openTo(my, { immediate: true });
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: maxY },
      axis: "y",
    }
  );

  useEffect(() => {
    if (isOpen) {
      if (showAllOnOpen) {
        openTo(maxY);
      } else {
        openToMainContent(false);
      }
    } else {
      close();
    }
    // eslint-disable-next-line
  }, [isOpen, showAllOnOpen]);

  const overlayStyle = {
    opacity: y.to([maxY, yOffset], [0.5, 0], "clamp"),
  };

  return (
    <Portal containerId={ROOT_ID}>
      {isOpen && (
        <a.div
          className="bottom-sheet-overlay"
          // @ts-ignore
          style={overlayStyle}
          onClick={() => close()}
        />
      )}

      <a.div
        className="bottom-sheet"
        {...bind()}
        style={{ y: y, touchAction: "pan-x", height: windowHeight }}
      >
        <div ref={mainContentContainerRef} className="main-content">
          {children}

          {atTop && (
            <button
              className="bottom-sheet-minimize-button"
              onClick={() => {
                if (closeAllOnClose) {
                  close();
                } else {
                  openToMainContent();
                }
              }}
            >
              <Icon icon={["fas", "chevron-down"]} />
            </button>
          )}
        </div>

        <div
          style={{ maxHeight: Math.abs(maxY) - mainContentHeight }}
          className="supplementary-content"
          ref={supplementaryContentRef}
        >
          {supplementaryContent}
        </div>
      </a.div>
    </Portal>
  );
};
