import { logoSrc } from "assets/images";
import cx from "classnames";
import { routeList, routePathMap } from "common/routeList";
import { NotificationBadge, ThemeToggleButton } from "components/common";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useLocation } from "react-router-dom";
import { useMedia } from "react-use";
import { useStoreState } from "stores";
import "styles/components/common/NavBar.sass";
import { checkIfRenderRoute } from "utils/auth.util";
import { getMediaBreakpoint } from "utils/common.utils";
import Icon from "./Icon";

interface SidebarItemProp {
  text?: string;
  icon: [string, string];
  iconClassName?: string;
  iconExtra?: React.ReactNode;
}
const SidebarItem = ({
  text,
  icon,
  iconClassName,
  iconExtra,
}: SidebarItemProp) => {
  return (
    <>
      <div className="icon-container">
        <div className="extra-container">{iconExtra}</div>
        <Icon icon={icon} className={cx(iconClassName, "icon", "fa-fw")} />
      </div>
      {text && <p className="text">{text}</p>}
    </>
  );
};

interface CustomNavLinkProp extends SidebarItemProp {
  path: string;
}
const CustomNavLink = ({
  path,
  text,
  icon,
  iconClassName,
  iconExtra,
}: CustomNavLinkProp) => {
  const location = useLocation();
  const navBarIcon = icon;
  if (location.pathname.includes(path)) {
    navBarIcon![0] = "cis";
  } else {
    navBarIcon![0] = "cio";
  }

  return (
    <NavLink
      to={path}
      className="item-container"
      activeClassName="item-container-active"
    >
      <SidebarItem
        icon={navBarIcon}
        text={text}
        iconClassName={iconClassName}
        iconExtra={iconExtra}
      />
    </NavLink>
  );
};

export const NavBar = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const { isLoggedIn } = useStoreState((state) => state.account);

  const numMessageUnread = useStoreState((state) => state.message.numUnread);

  const routeListToRender = routeList().filter(
    ({ navBarIcon, requireLogin, hideIfLoggedIn }) =>
      navBarIcon && checkIfRenderRoute(isLoggedIn, requireLogin, hideIfLoggedIn)
  );

  if (routeListToRender.length <= 0) {
    return null;
  }

  return (
    <nav className="navBar">
      <div className="item-container logo">
        <img src={logoSrc} alt="logo" />
      </div>

      <PerfectScrollbar
        className="content-outer-container"
        options={{
          suppressScrollX: true,
        }}
      >
        <ul className="content">
          {routeListToRender.map(({ navBarIcon, path, displayText }, key) => (
            <CustomNavLink
              key={`${key}-${displayText}`}
              icon={navBarIcon!}
              path={path}
              text={displayText!}
              iconExtra={
                path === routePathMap.messageBase && (
                  <NotificationBadge isActive={numMessageUnread > 0} />
                )
              }
            />
          ))}
        </ul>

        {isLg && (
          <ul className="extra">
            <ThemeToggleButton className="item-container" />

            <CustomNavLink
              path={routePathMap.settingBase}
              icon={["cis", "settings"]}
            />
          </ul>
        )}
      </PerfectScrollbar>
    </nav>
  );
};
