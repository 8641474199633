import { BottomSheet, CustomModal } from "components/common";
import { SubscriptionView } from "components/pages/Setting";
import React, { useState } from "react";
import { useMedia } from "react-use";
import "styles/views/common/SubscriptionViewTriggerButton.sass";
import { getMediaBreakpoint } from "utils/common.utils";

interface SubscriptionViewTriggerButtonProps {
  text: string;
}
export const SubscriptionViewTriggerButton = ({
  text,
}: SubscriptionViewTriggerButtonProps) => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  const [showSubscriptionView, setShowSubscriptionView] = useState(false);

  const closeSubscriptionView = () => {
    setShowSubscriptionView(false);
  };

  const renderTitle = () => `Subscription`;
  const renderContent = () => {
    return (
      <div className="subscription-trigger-view-container">
        <SubscriptionView />
      </div>
    );
  };

  return (
    <>
      <button
        className="subscription-trigger"
        onClick={() => {
          setShowSubscriptionView(true);
        }}
      >
        {text}
      </button>

      {showSubscriptionView &&
        (isLg ? (
          <CustomModal
            midComponent={renderTitle()}
            onClickAway={closeSubscriptionView}
            onCloseClick={closeSubscriptionView}
          >
            {renderContent()}
          </CustomModal>
        ) : (
          <BottomSheet
            maxHeightInPercent={80}
            showAllOnOpen
            closeAllOnClose
            isOpen={showSubscriptionView}
            supplementaryContent={renderContent()}
            onChange={(isOpen) => {
              if (!isOpen) {
                closeSubscriptionView();
              }
            }}
          >
            {renderTitle()}
          </BottomSheet>
        ))}
    </>
  );
};
