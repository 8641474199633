import { Sparklines, SparklinesLine, SparklinesProps } from "react-sparklines";

interface SparklineChartProps {
  config: SparklinesProps;
  color?: string;
}
export const SparklinesLineChart = ({ config, color }: SparklineChartProps) => {
  return (
    <Sparklines {...config}>
      <SparklinesLine color={color} />
    </Sparklines>
  );
};
