import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { BasePlanFeatureDto } from "models/dto/payment/base-plan-feature.dto";
import {
  CheckoutSessionRequestDto,
  CheckoutSessionResponseDto,
} from "models/dto/payment/checkout-session.dto";
import {
  CreatePortalLinkRequestDto,
  CreatePortalLinkResponseDto,
} from "models/dto/payment/create-portal-link.dto";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";
import { extractFunctionError } from "utils/error.util";

export const paymentService = {
  getPlanList: async (): Promise<SubscriptionPlanDto[]> => {
    try {
      const fetchResult = await sendRequest<SubscriptionPlanDto[]>({
        endpointInfo: functionEndpointList.getPlans,
        useTokenInHeaders: true,
      });
      return fetchResult.filter((entry) => entry.active);
    } catch (error) {
      throw await extractFunctionError(error);
    }
  },
  getBaseFeatureList: async (): Promise<BasePlanFeatureDto[]> => {
    try {
      return await sendRequest<BasePlanFeatureDto[]>({
        endpointInfo: functionEndpointList.getBaseFeatures,
        useTokenInHeaders: true,
      });
    } catch (error) {
      throw await extractFunctionError(error);
    }
  },
  createSubscribeCheckoutSession: async (
    data: CheckoutSessionRequestDto
  ): Promise<CheckoutSessionResponseDto> => {
    try {
      return await sendRequest<CheckoutSessionResponseDto>({
        endpointInfo: functionEndpointList.subscribePlan,
        data,
        useTokenInHeaders: true,
      });
    } catch (error) {
      throw await extractFunctionError(error);
    }
  },
  createCustomerPortalLink: async (
    data: CreatePortalLinkRequestDto
  ): Promise<CreatePortalLinkResponseDto> => {
    try {
      return await sendRequest({
        endpointInfo: functionEndpointList.createPortalLink,
        data,
        useTokenInHeaders: true,
      });
    } catch (error) {
      throw await extractFunctionError(error);
    }
  },
};
