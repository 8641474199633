import { watchListService } from "api/service";
import { internalErrorMessage } from "common/standardMessage";
import {
  WatchListLoadingType,
  WatchListWriteMode,
} from "common/watch-list.info";
import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { generateWatchList } from "generators";
import lodash from "lodash";
import {
  GetWatchListResponseDto,
  TickerDetail,
  TickerInfo,
  WatchListDto,
} from "models/dto/watchlist/watchlist.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";
import { getBoolValueFromFeatureKey } from "utils/subscription.util";

export interface WatchListModel extends LoadingModel<WatchListLoadingType> {
  // * State
  watchListList: WatchListDto[];
  watchListTickerInfoMap: Record<string, TickerDetail>;
  doShowSparkline: Computed<WatchListModel, boolean, StoreModel>;

  // --- Edit
  writeMode: WatchListWriteMode;
  watchListInWriting: WatchListDto | null;

  // * Actions
  resetStore: Action<WatchListModel>;
  setWatchListData: Action<WatchListModel, GetWatchListResponseDto>;

  // --- Edit
  initializeWriteMode: Action<WatchListModel, WatchListDto | null>;
  setEditMode: Action<WatchListModel, WatchListWriteMode>;
  setEditingWatchList: Action<WatchListModel, WatchListDto | null>;
  setWatchListName: Action<WatchListModel, string>;
  setWatchListIcon: Action<WatchListModel, string>;
  setTickerInfoList: Action<WatchListModel, TickerInfo[]>;
  addTicker: Action<WatchListModel, TickerInfo>;
  removeTicker: Action<WatchListModel, string>;

  // * Thunks
  initializeStore: Thunk<WatchListModel>;
  deleteWatchList: Thunk<WatchListModel, string[]>;

  // --- Edit
  submitWatchList: Thunk<WatchListModel, WatchListDto, any, StoreModel>;
}

export const watchList: WatchListModel = {
  // * State
  watchListList: [],
  watchListTickerInfoMap: {},
  doShowSparkline: computed(
    [(_, storeState) => storeState.account.featureList],
    (featureList) => getBoolValueFromFeatureKey(featureList, "watchListChart")
  ),

  // --- Edit
  writeMode: WatchListWriteMode.None,
  watchListInWriting: null,

  // * Actions
  resetStore: action((state) => {
    state.watchListList = [];
    state.writeMode = WatchListWriteMode.None;
    state.watchListInWriting = null;
  }),
  setWatchListData: action((state, watchListData) => {
    state.watchListList = watchListData.watchListList;

    const watchListTickerInfoMap: Record<string, TickerDetail> = {};
    watchListData.tickerDetailList.forEach((tickerDetail) => {
      watchListTickerInfoMap[tickerDetail.ticker] = tickerDetail;
    });

    state.watchListTickerInfoMap = watchListTickerInfoMap;
  }),

  // --- Edit
  initializeWriteMode: action((state, watchList) => {
    let newWatchList: WatchListDto;
    let editMode: WatchListWriteMode;
    if (watchList) {
      newWatchList = lodash.cloneDeep(watchList);
      editMode = WatchListWriteMode.Update;
    } else {
      newWatchList = generateWatchList();
      editMode = WatchListWriteMode.Add;
    }

    state.watchListInWriting = newWatchList;
    state.writeMode = editMode;
  }),
  setEditMode: action((state, editMode) => {
    state.writeMode = editMode;
  }),
  setEditingWatchList: action((state, watchList) => {
    state.watchListInWriting = watchList;
  }),
  setTickerInfoList: action((state, tickerInfoList) => {
    if (state.watchListInWriting) {
      state.watchListInWriting.tickerInfoList = tickerInfoList;
    } else {
      throw new Error(internalErrorMessage.missingEditingWatchList);
    }
  }),
  setWatchListName: action((state, name) => {
    if (state.watchListInWriting) {
      state.watchListInWriting.watchListUIInfo.name = name;
    } else {
      throw new Error(internalErrorMessage.missingEditingWatchList);
    }
  }),
  setWatchListIcon: action((state, icon) => {
    if (state.watchListInWriting) {
      state.watchListInWriting.watchListUIInfo.icon = icon;
    } else {
      throw new Error(internalErrorMessage.missingEditingWatchList);
    }
  }),
  addTicker: action((state, tickerInfo) => {
    if (state.watchListInWriting) {
      const curList = state.watchListInWriting.tickerInfoList;
      if (!curList.find((entry) => entry.ticker === tickerInfo.ticker)) {
        curList.push(tickerInfo);
      }
    } else {
      throw new Error(internalErrorMessage.missingEditingWatchList);
    }
  }),
  removeTicker: action((state, ticker) => {
    if (state.watchListInWriting) {
      const curList = state.watchListInWriting.tickerInfoList;
      const curIndex = curList.findIndex((entry) => entry.ticker === ticker);
      if (curIndex >= 0) {
        curList.splice(curIndex, 1);
      }
    } else {
      throw new Error(internalErrorMessage.missingEditingWatchList);
    }
  }),

  // * Thunks
  initializeStore: thunk((actions) => {
    executeAsync({
      funcToExecute: async () => {
        const result = await watchListService.getUserWatchList();
        actions.setWatchListData(result);
      },
      transformError: extractFunctionError,
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          isLoading: isLoading,
          loadingType: WatchListLoadingType.FetchingData,
        });
      },
    });
  }),

  deleteWatchList: thunk(async (actions, idList) => {
    const result = await executeAsync({
      funcToExecute: async () => {
        return await watchListService.deleteUserWatchList(idList);
      },
      transformError: extractFunctionError,
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          loadingType: WatchListLoadingType.DeletingWatchList,
          isLoading: isLoading,
        });
      },
    });

    if (result.result) {
      actions.setWatchListData(result.result);
    }
  }),

  // --- Edit
  submitWatchList: thunk(async (actions, watchList, { getState }) => {
    const { result } = await executeAsync({
      funcToExecute: async () => {
        return await (getState().writeMode === WatchListWriteMode.Add
          ? watchListService.createUserWatchList(watchList)
          : watchListService.updateUserWatchList(watchList));
      },
      transformError: extractFunctionError,
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          isLoading: isLoading,
          loadingType: WatchListLoadingType.WritingWatchList,
        });
      },
    });

    if (result) {
      actions.setWatchListData(result);
      actions.setEditMode(WatchListWriteMode.None);
    }
  }),

  // * Plugins
  ...loadingPlugin(),
};
