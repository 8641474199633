import { journalService } from "api/service/journal.service";
import {
  DEFAULT_JOURNAL_SEARCH_LIMIT,
  DEFAULT_JOURNAL_SEARCH_OFFSET,
  JournalPageLoadingType,
} from "common/journal/journal-page.info";
import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunk,
  ThunkOn,
  thunkOn,
} from "easy-peasy";
import lodash from "lodash";
import { GetJournalReqDto } from "models/dto/journal/get-journal.dto";
import { JournalDto } from "models/dto/journal/journal.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

export interface JournalDashboardModel
  extends LoadingModel<JournalPageLoadingType> {
  // * State
  journalList: JournalDto[];
  isInSelectMode: boolean;
  selectedJournalIdList: string[];
  isJournalSelected: Computed<JournalDashboardModel, (id: string) => boolean>;

  // --- Search
  tagListToSearch: string[];
  searchKeyword: string;
  hasMoreToSearch: boolean;

  // * Actions
  resetStore: Action<JournalDashboardModel>;
  setJournalList: Action<JournalDashboardModel, JournalDto[]>;

  selectOrDeselectJournal: Action<JournalDashboardModel, string>;
  setIsInSelectMode: Action<JournalDashboardModel, boolean>;
  clearSelectedJournal: Action<JournalDashboardModel>;

  addOrRemoveTagToFilter: Action<JournalDashboardModel, string>;
  setTagListToSearch: Action<JournalDashboardModel, string[]>;
  setSearchKeyword: Action<JournalDashboardModel, string>;
  setHasMoreToSearch: Action<JournalDashboardModel, boolean>;

  // * Thunks
  initializeStore: Thunk<JournalDashboardModel, never, any, StoreModel>;
  searchAndSaveJournal: Thunk<
    JournalDashboardModel,
    Partial<GetJournalReqDto> & { paginationMode?: boolean },
    any,
    StoreModel
  >;
  deleteJournal: Thunk<JournalDashboardModel, string[], any, StoreModel>;

  // * Listeners
  onAddRemoveTagToFilter: ThunkOn<JournalDashboardModel, any, StoreModel>;
}

export const journalDashboard: JournalDashboardModel = {
  // * State
  journalList: [],
  isInSelectMode: false,
  selectedJournalIdList: [],

  isJournalSelected: computed(
    [(state) => state.selectedJournalIdList],
    (selectedJournalIdList) => (id) => selectedJournalIdList.includes(id)
  ),
  // --- Search
  tagListToSearch: [],
  hasMoreToSearch: false,
  searchKeyword: "",

  // * Actions
  resetStore: action((state) => {
    state.journalList = [];

    state.selectedJournalIdList = [];
    state.isInSelectMode = false;

    state.searchKeyword = "";
    state.tagListToSearch = [];
    state.hasMoreToSearch = false;
  }),
  setJournalList: action((state, journalList) => {
    state.journalList = journalList;
    state.selectedJournalIdList = [];
    state.isInSelectMode = false;
  }),

  setIsInSelectMode: action((state, isInSelectMode) => {
    state.isInSelectMode = isInSelectMode;
  }),
  selectOrDeselectJournal: action((state, idToSelectOrRemove) => {
    const foundIndex = state.selectedJournalIdList.findIndex(
      (id) => id === idToSelectOrRemove
    );
    if (foundIndex >= 0) {
      state.selectedJournalIdList.splice(foundIndex, 1);
    } else {
      state.selectedJournalIdList.push(idToSelectOrRemove);
    }
  }),
  clearSelectedJournal: action((state) => {
    state.selectedJournalIdList = [];
  }),

  addOrRemoveTagToFilter: action((state, tag) => {
    const tagIndex = state.tagListToSearch.findIndex((curTag) =>
      lodash.isEqual(curTag, tag)
    );

    if (tagIndex >= 0) {
      state.tagListToSearch.splice(tagIndex, 1);
    } else {
      state.tagListToSearch.push(tag);
    }
  }),
  setSearchKeyword: action((state, searchKeyword) => {
    state.searchKeyword = searchKeyword;
  }),
  setTagListToSearch: action((state, tagListToSearch) => {
    state.tagListToSearch = tagListToSearch;
  }),
  setHasMoreToSearch: action((state, hasMore) => {
    state.hasMoreToSearch = hasMore;
  }),

  // * Thunks
  initializeStore: thunk(async (actions, _hidden) => {
    await actions.searchAndSaveJournal({ limit: DEFAULT_JOURNAL_SEARCH_LIMIT });
  }),

  searchAndSaveJournal: thunk(
    async (
      actions,
      { limit, offset, searchKeyword, tagListToSearch, paginationMode },
      { getState }
    ) => {
      if (searchKeyword != null) {
        actions.setSearchKeyword(searchKeyword);
      }

      if (tagListToSearch) {
        actions.setTagListToSearch(tagListToSearch);
      }

      let limitToSearch = limit || DEFAULT_JOURNAL_SEARCH_LIMIT;

      const { result } = await executeAsync({
        funcToExecute: async () =>
          await journalService.getJournalList({
            limit: limitToSearch,
            offset: offset || DEFAULT_JOURNAL_SEARCH_OFFSET,
            searchKeyword:
              searchKeyword != null ? searchKeyword : getState().searchKeyword,
            tagListToSearch: tagListToSearch || getState().tagListToSearch,
          }),
        transformError: extractFunctionError,
        doToastError: true,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: paginationMode
              ? JournalPageLoadingType.LoadingPagination
              : JournalPageLoadingType.LoadingJournalList,
            isLoading,
          });
          actions.setIsLoading({
            loadingType: JournalPageLoadingType.LoadingJournalDetail,
            isLoading,
          });
        },
      });

      if (result) {
        actions.setHasMoreToSearch(result.hasMore);

        if (paginationMode) {
          actions.setJournalList([
            ...getState().journalList,
            ...result.journalList,
          ]);
        } else {
          actions.setJournalList(result.journalList);
        }
      }
    }
  ),

  deleteJournal: thunk(
    async (actions, idList, { getStoreActions, getState }) => {
      await executeAsync({
        toastMessage: "Deleting journal 🗑️ (bye bye journal!)",
        funcToExecute: async () => {
          const successfullyDeletedIdList = await journalService.deleteJournal(
            idList
          );

          const newList = getState().journalList.filter(
            (entry) => !successfullyDeletedIdList.includes(entry.id)
          );
          actions.setJournalList(newList);
          getStoreActions().journalDetailView.resetStore();
        },

        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: JournalPageLoadingType.DeletingJournal,
            isLoading,
          });
        },
      });
    }
  ),

  // * Listeners
  onAddRemoveTagToFilter: thunkOn(
    (actions) => [actions.addOrRemoveTagToFilter],
    (actions, _, { getState }) => {
      // TODO: Complete this
    }
  ),

  // * Plugins
  ...loadingPlugin(),
};
