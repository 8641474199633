import cx from "classnames";
import {
  CURRENCY_DECIMAL,
  CURRENCY_DECIMAL_SEPARATOR,
  CURRENCY_SIGN,
  THOUSAND_SEPARATOR,
} from "common/common";
import CountUp, { CountUpProps } from "react-countup";
import "styles/components/common/PriceOrPercent.sass";

interface PriceOrPercentProps {
  value: number;
  type: "price" | "percent";
  className?: string;
  countUpProps?: CountUpProps;
  useSentimentColor?: boolean;
  showPosSignOnPos?: boolean;
}
export const PriceOrPercent = ({
  value,
  type,
  className,
  countUpProps,
  useSentimentColor = true,
  showPosSignOnPos: showPosNegSign,
}: PriceOrPercentProps) => {
  const calculatePosNegSign = () => {
    if (showPosNegSign && value > 0) {
      return "+";
    }
    return "";
  };

  return (
    <CountUp
      decimal={CURRENCY_DECIMAL_SEPARATOR}
      decimals={CURRENCY_DECIMAL}
      separator={THOUSAND_SEPARATOR}
      prefix={`${calculatePosNegSign()}${
        type === "price" ? CURRENCY_SIGN : ""
      }`}
      suffix={type === "percent" ? "%" : ""}
      preserveValue={true}
      end={value}
      className={cx(
        { "stock-change": useSentimentColor },
        { "stock-change-pos": value > 0 && useSentimentColor },
        { "stock-change-neg": value < 0 && useSentimentColor },
        className
      )}
      duration={0.5}
      {...(countUpProps || {})}
    />
  );
};
