import { discordDarkPng, discordLightPng } from "assets/images";
import { contactInfoMap } from "common/contact.info";
import { useStoreState } from "stores/StoreFront";

export const DiscordComponent = () => {
  const { theme } = useStoreState((state) => state.theme);

  return (
    <a
      className="join-discord-container"
      href={contactInfoMap.discord}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>Chat to us directly via Discord</p>
      <img
        src={theme === "dark" ? discordDarkPng : discordLightPng}
        alt="Join Discord"
      />
    </a>
  );
};
