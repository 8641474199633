import { BottomSheet } from "components/common/BottomSheet";
import Icon from "components/common/Icon";
import { CustomModal } from "components/common/Modal";
import { TickerInfo } from "models/dto/watchlist/watchlist.dto";
import React, { useState } from "react";
import { useMedia } from "react-use";
import { useStoreActions } from "stores";
import "styles/components/common/WatchList/WatchListWriteComponent.sass";
import { getMediaBreakpoint } from "utils/common.utils";
import { SearchCompanyView } from "views";
import { Draggable } from "react-beautiful-dnd";
import cx from "classnames";

export const WatchListAddTickerButton = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const [isAddingTicker, setIsAddingTicker] = useState(false);
  const { addTicker } = useStoreActions((actions) => actions.watchList);

  const onClick = () => {
    setIsAddingTicker(true);
  };
  const stopSearchCompany = () => {
    setIsAddingTicker(false);
  };

  const renderSearchTickerView = () => (
    <SearchCompanyView
      onCompanyNameClick={(companyName, ticker) => {
        addTicker({ ticker, companyName });
        stopSearchCompany();
      }}
    />
  );
  const renderSearchHeader = () => "Search for Ticker";

  return (
    <>
      <button className="watch-list-add-ticker-button" onClick={onClick}>
        <Icon icon={["fal", "plus-circle"]} className="fa-fw" />
        <p>Add Stock</p>
      </button>

      {isAddingTicker &&
        (isLg ? (
          <CustomModal
            onClickAway={stopSearchCompany}
            onCloseClick={stopSearchCompany}
            midComponent={renderSearchHeader()}
            bodyClassName="watch-list-add-ticker-search-company-view"
          >
            {renderSearchTickerView()}
          </CustomModal>
        ) : (
          <BottomSheet
            showAllOnOpen
            closeAllOnClose
            isOpen={isAddingTicker}
            maxHeightInPercent={80}
            onChange={(isOpen) => {
              if (!isOpen) {
                stopSearchCompany();
              }
            }}
            supplementaryContent={renderSearchTickerView()}
          >
            {renderSearchHeader()}
          </BottomSheet>
        ))}
    </>
  );
};

interface WatchListWriteTickerItemProps {
  tickerInfo: TickerInfo;
  onRemoveClick: (tickerInfo: TickerInfo) => void;
  index: number;
}
export const WatchListWriteTickerItem = ({
  tickerInfo,
  onRemoveClick,
  index,
}: WatchListWriteTickerItemProps) => {
  return (
    <Draggable index={index} draggableId={tickerInfo.ticker}>
      {(provided, snapshot) => (
        <div
          className={cx("watch-list-write-ticker-item", {
            "watch-list-write-ticker-item-is-dragging": snapshot.isDragging,
          })}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="move-button">
            <Icon icon={["far", "grip-lines"]} className="fa-fw" />
          </div>

          <p className="ticker">{tickerInfo.ticker}</p>
          <p className="company-name">{tickerInfo.companyName}</p>

          <button
            className="delete-button"
            onClick={() => {
              onRemoveClick(tickerInfo);
            }}
          >
            <Icon icon={["far", "times-circle"]} className="fa-fw" />
          </button>
        </div>
      )}
    </Draggable>
  );
};
