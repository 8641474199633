import { AddFileSvg } from "assets/images";
import React from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import "styles/components/common/FileUploadComponent.sass";

interface FileUploadComponentProps {
  dropdownOptions?: DropzoneOptions;
  dialogOpenerTag?: keyof JSX.IntrinsicElements;
  dialogOpenerComponent?: React.ReactNode;
  dragAndDropText?: React.ReactNode;
}

export const FileUploadComponent = ({
  dropdownOptions,
  dialogOpenerTag = "button",
  dialogOpenerComponent,
  dragAndDropText = "Drag 'n' drop some files here",
}: FileUploadComponentProps) => {
  const { getRootProps, getInputProps } = useDropzone(dropdownOptions);

  const DialogOpener = dialogOpenerTag;
  return (
    <div {...getRootProps({ className: "file-upload-component" })}>
      <input {...getInputProps()} />
      <div className="info">
        <AddFileSvg />
        <p>{dragAndDropText}</p>

        {dialogOpenerComponent && (
          <DialogOpener className="dialog-opener">
            {dialogOpenerComponent}
          </DialogOpener>
        )}
      </div>
    </div>
  );
};
