import { useEffect } from "react";
import { useStoreActions } from "stores";

export const AuthManager = () => {
  const { initializeAccount } = useStoreActions((actions) => actions.account);

  useEffect(() => {
    initializeAccount();
  }, [initializeAccount]);

  return null;
};
