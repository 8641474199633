import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import {
  DeleteWatchListDto,
  GetWatchListResponseDto,
  WatchListDto,
  WriteWatchListDto,
} from "models/dto/watchlist/watchlist.dto";

export const watchListService = {
  getUserWatchList: async () => {
    const endpointInfo = functionEndpointList.getUserWatchList;
    const response = await sendRequest<GetWatchListResponseDto>({
      endpointInfo,
      useTokenInHeaders: true,
    });

    return response;
  },

  createUserWatchList: async (watchList: WatchListDto) => {
    const endpointInfo = functionEndpointList.createUserWatchList;
    const response = await sendRequest<
      GetWatchListResponseDto,
      WriteWatchListDto
    >({
      endpointInfo,
      useTokenInHeaders: true,
      data: {
        tickerInfoList: watchList.tickerInfoList,
        watchListUIInfo: watchList.watchListUIInfo,
      },
    });

    return response;
  },

  updateUserWatchList: async (watchList: WatchListDto) => {
    const endpointInfo = functionEndpointList.updateUserWatchList;
    const response = await sendRequest<
      GetWatchListResponseDto,
      WriteWatchListDto
    >({
      endpointInfo,
      useTokenInHeaders: true,
      extraPath: [watchList.watchListId],
      data: {
        tickerInfoList: watchList.tickerInfoList,
        watchListUIInfo: watchList.watchListUIInfo,
      },
    });

    return response;
  },

  deleteUserWatchList: async (idList: string[]) => {
    const endpointInfo = functionEndpointList.deleteUserWatchList;
    const response = await sendRequest<
      GetWatchListResponseDto,
      DeleteWatchListDto
    >({
      endpointInfo,
      useTokenInHeaders: true,
      data: { idList },
    });

    return response;
  },
};
