import { alertService } from "api/service";
import { RelayType } from "common/alert-relay.info";
import { AlertType } from "common/alert/alert-common.info";
import { AlertManageType } from "common/alert/alert-manage.info";
import {
  AlertAddConditionStep,
  AlertAddConditionView,
  AlertManageLoadingType,
  AlertManageStep,
} from "common/alert/alert-page.info";
import { ApiReturnValue } from "common/api.info";
import { internalErrorMessage } from "common/standardMessage";
import { thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import { generateNewAlert } from "generators/alertGenerator";
import { FunctionBackendError } from "models";
import { AlertConditionModel, AlertDto } from "models/dto/alert";
import { StoreModel } from "stores";
import { AlertManageModel } from "stores/alert";
import {
  getErrorFromAlertLimitReach,
  getErrorFromConditionLimit,
  getErrorFromRelaySubscription,
} from "stores/alert/alert-manage.util";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";
import { getUserEmail, getUserPhoneNumber } from "utils/firebase.util";

export interface AlertManageThunk {
  // * Thunk
  initializeStore: Thunk<AlertManageModel, AlertDto | null>;
  switchAlertType: Thunk<
    AlertManageModel,
    AlertType,
    any,
    any,
    Promise<ApiReturnValue<boolean, FunctionBackendError>>
  >;

  addOrRemoveRelay: Thunk<AlertManageModel, RelayType, any, StoreModel>;

  checkCanAddCondition: Thunk<
    AlertManageModel,
    never,
    any,
    StoreModel,
    Promise<boolean>
  >;
  validateCondition: Thunk<
    AlertManageModel,
    AlertConditionModel,
    any,
    StoreModel,
    Promise<ApiReturnValue<boolean, FunctionBackendError>>
  >;

  submitAlert: Thunk<
    AlertManageModel,
    never,
    any,
    StoreModel,
    Promise<ApiReturnValue<AlertDto[], FunctionBackendError>>
  >;
  computeCompanySummary: ThunkOn<AlertManageModel, any, StoreModel>;
}

export const alertManageThunk: AlertManageThunk = {
  // * Thunk
  initializeStore: thunk((actions, newAlert) => {
    let manageType = AlertManageType.Add;
    let manageStep = AlertManageStep.CompanySearch;

    if (newAlert) {
      manageType = AlertManageType.Edit;
      manageStep = AlertManageStep.ConditionAdding;
    } else {
      newAlert = generateNewAlert();
    }

    actions.setAlert(newAlert);
    actions.setAlertManageType(manageType);
    actions.setCurManageStep(manageStep);
    actions.setCurAddConditionView(AlertAddConditionView.Empty);
    actions.setCurAddConditionStep(AlertAddConditionStep.TypeSelection);
  }),

  switchAlertType: thunk(async (actions, type) => {
    return await executeAsync({
      funcToExecute: async () => {
        switch (type) {
          case "chained":
            // TODO: Check subscription here
            break;
          case "single":
            break;
        }

        if (type === "single") {
          // TODO warn user here
          actions.sliceConditionList(1);
        }
        actions.setAlertType(type);
        actions.resetErrorMap();
        return true;
      },
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          loadingType: AlertManageLoadingType.SwitchingAlertType,
          isLoading,
        });
      },
      transformError: extractFunctionError,
    });
  }),

  addOrRemoveRelay: thunk((actions, relay, { getState, getStoreState }) => {
    const alert = getState().alert;

    if (alert) {
      if (relay === "sms") {
        if (!getUserPhoneNumber()) {
          actions.setError({
            key: "relay",
            detailMessage:
              "You have to add your phone number through Setting to use this option",
          });

          return;
        }
        if (!getStoreState().account.canUseFeature("smsAlert")) {
          actions.setError({
            key: "relay",
            detailMessage:
              "Only Premium and Elite Members Can Use This Feature",
          });
          return;
        }
      }

      if (relay === "email" && !getUserEmail()) {
        actions.setError({
          key: "relay",
          detailMessage:
            "You have to add your email through Setting to use this option",
        });

        return;
      }

      const relayList = alert.relayList;
      const curIndex = relayList.findIndex((curRelay) => curRelay === relay);

      if (curIndex < 0) {
        relayList.push(relay);
      } else {
        relayList.splice(curIndex, 1);
      }
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),

  checkCanAddCondition: thunk(async (_1, _2, { getState, getStoreState }) => {
    const alert = getState().alert;

    if (alert) {
      const featureList = getStoreState().account.featureList;
      return (
        getErrorFromConditionLimit(featureList, alert, "create-condition") ==
        null
      );
    } else {
      throw new Error(internalErrorMessage.alertNotFound);
    }
  }),
  validateCondition: thunk(async (actions, condition, { getState }) => {
    const alert = getState().alert;

    if (!alert) {
      throw new Error(internalErrorMessage.alertNotFound);
    }

    const result = await executeAsync({
      toastMessage: "Validating condition 📝",
      funcToExecute: async () => {
        return await alertService.checkingCondition({
          ticker: alert.ticker,
          condition,
        });
      },
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          loadingType: AlertManageLoadingType.CheckingCondition,
          isLoading,
        });
      },
      transformError: extractFunctionError,
      doToastError: false,
    });

    return result;
  }),

  submitAlert: thunk(
    async (actions, _, { getStoreActions, getState, getStoreState }) => {
      const alert = getState().alert;

      if (!alert) {
        throw new Error(internalErrorMessage.alertNotFound);
      }

      const alertList = getStoreState().alertDashboard.alertList;
      const featureList = getStoreState().account.featureList;
      const subscriptionError =
        getErrorFromRelaySubscription(featureList, alert.relayList) ||
        getErrorFromAlertLimitReach(featureList, alertList, alert);

      if (subscriptionError) {
        return { result: null, error: subscriptionError };
      }

      const result = await executeAsync({
        toastMessage: "Setting alert ⏰",
        funcToExecute: async () => {
          const manageType = getState().alertManageType;
          return await (manageType === AlertManageType.Add
            ? alertService.createAlert(alert)
            : alertService.updateAlert(alert));
        },
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: AlertManageLoadingType.SubmittingAlert,
            isLoading,
          });
        },
        transformError: extractFunctionError,
      });

      if (result.result) {
        getStoreActions().alertDashboard.setAlertList(result.result);
        actions.setAlert(alert);
      }

      return result;
    }
  ),

  computeCompanySummary: thunkOn(
    (actions) => [actions.setTicker, actions.setAlert],
    async (actions, _, { getStoreActions, getState }) => {
      const curAlert = getState().alert;

      if (curAlert && curAlert.ticker.length > 0) {
        const companySummary = (
          await executeAsync({
            funcToExecute: async () => {
              return getStoreActions().stock.searchStockCompany(
                curAlert.ticker
              );
            },
          })
        ).result;

        actions.setTargetCompanySummary(companySummary?.[0] || null);
      }
    }
  ),
};
