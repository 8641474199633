import { useEffect } from "react";

import { useStoreActions } from "stores";

export const ThemeManager = () => {
  const { initializeTheme } = useStoreActions((actions) => actions.theme);

  useEffect(() => {
    initializeTheme();
  }, [initializeTheme]);

  return null;
};
