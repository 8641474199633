import { ApiReturnValue } from "common/api.info";
import {
  StockPageLoadingType,
  STOCK_PAGE_BASE_TICKER,
} from "common/stock-page/stock-page.info";
import { Action, action, thunk, Thunk, thunkOn, ThunkOn } from "easy-peasy";
import { Quote } from "models";
import { CompanyDetailDto } from "models/dto/stock/get-company-detail.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

export interface StockPageModel extends LoadingModel<StockPageLoadingType> {
  // * State
  targetTicker: string | null;
  companyDetail: CompanyDetailDto | null;
  companyQuote: Quote | null;

  // * Actions
  setTargetTicker: Action<StockPageModel, string | null>;
  setCompanyDetail: Action<StockPageModel, CompanyDetailDto | null>;
  setCompanyQuote: Action<StockPageModel, Quote | null>;

  // * Thunks
  initializeStore: Thunk<StockPageModel, never, any, StoreModel>;
  fetchStockInfo: Thunk<StockPageModel, string, any, StoreModel>;

  // * Listeners
  onSetTargetTicker: ThunkOn<StockPageModel, any, StoreModel>;
}

export const stockPage: StockPageModel = {
  // * State
  targetTicker: null,
  companyDetail: null,
  companyQuote: null,

  // * Actions
  setTargetTicker: action((state, targetTicker) => {
    // Ticker are all upper case, so we use upper case to compare
    //  in other places such as QuoteMap or AlertMap
    state.targetTicker = targetTicker?.toUpperCase() || null;
  }),
  setCompanyDetail: action((state, companyDetail) => {
    state.companyDetail = companyDetail;
  }),
  setCompanyQuote: action((state, companyQuote) => {
    state.companyQuote = companyQuote;
  }),

  // * Thunks
  initializeStore: thunk((actions, _, { getState, getStoreActions }) => {
    actions.fetchStockInfo(getState().targetTicker || STOCK_PAGE_BASE_TICKER);
    getStoreActions().alertDashboard.fetchAndSaveAlertList();
  }),

  fetchStockInfo: thunk(async (actions, ticker, { getStoreActions }) => {
    const newTarget = ticker;

    const promiseList: Promise<any>[] = [];

    let detailFetchResult: ApiReturnValue<CompanyDetailDto | null> = {
      error: null,
      result: null,
    };
    let quoteFetchResult: ApiReturnValue<Quote[]> = {
      error: null,
      result: null,
    };

    promiseList.push(
      executeAsync({
        funcToExecute: async () =>
          await getStoreActions().stock.getAndSaveCompanyDetail(newTarget),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: StockPageLoadingType.FetchCompanyDetail,
            isLoading: isLoading,
          });
        },
      }).then((result) => {
        detailFetchResult = result;
      })
    );

    promiseList.push(
      executeAsync({
        funcToExecute: async () =>
          await getStoreActions().stock.getQuoteList([newTarget]),
        transformError: extractFunctionError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: StockPageLoadingType.FetchCompanyQuote,
            isLoading: isLoading,
          });
        },
      }).then((result) => {
        quoteFetchResult = result;
      })
    );

    await Promise.allSettled(promiseList);

    actions.setCompanyDetail(detailFetchResult.result);
    actions.setCompanyQuote(quoteFetchResult.result?.[0] || null);
  }),

  // * Listeners
  onSetTargetTicker: thunkOn(
    (actions) => [actions.setTargetTicker],
    async (actions, _, { getState }) => {
      actions.fetchStockInfo(getState().targetTicker || STOCK_PAGE_BASE_TICKER);
    }
  ),

  // * Plugins
  ...loadingPlugin(),
};
