import { AxiosError } from "axios";
import { IsDefined, IsNumber, IsString } from "class-validator";
import { FunctionErrorMessage } from "models";

interface FunctionAxiosResponse {
  status: number;
  message: FunctionErrorMessage;
  detailMessage: string;
}
export class FunctionErrorDto {
  @IsNumber()
  @IsDefined()
  code: number;
  @IsDefined()
  @IsString()
  message: FunctionErrorMessage;
  detailMessage: string;

  constructor(err: AxiosError<FunctionAxiosResponse>) {
    const data = err.response?.data;
    this.code = data?.status || 500;
    this.message = data?.message || "internal/unknown-error";
    this.detailMessage = data?.detailMessage || "";
  }
}
