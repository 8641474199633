import { PriceOrPercent } from "components/common";
import { CompanySummary } from "models";
import React from "react";
import "styles/components/common/CompanySummaryCard.sass";

interface CompanySummaryCardProp {
  companySummary: CompanySummary;
  wrapperOnClick?: (companySummary: CompanySummary) => void;
  wrapperTag?: keyof JSX.IntrinsicElements;
}
export const CompanySummaryCard = ({
  companySummary,
  wrapperTag = "div",
  wrapperOnClick,
}: CompanySummaryCardProp) => {
  const { companyName, changePercent, latestPrice, symbol } = companySummary;
  const WrapperTag = wrapperTag;

  return (
    <WrapperTag
      className="company-summary-card"
      onClick={() => {
        wrapperOnClick && wrapperOnClick(companySummary);
      }}
      // @ts-ignore
      type={wrapperTag === "button" ? "button" : undefined}
    >
      <div className="info">
        <p className="company-name">{companyName}</p>
        <p className="symbol">{symbol}</p>
      </div>

      <div className="price">
        <PriceOrPercent
          value={latestPrice}
          type="price"
          className="latest-price"
          useSentimentColor={false}
        />

        <PriceOrPercent
          value={changePercent * 100}
          type="percent"
          className="change-percent"
        />
      </div>
    </WrapperTag>
  );
};
