import { AlertManageStep } from "common/alert/alert-page.info";
import { ListSlider } from "components/common";
import { TypeSelectionStep } from "components/pages/Alert";
import {
  DetailAddingStep,
  SubTypeSelectionStep,
} from "components/pages/Alert/AddConditionSubStepComponents";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/AddAlertConditionSubStep.sass";

const sliderList = ["1. Select Type", "2. Select Sub-Type", "3. Add Detail"];

export const AddAlertConditionStep = () => {
  const curAddConditionStep = useStoreState(
    (state) => state.alertManage.curAddConditionStep
  );

  const { setCurAddConditionStep } = useStoreActions(
    (actions) => actions.alertManage
  );

  return (
    <div className="add-alert-condition-step">
      <ListSlider
        componentList={sliderList}
        curComponent={sliderList[curAddConditionStep]}
        useContainerPreset="preset-1"
        containerClassName="type-switch-container"
        itemWrapperClassName="item"
        activeItemWrapperClassName="item-active"
        barClassName="bar"
        onClick={(index) => {
          setCurAddConditionStep(index);
        }}
        isInline
      />

      <SwipeableViews
        index={curAddConditionStep}
        slideClassName="slide"
        onChangeIndex={(index) => {
          setCurAddConditionStep(index);
        }}
      >
        <TypeSelectionStep />
        <SubTypeSelectionStep />

        <DetailAddingStep />
      </SwipeableViews>
    </div>
  );
};

export const NeedSubscriptionStep = () => {
  return (
    <div className="need-subscription-step">
      <p className="title">Condition limit reach!</p>
      <p className="caption">Please check your subscription plan</p>
    </div>
  );
};

export const EmptyStep = () => {
  const alert = useStoreState((state) => state.alertManage.alert);
  const { setCurManageStep } = useStoreActions(
    (actions) => actions.alertManage
  );

  if (!alert) {
    return null;
  }

  const onClick = () => {
    setCurManageStep(AlertManageStep.Summary);
  };

  return (
    <div className="empty-step">
      <button disabled={alert.conditionList.length <= 0} onClick={onClick}>
        Go to Summary
      </button>
    </div>
  );
};
