import cx from "classnames";
import { DropdownContent } from "components/common";
import React, { useRef, useState } from "react";
import "styles/animation/basic.sass";
import "styles/components/common/DropdownNoForm.sass";

interface DropdownNoFormProps {
  containerClassName?: string;
  mainButtonContent: React.ReactNode;
  mainButtonClassName?: string;
  mainButtonPreset?: "preset-1";
  children: React.ReactNode;
  contentClassName?: string;
  shownByDefault?: boolean;
  onLeft?: boolean;
  closeOnClickAway?: boolean;
  usePortal?: boolean;
}
export const DropdownNoForm = ({
  containerClassName,
  mainButtonContent,
  mainButtonClassName,
  mainButtonPreset,
  children,
  contentClassName,
  shownByDefault = false,
  onLeft,
  closeOnClickAway = true,
  usePortal = true,
}: DropdownNoFormProps) => {
  const [shown, setShown] = useState(shownByDefault);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cx(
        "dropdown-no-form",
        { "dropdown-no-form-active": shown },
        containerClassName
      )}
      ref={containerRef}
    >
      <button
        className={cx(
          "main-button",
          { [`main-button-${mainButtonPreset}`]: mainButtonPreset },
          mainButtonClassName
        )}
        onClick={() => {
          setShown((prev) => !prev);
        }}
      >
        {mainButtonContent}
      </button>

      <DropdownContent
        containerRef={containerRef}
        usePortal={usePortal}
        isShown={shown}
        className={cx(
          "dropdown-no-form-content",
          { "dropdown-no-form-content-left": onLeft },
          contentClassName
        )}
        onClickAway={() => {
          if (closeOnClickAway) {
            setShown(false);
          }
        }}
      >
        {children}
      </DropdownContent>
    </div>
  );
};
