import { RelayType } from "common/alert-relay.info";
import {
  alertHardLimit,
  conditionHardLimit,
} from "common/alert/alert-common.info";
import { FunctionBackendError } from "models";
import { AlertDto } from "models/dto/alert";
import {
  FeatureKey,
  PlanMetadataFeatureDto,
} from "models/dto/payment/subscription-plan-metadata.dto";
import {
  getBoolValueFromFeatureKey,
  getNumberValueFromFeatureKey,
} from "utils/subscription.util";

export const getMaxAlert = (
  featureList: PlanMetadataFeatureDto[] | null,
  alertFeatureKey: FeatureKey
) => getNumberValueFromFeatureKey(featureList, alertFeatureKey, alertHardLimit);

export const getErrorFromConditionLimit = (
  featureList: PlanMetadataFeatureDto[] | null,
  alert: AlertDto,
  type: "create-condition" | "create-alert"
): FunctionBackendError | null => {
  const curNumConditionToCheck =
    alert.conditionList.length + (type === "create-alert" ? 0 : +1);
  // ? If we're creating condition, we're checking 1 beyond the current condition

  if (alert.type === "single" && curNumConditionToCheck > 1) {
    return {
      code: "",
      message: "internal/unknown-error",
      detailMessage: "Did you try to hack the system?",
    };
  }

  if (curNumConditionToCheck > conditionHardLimit) {
    return {
      code: "",
      message: "alert/hard-condition-limit-reach",
      detailMessage: `Woah you have discovered the hard cap of ${conditionHardLimit} for conditions!`,
    };
  }

  const maxCondition = getNumberValueFromFeatureKey(
    featureList,
    "numCondition",
    conditionHardLimit
  );
  if (curNumConditionToCheck > maxCondition) {
    return {
      code: "",
      message: "alert/num-condition-limit-reach",
      detailMessage: `You can't have more than ${maxCondition} conditions for alert!`,
    };
  }
  return null;
};

export const getErrorFromAlertLimitReach = (
  featureList: PlanMetadataFeatureDto[] | null,
  alertList: AlertDto[],
  alert: AlertDto
): FunctionBackendError | null => {
  if (alertList.length >= alertHardLimit) {
    return {
      code: "",
      message: "alert/hard-alert-limit-reach",
      detailMessage: `Woah you have discovered the hard cap of ${alertHardLimit} for alert!`,
    };
  }

  const conditionError = getErrorFromConditionLimit(
    featureList,
    alert,
    "create-alert"
  );
  if (conditionError) {
    return conditionError;
  }

  const alertFeatureKey: FeatureKey =
    alert.type === "single" ? "basicAlerts" : "chainedAlerts";
  const maxAlert = getMaxAlert(featureList, alertFeatureKey);

  const numAlertWithSameType =
    alertList.filter(
      // !== alert.alertId in case somehow the alert already there (UPDATE mostly)
      (entry) => entry.type === alert.type && entry.alertId !== alert.alertId
    ).length + 1; // +1 is for the new alert

  if (numAlertWithSameType > maxAlert) {
    return {
      code: "",
      message: "alert/num-alert-limit-reach",
      detailMessage: `Max ${alert.type} alert limit of ${maxAlert} reached!`,
    };
  }

  return null;
};

export const getErrorFromRelaySubscription = (
  featureList: PlanMetadataFeatureDto[] | null,
  relayList: RelayType[]
): FunctionBackendError | null => {
  if (
    relayList.includes("sms") &&
    !getBoolValueFromFeatureKey(featureList, "smsAlert")
  ) {
    return {
      code: "",
      message: "alert/sms-not-allowed",
      detailMessage: "SMS Notification is not include in current subscription",
    };
  }

  return null;
};
