import { stockService } from "api/service";
import { ApiReturnValue } from "common/api.info";
import { StockLoadingType } from "common/stock/stock.info";
import dayjs from "dayjs";
import {
  action,
  Action,
  computed,
  Computed,
  persist,
  thunk,
  Thunk,
} from "easy-peasy";
import { ChartData, CompanySummary, FunctionBackendError, Quote } from "models";
import { TimeRange } from "models/dto/stock.dto";
import { CompanyDetailDto } from "models/dto/stock/get-company-detail.dto";
import { GetEarningDto } from "models/dto/stock/get-earning.dto";
import { GetRatingResDto } from "models/dto/stock/get-rating.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { extractFunctionError } from "utils/error.util";

const QUOTE_INVALIDATION_TIMER = 1000;

type QuoteByCompanyMap = Partial<Record<string, Quote>>;
type QuoteCacheTimerByCompanyMap = Partial<Record<string, string>>;
type CompanyDetailMap = Record<string, CompanyDetailDto>;
export interface StockModel extends LoadingModel<StockLoadingType> {
  // * State
  companyDetailMap: CompanyDetailMap;
  companyDetail: Computed<
    StockModel,
    (ticker: string) => CompanyDetailDto | undefined
  >;

  companyInfoSearchResult: CompanySummary[];
  quoteByCompanyMap: QuoteByCompanyMap;
  quoteCacheTimerByCompanyMap: QuoteCacheTimerByCompanyMap;

  // * Actions
  setCompanyDetailMap: Action<StockModel, CompanyDetailMap>;
  overwriteQuoteByCompanyMap: Action<StockModel, QuoteByCompanyMap>;
  overwriteQuoteCacheTimerByCompanyMap: Action<
    StockModel,
    QuoteCacheTimerByCompanyMap
  >;
  setCompanyInfoSearchResult: Action<StockModel, CompanySummary[]>;

  // * Thunks
  rebuildQuoteCache: Thunk<StockModel, string[], any, StoreModel>;
  getQuoteList: Thunk<StockModel, string[], any, StoreModel, Promise<Quote[]>>;
  /**
   * Fetch company detail and save it to local storage.
   *
   * Will return error message if there is one
   * */
  getAndSaveCompanyDetail: Thunk<
    StockModel,
    string,
    any,
    StoreModel,
    Promise<CompanyDetailDto | null>
  >;
  searchStockCompany: Thunk<
    StockModel,
    string,
    any,
    any,
    Promise<CompanySummary[]>
  >;
  getRatingForTicker: Thunk<
    StockModel,
    string,
    any,
    any,
    Promise<GetRatingResDto | null>
  >;
  getEarningForTicker: Thunk<
    StockModel,
    string,
    any,
    any,
    Promise<GetEarningDto | null>
  >;
  /**
   * Get batch chart data for ticker list
   * @param: tickerList: list of ticker to fetch chart data for
   * @param: time range for that ticker
   * Note: the returned data will have chart data in the same time range
   */
  getChartDataForTickerList: Thunk<
    StockModel,
    { tickerList: string[]; timeRange: TimeRange; date?: string },
    any,
    any,
    Promise<{ chartData: ChartData; ticker: string }[]>
  >;
}

export const stock: StockModel = persist(
  {
    // * State
    companyDetailMap: {},
    companyDetail: computed(
      [(state) => state.companyDetailMap],
      (companyDetailMap) => (ticker) => companyDetailMap[ticker]
    ),

    companyInfoSearchResult: [],
    quoteByCompanyMap: {},
    quoteCacheTimerByCompanyMap: {},

    // * Actions
    setCompanyDetailMap: action((state, companyDetailMap) => {
      state.companyDetailMap = companyDetailMap;
    }),
    overwriteQuoteByCompanyMap: action((state, quoteByCompanyMap) => {
      state.quoteByCompanyMap = {
        ...state.quoteByCompanyMap,
        ...quoteByCompanyMap,
      };
    }),
    overwriteQuoteCacheTimerByCompanyMap: action(
      (state, quoteCacheTimerByCompanyMap) => {
        state.quoteCacheTimerByCompanyMap = {
          ...state.quoteCacheTimerByCompanyMap,
          ...quoteCacheTimerByCompanyMap,
        };
      }
    ),
    setCompanyInfoSearchResult: action((state, companyInfoSearchResult) => {
      // Sort by relevancy
      const newList = [...companyInfoSearchResult].sort(
        (entryA, entryB) => entryB.avgTotalVolume - entryA.avgTotalVolume
      );
      state.companyInfoSearchResult = newList;
    }),

    // * Thunks
    rebuildQuoteCache: thunk(async (actions, symbolList, { getState }) => {
      if (symbolList.length <= 0) {
        return;
      }

      // --- First try to get data from the cached
      const cachedDataMap = getState().quoteByCompanyMap;
      const cacheTimerMap = getState().quoteCacheTimerByCompanyMap;

      // --- Then invalidate if needed
      const quoteToFetch: string[] = [];
      symbolList.forEach((symbol) => {
        const shouldInvalidate =
          !cacheTimerMap[symbol] ||
          !cachedDataMap[symbol] ||
          dayjs().diff(dayjs(cacheTimerMap[symbol])) > QUOTE_INVALIDATION_TIMER;

        if (shouldInvalidate) {
          quoteToFetch.push(symbol);
        }
      });

      const newQuoteByCompanyMap: QuoteByCompanyMap = {};
      const newCacheTimerMap: QuoteCacheTimerByCompanyMap = {};
      const newQuoteList = await stockService.getBatchStockQuote(symbolList);
      for (const symbol in newQuoteList) {
        const quote = newQuoteList[symbol].quote;
        newQuoteByCompanyMap[quote.symbol as string] = quote;
        newCacheTimerMap[quote.symbol as string] = dayjs().toISOString();
      }

      // --- Reset both cache and timer
      actions.overwriteQuoteByCompanyMap(newQuoteByCompanyMap);
      actions.overwriteQuoteCacheTimerByCompanyMap(newCacheTimerMap);
    }),

    getQuoteList: thunk(async (actions, symbolList, { getState }) => {
      await actions.rebuildQuoteCache(symbolList);

      const quoteByCompanyMap = getState().quoteByCompanyMap;

      const quoteList: Quote[] = [];
      symbolList.forEach((symbol) => {
        const quote = quoteByCompanyMap[symbol];
        quote && quoteList.push(quote);
      });

      return quoteList;
    }),

    getAndSaveCompanyDetail: thunk(async (actions, ticker, { getState }) => {
      // --- The idea is since company info doesn't get update that much,
      //  we'll store it in the session cache
      let result: ApiReturnValue<CompanyDetailDto | null, FunctionBackendError>;

      // Try to get data from the cache first
      const curDetailMap = { ...getState().companyDetailMap };
      if (!curDetailMap[ticker]) {
        // Not found => fetch
        result = await executeAsync({
          funcToExecute: async () =>
            await stockService.getCompanyDetail(ticker),
          transformError: extractFunctionError,
          setIsLoading: (isLoading) => {
            actions.setIsLoading({
              loadingType: StockLoadingType.GetCompanyDetail,
              isLoading: isLoading,
            });
          },
        });

        if (result.result) {
          curDetailMap[ticker] = result.result;
        }
      } else {
        result = { error: null, result: curDetailMap[ticker] };
      }

      actions.setCompanyDetailMap(curDetailMap);
      return result.result;
    }),

    searchStockCompany: thunk(async (actions, searchTerm) => {
      if (searchTerm.length <= 0) {
        return [];
      }

      const result = await executeAsync({
        funcToExecute: async () => {
          // TODO: Add limit here maybe
          return await stockService.searchCompanyInfo(searchTerm);
        },
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: StockLoadingType.SearchingCompanyInfo,
            isLoading: isLoading,
          });
        },
      });

      actions.setCompanyInfoSearchResult(result.result || []);
      return result.result || [];
    }),

    getRatingForTicker: thunk(async (actions, ticker) => {
      const result = await executeAsync({
        funcToExecute: async () =>
          await stockService.getRatingForTicker(ticker),
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: StockLoadingType.FetchingTickerRating,
            isLoading: isLoading,
          });
        },
      });

      return result.result;
    }),

    getEarningForTicker: thunk(async (actions, ticker) => {
      const result = await executeAsync({
        funcToExecute: async () =>
          await stockService.getEarningForTicker(ticker),
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: StockLoadingType.FetchingTickerEarning,
            isLoading: isLoading,
          });
        },
      });

      return result.result;
    }),

    getChartDataForTickerList: thunk(
      async (actions, { tickerList, timeRange, date }) => {
        const { result } = await executeAsync({
          funcToExecute: async () =>
            await stockService.getBatchStockChartData(
              tickerList,
              timeRange,
              date
            ),
          transformError: extractFunctionError,
          setIsLoading: (isLoading) => {
            actions.setIsLoading({
              loadingType: StockLoadingType.FetchingChartData,
              isLoading: isLoading,
            });
          },
        });

        return result || [];
      }
    ),

    // * Plugins
    ...loadingPlugin(),
  },
  {
    storage: "sessionStorage",
    allow: ["companyDetailMap"],
  }
);
