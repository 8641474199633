import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { ChartData } from "models";
import { StockChartDto, StockQuoteDto, TimeRange } from "models/dto/stock.dto";
import {
  GetBatchChartReqDto,
  GetBatchChartResDto,
  GetBatchQuoteResDto,
} from "models/dto/stock/batch-request.dto";
import { CompanyDetailDto } from "models/dto/stock/get-company-detail.dto";
import { GetEarningDto } from "models/dto/stock/get-earning.dto";
import { GetRatingResDto } from "models/dto/stock/get-rating.dto";
import { CompanySummaryDto } from "models/dto/stock/search-company-info.dto";
import { extractFunctionError, handleCustomError } from "utils/error.util";

export const stockService = {
  getStockChartData: async (
    symbol: string,
    timeRange: TimeRange
  ): Promise<ChartData> => {
    try {
      const requestData: StockChartDto = { symbol, timeRange };
      const endpointInfo = functionEndpointList.getChartData;
      return await sendRequest<ChartData>({
        endpointInfo,
        data: requestData,
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
      return [];
    }
  },

  getBatchStockChartData: async (
    tickerList: string[],
    timeRange: TimeRange,
    date?: string
  ): Promise<GetBatchChartResDto> => {
    const endpointInfo = functionEndpointList.getBatchChartData;
    return await sendRequest<GetBatchChartResDto, GetBatchChartReqDto>({
      endpointInfo,
      data: { tickerList, timeRange, date },
    });
  },

  getBatchStockQuote: async (
    symbolList: string[]
  ): Promise<GetBatchQuoteResDto> => {
    try {
      const requestData: StockQuoteDto = { symbolList };
      const endpointInfo = functionEndpointList.getQuote;
      return await sendRequest<GetBatchQuoteResDto>({
        endpointInfo,
        data: requestData,
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
      return {};
    }
  },

  searchCompanyInfo: async (
    fragment: string,
    limit: number = 10
  ): Promise<CompanySummaryDto[]> => {
    if (fragment.length <= 0) {
      return [];
    }

    try {
      const endpointInfo = functionEndpointList.searchCompanyInfo;
      return await sendRequest<CompanySummaryDto[]>({
        endpointInfo,
        extraPath: [fragment],
        params: { limit },
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
      return [];
    }
  },

  getCompanyDetail: async (
    ticker: string
  ): Promise<CompanyDetailDto | null> => {
    try {
      const endpointInfo = functionEndpointList.getCompanyDetail;
      return await sendRequest<CompanyDetailDto>({
        endpointInfo,
        extraPath: [ticker],
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
    }

    return null;
  },

  getRatingForTicker: async (
    ticker: string
  ): Promise<GetRatingResDto | null> => {
    try {
      const endpointInfo = functionEndpointList.getRatingForTicker;
      return await sendRequest<GetRatingResDto>({
        endpointInfo,
        extraPath: [ticker],
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
    }

    return null;
  },

  getEarningForTicker: async (
    ticker: string
  ): Promise<GetEarningDto | null> => {
    try {
      const endpointInfo = functionEndpointList.getEarningForTicker;
      return await sendRequest<GetEarningDto>({
        endpointInfo,
        extraPath: [ticker],
      });
    } catch (error) {
      handleCustomError(await extractFunctionError(error));
    }

    return null;
  },
};
