import { BrokerageLoadingType } from "common/brokerage/brokerage.info";
import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/common/Plaid/PlaidLinkTrigger.sass";
import { toastCustomMessage } from "utils/ui.util";

interface Metadata {
  // There are more, but we only care about these
  institution: {
    institution_id: string;
    name: string;
  };
}
export const PlaidLink = () => {
  const isUnlinkingBrokerage = useStoreState((state) =>
    state.brokerage.isLoading(BrokerageLoadingType.UnlinkingBrokerage)
  );
  const isCreatingLinkToken = useStoreState((state) =>
    state.brokerage.isLoading(BrokerageLoadingType.CreatingPlaidLinkToken)
  );
  const isExchangingPublicToken = useStoreState((state) =>
    state.brokerage.isLoading(BrokerageLoadingType.ExchangingPlaidPublicToken)
  );
  const {
    createPlaidLinkToken: createLinkToken,
    setPlaidAccessToken: setAccessToken,
  } = useStoreActions((actions) => actions.brokerage);
  const linkToken = useStoreState((state) => state.brokerage.plaidLinkToken);

  const onSuccess = React.useCallback(
    (publicToken: string, metadata: Metadata) => {
      const { institution } = metadata;

      setAccessToken({
        publicToken: publicToken,
        institutionId: institution.institution_id,
        institutionName: institution.name,
      });
    },
    [setAccessToken]
  );

  const { open, ready, error } = usePlaidLink({
    token: linkToken || "",
    onSuccess,
  });

  useEffect(() => {
    // Generate a link token on mount
    createLinkToken();
  }, [createLinkToken]);

  useEffect(() => {
    if (error) {
      console.error(error);
      toastCustomMessage(error.message, "error");
    }
  }, [error]);

  if (!linkToken) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={() => open()}
      disabled={
        !ready ||
        isCreatingLinkToken ||
        isExchangingPublicToken ||
        isUnlinkingBrokerage
      }
      className="plaid-link-trigger"
    >
      Link Brokerage
    </button>
  );
};
