export const sentimentList = ["pos", "neg", "neu"] as const;
export type SentimentType = typeof sentimentList[number];

export const sentimentFullText: Record<SentimentType, string> = {
  pos: "Positive",
  neg: "Negative",
  neu: "Neutral",
};

export const newsMediaTypeList = ["video", "article"] as const;
export type NewsMediaType = typeof newsMediaTypeList[number];

export const newsSentimentMap = {
  positive: "Bullish",
  neutral: "Neutral",
  negative: "Bearish",
} as const;

export const newSentimentList = Object.keys(newsSentimentMap);
export type NewsSentiment = keyof typeof newsSentimentMap;

export const newsDateParamFormat = "MMDDYYYY";

export const newSourceMap = {
  "24/7 Wall Street": "24/7 Wall Street",
  Accesswire: "Accesswire",
  Barrons: "Barrons",
  Benzinga: "Benzinga",
  "Business Insider": "Business Insider",
  "Business Wire": "Business Wire",
  CNBC: "CNBC",
  "CNBC International TV": "CNBC International TV",
  "CNBC Television": "CNBC Television",
  CNET: "CNET",
  CNN: "CNN",
  "CNN Business": "CNN Business",
  Cheddar: "Cheddar Videos",
  Deadline: "Deadline",
  "Discount The Obvious": "Discount The Obvious",
  "ETF Trends": "ETF Trends",
  ETFcom: "ETF.com",
  "Fast Company": "Fast Company",
  Forbes: "Forbes",
  "Fox Business": "Fox Business",
  FreightWaves: "FreightWaves",
  GeekWire: "GeekWire",
  GlobeNewsWire: "Globe News Wire",
  GuruFocus: "GuruFocus",
  "Huffington Post": "Huffington Post",
  Investopedia: "Investopedia",
  InvestorPlace: "Investor Place",
  "Investors Business Daily": "Investors Business Daily",
  Invezz: "Invezz",
  Kiplinger: "Kiplinger",
  "Market Watch": "Market Watch",
  Morningstar: "Morningstar",
  NYTimes: "New York Times",
  "New York Post": "New York Post",
  "Newsfile Corp": "Newsfile Corp",
  "OTC PR Wire": "OTC PR Wire",
  "Proactive Investors": "Proactive Investors",
  PYMNTS: "PYMNTS.com",
  "PR Newswire": "PR Newswire",
  Pulse2: "Pulse2",
  Reuters: "Reuters",
  "Schaeffers Research": "Schaeffers Research",
  "See It Market": "See It Market",
  "Seeking Alpha": "Seeking Alpha",
  Skynews: "Skynews",
  TechCrunch: "TechCrunch",
  "The Dog of Wall Street": "The Dog of Wall Street",
  "The Guardian": "The Guardian",
  "The Motley Fool": "The Motley Fool",
  VentureBeat: "VentureBeat",
  "Zacks Investment Research": "Zacks Investment Research",
} as const;

export const newsSourceList = Object.keys(newSourceMap);
export type NewsSource = typeof newSourceMap[keyof typeof newSourceMap];

export enum NewsLoadingType {
  FetchNews,
}
