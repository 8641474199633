import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { DeleteImageDto } from "models/dto/media/delete-image.dto";

export const mediaService = {
  uploadImage: async (imageList: File[]) => {
    const endpointInfo = functionEndpointList.uploadImage;

    const formData = new FormData();
    imageList.forEach((image, index) => {
      formData.append(`image-${index}`, image);
    });

    return await sendRequest<string[]>({
      endpointInfo,
      useTokenInHeaders: true,
      data: formData,
      customHeader: { "Content-Type": "multipart/form-data" },
    });
  },

  deleteImageFromUrlList: async (urlList: string[]) => {
    const endpointInfo = functionEndpointList.deleteImageFromUrlList;
    return await sendRequest<boolean, DeleteImageDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: { urlList },
    });
  },
};
