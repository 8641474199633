import { useThemeColor } from "hooks/useThemeColor";
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

export interface LoadingSpinnerProps {
  size?: number;
}

export const LoadingSpinner = ({ size = 15 }: LoadingSpinnerProps) => {
  const primaryColor = useThemeColor("sw-blue");

  return <BeatLoader color={primaryColor} size={size} />;
};
