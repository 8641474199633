import {
  TransactionType,
  transactionTypeList,
} from "common/transaction/transaction.info";
import { FormDropDown } from "components/common";
import {
  getOptionTransactionFromFormData,
  OptionTransactionComponent,
  OptionTransactionFormField,
} from "components/pages/Journal/Manage/OptionTransactionComponent";
import {
  getStockTransactionFromFormData,
  StockTransactionComponent,
  StockTransactionFormField,
} from "components/pages/Journal/Manage/StockTransactionComponent";
import lodash from "lodash";
import { Transaction } from "models/schema/transaction.model";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMedia } from "react-use";
import "styles/components/common/page-component.common.sass";
import "styles/components/pages/Journal/Manage/TransactionInputComponent.sass";
import { getMediaBreakpoint } from "utils/common.utils";

const componentMap: Record<TransactionType, React.ReactNode> = {
  option: <OptionTransactionComponent />,
  stock: <StockTransactionComponent />,
};

interface TransactionInputComponentProps {
  buttonText: string;
  addTransaction: (param: {
    type: TransactionType;
    transaction: Transaction;
  }) => void;
}
export const TransactionInputComponent = ({
  buttonText,
  addTransaction,
}: TransactionInputComponentProps) => {
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const [transactionType, setTransactionType] = useState<TransactionType>(
    "stock"
  );

  const formMethod = useForm();
  const handleSubmit = formMethod.handleSubmit((data) => {
    switch (transactionType) {
      case "option":
        addTransaction({
          type: transactionType,
          transaction: getOptionTransactionFromFormData(
            data as OptionTransactionFormField
          ),
        });
        break;
      case "stock":
        addTransaction({
          type: transactionType,
          transaction: getStockTransactionFromFormData(
            data as StockTransactionFormField
          ),
        });
        break;
    }
  });

  return (
    <FormProvider {...formMethod}>
      <form
        className="page-component-common transaction-input-component"
        onSubmit={handleSubmit}
      >
        {isLg && <p className="component-title">Manually Input Transaction</p>}

        <FormDropDown
          label="Transaction Type"
          className="type-selector"
          defaultValue={transactionType}
          dataList={transactionTypeList.map((type) => ({
            label: lodash.capitalize(type),
            value: type,
          }))}
          onChange={(value) => {
            setTransactionType(value);
          }}
        />

        {componentMap[transactionType]}

        <button className="submit-button">{buttonText}</button>
      </form>
    </FormProvider>
  );
};
