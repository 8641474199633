import { routePathMap } from "common/routeList";

export const STOCK_PAGE_BASE_TICKER = "SPY";

export enum StockPageLoadingType {
  FetchNews,
  FetchCompanyDetail,
  FetchCompanyQuote,
}

export enum StockPageView {
  MainView,
  AlertManageView,
  CompanySearchView,
}

export const ROUTE_MATCH = `${routePathMap.stock}/:ticker`;
