import { sendRequest } from "api/utils/common.util";
import { messageEndpointMap } from "api/utils/function.util";
import { FirebaseCollection } from "common/firebase.info";
import { MessageType } from "common/notification.info";
import firebase from "firebase/app";
import { MessageDto } from "models/dto/message/message.dto";
import { getFirebaseUser } from "utils/firebase.util";

const subcollectionMap: Record<MessageType, string> = {
  general: "messageGeneralList",
  media: "messageMediaList",
} as const;

export const messageService = {
  setupSnapshotListener: (
    type: MessageType,
    onNewSnapshot: (messageList: MessageDto[]) => void
  ) => {
    const db = firebase.firestore();
    const userId = getFirebaseUser()?.uid;

    const subcollection = subcollectionMap[type];

    if (userId) {
      return db
        .collection(`${FirebaseCollection.Message}/${userId}/${subcollection}`)
        .onSnapshot((doc) => {
          onNewSnapshot(doc.docs.map((entry) => entry.data() as MessageDto));
        });
    }

    return null;
  },

  markAsAllOfTypeRead: async (type: MessageType) => {
    const endpointInfo = messageEndpointMap.markAllOfTypeAsRead;
    await sendRequest({
      endpointInfo,
      useTokenInHeaders: true,
      extraPath: [type],
    });
  },
};
