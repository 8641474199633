export const supportedBrokerage = [
  "robinhood",
  "tdAmeritrade",
  "fidelity",
] as const;
export type SupportedBrokerage = typeof supportedBrokerage[number];

// ! Make sure this match the above typo
export const activeBrokerage = ["tdAmeritrade"] as const;
export type ActiveCSVBrokerage = typeof activeBrokerage[number];

export const supportedBrokerageDisplayMap: Record<
  SupportedBrokerage,
  { name: string; imgUrl: string }
> = {
  tdAmeritrade: {
    name: "TD Ameritrade",
    imgUrl:
      "https://upload.wikimedia.org/wikipedia/commons/a/a4/Toronto-Dominion_Bank_logo.svg",
  },
  robinhood: {
    name: "Robinhood",
    imgUrl:
      "https://cdn.dribbble.com/users/161397/screenshots/1459753/robinhood_glyph.jpg?compress=1&resize=400x300",
  },
  fidelity: {
    name: "Fidelity",
    imgUrl:
      "https://images.ctfassets.net/rh81el08iwni/2JKs32RNheUxjxfFj68V4Z/050ab21318c5e7b3e6ec59c2c4b7aeed/fidelity.png?fl=progressive",
  },
};
