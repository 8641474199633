import { LoadingSpinner } from "components/common/LoadingSpinner";
import { SubscriptionCardsView } from "components/common/SubscriptionCardsView";
import { SubscriptionTableView } from "components/common/SubscriptionTableView";
import React, { useEffect } from "react";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import { PaymentSettingsLoading } from "stores/payment";
import "styles/components/pages/Setting/SubscriptionView.sass";
import { getMediaBreakpoint } from "utils/common.utils";

export const SubscriptionView = () => {
  const isLoadingMap = useStoreState(
    (state) => state.paymentSettings.isLoadingMap
  );

  const { fetchAllPlans, fetchAllBaseFeatures } = useStoreActions(
    (actions) => actions.paymentSettings
  );

  const isMd = useMedia(getMediaBreakpoint("md"));
  const isLg = useMedia(getMediaBreakpoint("lg"));
  const isXl = useMedia(getMediaBreakpoint("xl"));

  useEffect(() => {
    fetchAllPlans();
  }, [fetchAllPlans]);

  useEffect(() => {
    fetchAllBaseFeatures();
  }, [fetchAllBaseFeatures]);

  const isLoadingBaseFeatureList =
    isLoadingMap[PaymentSettingsLoading.baseFeatureList];
  const isLoadingPlanList = isLoadingMap[PaymentSettingsLoading.planList];

  const isPageLoading = (): boolean => {
    return isLoadingBaseFeatureList === true || isLoadingPlanList === true;
  };

  if (isPageLoading() === true) {
    return (
      <div className="loading-container">
        <LoadingSpinner />
      </div>
    );
  }

  if (isXl) {
    return <SubscriptionTableView />;
  }
  if (isLg) {
    return <SubscriptionCardsView />;
  }
  if (isMd) {
    return <SubscriptionTableView />;
  }

  return <SubscriptionCardsView />;
};
