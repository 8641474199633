import { MyAccount } from "models/schema/Account.model";
import { SubscriptionPlanDto } from "../payment/subscription-plan.dto";
import { AccountDataInfoDto } from "./account-data-info.dto";
import firebase from "firebase/app";

export class AccountDataDto {
  info: AccountDataInfoDto;
  currentPlan: SubscriptionPlanDto;

  static toSchema(
    accountDataDto: AccountDataDto,
    firebaseUser: firebase.User
  ): MyAccount {
    return {
      accountInfo: AccountDataInfoDto.toSchema(accountDataDto.info),
      accountFirebaseInfo: firebaseUser,
      currentPlan: accountDataDto.currentPlan,
    };
  }
}
