import { Action, action, computed, Computed } from "easy-peasy";

export interface LoadingModel<T extends string | number> {
  // * State
  isLoadingMap: Partial<Record<string | number, boolean>>;
  isLoading: Computed<LoadingModel<T>, (arg: T) => boolean>;

  // * Actions
  setIsLoading: Action<
    LoadingModel<T>,
    {
      loadingType: T;
      isLoading: boolean;
    }
  >;
}

export function loadingPlugin<T extends string | number>(): LoadingModel<T> {
  return {
    isLoadingMap: {},
    isLoading: computed(
      [(state) => state.isLoadingMap],
      (isLoadingMap) => (loadingType) =>
        (isLoadingMap[loadingType] as boolean | undefined) || false
    ),

    setIsLoading: action((state, { loadingType, isLoading }) => {
      state.isLoadingMap[loadingType] = isLoading;
    }),
  };
}
