import { AlertDashboardLoadingType } from "common/alert/alert-page.info";
import {
  CardShimmer,
  DashboardMainCTA,
  ListSlider,
  ShimmerContainer,
} from "components/common";
import { CompanyAlertSummaryCard } from "components/pages/Alert/CompanyAlertSummaryCard";
import { AlertDto } from "models/dto/alert";
import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useMedia } from "react-use";
import { useStoreActions, useStoreState } from "stores";
import "styles/views/AlertDashboard/AllAlertView.sass";
import { getMediaBreakpoint } from "utils/common.utils";

const cardDisplayTypeList = ["summary", "detail"] as const;
type CardDisplayType = typeof cardDisplayTypeList[number];

export const AllAlertView = () => {
  const isLg = useMedia(getMediaBreakpoint("lg"));

  const [cardDisplayType, setCardDisplayType] = useState<CardDisplayType>(
    "detail"
  );

  const alertByCompanyList = useStoreState(
    (state) => state.alertDashboard.alertByCompanyList
  );
  const isTogglingAlert = useStoreState((state) =>
    state.alertDashboard.isLoading(AlertDashboardLoadingType.ToggleAlert)
  );
  const isLoadingAlert = useStoreState((state) =>
    state.alertDashboard.isLoading(AlertDashboardLoadingType.LoadingAlert)
  );

  const { toggleAlertList, deleteAlertList } = useStoreActions(
    (actions) => actions.alertDashboard
  );

  const { initializeStore } = useStoreActions((actions) => actions.alertManage);

  const addNewAlert = () => {
    initializeStore(null);
  };

  const onAlertToggle = (alert: AlertDto) => {
    toggleAlertList({
      alertIdList: [alert.alertId],
      curActive: alert.isActive,
    });
  };

  const onAlertDelete = (alert: AlertDto) => {
    deleteAlertList([alert.alertId]);
  };

  const onAlertEditClick = (alert: AlertDto) => {
    initializeStore(alert);
  };

  const Container = isLg ? PerfectScrollbar : "div";
  return (
    <Container className="all-alert-view">
      <div className="title">
        <h3>{`All Alerts (${alertByCompanyList.length})`}</h3>

        <ListSlider<CardDisplayType>
          componentList={(cardDisplayTypeList as unknown) as CardDisplayType[]}
          curComponent={cardDisplayType}
          useContainerPreset="preset-1"
          containerClassName="type-switch-container"
          itemWrapperClassName="item"
          activeItemWrapperClassName="item-active"
          barClassName="bar"
          onClick={(_, item) => {
            setCardDisplayType(item);
          }}
          isInline
        />
      </div>

      <div className="alert-container">
        <ShimmerContainer
          shimmerComponent={<CardShimmer />}
          isReady={!isLoadingAlert && !isTogglingAlert}
          numShimmer={4}
        >
          {alertByCompanyList.map((entry, index) => {
            if (entry) {
              let isActive = false;
              for (const alert of entry.alertList) {
                if (alert.isActive) {
                  isActive = true;
                  break;
                }
              }

              const alertIdList = entry.alertList.map((alert) => alert.alertId);
              const onToggle = (curActive: boolean) => {
                toggleAlertList({ alertIdList, curActive });
              };

              return (
                <CompanyAlertSummaryCard
                  key={index}
                  alertList={entry.alertList}
                  companySummary={entry.companySummary}
                  isActive={isActive}
                  type="flat"
                  onToggle={onToggle}
                  cardDisplayType={cardDisplayType}
                  onAlertToggle={onAlertToggle}
                  onAlertEditClick={onAlertEditClick}
                  onAlertDelete={onAlertDelete}
                />
              );
            }

            return null;
          })}
        </ShimmerContainer>
      </div>

      {!isLg && (
        <DashboardMainCTA
          icon={["cis", "add-alert"]}
          onClick={addNewAlert}
          text="Add Alert"
        />
      )}
    </Container>
  );
};
