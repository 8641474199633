import { Theme } from "common/theme.info";
import { Action, action, persist } from "easy-peasy";

export interface ThemeModel {
  // * State -------------------------
  theme: Theme;

  // * Actions -----------------------
  resetStore: Action<ThemeModel>;
  toggleTheme: Action<ThemeModel>;
  initializeTheme: Action<ThemeModel>;
  changeTheme: Action<ThemeModel, Theme>;
}

export const theme: ThemeModel = persist(
  {
    theme: "light",

    // Actions
    resetStore: action((state) => {
      state.theme = "light";
    }),
    toggleTheme: action((state) => {
      document.body.classList.remove(state.theme);
      state.theme = state.theme === "light" ? "dark" : "light";
      document.body.classList.add(state.theme);
    }),

    initializeTheme: action((state) => {
      document.body.classList.add(state.theme);
    }),

    changeTheme: action((state, newTheme) => {
      document.body.classList.remove(state.theme);
      state.theme = newTheme;
      document.body.classList.add(state.theme);
    }),
  },
  { storage: "localStorage" }
);

export default theme;
