import { AlertConditionType } from "common/alert/alert-condition.info";
import {
  AlertManageErrorMapKey,
  AlertManageType,
  AlertSubType
} from "common/alert/alert-manage.info";
import {
  AlertAddConditionStep,
  AlertAddConditionView,
  AlertManageLoadingType,
  AlertManageStep
} from "common/alert/alert-page.info";
import { computed, Computed } from "easy-peasy";
import lodash from "lodash";
import { CompanySummary, FunctionErrorMessage } from "models";
import { AlertConditionModel, AlertDto } from "models/dto/alert";
import {
  alertManageAction,
  AlertManageAction
} from "stores/alert/alert-manage.action";
import {
  AlertManageThunk,
  alertManageThunk
} from "stores/alert/alert-manage.thunk";
import { LoadingModel, loadingPlugin } from "stores/plugin";

export interface AlertManageModel
  extends AlertManageThunk,
    AlertManageAction,
    LoadingModel<AlertManageLoadingType> {
  alertManageType: AlertManageType;
  targetCompanySummary: CompanySummary | null;

  originalAlert: AlertDto | null;
  alert: AlertDto | null;
  isUnchangedObjectWise: Computed<AlertManageModel, boolean>;

  curEditingCondition: AlertConditionModel | null;
  curConditionType: AlertConditionType | null;
  curConditionSubType: AlertSubType | null;

  // --- UI
  curManageStep: AlertManageStep;
  curAddConditionStep: AlertAddConditionStep;
  curAddConditionView: AlertAddConditionView;

  errorMap: Partial<
    Record<
      AlertManageErrorMapKey,
      { message?: FunctionErrorMessage; detailMessage: string | null }
    >
  >;
}

export const alertManage: AlertManageModel = {
  alertManageType: AlertManageType.None,
  targetCompanySummary: null,

  originalAlert: null,
  alert: null,
  isUnchangedObjectWise: computed(
    [(state) => state.alert, (state) => state.originalAlert],
    (alert, originalAlert) => lodash.isEqual(alert, originalAlert)
  ),

  // --- Condition
  curEditingCondition: null,
  curConditionSubType: null,
  curConditionType: null,

  // --- UI
  curManageStep: AlertManageStep.CompanySearch,
  curAddConditionStep: AlertAddConditionStep.TypeSelection,
  curAddConditionView: AlertAddConditionView.Empty,
  errorMap: {},

  ...alertManageAction,
  ...alertManageThunk,
  ...loadingPlugin(),
};
