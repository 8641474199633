export const timelineType = [
  "all",
  "ytd",
  "year",
  "month",
  "week",
  "day",
] as const;
export type TimelineType = typeof timelineType[number];

export interface TimeSelector {
  type: TimelineType;
  count?: number;
  displayText: string;
  withInterval?: boolean;
}
export const chartTimeSelectorList: TimeSelector[] = [
  {
    type: "day",
    count: 1,
    displayText: "1D",
  },
  {
    type: "day",
    count: 5,
    displayText: "1W",
    withInterval: true,
  },
  {
    type: "month",
    count: 1,
    displayText: "1M",
    withInterval: true,
  },
  {
    type: "month",
    count: 3,
    displayText: "3M",
  },
  {
    type: "year",
    count: 1,
    displayText: "1Y",
  },
  {
    type: "year",
    count: 5,
    displayText: "5Y",
  },
];
