import { CURRENCY_DECIMAL, CURRENCY_SIGN } from "common/common";
import { stockTransactionFormName } from "common/journal/journal-manage.info";
import {
  TransactionTradeType,
  transactionTradeTypeList,
} from "common/transaction/transaction.info";
import { FormDatePicker, FormInput, SwitchSlider } from "components/common";
import { StockTransactionModel } from "models/schema/transaction.model";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";
import {
  calculateStockCost,
  getMoneyFromCurrencyText,
} from "utils/common.utils";
import { createTransactionId } from "utils/transaction.util";
import { v4 as uuidv4 } from "uuid";
import { SearchCompanyView } from "views";

export interface StockTransactionFormField {
  [stockTransactionFormName.ticker]: string;
  [stockTransactionFormName.tradeType]: TransactionTradeType;
  [stockTransactionFormName.quantity]: string;
  [stockTransactionFormName.price]: string;
  [stockTransactionFormName.transactionDate]: Date;
}

const priceInputMask: NumberFormatProps = {
  decimalScale: CURRENCY_DECIMAL,
  fixedDecimalScale: true,
  allowNegative: false,
  prefix: CURRENCY_SIGN,
  isAllowed: (value) => {
    if (isNaN(value.floatValue || 0) || value.floatValue == null) {
      return false;
    }

    return true;
  },
};

export const getStockTransactionFromFormData = (
  data: StockTransactionFormField
): StockTransactionModel => ({
  id: createTransactionId(uuidv4(), "manual", "unknown"),
  price: getMoneyFromCurrencyText(data.price),
  quantity: parseInt(data.quantity),
  transactionDate: data.transactionDate.toISOString(),
  ticker: data.ticker,
  tradeType: data.tradeType,
  type: "stock",
});

const defaultTradeType: TransactionTradeType = "buy";

export const StockTransactionComponent = () => {
  const {
    register,
    errors,
    control,
    setValue,
  } = useFormContext<StockTransactionFormField>();

  useEffect(() => {
    setValue(stockTransactionFormName.tradeType, defaultTradeType);
  }, [setValue]);

  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [transactionDate, setTransactionDate] = useState(new Date());

  return (
    <div className="stock-transaction-component">
      {/* Empty input to register trade and option type */}
      <input
        className="_hidden"
        readOnly={true}
        tabIndex={-1}
        name={stockTransactionFormName.tradeType}
        ref={register}
      />

      <SwitchSlider
        switchList={transactionTradeTypeList}
        defaultIndex={transactionTradeTypeList.findIndex(
          (type) => type === defaultTradeType
        )}
        onClick={(type) => {
          setValue(stockTransactionFormName.tradeType, type);
        }}
      />

      <SearchCompanyView
        className="ticker"
        formOption={{
          label: "Stock/Ticker",
          borderType: "normal",
          formValidator: {
            inputRef: register({ required: "Can't be empty" }),
            name: stockTransactionFormName.ticker,
            errorText: errors[stockTransactionFormName.ticker]?.message,
          },
        }}
        onlyShowResultOnFocus
        resultPreset="dropdown"
        onCompanyNameClick={(_, _1, summary) => {
          setPrice(summary.latestPrice);
        }}
      />

      <FormInput
        label="Price"
        min={0}
        formValidator={{
          inputRef: register({
            required: "Can't be empty",
            validate: (value) =>
              getMoneyFromCurrencyText(value) > 0.001 ||
              "Must be greater than 0",
          }),
          name: stockTransactionFormName.price,
          errorText: errors[stockTransactionFormName.price]?.message,
        }}
        className="price"
        maskProp={priceInputMask}
        value={price}
        onChange={(newPrice) => {
          setPrice(getMoneyFromCurrencyText(newPrice));
        }}
      />

      <FormInput
        label="Quantity"
        min={0}
        rightCTA="shares"
        type="number"
        formValidator={{
          inputRef: register({
            required: "Can't be empty",
            validate: (value) => value > 0.001 || "Must be greater than 0",
          }),
          name: stockTransactionFormName.quantity,
          errorText: errors[stockTransactionFormName.quantity]?.message,
        }}
        className="quantity"
        defaultValue={quantity}
        step="any"
        onChange={(newQuantity) => {
          setQuantity(parseInt(newQuantity || 0));
        }}
      />

      <FormDatePicker
        label="Date Filled"
        datePickerProps={{ showTimeSelect: true, dateFormat: "Pp" }}
        value={transactionDate}
        formValidator={{
          name: stockTransactionFormName.transactionDate,
          control,
          inputRef: register,
        }}
        className="transaction-date"
        popperPlacement="top-end"
        onChange={(date) => {
          setTransactionDate(date as Date);
        }}
      />

      <FormInput
        label="Total Cost"
        readOnly={true}
        className="total-cost"
        value={`$${calculateStockCost(price, quantity)}`}
      />
    </div>
  );
};
