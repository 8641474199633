import { library } from "@fortawesome/fontawesome-svg-core";
import * as fal from "@fortawesome/pro-light-svg-icons";
import * as far from "@fortawesome/pro-regular-svg-icons";
// import * as fab from "@fortawesome/free-brands-svg-icons";
import * as fas from "@fortawesome/pro-solid-svg-icons";
import * as customIconSet from "assets/icons/customIconSet";

export const initializeIconList = () => {
  // Family name : "cio" | "cis"; -> outline | solid
  const extractCustomIcon = Object.values(customIconSet);

  const iconList = [
    // * General
    fas.faAlignLeft,
    far.faArrowAltCircleDown,
    fas.faChevronLeft,
    fas.faChevronRight,
    fas.faChevronUp,
    fas.faChevronDown,
    fas.faCheck,
    fas.faExclamationCircle,
    fas.faCircle,
    fas.faPen,
    fas.faPlus,
    fas.faTimes,
    far.faTimesCircle,
    far.faCalendar,
    fas.faSearch,
    fas.faUserCircle,
    fas.faUpload,
    fas.faCaretUp,
    fas.faCaretDown,
    fas.faMinus,
    fas.faFileMedical,
    fas.faEye,
    fas.faEyeSlash,
    fas.faTrash,
    fas.faShare,
    fas.faFilter,
    fas.faEllipsisV,
    fas.faEllipsisH,
    fas.faNewspaper,
    fas.faCommentAlt,
    far.faFile,
    fas.faCircle,
    fal.faInfoCircle,
    fal.faObjectGroup,
    fal.faPlusCircle,
    far.faGripLines,
    fas.faDiamond,
    fas.faEnvelopeOpenDollar,
    fas.faFileExcel,
    far.faPlus,

    // * Setting
    fas.faSignOutAlt,
    fas.faCog,
    fas.faSunCloud,
    fas.faMoonStars,
    fas.faHeart,
    fas.faRedo,
    fas.faPhone,
    fas.faUser,
    fas.faCheckCircle,

    // * Routes
    fas.faHome,
    fas.faChartLine,
    fas.faEdit,
    fas.faBell,
    fas.faExclamationCircle,

    // * Examples
    fas.faDog,
    fas.faCat,
    fas.faHotdog,

    // * Alert
    fas.faDollarSign,
    fas.faPercent,
    fas.faChevronCircleUp,
    fas.faChevronCircleDown,
    fas.faLink,
    fas.faVolumeUp,
    fas.faSquareRootAlt,
    fas.faStar,
    fas.faAnalytics,

    // * Relay
    fas.faEnvelope,
    fas.faSms,

    // * Message
    far.faMegaphone,
    far.faGraduationCap,

    // * Tien's icon
    ...extractCustomIcon,
  ];

  // Set up icon libraries
  // @ts-ignore
  library.add(...iconList);
};
