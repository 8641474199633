import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { AlertDto, CreateAlertDto, DeleteAlertDto } from "models/dto/alert";
import { ToggleAlertListDto } from "models/dto/alert/alert-toggle.dto";
import { CheckConditionDto } from "models/dto/alert/condition-check.dto";

export const alertService = {
  getAlertList: async () => {
    const endpointInfo = functionEndpointList.getAlertList;
    const response = await sendRequest<AlertDto[]>({
      endpointInfo,
      useTokenInHeaders: true,
    });

    return response;
  },

  createAlert: async (alert: CreateAlertDto) => {
    const endpointInfo = functionEndpointList.createAlert;
    const response = await sendRequest<AlertDto[], CreateAlertDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: alert,
    });

    return response;
  },

  updateAlert: async (alert: AlertDto) => {
    const endpointInfo = functionEndpointList.updateAlert;
    const response = await sendRequest<AlertDto[], AlertDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: alert,
    });

    return response;
  },

  deleteAlertList: async (alertIdList: string[]) => {
    const endpointInfo = functionEndpointList.deleteAlertList;
    const response = await sendRequest<AlertDto[], DeleteAlertDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: { alertIdList },
    });

    return response;
  },

  toggleAlertList: async (alertIdList: string[], isActive: boolean) => {
    const endpointInfo = functionEndpointList.toggleAlertList;
    const response = await sendRequest<AlertDto[], ToggleAlertListDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: { alertIdList, isActive },
    });

    return response;
  },

  checkingCondition: async (checkConditionDto: CheckConditionDto) => {
    const endpointInfo = functionEndpointList.checkCondition;
    const response = await sendRequest<boolean, CheckConditionDto>({
      endpointInfo,
      useTokenInHeaders: true,
      data: checkConditionDto,
    });

    return response;
  },
};
