import { AlertType } from "common/alert/alert-common.info";
import { AlertDto } from "models/dto/alert";
import { v4 as uuidv4 } from "uuid";

export const generateNewAlert = (type?: AlertType): AlertDto => ({
  alertId: uuidv4(),
  type: type || "single",

  isActive: true,
  ticker: "",
  conditionList: [],
  relayList: [],
  createDate: new Date().toISOString(),
});
