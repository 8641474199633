import cx from "classnames";
import { FormChoice, PriceOrPercent } from "components/common";
import { AlertSummaryCard } from "components/pages/Alert/AlertSummaryCard";
import { CompanySummary } from "models";
import { AlertDto } from "models/dto/alert";
import React from "react";
import FadeIn from "react-fade-in";
import "styles/components/pages/Alert/CompanyAlertSummaryCard.sass";

interface CompanyAlertSummaryCardProps {
  companySummary: CompanySummary;
  onToggle?: (newState: boolean, symbol: string) => void;
  alertList: AlertDto[];
  isActive: boolean;
  type?: "card" | "flat";
  cardDisplayType?: "summary" | "detail";

  onAlertToggle?: (alert: AlertDto) => void;
  onAlertEditClick?: (alert: AlertDto) => void;
  onAlertDelete?: (alert: AlertDto) => void;
}
export const CompanyAlertSummaryCard = ({
  companySummary,
  onToggle,
  alertList,
  isActive,
  type = "flat",
  cardDisplayType,

  onAlertEditClick,
  onAlertToggle,
  onAlertDelete,
}: CompanyAlertSummaryCardProps) => {
  return (
    <div
      className={cx(
        "company-alert-summary-card",
        { "company-alert-summary-card-active": isActive },
        { "company-alert-summary-card-card": type === "card" },
        { "company-alert-summary-card-flat": type === "flat" }
      )}
    >
      <div className="company-summary">
        <div>
          <p className="symbol">{companySummary.symbol}</p>
          <PriceOrPercent
            value={companySummary.latestPrice}
            type="price"
            className="price"
          />
        </div>

        <div>
          <h4 className="name">{companySummary.companyName}</h4>
          <PriceOrPercent
            value={companySummary.changePercent * 100}
            type="percent"
            className="percent"
          />
        </div>
      </div>

      <div className="content">
        <div className="num-alert-container">
          {alertList.length > 0 && <p>Alerts: {alertList.length}</p>}
        </div>

        {onToggle && (
          <div className="toggle-container">
            <FormChoice
              type="toggle"
              value={isActive}
              onChange={(newState) => {
                onToggle(!newState, companySummary.symbol);
              }}
            />
          </div>
        )}
      </div>

      {cardDisplayType === "detail" && (
        <FadeIn className="alert-container">
          {alertList.map((alert) => (
            <AlertSummaryCard
              alert={alert}
              key={alert.alertId}
              onEditClick={onAlertEditClick}
              onToggle={onAlertToggle}
              onDelete={onAlertDelete}
            />
          ))}
        </FadeIn>
      )}
    </div>
  );
};
