import { sendRequest } from "api/utils/common.util";
import functionEndpointList from "api/utils/function.util";
import { NewsReqDto, NewsResDto } from "models/dto/news.dto";
import { NewsFilterCriteria } from "models/schema/news.schema";
import { extractFunctionError } from "utils/error.util";

export const newsService = {
  getNewsList: async (tickerList: string[], criteria?: NewsFilterCriteria) => {
    try {
      const requestData: NewsReqDto = { tickerList, limit: 10, ...criteria };
      const endpointInfo = functionEndpointList.getNews;
      return await sendRequest<NewsResDto>({
        endpointInfo,
        data: requestData,
        useTokenInHeaders: true,
      });
    } catch (error) {
      throw await extractFunctionError(error);
    }
  },
};
