import { COMMON_DECIMAL, SHARE_PER_CONTRACT } from "common/common";
import { otherDateFormat } from "common/locale/dateLocale";
import { FormChoice } from "components/common/Forms";
import Icon from "components/common/Icon";
import dayjs from "dayjs";
import { useThemeColor } from "hooks/useThemeColor";
import lodash from "lodash";
import {
  OptionTransactionModel,
  Transaction,
} from "models/schema/transaction.model";
import React from "react";
import "styles/components/common/TransactionSummary.sass";
import { formatCurrency } from "utils/common.utils";

const renderOptionHeader = ({
  strikePrice,
  optionType,
  expirationDate,
}: OptionTransactionModel) =>
  `Exp ${dayjs(expirationDate).format(otherDateFormat[2])} ${formatCurrency(
    strikePrice
  )} ${lodash.capitalize(optionType)}`;

const renderTransactionTotalPrice = (transaction: Transaction) => {
  let value: number;

  switch (transaction.type) {
    case "option":
      value =
        transaction.premiumPrice * transaction.contract * SHARE_PER_CONTRACT;
      break;
    case "stock":
      value = transaction.price * transaction.quantity;
      break;
  }

  return formatCurrency(value);
};

const renderTransactionDetail = (transaction: Transaction) => {
  switch (transaction.type) {
    case "option":
      return `${formatCurrency(
        transaction.premiumPrice
      )} x ${transaction.contract.toFixed(0)} contract`;
    case "stock":
      return `${formatCurrency(
        transaction.price
      )} x ${transaction.quantity.toFixed(COMMON_DECIMAL)} shares`;
  }
};

interface TransactionSummaryProps {
  transaction: Transaction;
  isSelected?: boolean;
  onDeleteClick?: (transaction: Transaction) => void;
  onSelectClick?: (isChecked: boolean, transaction: Transaction) => void;
}
export const TransactionSummary = ({
  transaction,
  isSelected,
  ...props
}: TransactionSummaryProps) => {
  const iconColor = useThemeColor(
    transaction.tradeType === "buy" ? "success" : "warning"
  );

  const onDeleteClick = () => {
    props.onDeleteClick?.(transaction);
  };
  const onSelectClick = (isChecked: boolean) => {
    props.onSelectClick?.(isChecked, transaction);
  };

  return (
    <div className="transaction-summary">
      <div className="content">
        <div className="title">
          <div className="left">
            <Icon
              icon={["cio", transaction.tradeType]}
              style={{ color: iconColor }}
              className="icon fa-fw"
            />

            <div className="summary">
              <div className="basic">
                <div className="info">
                  <p className="trade-type">{transaction.tradeType}</p>
                  <p className="ticker">{transaction.ticker}</p>
                </div>

                <div className="price">
                  {renderTransactionTotalPrice(transaction)}
                </div>
              </div>

              {transaction.type === "option" && (
                <p className="option">{renderOptionHeader(transaction)}</p>
              )}
            </div>
          </div>
        </div>

        <div className="info">
          <div className="left">
            {/* // TODO Add logo here */}
            <div className="logo-container">{/* LOG */}</div>

            <p>
              {dayjs(transaction.transactionDate).format(otherDateFormat[3])}
            </p>
          </div>

          <p>{renderTransactionDetail(transaction)}</p>
        </div>
      </div>

      <div className="cta-container">
        {props.onDeleteClick && (
          <button onClick={onDeleteClick}>
            <Icon icon={["fas", "trash"]} className="fa-fw" />
          </button>
        )}

        {props.onSelectClick && (
          <FormChoice
            type="checkbox"
            onChange={onSelectClick}
            value={isSelected}
          />
        )}
      </div>
    </div>
  );
};
