import {
  AddAlertConditionStep,
  AlertConditionSummary,
  EmptyStep,
  NeedSubscriptionStep,
} from "components/pages/Alert";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { useStoreState } from "stores";

export const AddConditionStep = () => {
  const curAddConditionView = useStoreState(
    (state) => state.alertManage.curAddConditionView
  );

  return (
    <div className="add-condition-view">
      <AlertConditionSummary />

      <SwipeableViews disabled={true} index={curAddConditionView}>
        <EmptyStep />

        <AddAlertConditionStep />
        <NeedSubscriptionStep />
      </SwipeableViews>
    </div>
  );
};
