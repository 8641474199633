import { internalErrorMessage } from "common/standardMessage";
import { TimeSelector } from "common/stock/stock-graph.info";
import { TimeRange } from "models/dto/stock.dto";

export const getDataRangeFromTimeSelector = (
  timeSelector: TimeSelector
): TimeRange => {
  const { type, count, withInterval } = timeSelector;

  switch (type) {
    case "all":
      return "max";
    case "ytd":
      return "ytd";
    case "year":
    case "month":
    case "day":
      if (count == null) {
        throw new Error(internalErrorMessage.unitCountNotFound);
      }
      return `${count}${type[0]}${withInterval ? "m" : ""}` as TimeRange;
    default:
      throw new Error(internalErrorMessage.timeTypeNotFound);
  }
};
