import {
  JournalManageModalState,
  JournalManageType,
} from "common/journal/journal-manage.info";
import { Computed, computed } from "easy-peasy";
import lodash from "lodash";
import { JournalDto } from "models/dto/journal/journal.dto";
import { Transaction } from "models/schema/transaction.model";
import { JournalManageModel } from "stores/journal";

export interface JournalManageState {
  // * State
  originalEntry: JournalDto | null;
  entry: JournalDto | null;
  entryImageSrcList: string[];
  manageType: JournalManageType;

  draft: JournalDto | null; // Used to store for new entry
  draftImageSrcList: string[];

  tickerList: Computed<JournalManageModel, string[]>;
  transactionList: Computed<JournalManageModel, Transaction[]>;
  alertIdList: Computed<JournalManageModel, string[]>;

  isUnchangedObjectWise: Computed<JournalManageModel, boolean>;

  // * --- UI
  modalState: JournalManageModalState;
}

export const journalManageState: JournalManageState = {
  // * State
  originalEntry: null,
  entry: null,
  entryImageSrcList: [],
  manageType: JournalManageType.None,
  // Draft will save the currently ADDING journal
  draft: null,
  draftImageSrcList: [],

  tickerList: computed(
    [(state) => state.entry?.transactionsMap],
    (transactionsMap) => {
      const tickerList: string[] = [];
      if (transactionsMap) {
        Object.values(transactionsMap).forEach((transactionList) => {
          tickerList.push(
            ...(transactionList as Transaction[]).map((entry) => entry.ticker),
          );
        });
      }

      const uniqTickerList = lodash.uniq(tickerList);
      return uniqTickerList;
    },
  ),

  transactionList: computed(
    [(state) => state.entry?.transactionsMap],
    (transactionsMap) => {
      if (!transactionsMap) {
        return [];
      }

      const returnList: Transaction[] = [];
      Object.values(transactionsMap).forEach((list) =>
        returnList.push(...list),
      );

      return returnList;
    },
  ),

  alertIdList: computed(
    [(state) => state.entry?.alertIdList],
    (alertIdList) => alertIdList || [],
  ),

  isUnchangedObjectWise: computed(
    [(state) => state.entry, (state) => state.originalEntry],
    (entry, originalEntry) => lodash.isEqual(entry, originalEntry),
  ),

  // * --- UI
  modalState: JournalManageModalState.Hidden,
};
