import cx from "classnames";
import {
  ControllerWrapperProps,
  FormWrapperExtraProp,
} from "common/form/form.common";
import React, { RefObject } from "react";
import FadeIn from "react-fade-in";
import { Controller } from "react-hook-form";
import Icon from "../Icon";

interface FormWrapperProps extends FormWrapperExtraProp {
  isActive?: boolean;
  className?: string;

  hasError: boolean;
  errorText?: string;

  label?: React.ReactNode;

  children: React.ReactNode;
  containerRef?: RefObject<HTMLDivElement>;

  id: string;
}

export const FormWrapper = ({
  isActive = false,
  className,
  hasError,
  errorText,
  label,
  children,
  rightCTA,
  containerRef,
  searchResultContainer,
  wrapperOnClick,
  id,
}: FormWrapperProps) => {
  const renderSearchResult = () => {
    if (searchResultContainer && searchResultContainer.data) {
      const {
        data,
        entryClassName,
        containerClassName,
        isStatic,
        onClick,
        entryTag,
      } = searchResultContainer;
      const className = cx(
        "search-result-container",
        { "search-result-container-static": isStatic },
        containerClassName
      );
      const Entry = entryTag || "div";

      if (searchResultContainer.data.length > 0) {
        return (
          <div className={className}>
            {data.map((result, index) => (
              <Entry
                key={index}
                onClick={() => {
                  onClick && onClick(index);
                }}
                className={cx("search-entry", entryClassName)}
              >
                {result}
              </Entry>
            ))}
          </div>
        );
      } else {
        return (
          <div className={"search-result-container-empty " + className}>
            No result
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={cx("form-section", className)} ref={containerRef}>
      <div
        className={cx("form-input-container", {
          "form-input-container-active": isActive,
        })}
        onClick={wrapperOnClick}
      >
        <div className="form-inner-container">
          {children}

          <div className="right-cta">{rightCTA}</div>

          {label && (
            <label
              className={cx("form-label", { "form-label-error": hasError })}
            >
              {label}
            </label>
          )}
        </div>
      </div>

      {renderSearchResult()}

      {hasError && (
        <FadeIn>
          <p className="form-error-text">
            <Icon icon={["fas", "exclamation-circle"]} />
            {errorText}
          </p>
        </FadeIn>
      )}
    </div>
  );
};

export function ControllerWrapper<T>({
  formValidator,

  Component,
  props,
  defaultValue,
  onChange: defaultOnChange,
}: ControllerWrapperProps<T>) {
  if (!formValidator || !formValidator.control) {
    return <Component {...props} />;
  }

  const { control, rules, name } = formValidator;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <Component
          {...props}
          onChange={(newValue: any) => {
            onChange(newValue);
            defaultOnChange && defaultOnChange(newValue);
          }}
          value={value}
        />
      )}
    />
  );
}
