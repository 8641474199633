export const firebaseConfig =
  process.env.REACT_APP_INSTANCE === "dev"
    ? {
        apiKey: "AIzaSyDmLk4zQr3L4qxd-48TIFSIma3RkNR0aJM",
        authDomain: "stockwise-dev.firebaseapp.com",
        projectId: "stockwise-dev",
        storageBucket: "stockwise-dev.appspot.com",
        messagingSenderId: "417330709405",
        appId: "1:417330709405:web:de53881c554e894194e574",
        measurementId: "G-QSRJXCM8H9",
      }
    : {
        apiKey: "AIzaSyDsMe_jZI7Q8cA4gbCQ5aQ6bpaWitcJksU",
        authDomain: "stockwise-f24fe.firebaseapp.com",
        databaseURL: "https://stockwise-f24fe.firebaseio.com",
        projectId: "stockwise-f24fe",
        storageBucket: "stockwise-f24fe.appspot.com",
        messagingSenderId: "16332129345",
        appId: "1:16332129345:web:1f8b599c7e153258fbb2c0",
        measurementId: "G-7916MVM6QK",
      };
