import { validate } from "class-validator";
import { serverErrorMessage } from "common/standardMessage";
import firebase from "firebase/app/";
import "firebase/auth";
import { CustomError, FunctionBackendError } from "models";
import { FunctionErrorDto } from "models/dto/error.dto";
import { toastCustomMessage } from "./ui.util";

export const isFirebaseAuthError = (err: any): err is firebase.auth.Error => {
  if (err.code) {
    return err.code.startsWith("auth/");
  }
  return false;
};

// TODO: Handle more error here
// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithpopup
export const extractFirebaseAuthError = (err: any): CustomError => {
  if (!isFirebaseAuthError(err)) {
    return { code: 500, message: JSON.stringify(err) };
  }

  let code = 500;
  let detailMessage = `${serverErrorMessage.internalError}: ${err.code} ${err.message}`;
  let canToast = false;
  switch (err.code) {
    case "auth/invalid-user-token":
    case "auth/user-token-expired":
      code = 401;
      detailMessage = "Please sign in again";
      canToast = true;
      break;
    case "auth/too-many-requests":
      code = 403;
      detailMessage = "Login too frequent, please wait try again later";
      canToast = true;
      break;
    case "auth/user-disabled":
      code = 403;
      detailMessage = "Account disabled, please contact support";
      canToast = true;
      break;
    case "auth/account-exists-with-different-credential":
    case "auth/email-already-in-use":
      code = 400;
      detailMessage = "Account with same email already exists";
      canToast = true;
      break;
    case "auth/popup-blocked":
      code = 400;
      detailMessage =
        "Signup/Login popup is block. Please check your browser setting";
      canToast = true;
      break;
    case "auth/cancelled-popup-request":
    case "auth/popup-closed-by-user":
      code = 400;
      detailMessage = err.message;
      break;
    case "auth/user-not-found":
      code = 400;
      detailMessage = "Account not found, please check your email/password";
      canToast = true;
      break;
    case "auth/invalid-email":
      code = 400;
      detailMessage = "Invalid email";
      canToast = true;
      break;
    case "auth/wrong-password":
      code = 400;
      detailMessage = "Invalid password";
      canToast = true;
      break;
    case "auth/invalid-phone-number":
      code = 404;
      detailMessage = "Invalid phone number";
      canToast = true;
      break;
  }

  return { code, message: err.message, detailMessage, canToast };
};

export const extractFunctionError = async (
  err: any
): Promise<FunctionBackendError> => {
  const errorData = new FunctionErrorDto(err);
  const validateResult = await validate(errorData);
  if (validateResult.length > 0) {
    return {
      code: 500,
      message: "internal/unknown-error",
      detailMessage: JSON.stringify(err),
    };
  }

  let code = 500;
  let detailMessage: string = errorData.detailMessage;
  let canToast = true;
  switch (errorData.message) {
    case "custom/validation-failed":
      detailMessage = "Bad request. Please contact your admin with detail";
      break;
    case "auth/unauthenticated":
      detailMessage = "User is not authenticated!";
      break;
    case "resource/too-many-request":
      detailMessage = "You have tried to refresh too many times in a row!";
      break;
  }

  console.error(errorData.detailMessage);

  return {
    code,
    message: errorData.message,
    detailMessage,
    canToast,
  };
};

export const handleCustomError = (
  error: CustomError,
  doToastError: boolean = true
) => {
  const { canToast, message, code, detailMessage } = error;

  console.error(code, message);
  console.error(detailMessage);

  if (canToast && doToastError) {
    toastCustomMessage(JSON.stringify(detailMessage || message), "error");
  }
};
