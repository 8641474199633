import { routePathMap } from "common/routeList";
import { PriceOrPercent, SparklinesLineChart } from "components/common";
import { useThemeColor } from "hooks/useThemeColor";
import { TickerDetail } from "models/dto/watchlist/watchlist.dto";
import React from "react";
import { Link } from "react-router-dom";
import "styles/components/common/WatchList/WatchListTickerItem.sass";

interface WatchListItemProps {
  tickerDetail: TickerDetail;
}
export const WatchListTickerItem = ({
  tickerDetail: { quote, sparklineData, ticker },
}: WatchListItemProps) => {
  const successColor = useThemeColor("success");
  const failColor = useThemeColor("danger");

  const changePercent = (quote.changePercent || 0) * 100;

  return (
    <div className="watch-list-ticker-item">
      <Link className="info" to={`${routePathMap.stock}/${ticker}`}>
        <p className="ticker">{ticker.toUpperCase()}</p>
        <p className="name">{quote.companyName}</p>
      </Link>

      <div className="sparkline-container">
        {sparklineData && (
          <SparklinesLineChart
            config={{ data: sparklineData.map((entry) => entry.close) }}
            color={
              changePercent > 0
                ? successColor
                : changePercent < 0
                ? failColor
                : undefined
            }
          />
        )}
      </div>

      <div className="quote">
        {quote ? (
          <>
            <PriceOrPercent
              type="price"
              value={quote.latestPrice}
              className="price"
            />
            <PriceOrPercent
              type="percent"
              value={changePercent}
              className="percent"
            />
          </>
        ) : (
          "-"
        )}
      </div>
    </div>
  );
};
