import React, { useEffect, useMemo } from "react";
import { Bounds } from "react-use-gesture/dist/types";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";
import { useMeasure } from "react-use";
import cx from "classnames";

import "styles/components/common/DraggableCard.sass";

interface DraggableCardProps {
  containerClassName?: string;
  childrenContainerClassName?: string;
  backgroundContentClassName?: string;
  children: React.ReactNode;
  backgroundContent?: React.ReactNode;
  animationProps?: {
    lockDirection?: Array<keyof Bounds>;
    activeDirection?: keyof Bounds;
    useBgContentAsAnimProps?: boolean;
    bounds?: Bounds;
    rubberband?: [number, number] | boolean;
    axis?: "x" | "y";
    threshold?: [number | number] | number;
  };
  touchAction?:
    | "-ms-manipulation"
    | "-ms-none"
    | "-ms-pinch-zoom"
    | "auto"
    | "manipulation"
    | "none"
    | "pan-down"
    | "pan-left"
    | "pan-right"
    | "pan-up"
    | "pan-x"
    | "pan-y"
    | "pinch-zoom";
}

export const DraggableCard = ({
  containerClassName,
  children: foregoundContent,
  childrenContainerClassName,
  backgroundContentClassName,
  backgroundContent,

  animationProps,
  touchAction,
}: DraggableCardProps) => {
  const [bgRef, { width: bgWidth, height: bgHeight }] = useMeasure<any>();
  const [containerRef, { width: containerWidth }] = useMeasure<any>();
  const {
    useBgContentAsAnimProps,
    lockDirection,
    bounds,
    threshold,
    rubberband,
    axis,
    activeDirection,
  } = animationProps || {};

  const curBounds = useMemo(
    () =>
      useBgContentAsAnimProps
        ? {
            left:
              lockDirection && lockDirection.includes("left") ? 0 : -bgWidth,
            right:
              lockDirection && lockDirection.includes("right") ? 0 : bgWidth,
            top: lockDirection && lockDirection.includes("top") ? 0 : -bgHeight,
            bottom:
              lockDirection && lockDirection.includes("bottom") ? 0 : bgHeight,
          }
        : bounds,
    [useBgContentAsAnimProps, lockDirection, bgWidth, bgHeight, bounds]
  );

  const [{ x, y }, setMainComp] = useSpring(() => ({
    x: 0,
    y: 0,
    config: { duration: 100 },
  }));
  const [{ left, opacity }, setBackgroundComp] = useSpring(() => ({
    left: 0,
    opacity: 0,
  }));
  const bind = useDrag(
    ({ down, movement: [mx, my] }) => {
      const isInXThreshold = !down && Math.abs(mx) < bgWidth;
      const isInYThreshold = !down && Math.abs(my) < bgHeight;

      setBackgroundComp({
        left: mx >= 0 ? 0 : containerWidth - bgWidth,
        opacity: !isInXThreshold || !isInYThreshold ? 1 : 0,
      });
      setMainComp({
        x: isInXThreshold ? 0 : mx,
        y: isInYThreshold ? 0 : my,
      });
    },
    {
      bounds: curBounds,
      threshold,
      rubberband: rubberband || true,
      axis,
      initial: () => [x?.get() || 0, y?.get() || 0],
    }
  );

  useEffect(() => {
    if (activeDirection && curBounds) {
      const direction =
        activeDirection === "bottom" || activeDirection === "top" ? "y" : "x";
      setBackgroundComp({
        opacity: 1,
        left: activeDirection === "left" ? containerWidth - bgWidth : 0,
      });
      setMainComp({ [direction]: curBounds[activeDirection] });
    }

    if (!activeDirection) {
      setBackgroundComp({ opacity: 0 });
      setMainComp({ x: 0, y: 0 });
    }
    // ? reset x, y after initial bgWidth, bgHeight change from 0
  }, [
    activeDirection,
    bgWidth,
    bgHeight,
    containerWidth,
    curBounds,
    setMainComp,
    setBackgroundComp,
  ]);

  // Bind it to a component
  return (
    <animated.div
      {...bind()}
      ref={containerRef}
      className={cx("draggable-card-container", containerClassName)}
    >
      <animated.div
        ref={bgRef}
        className={cx("bg-container", backgroundContentClassName)}
        // TODO: Change this any once the lib is fixed (rc.4)
        // https://github.com/pmndrs/react-spring/issues/1102
        style={{ left, opacity: opacity as any }}
      >
        {backgroundContent}
      </animated.div>

      <animated.div
        style={{ x, y, touchAction: touchAction || "pan-y" }}
        className={cx(childrenContainerClassName)}
      >
        {foregoundContent}
      </animated.div>
    </animated.div>
  );
};
