import { lazy, LazyExoticComponent } from "react";

const TestingPage = lazy(() => import("pages/TestingPage"));

const HomePage = lazy(() => import("pages/HomePage"));
const AuthPage = lazy(() => import("pages/AuthPage"));
const SettingPage = lazy(() => import("pages/SettingPage"));
const StockPage = lazy(() => import("pages/StockPage"));

const JournalDashboardPage = lazy(() => import("pages/JournalDashboardPage"));
const JournalManagePage = lazy(() => import("pages/JournalManagePage"));

const AlertDashboardPage = lazy(() => import("pages/AlertDashboardPage"));

const MessagePage = lazy(() => import("pages/MessagePage"));

type ComponentType = () => JSX.Element;
interface RouteProp {
  displayText?: string;
  path: string;
  Component: ComponentType | LazyExoticComponent<ComponentType>;
  requireLogin: boolean;
  hideIfLoggedIn?: boolean;
  navBarIcon?: [string, string];
  useLocationHeaderPathSplit?: boolean;
}

export const routePathMap = {
  uc: "/uc",

  authBase: "/auth",

  home: "/home",

  settingBase: "/setting",

  stock: "/stock",

  journalDashboard: "/journal-dashboard",
  journalManage: "/journal/manage",

  alertDashboard: "/alert",

  messageBase: "/message",
} as const;

export const authPathMap = {
  register: `${routePathMap.authBase}/register`,
  login: `${routePathMap.authBase}/login`,
} as const;

export const settingPathMap = {
  settingAccount: `${routePathMap.settingBase}/account`,
  settingSubscription: `${routePathMap.settingBase}/subscription`,
  settingFeedback: `${routePathMap.settingBase}/feedback`,
  settingContact: `${routePathMap.settingBase}/contact`,
} as const;

export const messagePathMap = {
  general: `${routePathMap.messageBase}/general`,
  media: `${routePathMap.messageBase}/media`,
} as const;

export const redirectList: Array<{ from: string; to: string }> = [
  {
    from: "/",
    to: routePathMap.journalDashboard,
  },
];

export const routeList = (): ReadonlyArray<RouteProp> => [
  // --- Demo routes
  {
    path: routePathMap.uc,
    Component: TestingPage,
    requireLogin: false,
  },
  // --- Main routes
  {
    displayText: "Home",
    path: routePathMap.home,
    Component: HomePage,
    navBarIcon: ["cio", "home"],
    requireLogin: true,
  },
  {
    path: routePathMap.authBase,
    Component: AuthPage,
    requireLogin: false,
    hideIfLoggedIn: true,
  },
  {
    path: routePathMap.settingBase,
    Component: SettingPage,
    requireLogin: true,
  },
  {
    displayText: "Stock",
    path: routePathMap.stock,
    Component: StockPage,
    navBarIcon: ["cio", "stock-chart"],
    requireLogin: true,
    useLocationHeaderPathSplit: true,
  },
  {
    path: routePathMap.journalManage,
    Component: JournalManagePage,
    requireLogin: true,
  },
  {
    displayText: "Journal",
    path: routePathMap.journalDashboard,
    Component: JournalDashboardPage,
    navBarIcon: ["cio", "journal"],
    requireLogin: true,
  },
  {
    displayText: "Alert",
    path: routePathMap.alertDashboard,
    Component: AlertDashboardPage,
    navBarIcon: ["cio", "bell"],
    requireLogin: true,
  },
  {
    displayText: "Messages",
    path: routePathMap.messageBase,
    Component: MessagePage,
    navBarIcon: ["cio", "chat"],
    requireLogin: true,
  },
];
