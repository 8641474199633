import { StockLoadingType } from "common/stock/stock.info";
import { CardShimmer, ShimmerContainer } from "components/common";
import { EarningChart } from "components/common/Chart/EarningChart";
import React, { useMemo } from "react";
import { useAsync } from "react-use";
import { useStoreActions, useStoreState } from "stores";

interface EarningChartWithDetailProps {
  ticker: string;
}

type ChartDataEntry = [string, number | null][];
interface ChartData {
  expectedData: ChartDataEntry;
  actualData: ChartDataEntry;
}

export const EarningChartWithDetail = ({
  ticker,
}: EarningChartWithDetailProps) => {
  const { getEarningForTicker } = useStoreActions((actions) => actions.stock);
  const { value: earningData, loading } = useAsync(async () => {
    return await getEarningForTicker(ticker);
  }, [ticker]);

  const isFetchingTickerEarning = useStoreState((state) =>
    state.stock.isLoading(StockLoadingType.FetchingTickerEarning)
  );

  const { expectedData, actualData } = useMemo<ChartData>(() => {
    let expectedData: ChartDataEntry = [];
    let actualData: ChartDataEntry = [];

    if (earningData) {
      earningData.earningList.forEach((entry) => {
        const date = entry.fiscalDateEnding;

        expectedData.push([date, entry.estimatedEPS]);
        actualData.push([date, entry.reportedEPS]);
      });
    }

    return { expectedData, actualData };
  }, [earningData]);

  if (!expectedData || !actualData) {
    return null;
  }

  return (
    <ShimmerContainer
      isReady={!loading && !isFetchingTickerEarning}
      shimmerComponent={<CardShimmer />}
      numShimmer={1}
    >
      {earningData && (
        <EarningChart
          expectedEPSDataList={expectedData}
          actualEPSDataList={actualData}
        />
      )}
    </ShimmerContainer>
  );
};
