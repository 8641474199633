export enum AlertDashboardView {
  AlertAndNotification,
  AllAlert,
  AllNotification,
  OnDesktop,
  AlertManage,
}

export enum AlertManageStep {
  CompanySearch,
  TypeChose,
  ConditionAdding,
  Summary,
  End,
  LimitReach,
  SubscriptionChoose,
}

export enum AlertAddConditionStep {
  TypeSelection,
  SubTypeSelection,
  DetailAdding,
}

export enum AlertAddConditionView {
  Empty,
  AddCondition,
  SubscriptionWarning,
}

export enum AlertManageLoadingType {
  CheckingCondition,
  CheckingAlert,
  SwitchingAlertType,
  SubmittingAlert,
}

export enum AlertDashboardLoadingType {
  LoadingAlert,
  ToggleAlert,
}
