import { AlertManageType } from "common/alert/alert-manage.info";
import { AlertManageStep } from "common/alert/alert-page.info";
import { BackButton, CustomModal } from "components/common";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import { getAlertScreenHeaderText } from "utils/alert.util";
import { AlertManageView } from "views/AlertDashboard";

export const AlertManageModal = () => {
  const alertManageType = useStoreState(
    (state) => state.alertManage.alertManageType
  );
  const isUnchangedObjectWise = useStoreState(
    (state) => state.alertManage.isUnchangedObjectWise
  );
  const curManageStep = useStoreState(
    (state) => state.alertManage.curManageStep
  );
  const alert = useStoreState((state) => state.alertManage.alert);

  const { setAlertManageType, setCurManageStep } = useStoreActions(
    (actions) => actions.alertManage
  );

  const getHeaderText = () =>
    getAlertScreenHeaderText(false, curManageStep, alert?.ticker);

  const renderHeaderLeftComponent = () => {
    return (
      <BackButton
        onClick={() => {
          if (alertManageType !== AlertManageType.None) {
            if (curManageStep > 0) {
              if (curManageStep === AlertManageStep.LimitReach) {
                // We don't want to move back to summary screen
                //  if it's not valid
                setCurManageStep(AlertManageStep.Summary);
              } else {
                setCurManageStep(curManageStep - 1);
              }
            } else {
              handleModalClose();
            }
          } else {
            handleModalClose();
          }
        }}
      />
    );
  };

  const handleModalClose = () => {
    const closeMessage = "You made changes. Are you sure you want to leave?";

    if (isUnchangedObjectWise || window.confirm(closeMessage)) {
      setAlertManageType(AlertManageType.None);
    }
  };

  return (
    <CustomModal
      midComponent={getHeaderText()}
      leftComponent={renderHeaderLeftComponent()}
      onCloseClick={handleModalClose}
      onClickAway={handleModalClose}
    >
      <AlertManageView />
    </CustomModal>
  );
};
