import { accountService } from "api/service";
import { AccountLoadingType } from "common/account.info";
import { ApiReturnValue } from "common/api.info";
import { StripeRole } from "common/payment.info";
import { internalErrorMessage } from "common/standardMessage";
import {
  Action,
  action,
  Computed,
  computed,
  persist,
  Thunk,
  thunk,
} from "easy-peasy";
import firebase from "firebase/app";
import "firebase/auth";
import { MyAccount } from "models";
import {
  FeatureKey,
  PlanMetadataFeatureDto,
} from "models/dto/payment/subscription-plan-metadata.dto";
import { LoadingModel, loadingPlugin } from "stores/plugin";
import { StoreModel } from "stores/StoreFront";
import { executeAsync } from "utils/api.util";
import { executeBasedOnEnv } from "utils/env.utils";
import {
  extractFirebaseAuthError,
  extractFunctionError,
} from "utils/error.util";
import {
  getBoolValueFromFeatureKey,
  getNumberValueFromFeatureKey,
} from "utils/subscription.util";
import { toastCustomMessage } from "utils/ui.util";

export interface AccountModel extends LoadingModel<AccountLoadingType> {
  // * State
  firebaseUser: firebase.User | null;
  account: MyAccount | null;
  isLoggedIn: boolean;
  featureList: Computed<AccountModel, PlanMetadataFeatureDto[] | null>;
  stripeRole: Computed<AccountModel, StripeRole | null>;

  canUseFeature: Computed<AccountModel, (featureKey: FeatureKey) => boolean>;
  featureLimit: Computed<
    AccountModel,
    (featureKey: FeatureKey, fallbackLimit?: number) => number
  >;

  // * Actions
  setIsLoggedIn: Action<AccountModel, boolean>;
  setFirebaseUser: Action<AccountModel, firebase.User | null>;
  setAccount: Action<AccountModel, MyAccount | null>;
  resetStore: Action<AccountModel>;

  // * Thunks
  initializeAccount: Thunk<AccountModel, never, any, StoreModel>;
  login: Thunk<
    AccountModel,
    {
      provider?: firebase.auth.AuthProvider;
      emailPasswordObj?: {
        email: string;
        password: string;
      };
    }
  >;
  logout: Thunk<AccountModel>;
  register: Thunk<AccountModel, { email: string; password: string }>;
  resetPassword: Thunk<
    AccountModel,
    string,
    any,
    any,
    Promise<ApiReturnValue<boolean>>
  >;
}

export const account: AccountModel = persist(
  {
    // * State
    firebaseUser: null,
    account: null,
    isLoggedIn: false,
    featureList: computed(
      [(state) => state.account?.currentPlan.metadata?.featureList],
      (featureList) => featureList || null
    ),
    stripeRole: computed(
      [(state) => state.account?.currentPlan.role],
      (role) => role || null
    ),

    // TODO: Rework this to also work on numeric feature
    canUseFeature: computed(
      [(state) => state.featureList],
      (featureList) => (featureKey) =>
        getBoolValueFromFeatureKey(featureList, featureKey)
    ),
    featureLimit: computed(
      [(state) => state.featureList],
      (featureList) => (featureKey, fallbackLimit) =>
        getNumberValueFromFeatureKey(featureList, featureKey, fallbackLimit)
    ),

    // * Actions
    setFirebaseUser: action((state, firebaseUser) => {
      state.firebaseUser = firebaseUser;
    }),
    setAccount: action((state, account) => {
      state.account = account;
    }),
    setIsLoggedIn: action((state, isLoggedIn) => {
      state.isLoggedIn = isLoggedIn;
    }),
    resetStore: action((state) => {
      state.firebaseUser = null;
      state.account = null;
      state.featureList = null;
    }),

    // * Thunks
    initializeAccount: thunk((actions, _, { getState, getStoreActions }) => {
      firebase.auth().onAuthStateChanged(async (user) => {
        executeAsync({
          funcToExecute: async () => {
            if (user) {
              // Condition to avoid re-fetching on reload
              const newAccount = (
                await executeAsync({
                  funcToExecute: async () => await accountService.getAccount(),
                  setIsLoading: (isLoading) => {
                    actions.setIsLoading({
                      loadingType: AccountLoadingType.LoggingIn,
                      isLoading: isLoading,
                    });
                  },
                  transformError: extractFunctionError,
                })
              ).result;

              if (!getState().isLoggedIn) {
                toastCustomMessage("Login successfully!", "success");
              }

              executeBasedOnEnv({
                devFunc: () => {
                  console.log(user.getIdToken());
                },
                uatFunc: () => {
                  console.log(user.getIdToken());
                },
                prdFunc: () => {},
              });

              actions.setFirebaseUser(user);
              actions.setAccount(newAccount);

              getStoreActions().global.initializeRequireLoginSection();
            } else {
              if (getState().account != null) {
                getStoreActions().global.resetRequireLoginSection();
                actions.setAccount(null);
              }
            }

            actions.setIsLoggedIn(user != null);
            actions.setFirebaseUser(user);
          },
        });
      });
    }),

    login: thunk(async (actions, { provider, emailPasswordObj }) => {
      await executeAsync({
        funcToExecute: async () => {
          if (!provider && !emailPasswordObj) {
            throw new Error(internalErrorMessage.missingLoginCredential);
          }

          if (provider == null) {
            const { email, password } = emailPasswordObj!;
            await firebase.auth().signInWithEmailAndPassword(email, password);
          } else {
            await firebase.auth().signInWithPopup(provider);
          }
        },
        transformError: extractFirebaseAuthError,
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: AccountLoadingType.LoggingIn,
            isLoading: isLoading,
          });
        },
      });
    }),

    logout: thunk(async (actions) => {
      await executeAsync({
        funcToExecute: async () => {
          await firebase.auth().signOut();
          toastCustomMessage("Logout successfully!", "success");
        },
        setIsLoading: (isLoading) => {
          actions.setIsLoading({
            loadingType: AccountLoadingType.LoggingOut,
            isLoading: isLoading,
          });
        },
        transformError: extractFirebaseAuthError,
      });
    }),

    register: thunk(async (_, { email, password }) => {
      executeAsync({
        funcToExecute: async () => {
          await firebase.auth().createUserWithEmailAndPassword(email, password);
        },
        transformError: extractFirebaseAuthError,
      });
    }),

    resetPassword: thunk(async (_, email) => {
      return await executeAsync({
        funcToExecute: async () => {
          await firebase.auth().sendPasswordResetEmail(email);
          return true;
        },
        transformError: extractFirebaseAuthError,
      });
    }),

    ...loadingPlugin(),
  },
  { storage: "localStorage", allow: ["isLoggedIn"] }
);
