import { relayList, RelayType } from "common/alert-relay.info";
import {
  AlertManageLoadingType,
  AlertManageStep,
} from "common/alert/alert-page.info";
import { FormChoice } from "components/common";
import lodash from "lodash";
import { FunctionErrorMessage } from "models";
import React from "react";
import { useStoreActions, useStoreState } from "stores";
import "styles/components/pages/Alert/AddAlertSummaryStep.sass";
import { craftConditionMessage } from "utils/alert.util";

export const AddAlertSummaryStep = () => {
  const alert = useStoreState((state) => state.alertManage.alert);
  const isLoadingMap = useStoreState((state) => state.alertManage.isLoadingMap);
  const errorMap = useStoreState((state) => state.alertManage.errorMap);
  const {
    addOrRemoveRelay,
    setCurManageStep,
    submitAlert,
    setError,
  } = useStoreActions((actions) => actions.alertManage);

  if (!alert) {
    return null;
  }

  const curRelayList = alert.relayList;
  const curConditionList = alert.conditionList;

  const isSubmittingAlert =
    isLoadingMap[AlertManageLoadingType.SubmittingAlert];

  const onRelayClick = (relay: RelayType) => {
    setError({ key: "relay", detailMessage: null });
    addOrRemoveRelay(relay);
  };

  const onEditConditionClick = () => {
    setCurManageStep(AlertManageStep.ConditionAdding);
  };

  const onSetAlertClick = async () => {
    if (curRelayList.length <= 0) {
      setError({
        key: "relay",
        detailMessage: "You have to choose one of notification type",
      });
    } else {
      setError({ key: "relay", detailMessage: null });

      const result = await submitAlert();

      if (result.error) {
        setError({
          key: "submitError",
          detailMessage: result.error.detailMessage || result.error.message,
        });
      }

      if (result.result) {
        setCurManageStep(AlertManageStep.End);
      } else if (result.error) {
        setCurManageStep(AlertManageStep.LimitReach);
        setError({
          key: "submitError",
          message: result.error.message as FunctionErrorMessage,
          detailMessage: result.error.detailMessage || "",
        });
      }
    }
  };

  return (
    <div className="add-alert-summary-step">
      <div className="summary-container">
        <p className="title">Alert Summary</p>
        <p className="caption">
          Alert for {alert.ticker} will be triggered when
        </p>

        <ul>
          {curConditionList.map((condition) => (
            <li key={condition.id}>{craftConditionMessage(condition)}</li>
          ))}
        </ul>
      </div>

      <div className="relay-container">
        <p className="title">Notification Preferences</p>
        <p className="caption">
          Choose where you'll receive alert notification
        </p>

        <div className="choice-container">
          {relayList.map((relay) => (
            <FormChoice
              key={relay}
              type="checkbox"
              label={lodash.startCase(relay)}
              onChange={() => onRelayClick(relay)}
              value={curRelayList.includes(relay)}
            />
          ))}
        </div>

        {errorMap.relay?.detailMessage && (
          <p className="relay-error">{errorMap.relay.detailMessage}</p>
        )}
      </div>

      <div className="cta-container">
        <button
          className="submit"
          onClick={onSetAlertClick}
          disabled={isSubmittingAlert}
        >
          Set alert
        </button>
        <button
          className="go-back"
          onClick={onEditConditionClick}
          disabled={isSubmittingAlert}
        >
          Edit condition
        </button>
      </div>
    </div>
  );
};
