import {
  FormShimmer,
  Icon,
  InformationCard,
  ShimmerContainer,
} from "components/common";
import { AlertSummaryCard } from "components/pages/Alert/AlertSummaryCard";
import { AlertDto } from "models/dto/alert";
import React, { useMemo } from "react";
import "styles/components/common/page-component.common.sass";
import "styles/pages/common/LinkedAlertView.sass";

interface LinkedAlertViewProps {
  alertList: AlertDto[];
  onCtaClick?: () => void;
  isLoading?: boolean;
}
export const LinkedAlertView = ({
  alertList,
  onCtaClick,
  isLoading,
}: LinkedAlertViewProps) => {
  const alertByTickerList = useMemo(() => {
    const alertByTickerMap: Record<string, AlertDto[]> = {};
    alertList.forEach((alert) => {
      const ticker = alert.ticker;
      if (alertByTickerMap[ticker]) {
        alertByTickerMap[ticker].push(alert);
      } else {
        alertByTickerMap[ticker] = [alert];
      }
    });

    return Object.entries(alertByTickerMap).map(([ticker, alertList]) => ({
      ticker: ticker,
      alertList: alertList,
    }));
  }, [alertList]);

  return (
    <div className="page-component-common linked-alert-view">
      <p className="component-title">Linked Alert</p>

      <div className="alert-container">
        <ShimmerContainer
          shimmerComponent={<FormShimmer />}
          isReady={!isLoading}
          numShimmer={2}
        >
          {alertByTickerList.length <= 0 ? (
            <InformationCard>No Alert Linked</InformationCard>
          ) : (
            alertByTickerList.map(({ alertList, ticker }) => (
              <div className="ticker-wrapper" key={ticker}>
                <div className="title">
                  <p>Company: </p>
                  <p className="ticker">{ticker}</p>
                </div>

                {alertList.map((alert) => (
                  <AlertSummaryCard alert={alert} key={alert.alertId} />
                ))}
              </div>
            ))
          )}
        </ShimmerContainer>
      </div>

      {onCtaClick && (
        <button onClick={onCtaClick}>
          <Icon icon={["cis", "add-alert"]} className="fa-fw" />
          <p>Link or Unlink Alert</p>
        </button>
      )}
    </div>
  );
};
