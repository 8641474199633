import { ROOT_ID } from "common/common";
import { Portal } from "components/common/Portal";
import { ReactNode, RefObject, useLayoutEffect, useRef, useState } from "react";
import FadeIn from "react-fade-in";
import { CSSTransition } from "react-transition-group";
import { useClickAway, useMeasure } from "react-use";

interface DropdownContentProps {
  containerRef: RefObject<HTMLElement>;
  onClickAway?: () => void;
  usePortal?: boolean;
  className?: string;
  children: ReactNode;
  isShown: boolean;
  zIndex?: number;
}
export const DropdownContent = ({
  containerRef,
  onClickAway,
  usePortal = true,
  className,
  children,
  isShown,
  zIndex = 50,
}: DropdownContentProps) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [measureRef, { width: measureWidth }] = useMeasure();
  const [anchorPos, setAnchorPos] = useState<{
    bottom: number;
    left: number;
    width: number;
  }>({ left: 0, bottom: 0, width: 0 });

  useClickAway(dropdownRef, () => {
    onClickAway && onClickAway();
  });

  useLayoutEffect(() => {
    if (containerRef.current) {
      const {
        bottom,
        left,
        width,
      } = containerRef.current.getBoundingClientRect();
      setAnchorPos({ bottom, left, width: Math.max(measureWidth, width) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current, measureWidth]);

  const renderContent = () => (
    <CSSTransition
      in={isShown}
      timeout={500}
      classNames="basic-fade-with-translate"
      unmountOnExit
    >
      <div
        ref={(ref) => {
          if (ref) {
            dropdownRef.current = ref;
            measureRef(ref);
          }
        }}
        style={
          usePortal
            ? {
                position: "absolute",
                top: anchorPos.bottom,
                left: anchorPos.left,
                width: anchorPos.width,
                zIndex,
              }
            : {}
        }
      >
        <FadeIn className={className}>{children}</FadeIn>
      </div>
    </CSSTransition>
  );

  return usePortal ? (
    <Portal containerId={ROOT_ID}>{renderContent()}</Portal>
  ) : (
    renderContent()
  );
};
