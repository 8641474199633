import cx from "classnames";
import { BasicFormInputProp, FormInputProp } from "common/form/form.common";
import lodash, { isNumber } from "lodash";
import React, { FocusEvent, useRef } from "react";
import TextareaAutosize from "react-autosize-textarea";
import NumberFormat from "react-number-format";
import "styles/components/common/form.sass";
import { v4 as uuidv4 } from "uuid";
import { FormWrapper } from "./FormCommon";

// * Basic input
export const FormInput = ({
  placeholder = " ",
  type = "text",

  autoComplete = "off",
  autoFocus,
  readOnly,

  step,
  value,
  min,
  max,
  defaultValue,

  maskProp,
  formValidator,
  onChange,
  onBlur,
  onFocus,
  borderType = "normal",
  ...wrapperProps
}: FormInputProp) => {
  const hasError = !lodash.isNil(formValidator?.errorText);
  const hasPlaceholder = !lodash.isEmpty(lodash.trim(placeholder));

  const id = useRef(uuidv4());

  const defaultProp = {
    autoFocus,
    className: cx(
      "form-input",
      { "form-input-full-border": borderType === "full-border" },
      { "form-input-no-border": borderType === "no-border" },
      { "form-input-error": hasError },
      { "form-input-has-placeholder": hasPlaceholder }
    ),
    placeholder,
    value,
    defaultValue,
    readOnly,
    autoComplete,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal =
        type === "number" && isNumber(e.target.value)
          ? parseFloat(e.target.value)
          : e.target.value;
      onChange && onChange(newVal);
    },
    onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
      const newVal =
        type === "number" && isNumber(e.target.value)
          ? parseFloat(e.target.value)
          : e.target.value;
      onBlur && onBlur(newVal);
    },
    onFocus: (e: FocusEvent<HTMLInputElement>) => {
      const newVal =
        type === "number" && isNumber(e.target.value)
          ? parseFloat(e.target.value)
          : e.target.value;
      onFocus && onFocus(newVal);
    },
    min,
    max,
    step,
  };

  return (
    <FormWrapper
      id={id.current}
      {...{ ...wrapperProps, hasError, errorText: formValidator?.errorText }}
    >
      {maskProp ? (
        <NumberFormat
          id={id.current}
          {...defaultProp}
          getInputRef={(el: HTMLInputElement) => {
            if (el) {
              el.name = formValidator?.name || "";
              formValidator && formValidator.inputRef(el);
            }
          }}
          displayType="input"
          {...maskProp}
        />
      ) : (
        <input
          id={id.current}
          {...defaultProp}
          type={type}
          ref={formValidator?.inputRef}
          name={formValidator?.name}
        />
      )}
    </FormWrapper>
  );
};

// * Textarea
export const FormTextArea = ({
  placeholder = " ",
  autoComplete = "off",
  autoFocus,
  readOnly,
  value,
  defaultValue,
  formValidator,
  onChange,
  borderType,
  ...wrapperProps
}: BasicFormInputProp) => {
  const hasError = !lodash.isNil(formValidator?.errorText);
  const hasPlaceholder = !lodash.isEmpty(lodash.trim(placeholder));

  const id = useRef(uuidv4());

  return (
    <FormWrapper
      id={id.current}
      {...{ ...wrapperProps, hasError, errorText: formValidator?.errorText }}
    >
      <TextareaAutosize
        id={id.current}
        autoFocus={autoFocus}
        className={cx(
          "form-input",
          { "form-input-full-border": borderType === "full-border" },
          { "form-input-no-border": borderType === "no-border" },
          { "form-input-error": hasError },
          { "form-input-has-placeholder": hasPlaceholder }
        )}
        placeholder={placeholder}
        name={formValidator?.name}
        value={value}
        defaultValue={defaultValue}
        readOnly={readOnly}
        autoComplete={autoComplete}
        ref={formValidator?.inputRef}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          onChange && onChange(e.target.value);
        }}
      />
    </FormWrapper>
  );
};
