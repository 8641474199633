import {
  AlertConditionType,
  ConditionLimitType,
  IndicatorType,
} from "common/alert/alert-condition.info";
import { TimeRange } from "models/dto/stock.dto";
import { v4 as uuidv4 } from "uuid";
export class BaseAlertConditionModel {
  id: string;
  type: AlertConditionType;
}

export class PriceConditionModel extends BaseAlertConditionModel {
  targetPrice: number = 0;
  type: "price" = "price";
  limitType: ConditionLimitType = "above";

  constructor(limitType: ConditionLimitType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.limitType = limitType;
  }
}

export class MarketCapConditionModel extends BaseAlertConditionModel {
  targetMarketCap: number = 0;
  type: "marketCap" = "marketCap";
  limitType: ConditionLimitType = "above";

  constructor(limitType: ConditionLimitType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.limitType = limitType;
  }
}

export class PERatioConditionModel extends BaseAlertConditionModel {
  targetPERatio: number = 0;
  type: "peRatio" = "peRatio";
  limitType: ConditionLimitType = "above";

  constructor(limitType: ConditionLimitType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.limitType = limitType;
  }
}

export class VolumeConditionModel extends BaseAlertConditionModel {
  targetVolume: number = 0;
  type: "volume" = "volume";
  limitType: ConditionLimitType = "above";

  constructor(limitType: ConditionLimitType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.limitType = limitType;
  }
}

export class FiftyTwoHighConditionModel extends BaseAlertConditionModel {
  type: "fiftyTwoHigh" = "fiftyTwoHigh";
  constructor(oldId?: string) {
    super();
    this.id = oldId || uuidv4();
  }
}
export class FiftyTwoLowConditionModel extends BaseAlertConditionModel {
  type: "fiftyTwoLow" = "fiftyTwoLow";
  constructor(oldId?: string) {
    super();
    this.id = oldId || uuidv4();
  }
}

export class PercentChangeConditionModel extends BaseAlertConditionModel {
  type: "percentChange" = "percentChange";
  targetPercentChange: number = 0;
  basePrice: number = 0;
  constructor(oldId?: string) {
    super();
    this.id = oldId || uuidv4();
  }
}

export class IndicatorConditionModel extends BaseAlertConditionModel {
  targetStat: number = 0;
  range: TimeRange = "1d";
  input1?: number; // period
  input2?: number; // period
  input3?: number; // period
  input4?: number; // period

  type: "indicator" = "indicator";
  indicatorType: IndicatorType = "sma";
  limitType: ConditionLimitType = "above";

  constructor(indicatorType: IndicatorType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.indicatorType = indicatorType;
  }
}

export class TrailingPriceConditionModel extends BaseAlertConditionModel {
  type: "trailingPrice" = "trailingPrice";
  targetPrice: number;
  trailingTarget: number = 1;
  limitType: ConditionLimitType = "above";

  constructor(limitType: ConditionLimitType, oldId?: string) {
    super();
    this.id = oldId || uuidv4();
    this.limitType = limitType;
  }
}

type TAConditionModel = IndicatorConditionModel;
type GeneralConditionModel =
  | PriceConditionModel
  | PercentChangeConditionModel
  | MarketCapConditionModel
  | PERatioConditionModel
  | FiftyTwoHighConditionModel
  | FiftyTwoLowConditionModel
  | VolumeConditionModel;
type SpecialConditionModel = TrailingPriceConditionModel;

export type AlertConditionModel =
  | GeneralConditionModel
  | TAConditionModel
  | SpecialConditionModel;
