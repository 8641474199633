import {
  JournalManageModalState,
  JournalManageType,
} from "common/journal/journal-manage.info";
import { internalErrorMessage } from "common/standardMessage";
import { Action, action, actionOn, ActionOn } from "easy-peasy";
import { generateBaseJournal } from "generators/journal.generator";
import lodash from "lodash";
import { JournalDto } from "models/dto/journal/journal.dto";
import { Transaction } from "models/schema/transaction.model";
import { addOrRemoveItemFromList } from "utils/common.utils";
import { extractImgSrcFromString } from "utils/wysiwyg.util";
import { JournalManageModel } from "./journal-manage.store";

type TransactionType = "stock" | "option";
export interface JournalManageAction {
  // * Actions
  resetStore: Action<JournalManageModel, boolean | void>;
  // --- Entry level
  initalizeStore: Action<JournalManageModel, JournalDto | null>;
  clearDraft: Action<JournalManageModel>;

  // Setter
  setJournalName: Action<JournalManageModel, string>;
  setJournalNote: Action<JournalManageModel, string>;

  addImageSrc: Action<JournalManageModel, string>;
  addTag: Action<JournalManageModel, string>;
  removeTag: Action<JournalManageModel, string>;
  addTransaction: Action<
    JournalManageModel,
    {
      type: TransactionType;
      transaction: Transaction;
    }
  >;
  removeTransaction: Action<
    JournalManageModel,
    { type: TransactionType; id: string }
  >;
  addOrRemoveAlertId: Action<JournalManageModel, string>;

  // --- Listeners
  onDataChange: ActionOn<JournalManageModel>;
  onAddImgSrc: ActionOn<JournalManageModel>;

  // * --- UI
  setModalState: Action<JournalManageModel, JournalManageModalState>;
}

export const journalManageAction: JournalManageAction = {
  // * Actions
  resetStore: action((state, hardReset = true) => {
    state.originalEntry = null;
    state.entry = null;
    state.entryImageSrcList = [];
    state.manageType = JournalManageType.None;
    if (hardReset) {
      state.draft = null;
      state.draftImageSrcList = [];
    }
  }),
  // --- Entry level
  clearDraft: action((state) => {
    state.draft = null;
    state.draftImageSrcList = [];
  }),

  initalizeStore: action((state, entry) => {
    let newEntry: JournalDto;
    let originalEntry: JournalDto;
    let manageType: JournalManageType;
    let imageSrcList: string[] = [];

    if (entry) {
      newEntry = lodash.cloneDeep(entry);
      originalEntry = newEntry; // Use the given entry
      manageType = JournalManageType.Edit;
      imageSrcList = extractImgSrcFromString(newEntry.htmlNote);
    } else {
      // Make a general entry by default
      newEntry = state.draft || generateBaseJournal();
      imageSrcList = extractImgSrcFromString(newEntry.htmlNote);

      originalEntry = lodash.cloneDeep(newEntry);
      manageType = JournalManageType.Add;

      if (!state.draft) {
        state.draft = newEntry;
        state.draftImageSrcList = imageSrcList;
      }
    }

    state.originalEntry = originalEntry;
    state.entry = newEntry;
    state.manageType = manageType;
    state.entryImageSrcList = imageSrcList;
  }),

  // Setters
  setJournalName: action((state, title) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    state.entry.title = title;
  }),
  setJournalNote: action((state, htmlNote) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    state.entry.htmlNote = htmlNote;
  }),

  addImageSrc: action((state, newSrc) => {
    state.entryImageSrcList.push(newSrc);
  }),
  addTag: action((state, newTag) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    if (!state.entry.tagList?.find((tag) => lodash.isEqual(newTag, tag))) {
      state.entry.tagList?.push(newTag);
    }
  }),
  removeTag: action((state, tagToRemove) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    const tagList = state.entry.tagList;
    const foundIndex = tagList?.findIndex((tag) =>
      lodash.isEqual(tagToRemove, tag),
    );

    if (foundIndex >= 0) {
      tagList?.splice(foundIndex, 1);
    }
  }),

  addTransaction: action((state, { transaction, type }) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    let curList: Transaction[];
    switch (type) {
      case "option":
        curList = state.entry.transactionsMap.optionTransactionList;
        break;
      case "stock":
        curList = state.entry.transactionsMap.stockTransactionList;
        break;
    }

    const curIndex = curList.findIndex((entry) => entry.id === transaction.id);
    if (curIndex < 0) {
      curList.push(transaction);
    }
  }),
  removeTransaction: action((state, { id, type }) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    let curList: Transaction[];
    switch (type) {
      case "option":
        curList = state.entry.transactionsMap.optionTransactionList;
        break;
      case "stock":
        curList = state.entry.transactionsMap.stockTransactionList;
        break;
    }

    const curIndex = curList.findIndex((entry) => entry.id === id);
    if (curIndex >= 0) {
      curList.splice(curIndex, 1);
    }
  }),
  addOrRemoveAlertId: action((state, alertId) => {
    if (!state.entry) {
      throw new Error(internalErrorMessage.nullJournalEntry);
    }

    addOrRemoveItemFromList(state.entry.alertIdList, alertId);
  }),

  // --- Listeners
  onDataChange: actionOn(
    (actions) => [
      actions.setJournalName,
      actions.setJournalNote,

      actions.addTag,
      actions.removeTag,
      actions.addTransaction,
      actions.removeTransaction,
      actions.addOrRemoveAlertId,
    ],
    (state) => {
      if (state.manageType === JournalManageType.Add) {
        state.draft = state.entry;
      }
    },
  ),
  onAddImgSrc: actionOn(
    (actions) => [actions.addImageSrc],
    (state) => {
      if (state.manageType === JournalManageType.Add) {
        state.draftImageSrcList = state.entryImageSrcList;
      }
    },
  ),

  // * --- UI
  setModalState: action((state, modalState) => {
    state.modalState = modalState;
  }),
};
