import { executeBasedOnEnv } from "utils/env.utils";

const resourceLocationList = [
  "none/",
  "http://localhost:5001/stockwise-f24fe/us-central1/",
  "https://us-central1-stockwise-f24fe.cloudfunctions.net/",
] as const;
type ResourceLocationString = typeof resourceLocationList[number];

let resourceLocation: ResourceLocationString = resourceLocationList[0];
executeBasedOnEnv({
  devFunc: () => {
    resourceLocation = resourceLocationList[0];
  },
  uatFunc: () => {
    resourceLocation = resourceLocationList[1];
  },
  prdFunc: () => {
    resourceLocation = resourceLocationList[2];
  },
});

// * General endpoint information
const baseEndpointMap = {
  userInfo: resourceLocation + `userInfo/`,
  userWatchList: resourceLocation + `userWatchList/`,

  payment: resourceLocation + `payment/`,
  brokerage: resourceLocation + `brokerage/`,
  stock: resourceLocation + `stock/`,
  news: resourceLocation + `news/`,

  alert: resourceLocation + `alert/`,
  journal: resourceLocation + `journal/`,

  notification: resourceLocation + `notification/`,
  message: resourceLocation + `message/`,

  internal: resourceLocation + `internal/`,
  media: resourceLocation + `media/`,
} as const;

export type BaseEndpointKey = keyof typeof functionEndpointList;

export const userBrokerageEndpointMap = {
  unlinkBrokerage: {
    endpoint: baseEndpointMap.brokerage + "link/",
    method: "delete",
  },
  getBrokerageUser: {
    endpoint: baseEndpointMap.brokerage,
    method: "get",
  },
  getAnalyzedTransactionList: {
    endpoint: baseEndpointMap.brokerage + "analyzed/transactions/",
    method: "post",
  },
  getAnalyzedHoldingList: {
    endpoint: baseEndpointMap.brokerage + "analyzed/holdings/",
    method: "post",
  },
  // --- Plaid
  createPlaidLinkToken: {
    endpoint: baseEndpointMap.brokerage + "plaid/link-token",
    method: "get",
  },
  setPlaidAccessToken: {
    endpoint: baseEndpointMap.brokerage + "plaid/access-token",
    method: "post",
  },
} as const;

export const userInfoEndpointMap = {
  getCurrentUser: {
    endpoint: baseEndpointMap.userInfo,
    method: "get",
  },
  updateUserInfo: {
    endpoint: baseEndpointMap.userInfo,
    method: "put",
  },
} as const;

export const userWatchListEndpointMap = {
  getUserWatchList: {
    endpoint: baseEndpointMap.userWatchList,
    method: "get",
  },
  createUserWatchList: {
    endpoint: baseEndpointMap.userWatchList,
    method: "post",
  },
  updateUserWatchList: {
    endpoint: baseEndpointMap.userWatchList,
    method: "put",
  },
  deleteUserWatchList: {
    endpoint: baseEndpointMap.userWatchList,
    method: "delete",
  },
} as const;

export const alertEndpointMap = {
  getAlertList: {
    endpoint: baseEndpointMap.alert,
    method: "get",
  },
  createAlert: {
    endpoint: baseEndpointMap.alert,
    method: "post",
  },
  updateAlert: {
    endpoint: baseEndpointMap.alert,
    method: "patch",
  },
  deleteAlertList: {
    endpoint: baseEndpointMap.alert,
    method: "delete",
  },
  toggleAlertList: {
    endpoint: baseEndpointMap.alert + "toggle/",
    method: "post",
  },
  checkCondition: {
    endpoint: baseEndpointMap.alert + "check/condition",
    method: "post",
  },
} as const;

export const notificationEndpointMap = {
  markAllOfTypeAsRead: {
    endpoint: baseEndpointMap.notification,
    method: "patch",
  },
} as const;

export const messageEndpointMap = {
  markAllOfTypeAsRead: {
    endpoint: baseEndpointMap.message + "read/type/",
    method: "patch",
  },
} as const;

export const newsEndpointMap = {
  getNews: {
    endpoint: baseEndpointMap.news,
    method: "post",
  },
} as const;

export const stockEndpointMap = {
  getQuote: {
    endpoint: baseEndpointMap.stock + "quote/",
    method: "post",
  },
  getChartData: {
    endpoint: baseEndpointMap.stock + "chart/",
    method: "post",
  },
  getBatchChartData: {
    endpoint: baseEndpointMap.stock + "chart-batch/",
    method: "post",
  },
  searchCompanyInfo: {
    endpoint: baseEndpointMap.stock + "search/company/",
    method: "get",
  },
  getCompanyDetail: {
    endpoint: baseEndpointMap.stock + "get/company/detail/",
    method: "get",
  },
  getRatingForTicker: {
    endpoint: baseEndpointMap.stock + "get/rating/",
    method: "get",
  },
  getEarningForTicker: {
    endpoint: baseEndpointMap.stock + "get/earning/",
    method: "get",
  },
} as const;

export const paymentEndpointMap = {
  getBaseFeatures: {
    endpoint: baseEndpointMap.payment + "base-features/",
    method: "get",
  },
  getPlans: {
    endpoint: baseEndpointMap.payment + "plans/",
    method: "get",
  },
  subscribePlan: {
    endpoint: baseEndpointMap.payment + "plans/subscribe/",
    method: "post",
  },
  createPortalLink: {
    endpoint: baseEndpointMap.payment + "customer-portal/",
    method: "post",
  },
} as const;

export const internalEndpointMap = {
  sendFeedbackEmail: {
    endpoint: baseEndpointMap.internal + "feedback",
    method: "post",
  },
  sendContactEmail: {
    endpoint: baseEndpointMap.internal + "contact",
    method: "post",
  },
} as const;

export const mediaEndpointMap = {
  uploadImage: {
    endpoint: baseEndpointMap.media + "image",
    method: "post",
  },
  deleteImageFromUrlList: {
    endpoint: baseEndpointMap.media + "image",
    method: "delete",
  },
} as const;

export const journalEndpointMap = {
  createJournal: {
    endpoint: baseEndpointMap.journal,
    method: "post",
  },
  updateJournal: {
    endpoint: baseEndpointMap.journal,
    method: "put",
  },
  deleteJournalList: {
    endpoint: baseEndpointMap.journal,
    method: "delete",
  },
  getAllJournal: {
    endpoint: baseEndpointMap.journal + "get",
    method: "post",
  },
} as const;

const functionEndpointList = {
  ...userInfoEndpointMap,
  ...userWatchListEndpointMap,
  ...userBrokerageEndpointMap,

  ...journalEndpointMap,
  ...alertEndpointMap,

  ...messageEndpointMap,
  ...newsEndpointMap,
  ...stockEndpointMap,
  ...paymentEndpointMap,

  ...internalEndpointMap,
  ...mediaEndpointMap,
} as const;

export type EndpointKey = keyof typeof functionEndpointList;

export default functionEndpointList;
