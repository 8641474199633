import React from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import "styles/components/common/icon.sass";

interface IconInfo {
  icon: [string, string];
  className?: string;
  style?: object;
  spin?: boolean;
}

const customIconPrefix = "c";
const notDuoList = ["cio", "cis"];

export const Icon = ({ icon, className, style, spin = false }: IconInfo) => {
  return (
    <FontAwesomeIcon
      icon={icon as IconProp}
      className={cx(
        { "custom-icon": icon[0][0] === customIconPrefix },
        { "custom-icon-not-duo": notDuoList.includes(icon[0]) },
        className
      )}
      style={style}
      spin={spin}
    />
  );
};

export default Icon;
