import React from "react";
import { useHistory } from "react-router-dom";

import Icon from "./Icon";

interface BackButtonProp {
  backPath?: string;
  onClick?: () => void;
}

export const BackButton = ({ backPath, onClick }: BackButtonProp) => {
  const history = useHistory();
  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          backPath ? history.push(backPath) : history.goBack();
        }
      }}
    >
      <Icon icon={["fas", "chevron-left"]} />
    </button>
  );
};
