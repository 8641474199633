import cx from "classnames";
import {
  CardShimmer,
  ShimmerContainer,
} from "components/common/ContentShimmer";
import { NewsEntry } from "models";
import React from "react";
import "styles/components/common/NewsSection.sass";
import { Icon, InformationCard, NewsSummary, NewsSummaryPreset } from ".";

interface NewsSectionProps {
  title?: React.ReactNode;
  titleClassName?: string;
  isLoading?: boolean;
  newsList: NewsEntry[];
  onAddNewNewsClick?: Function;
  onRemoveSingleNewsClick?: (arg: NewsEntry) => void;
  onAddSingleNewsClick?: (arg: NewsEntry) => void;
  isRemovedCheck?: (news: NewsEntry) => boolean;
  isAddedCheck?: (news: NewsEntry) => boolean;
  newsSummaryPreset?: NewsSummaryPreset;
  showNewsPicture?: boolean;
}
export const NewsSection = ({
  title = "News Source",
  titleClassName,
  isLoading = false,
  newsList,
  onAddNewNewsClick,
  onRemoveSingleNewsClick,
  onAddSingleNewsClick,
  isRemovedCheck,
  isAddedCheck,
  newsSummaryPreset,
  showNewsPicture = true,
}: NewsSectionProps) => {
  return (
    <div className="news-section">
      <div className="section-header">
        <div className={cx("title", titleClassName)}>{title}</div>

        {onAddNewNewsClick && (
          <button type="button" onClick={() => onAddNewNewsClick()}>
            <Icon icon={["cio", "news"]} />
            <p>Add Source</p>
          </button>
        )}
      </div>

      {
        <ShimmerContainer
          shimmerComponent={
            <CardShimmer shimmerProps={{ useRandomInterval: true }} />
          }
          numShimmer={10}
          isReady={!isLoading}
        >
          {newsList.length > 0 ? (
            newsList.map((news, index) => (
              <NewsSummary
                newsEntry={news}
                key={`${index}-${news.title}`}
                showPicture={showNewsPicture}
                usePreset={newsSummaryPreset}
                onRemoveClick={onRemoveSingleNewsClick}
                onAddClick={onAddSingleNewsClick}
                isRemoved={isRemovedCheck && isRemovedCheck(news)}
                isAdded={isAddedCheck && isAddedCheck(news)}
              />
            ))
          ) : (
            <InformationCard
              usePreset="faded"
              onCardClick={() => {
                onAddNewNewsClick && onAddNewNewsClick();
              }}
            >
              No News So Far
            </InformationCard>
          )}
        </ShimmerContainer>
      }
    </div>
  );
};
