import { loadStripe } from "@stripe/stripe-js";
import { paymentService } from "api/service/payment.service";
import { routePathMap } from "common/routeList";
import { subscriptionPlanOrder } from "common/subscription.info";
import { action, Action, thunk, Thunk } from "easy-peasy";
import { BasePlanFeatureDto } from "models/dto/payment/base-plan-feature.dto";
import { SubscriptionPlanDto } from "models/dto/payment/subscription-plan.dto";
import { StoreModel } from "stores";
import { LoadingModel, loadingPlugin } from "stores/plugin/loading.plugin";
import { executeAsync } from "utils/api.util";
import { executeBasedOnEnv } from "utils/env.utils";

export enum PaymentSettingsLoading {
  planList,
  baseFeatureList,
  choosePlan,
  createPortalLink,
}
export interface PaymentSettingsModel
  extends LoadingModel<PaymentSettingsLoading> {
  // * State
  planList: SubscriptionPlanDto[];
  baseFeatureList: BasePlanFeatureDto[];

  // * Actions
  setPlanList: Action<PaymentSettingsModel, SubscriptionPlanDto[]>;
  setBaseFeatures: Action<PaymentSettingsModel, BasePlanFeatureDto[]>;

  // * Thunks
  fetchAllPlans: Thunk<PaymentSettingsModel, never, any, StoreModel>;
  fetchAllBaseFeatures: Thunk<PaymentSettingsModel, never>;
  subscribeToPlan: Thunk<PaymentSettingsModel, { planPriceId: string }>;
  navigateToCustomerPortal: Thunk<PaymentSettingsModel, never, any, StoreModel>;
}

export const paymentSettings: PaymentSettingsModel = {
  // * State
  planList: [],
  baseFeatureList: [],

  // * Actions
  setPlanList: action((state, planList) => {
    planList.sort((planA: SubscriptionPlanDto, planB: SubscriptionPlanDto) => {
      const planAOrder = subscriptionPlanOrder[planA.planKey];
      const planBOrder = subscriptionPlanOrder[planB.planKey];
      return planAOrder - planBOrder;
    });
    state.planList = planList;
  }),
  setBaseFeatures: action((state, baseFeatureList) => {
    state.baseFeatureList = baseFeatureList;
  }),

  // *Thunks
  fetchAllPlans: thunk(async (actions, _, { getState }) => {
    if (getState().planList.length > 0) {
      // Only fetch if not already fetched
      return;
    }

    await executeAsync({
      funcToExecute: async () => {
        const fetchPlanList = async () => {
          return await paymentService.getPlanList();
        };

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.planList,
          isLoading: true,
        });

        const fetchedPlanList = await executeBasedOnEnv({
          devFunc: () => [],
          uatFunc: fetchPlanList,
          prdFunc: fetchPlanList,
        });

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.planList,
          isLoading: false,
        });
        actions.setPlanList(fetchedPlanList);
      },
    });
  }),

  fetchAllBaseFeatures: thunk(async (actions, _, { getState }) => {
    if (getState().baseFeatureList.length > 0) {
      // Only fetch if not already fetched
      return;
    }

    await executeAsync({
      funcToExecute: async () => {
        const fetchBaseFeatures = async () => {
          return await paymentService.getBaseFeatureList();
        };

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.baseFeatureList,
          isLoading: true,
        });

        const fetchedBaseFeatures = await executeBasedOnEnv({
          devFunc: () => [],
          uatFunc: fetchBaseFeatures,
          prdFunc: fetchBaseFeatures,
        });

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.baseFeatureList,
          isLoading: false,
        });
        actions.setBaseFeatures(fetchedBaseFeatures);
      },
    });
  }),

  subscribeToPlan: thunk(async (actions, { planPriceId }) => {
    executeAsync({
      funcToExecute: async () => {
        if (!process.env.REACT_APP_STRIPE_API_KEY) {
          console.error("Missing Stripe API Key");
          return;
        }

        let sessionId: string = "";

        const createCheckoutSession = async () => {
          const result = await paymentService.createSubscribeCheckoutSession({
            stripePriceId: planPriceId,
            successUrl: window.origin + routePathMap.home,
            cancelUrl: window.location.href,
          });

          sessionId = result.sessionId;
        };

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.choosePlan,
          isLoading: true,
        });

        await executeBasedOnEnv({
          devFunc: () => {},
          uatFunc: createCheckoutSession,
          prdFunc: createCheckoutSession,
        });

        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
        const error = await stripe?.redirectToCheckout({
          sessionId,
        });

        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.choosePlan,
          isLoading: false,
        });

        if (error) {
          console.error(error);
        }
      },
    });
  }),

  navigateToCustomerPortal: thunk(async (actions, _none) => {
    executeAsync({
      funcToExecute: async () => {
        const getPortalLink = async (): Promise<string | undefined> => {
          const response = await paymentService.createCustomerPortalLink({
            returnUrl: window.location.href,
          });
          return response.portalLink;
        };

        const portalLink = await getPortalLink();

        if (!portalLink) {
          console.error("Error while creating portal link.");
        } else {
          window.location.assign(portalLink);
        }
      },
      setIsLoading: (isLoading) => {
        actions.setIsLoading({
          loadingType: PaymentSettingsLoading.createPortalLink,
          isLoading: isLoading,
        });
      },
    });
  }),

  // * Plugins
  ...loadingPlugin(),
};
