export const chartTimeRangeList = [
  "max",
  "5y",
  "2y",
  "1y",
  "ytd",
  "6m",
  "3m",
  "1m",
  "1mm",
  "1d",
  "5d",
  "5dm",
  "dynamic",
  "date",
] as const;
export type TimeRange = typeof chartTimeRangeList[number];
export const timeRangeMap: Partial<Record<TimeRange, string>> = {
  max: "All",
  "5y": "5 years",
  "2y": "2 years",
  "1y": "1 year",
  ytd: "Year to date",
  "6m": "6 months",
  "3m": "3 months",
  "1m": "1 months",
  "1mm": "30 minute intervals",
  "5d": "5 days",
  "5dm": "10 minute intervals",
};

export interface StockChartDto {
  symbol: string;
  timeRange: TimeRange;
  date?: string;
}

export interface StockQuoteDto {
  symbolList: string[];
}
