import { themeList } from "common/theme.info";
import { useEffect, useRef, useState } from "react";
import { useStoreState } from "stores";
/* Note: The import from scss will be in this form
  key: <type>_[light|dark]
  value: color string
 */
import mainTheme from "styles/main/colorPalette.module.scss";

const convertThemeMap = (colorMap: object) => {
  // First generate an empty result map
  const result: Record<string, Record<string, string>> = {};
  themeList.forEach((theme) => {
    result[theme] = {};
  });

  // Extract theme from the map
  for (let [colorThemeKey, color] of Object.entries(colorMap)) {
    const [colorKey, theme] = colorThemeKey.split("_"); // Split the light and dark
    result[theme][colorKey] = color;
  }

  return result;
};

export const useThemeColor = (colorKey: string) => {
  const theme = useStoreState((state) => state.theme.theme);

  // Use useRef to avoid re-render every single time
  const allColor = useRef(convertThemeMap(mainTheme));
  const [color, setColor] = useState<string>("#fff");

  useEffect(() => {
    if (theme) {
      setColor(allColor.current[theme][colorKey]);
    }
  }, [theme, colorKey]);

  return color;
};
