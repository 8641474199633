import { action, Action, persist, thunk, Thunk } from "easy-peasy";
import { StoreModel } from "stores/StoreFront";

type Subscripter = (() => void) | null;

export interface GlobalModel {
  // * State
  subscripterList: Subscripter[];

  // * Actions
  setSubscripterList: Action<GlobalModel, Subscripter[]>;

  // * Thunk
  initializeRequireLoginSection: Thunk<GlobalModel, never, any, StoreModel>;
  resetRequireLoginSection: Thunk<GlobalModel, never, any, StoreModel>;
}

export const global: GlobalModel = persist(
  {
    // * State
    subscripterList: [],

    // * Actions
    setSubscripterList: action((state, newList) => {
      state.subscripterList = newList;
    }),

    // * Thunk
    initializeRequireLoginSection: thunk((actions, _2, { getStoreActions }) => {
      const subscripterList: Subscripter[] = [];

      // Set up global store that requires account login
      subscripterList.push(...getStoreActions().notification.initializeStore());
      subscripterList.push(...getStoreActions().message.initializeStore());

      getStoreActions().watchList.initializeStore();
      getStoreActions().brokerage.initializeStore();

      actions.setSubscripterList(subscripterList);
    }),
    resetRequireLoginSection: thunk(
      (actions, _, { getState, getStoreActions }) => {
        getState().subscripterList.forEach((subscriptor) => {
          subscriptor?.();
        });

        getStoreActions().journalDetailView.resetStore();
        getStoreActions().journalDashboard.resetStore();
        getStoreActions().alertDashboard.resetStore();
        getStoreActions().journalManage.resetStore();
        getStoreActions().notification.resetStore();
        getStoreActions().alertManage.resetStore();
        getStoreActions().watchList.resetStore();
        getStoreActions().brokerage.resetStore();
        getStoreActions().setting.resetStore();
        getStoreActions().account.resetStore();
        getStoreActions().theme.resetStore();
        getStoreActions().news.resetStore();

        actions.setSubscripterList([]);
      }
    ),
  },
  {
    storage: "localStorage",
  }
);
