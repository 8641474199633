import cx from "classnames";
import { Icon } from "components/common";
import dayjs from "dayjs";
import { NotificationDto } from "models/dto/notification/notification.dto";
import React from "react";
import "styles/components/common/NotificationCard.sass";
import parse from "html-react-parser";

interface NotificationCardProps {
  notification: NotificationDto;
  type?: "flat" | "card";
}
export const NotificationCard = ({
  notification: { title, body, createDate, isRead, htmlContent },
  type = "flat",
}: NotificationCardProps) => {
  return (
    <div
      className={cx(
        "notification-card",
        { "notification-card-flat": type === "flat" },
        { "notification-card-card": type === "card" }
      )}
    >
      <h3 className="title">{title}</h3>

      <div className="body">{htmlContent ? parse(htmlContent) : body}</div>

      <div className="date">
        {!isRead && <Icon icon={["fas", "circle"]} className="active-icon" />}
        <p className="value">{dayjs(createDate).fromNow()}</p>
      </div>
    </div>
  );
};
