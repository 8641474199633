import { routePathMap } from "common/routeList";
import React from "react";
import { Link } from "react-router-dom";

import { Icon } from ".";

export const ToSettingButton = () => {
  return (
    <Link to={routePathMap.settingBase}>
      <Icon icon={["cio", "settings"]} />
    </Link>
  );
};
