import { GroupHiFiSvg } from "assets/images";
import React from "react";

interface ThankyouViewProps {
  extraText: JSX.Element;
}
export const ThankyouView = ({ extraText }: ThankyouViewProps) => {
  return (
    <div className="thankyou-view">
      <GroupHiFiSvg />

      <div>
        <h4>Thank you!</h4>
        {extraText}
      </div>
    </div>
  );
};
