export const stockTransactionFormName = {
  ticker: "ticker",
  price: "price",
  quantity: "quantity",
  transactionDate: "transactionDate",
  tradeType: "tradeType",
} as const;

export const optionTransactionFormName = {
  ticker: "ticker",
  transactionDate: "transactionDate",
  expirationDate: "expirationDate",
  premiumPrice: "premiumPrice",
  strikePrice: "strikePrice",
  contract: "contract",
  tradeType: "tradeType",
  optionType: "optionType",
} as const;

export const journalFormName = {
  title: "title",
  htmlNote: "htmlNote",
} as const;

export enum JournalManageType {
  None,
  Edit,
  Add,
}

export enum JournalLoadingType {
  FetchingAlertList,
  FetchingChart,
  FetchingJournalData,
}

export enum JournalManageModalState {
  Hidden,
  LinkAlert,
  AddTransaction,
}
